import React from 'react';
import { FacultyCard } from './FacultyCard';
import { useSelector } from 'react-redux';
import { Loader, NothingFound } from '@components/shared';


export const FacultyBody = () => {
  const instructors = useSelector((state) => state.faculty.instructors);

  return (
    <div className="faculty__body">
      {instructors.loading && <Loader global />}

      {instructors.data.map((instructor) => (
        <div key={instructor.id} className="faculty__item">
          <FacultyCard instructor={instructor} />
        </div>
      ))}
      {!instructors.loading && !instructors.data.length && (
        <NothingFound
          title="For now here are no Faculty for the selected filters."
          subtitle="Please look up with another filter options or just clear the filters."
        />
      )}
    </div>
  );
};