import React from 'react';
import { insertClassName } from '@src/utils';

export const Badge = ({ className, children }) => {
  return (
    <div
      title={typeof children === 'string' ? children : ''}
      className={`badge${insertClassName(className, className)}`}
    >
      {children}
    </div>
  );
};