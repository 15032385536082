export const transformLink = (link, mail) => {
  if (mail) {
    return `mailto:${link}`
  } else {
    if (link.includes('https://') || link.includes('http://'))  {
      return link
    } else {
      return `https://${link}`
    }
  }
};