import { apiRequest, requestReduxHandler } from '@src/utils';
import {
  normalizeDropinRegData,
  normalizeBlockRegData,
  normalizeScaleRegData,
  normalizePreRegData,
} from '@src/normalizers';
import {
  onError,
  onLoading,
  onSuccess,
  getAction,
} from '@src/store/actionCreators';
import * as types from '@types/registrationTypes';
import { requestCallback, openLink } from '@src/utils';

export const getDropinSlots = (sessionId, isFree) => () => {
  apiRequest({
    url: `/sessions/${sessionId}/slots?type=${isFree ? 'free' : 'drop-in'}`,
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.REG_SLOTS, res.data),
      () => onLoading(types.REG_SLOTS_LOADING),
      (err) => onError(types.REG_SLOTS_ERROR, err)
    ),
  });
};

export const getDropinOrderData = (data) => () => {
  apiRequest({
    url: `/orders/drop-in-registration/total`,
    method: 'POST',
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.ORDER_DATA, res.data),
      () => onLoading(types.ORDER_DATA_LOADING),
      (err) => onError(types.ORDER_DATA_ERROR, err)
    ),
    data: JSON.stringify(normalizeDropinRegData(data)),
  });
};

export const getScaleSlots = (sessionId) => () => {
  apiRequest({
    url: `/sessions/${sessionId}/slots?type=sliding-scale`,
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.REG_SLOTS, res.data),
      () => onLoading(types.REG_SLOTS_LOADING),
      (err) => onError(types.REG_SLOTS_ERROR, err)
    ),
  });
};

export const getScalePrice = (data) => () => {
  apiRequest({
    url: `/orders/sliding-scale-registration/total`,
    method: 'POST',
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.ORDER_DATA, res.data),
      () => onLoading(types.ORDER_DATA_LOADING),
      (err) => onError(types.ORDER_DATA_ERROR, err)
    ),
    data: JSON.stringify(normalizeScaleRegData(data)),
  });
};

export const getBlockSlots = (sessionId) => () => {
  apiRequest({
    url: `/sessions/${sessionId}/slots?type=all`,
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.REG_SLOTS, res.data),
      () => onLoading(types.REG_SLOTS_LOADING),
      (err) => onError(types.REG_SLOTS_ERROR, err)
    ),
  });
};

export const getBlockSelectedSlots = (sessionId, blockId, startDate) => () => {
  apiRequest({
    url: `/sessions/${sessionId}/slots?type=block&block_id=${blockId}&start_date=${startDate}`,
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.BLOCK_SELECTED_SLOTS, res.data),
      () => onLoading(types.BLOCK_SELECTED_SLOTS_LOADING),
      (err) => onError(types.BLOCK_SELECTED_SLOTS_ERROR, err)
    ),
  });
};

export const getBlockOrderData = (reqData) => () => {
  apiRequest({
    url: `/orders/block-registration/total`,
    method: 'POST',
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.ORDER_DATA, res.data),
      () => onLoading(types.ORDER_DATA_LOADING),
      (err) => onError(types.ORDER_DATA_ERROR, err)
    ),
    data: JSON.stringify(normalizeBlockRegData(reqData)),
  });
};

export const getBlockStartDates = (id) => () => {
  apiRequest({
    url: `/blocks/${id}/start-dates`,
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.BLOCK_START_DATES, res.data),
      () => onLoading(types.BLOCK_START_DATES_LOADING),
      (err) => onError(types.BLOCK_START_DATES_ERROR, err)
    ),
  });
};

export const getPreRegSlots = (sessionId) => () => {
  apiRequest({
    url: `/sessions/${sessionId}/slots?type=pre-reg`,
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.REG_SLOTS, res.data),
      () => onLoading(types.REG_SLOTS_LOADING),
      (err) => onError(types.REG_SLOTS_ERROR, err)
    ),
  });
};

export const getPreRegOrderData = (reqData) => () => {
  apiRequest({
    url: `/orders/pre-registration/total`,
    method: 'POST',
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.ORDER_DATA, res.data),
      () => onLoading(types.ORDER_DATA_LOADING),
      (err) => onError(types.ORDER_DATA_ERROR, err)
    ),
    data: JSON.stringify(normalizePreRegData(reqData)),
  });
};

export const getVideosOrderData = (reqData) => () => {
  apiRequest({
    url: `/orders/video-purchase/total`,
    method: 'POST',
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.ORDER_DATA, res.data),
      () => onLoading(types.ORDER_DATA_LOADING),
      (err) => onError(types.ORDER_DATA_ERROR, err)
    ),
    data: JSON.stringify(reqData),
  });
};

export const donate = (donationData) => () => {
  apiRequest({
    url: '/donations',
    method: 'POST',
    data: JSON.stringify(donationData),
    callbacks: requestCallback((res) => {
      openLink(res.data.redirect_link);
    }),
  });
};

export const getTicketsOrderData = (data) => () => {
  apiRequest({
    url: `/tickets/price`,
    method: 'POST',
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.ORDER_DATA, res.data),
      () => onLoading(types.ORDER_DATA_LOADING),
      (err) => onError(types.ORDER_DATA_ERROR, err)
    ),
    data: JSON.stringify(data),
  });
};

export const getTrialSlots = (sessionId) => () => {
  apiRequest({
    url: `/sessions/${sessionId}/slots?type=trial`,
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.REG_SLOTS, res.data),
      () => onLoading(types.REG_SLOTS_LOADING),
      (err) => onError(types.REG_SLOTS_ERROR, err)
    ),
  });
};

export const getTrialOrderData = (data) => () => {
  apiRequest({
    url: `/orders/trial-registration/total`,
    method: 'POST',
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.ORDER_DATA, res.data),
      () => onLoading(types.ORDER_DATA_LOADING),
      (err) => onError(types.ORDER_DATA_ERROR, err)
    ),
    data: JSON.stringify(normalizeDropinRegData(data)),
  });
};

export const getDonationOrderData = (data) => () => {
  apiRequest({
    url: `/donations/total`,
    method: 'POST',
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.ORDER_DATA, res.data),
      () => onLoading(types.ORDER_DATA_LOADING),
      (err) => onError(types.ORDER_DATA_ERROR, err)
    ),
    data: JSON.stringify(data),
  });
};

export const getManualOrderData = (orderId) => () => {
  apiRequest({
    url: `/orders/${orderId}`,
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.ORDER_DATA, res.data),
      () => onLoading(types.ORDER_DATA_LOADING),
      (err) => onError(types.ORDER_DATA_ERROR, err)
    ),
  });
};

export const setPaymentMethod = (method) =>
  getAction(types.PAYMENT_METHOD, method);

export const setPaymentLoading = (isLoading) =>
  getAction(types.PAYMENT_LOADING, isLoading);

export const clearRegData = () => getAction(types.CLEAR_REG_DATA);
