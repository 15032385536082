import React from "react";
import { Link, Image } from '@components/shared';
import { classNames } from '@src/utils';

export const ProviderLogo = ({ className, src, logoUrl }) => {
  return (
    <div
      className={classNames([
        'provider-logo', 
        className
      ])}
    >
      <Link href={logoUrl}>
        <Image 
          src={src}
          alt="provider logo" 
          loaderSize={'xs'}/>
          
      </Link>
    </div>
  );
};