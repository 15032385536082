import React, { useState } from 'react';
import { Loader, Icon, Button, NothingFound } from '@components/shared';
import { AccountTransactionsCard } from './AccountTransactionsCard';
import { useViewport, useApi } from '@src/hooks';

export const AccountTransactions = () => {
  const [pageCounter, setPageCounter] = useState(1);
  const { isMobile } = useViewport();

  const accountTransactions = useApi(
    {
      url: `/account/transactions?page=${pageCounter}`,
      reduce: true,
    },
    [pageCounter]
  );

  const renderNoOrdersBanner = () => {
    if (!accountTransactions.data) return;
    return (
      <NothingFound
        title="You haven't made any transactions yet"
        subtitle=""
        icon="wallet"
      />
    );
  };

  return (
    <div className="account-transactions">
      <h2 className="account__content-title">Transactions</h2>
      <Loader sticky show={accountTransactions.loading} />
      {accountTransactions?.data?.length ? (
        <>
          <div className="ftable">
            {!isMobile && (
              <div className="ftable__row account-transactions__table-heading">
                <div className="ftable__cell account-transactions__id">
                  <div className="ftable__cell-content">ID</div>
                </div>

                <div className="ftable__cell account-transactions__date">
                  <div className="ftable__cell-content">Date</div>
                </div>

                <div className="ftable__cell account-transactions__class-title">
                  <div className="ftable__cell-content">Title</div>
                </div>

                <div className="ftable__cell account-transactions__amount">
                  <div className="ftable__cell-content">Amount</div>
                </div>

                <div className="ftable__cell account-transactions__action">
                  <div className="ftable__cell-content"></div>
                </div>
              </div>
            )}
            {accountTransactions.data.map((transaction) => (
              <AccountTransactionsCard
                transaction={transaction}
                key={transaction.id}
              />
            ))}
          </div>
          {accountTransactions.hasNextPage && (
            <Button
              className="account__more-button"
              onClick={() => setPageCounter(pageCounter + 1)}
              iconRight={<Icon type="chevron" size={'sm'} />}
            >
              View More
            </Button>
          )}
        </>
      ) : (
        renderNoOrdersBanner()
      )}
    </div>
  );
};
