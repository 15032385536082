import React, { useState } from 'react';
import { updateUserData } from '@actions/authActions';
import { useForm } from '@src/hooks';
import { getProfileSchema } from '@src/utils';
import { normalizeUserData } from '@src/normalizers';
import { Button, Checkbox, InfoBox, Tooltip, Link } from '@components/shared';
import { AccountProfileCard } from '../../Supports/AccountProfileCard';
import { useDispatch } from 'react-redux';

export const MediaWaiver = ({
  editorState,
  setEditorState,
  institution,
  updateProviderAffiliation,
}) => {
  const [isMediaWaiverShown, setIsMediaWaiverShown] = useState(false);
  const dispatch = useDispatch();

  const {
    is_media_consent_and_waiver_agreed,
    content_provider,
    isGlobal,
    content_provider_id,
  } = institution || {};

  const { base_url, config } = content_provider || {};
  const { media_consent_waiver, support_email, support_phone_number } =
    config || {};

  const blockName = `mediaWaiver-${content_provider_id}`;
  const isMediaWaiver = editorState === blockName;

  const submitHandler = (values) => {
    const userData = normalizeUserData(values, 'mediaWaiver');
    isGlobal
      ? dispatch(updateUserData(userData))
      : updateProviderAffiliation(userData.student, content_provider_id);
  };

  const schema = getProfileSchema(editorState);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setErrors,
    clearValues,
  } = useForm({
    callback: submitHandler,
    schema,
  });

  const renderMediaWaiverNote = () => {
    return isMediaWaiver ? null : (
      <>
        You{' '}
        {is_media_consent_and_waiver_agreed
          ? 'already accepted'
          : 'did not accept'}{' '}
        the{' '}
        <Button
          onClick={() => setIsMediaWaiverShown(!isMediaWaiverShown)}
          className="account-profile__media-waiver-button"
          type="link"
          htmlType="button"
        >
          Media Waiver
        </Button>
        {isMediaWaiverShown && (
          <InfoBox height={150} className="info-box--media-waiver">
            {isGlobal ? (
              <p>
                I understand that occasional photography, video, and audio, may
                be taken during classes/workshops/events/other offerings, and
                used for archival, marketing, and social media purposes. When
                used for archival and reselling of digital recordings of
                classes/workshops/events/other offerings (to provide residual
                income for instructors/producers/faculty members, and for other
                charitable purposes) a specific authorization in the form of
                explicit permission will be needed. By signing the Media Consent
                and Waiver, I am giving permission only to be recorded with
                photography, video, and/or audio during
                classes/workshops/events/other offerings. I understand that I
                may reserve the right not to sign the Media Consent and Waiver
                or to change my preference at any time, in which case my image,
                and/or video, and/or audio, will no longer be taken going
                forward from the date I choose to decline the Media and Consent
                Waiver. This Media Consent and Waiver applies to offerings
                produced/facilitated by the World Education Web
                (worldeducationweb.org), a non-profit online platform created,
                owned, and developed by the Brookline/Cambridge Community Center
                for the Arts, Inc. (AKA BCCA / CCCA), while each content
                Provider that has an account in the World Education Web, may
                have their own individual media consent and waiver to govern the
                offerings it produces/facilitates. Additionally, the World
                Education Web (worldeducationweb.org) platform may use examples
                of photography, cover art, video, and audio from Provider
                offerings it hosts, to demonstrate the types and diversity of
                offerings in the platform for archival, marketing, and social
                media purposes.
              </p>
            ) : (
              <p>{media_consent_waiver}</p>
            )}
          </InfoBox>
        )}
      </>
    );
  };

  const renderSupportTooltip = () => (
    <Tooltip position="bottom">
      <span>Also you can directly email</span>
      <a href={`mailto:${support_email}`}>{support_email}</a>
      <span>or call {support_phone_number}</span>
    </Tooltip>
  );

  const renderWaiverSuggestion = () => {
    if (!editorState) {
      return is_media_consent_and_waiver_agreed ? (
        <span className="account-profile__subcription-note">
          If you want to decline please{' '}
          <span className="account-profile__subcription-support">
            <Link href={`${base_url}/contact`}>contact Support</Link>
            {renderSupportTooltip()}
          </span>
        </span>
      ) : (
        <span className="account-profile__subcription-note">
          If you want to accept please{' '}
          <Button onClick={() => setEditorState(blockName)} type="link">
            click here
          </Button>
        </span>
      );
    }
  };

  const mediaWaiverBlockData = [
    {
      label: 'I accept the Media Waiver',
      inputType: Checkbox,
      inputName: 'mediaWaiver',
      hide: !isMediaWaiver,
    },
  ];

  const profileBlocksData = {
    name: blockName,
    title: 'Media Waiver',
    data: mediaWaiverBlockData,
    infoText: renderMediaWaiverNote(),
    subscription: renderWaiverSuggestion(),
    editRestricted: true,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    editorState,
    setEditorState,
    clearValues,
    setErrors,
  };
  return <>{AccountProfileCard(profileBlocksData)}</>;
};
