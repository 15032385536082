import React from 'react';
import { Button, Icon, CourseBadges, Link } from '@components/shared';

export const CourseTitleBlockNew = ({ courseInfo, setView, view }) => {
  const { label, title, is_private } = courseInfo || {};

  const isVideos = courseInfo?.videos.length !== 0;
  const isRegistrations = courseInfo?.sessions.some(
    ({
      drop_in_registration,
      block_registration,
      sliding_scale_registration,
      pre_registration,
      free_registration,
      available_tickets,
      trial_registration,
    }) =>
      drop_in_registration ||
      block_registration ||
      sliding_scale_registration ||
      pre_registration ||
      free_registration ||
      available_tickets ||
      trial_registration
  );

  const onWatchVideoClick = () => {
    setView('multimedia');
  };

  const onRegisterClick = () => {
    setView('schedule');
  };

  const referrerPage = document.referrer;
  const isReferrerPage =
    referrerPage.includes('account') ||
    referrerPage.includes('schedule') ||
    referrerPage.includes('faculty') ||
    referrerPage.includes('offerings');

  const defineReferrerPageType = () => {
    if (referrerPage.includes('account')) return 'Account';
    if (referrerPage.includes('schedule')) return 'Schedule';
    if (referrerPage.includes('faculty')) return 'Faculty';
    else return 'Offerings';
  };

  const pageLink = () => {
    if (isReferrerPage) {
      return referrerPage;
    } else {
      return `${window.location.origin}/offerings`;
    }
  };

  return (
    <div className="offering-details__title-block offering-title-block">
      <div className="offering-title-block__top">
        <div className="breadcrumbs-block offering-title-block__breadcrumbs">
          <Link href={pageLink()} target="">
            {defineReferrerPageType()}
          </Link>
          <Icon type="chevron" size="xs" />
          <span>Offering details</span>
        </div>
      </div>

      <div className="offering-title-block__middle">
        {label && <span className="offering-title-block__id">{label}</span>}
        {<CourseBadges course={courseInfo} />}
      </div>

      <div className="offering-title-block__bottom">
        {title && <h1 className="page-title">{title}</h1>}

        <div className="offering-title-block__actions">
          {isVideos && view !== 'multimedia' && (
            <Button
              className="offering-details__watch-button"
              type="transparent"
              onClick={onWatchVideoClick}
            >
              Watch the Video(s)
            </Button>
          )}

          {view !== 'schedule' && isRegistrations && (
            <Button
              className="offering-details__register-button"
              type="primary"
              onClick={onRegisterClick}
            >
              {is_private ? 'Pay Studio Fee(s)' : 'Register'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
