import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '../index';
import { useViewport } from '@src/hooks';

export const AlphabetButtons = ({ onButtonClick, tabs }) => {
  const { isTablet } = useViewport();

  return (
    <>
      {isTablet && (
        <span className="alphabet-filter-tabs__label">Filter by alphabet:</span>
      )}
      <Tabs
        tabsData={tabs}
        onTabClick={onButtonClick}
        className="alphabet-filter-tabs"
        isShadowVisible={!isTablet}
      />
    </>
  );
};

AlphabetButtons.propTypes = {
  onButtonClick: PropTypes.func,
};
AlphabetButtons.defaultProps = {
  onButtonClick: () => {},
};