import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { normalizeUserData } from '@src/normalizers';
import { getModal, openModal, closeModal } from '@components/shared';
import { AffiliationInvitation } from './AffiliationInvitation';
import { updateProviderAffiliations } from '@actions/authActions';
import { openLink, queryParam, apiRequest, requestCallback } from '@src/utils';

export const ProviderAffiliationInvitation = ({ redirectTo }) => {
  const dispatch = useDispatch();
  const provider = useSelector((state) => state.provider.providerData.data);
  const providerId = queryParam.get('provider-affiliation-invitation');

  const submitHandler = (values) => {
    const userData = normalizeUserData(values, 'providerAffiliation');
    dispatch(updateProviderAffiliations(userData, provider.id, submitCallback));
  };

  const submitCallback = () => {
    redirectTo
      ? openLink(redirectTo)
      : closeModal('provider-affiliation-invitation');
  };

  useEffect(() => {
    if (providerId) {
      checkIsPolicyConfirmed(providerId);
    }
    return () =>
      providerId && queryParam.delete('provider-affiliation-invitation');
  }, []);

  const checkIsPolicyConfirmed = (providerId) => {
    apiRequest({
      url: `/users/affiliations/providers/${providerId}/check`,
      method: 'POST',
      callbacks: requestCallback(({ data }) => {
        data.is_confirmed && closeModal('provider-affiliation-invitation');
      }),
    });
  };

  const openPolicy = () => {
    openModal(
      getModal({
        name: 'policy-notice',
        data: {
          isModal: true,
          isGlobal: false,
          policyContent: provider.config.terms,
        },
      })
    );
  };

  const providerData = {
    organizationName: provider.name,
    waiverText: provider.config?.media_consent_waiver,
    waiverEnabled: provider.config?.media_consent_waiver_enabled,
    snailSubcriptionEnabled: provider.config?.snail_mail_subscription_enabled,
    emailSubcriptionEnabled:
      provider.config?.emails_newsletters_subscription_enabled,
    sourceSurveysEnabled: provider.config?.source_surveys_enabled,
  };

  return (
    <AffiliationInvitation
      submitHandler={submitHandler}
      openPolicy={openPolicy}
      organizationData={providerData}
      isGlobal={false}
    />
  );
};
