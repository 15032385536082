export const changeBodyScroll = (isBlocking, type) => {
  const body = document.body;

  if (isBlocking) {
    let scrollPosition = window.pageYOffset;
    body.classList.add(`${type}-block`);
    body.style.top = `-${scrollPosition}px`;
  } else {
    const position = body.getBoundingClientRect().top;
    body.classList.remove(`${type}-block`);
    window.scrollTo({
      top: -position,
      behavior: 'instant',
    });
  }
};
