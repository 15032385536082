import React from 'react';
import { Button, Icon, closeModal } from '@components/shared';

export const ContactSuccessModal = () => {
  return (
    <div className="auth">
      <div className="registration-step registration-step--email-verification">
        <div className="registration-step__container">
          <Icon className="auth__icon" type="confirm-email" />

          <h1 className="auth__title">Thanks for contacting us!</h1>

          <div className="registration-step__text">
            We will get in touch with you within the next hours.
          </div>

          <div className="registration-step__text">
            We look forward to assisting you.
          </div>

          <div className="form__group form__group--auth-buttons">
            <Button
              type="primary"
              onClick={() => closeModal('contact-success')}
            >
              Got it!
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
