export const addScriptAfterCookie = () => {
  // const script = document.createElement('script');
      
  // script.innerHTML = `
  //   var _paq = (window._paq = window._paq || []);
  //   /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  //   _paq.push(['trackPageView']);
  //   _paq.push(['enableLinkTracking']);
  //   (function () {
  //     var u = '//analytics.worldeducationweb.org/';
  //     _paq.push(['setTrackerUrl', u + 'matomo.php']);
  //     _paq.push(['setSiteId', '1']);
  //     var d = document,
  //       g = d.createElement('script'),
  //       s = d.getElementsByTagName('script')[0];
  //     g.type = 'text/javascript';
  //     g.async = true;
  //     g.src = u + 'matomo.js';
  //     s.parentNode.insertBefore(g, s);
  //   })();
  // `
  // document.body.appendChild(script);
}