import React from 'react';
import { getModal, openModal, Icon, Link } from '@components/shared';
import { Container } from '@components/layout';
import { Sessions } from '@components/features';
import { queryParam } from '@src/utils';

export const OfferingDetailsSchedule = ({ data, eventId,  }) => {
  const { is_private_rental, is_private, content_provider, content_type } =
    data;

  const onRegisterClick = (sessionId) => {
    openModal(
      getModal({
        name: 'registration',
        data: {
          offering: data,
          sessionId,
        },
      })
    );
  };

  const onTrialClick = (sessionId) => {
    queryParam.set('regType', 'tr');
    queryParam.set('regStep', 3);
    openModal(
      getModal({
        name: 'registration',
        data: {
          offering: data,
          sessionId,
        },
      })
    );
  };

  return (
    <div className="offering-details__tab-content offering-multimedia__schedule">
      <Container>
        {is_private && !is_private_rental && (
          <div className="offering-main__notification">
            <Icon type="info" size="md" />
            <span>
              This Offering is available for registation. Please see
              registration details in{' '}
              <Link href={`${content_provider.base_url}/offerings/${eventId}`}>
                Main Info
              </Link>
              .
            </span>
          </div>
        )}
        {is_private_rental && (
          <div className="offering-main__notification">
            <Icon type="info" size="md" />
            <span>
              This is a Private Studio Rental. If you are interested in renting
              facilities for your personal events, art events, rehearsals in{' '}
              {content_provider.name}, please check our rental policy{' '}
              <Link isRouterLink={true} to="/rentals">
                here
              </Link>
              .
            </span>
          </div>
        )}
        <Sessions
          onRegisterClick={onRegisterClick}
          onTrialClick={onTrialClick}
          offeringId={eventId}
          contentType={content_type}
          isPrivate={is_private}
        />
      </Container>
    </div>
  );
};
