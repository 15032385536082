import React from 'react';
import { format } from 'date-fns';
import { userTicketsDateFormat } from '@src/constants';
import { Loader, NothingFound } from '@components/shared';
import { zonedDate } from '@src/utils';
import { AccountTicketsCard } from './AccountTicketsCard';
import { useApi } from '@src/hooks';
import { AccountTicketsNavigation } from './AccountTicketsNavigation';

export const AccountTickets = () => {
  const tickets = useApi({ url: '/account/tickets' }),
    { data, loading } = tickets || {},
    { Past, Upcoming } = data || {};

  const getDateBlocks = (block) => {
    return Object.entries(block).map(([date, tickets]) => (
      <div className="account-tickets__date-block" key={date}>
        <h3 className="account-tickets__date">
          {format(zonedDate(date), userTicketsDateFormat)}
        </h3>
        {renderTickets(tickets)}
      </div>
    ));
  };

  const renderTickets = (tickets) => {
    return tickets.map((ticket) => (
      <AccountTicketsCard ticketData={ticket} key={ticket.id} />
    ));
  };

  const NoTicketsBlock = ({ isUpcoming }) => (
    <NothingFound
      title={`You currently don't have any tickets for ${
        isUpcoming ? 'upcoming' : 'past'
      } offerings.`}
      icon="tickets-account"
      subtitle=""
    />
  );

  return (
    <div className="account-tickets">
      <h2 className="account__content-title">Tickets</h2>
      <Loader sticky show={loading} />
      <AccountTicketsNavigation
        upcomingTickets={
          Upcoming ? (
            <>{getDateBlocks(Upcoming)}</>
          ) : (
            !loading && <NoTicketsBlock isUpcoming={true} />
          )
        }
        pastTickets={
          Past ? (
            <>{getDateBlocks(Past)}</>
          ) : (
            !loading && <NoTicketsBlock isUpcoming={false} />
          )
        }
      />
    </div>
  );
};
