import React from 'react';
import { ProgramsCourseCard } from '@components/features';

export const CoursesTitles = ({ courses }) => {
  return (
    <>
      {courses.data.map(course => (
        <ProgramsCourseCard 
          className={"programs-course--programs"} 
          course={course} 
          key={course.id} 
          showScheduleBlock={true}
        />
      ))}
    </>
  );
};