import React from 'react';
import { Icon, InstructorName } from '@components/shared';
import { CourseBadges } from '@components/shared/Course/CourseBadges';
import { registerTypes, enrollmentTypes } from '@src/constants';

export const RegistrationTitleBlock = ({ classDetails, regStep, regType }) => {
  const { label, title, location, type, is_onsite } = classDetails || {};
  const { name, address } = location;
  const { address_1, city } = address;

  return (
    <div className="registration-details__title-wrapper">
      <div className="registration-details__contact-wrapper">
        <div className="offering-title-block__middle">
          {label && <span className="offering-title-block__id">{label}</span>}
          <CourseBadges course={classDetails} />
          <div className="class-details__class-types">
            <span className="class-details__class-type">
              {enrollmentTypes[type]}
            </span>
            {regType && regStep > 1 && (
              <span className="class-details__class-type">
                {' '}
                | {registerTypes[regType]}
              </span>
            )}
          </div>
        </div>
        {title && <h3 className="offering-title-block__title">{title}</h3>}
        <InstructorName
          course={classDetails}
          className="offering-title-block__subtitle"
          hasLink={false}
        />
        {location && is_onsite && (
          <div className="registration-details__location">
            <div className="registration-details__icon">
              <Icon type="map-marker" size="md" />
            </div>
            <span className="registration-details__name">
              {name} – {address_1}, {city}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
