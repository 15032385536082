import { queryParam } from '@src/utils';
import { apiUrl } from '@src/constants';

export const PROVIDER_CURRENT_URL = () =>
  `${apiUrl}/providers/current`;

export const PROVIDER_INFO_URL = (id) =>
  `${apiUrl}/providers/${id}`;

export const PROVIDER_PAYMENT_METHODS_URL = (id) =>
  `${apiUrl}/providers/${id}/payment-methods`;

  export const PROVIDERS_LIST_URL = (search, sortingBy, sortingOrder) => 
  `/providers${queryParam.generateQuery([
    {
      label: 'search',
      value: search,
    },
    {
      label: 'sort_by',
      value: sortingBy,
    },
    {
      label: 'sort',
      value: sortingOrder,
    },
  ])}`
