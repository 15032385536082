import { encode, decode } from 'js-base64';
import { history } from '../../history';

export const queryParam = {
  encode: (data) => encode(JSON.stringify(data)),
  decode: (data) => JSON.parse(decode(data)),
  set: (name, data) => {
    const query = new URLSearchParams(window.location.search);
    if (Array.isArray(data)) {
      data.forEach((value) => query.append(name, value));
    } else {
      query.set(name, data);
    }

    const queryString = query.toString();

    history.replace({
      pathname: window.location.pathname,
      search: queryString ? `?${queryString}` : '',
    });
  },
  setEntries: (data) => {
    const query = new URLSearchParams(window.location.search);

    Object.entries(data).forEach(([name, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => query.append(name, item));
      } else {
        query.set(name, value);
      }
    });

    const queryString = query.toString();

    history.replace({
      pathname: window.location.pathname,
      search: queryString ? `?${queryString}` : '',
    });
  },
  delete: (data) => {
    const query = new URLSearchParams(window.location.search);

    if (Array.isArray(data)) {
      data.forEach((name) => query.delete(name));
    } else {
      query.delete(data);
    }

    const queryString = query.toString();

    history.replace({
      pathname: window.location.pathname,
      search: queryString ? `?${queryString}` : '',
    });
  },
  get: (name) => {
    const query = new URLSearchParams(window.location.search);
    return query.get(name);
  },
  getAll: (name) => {
    const query = new URLSearchParams(window.location.search);
    return query.getAll(name);
  },
  generateQuery: (queries, isNewQuery = true) => {
    const queriesPresent = queries.some(
      (query) => query.value || query.value === 0
    );

    if (!queriesPresent) return '';

    let string = isNewQuery ? '?' : '';
    let firstQueryAdded = !isNewQuery;

    queries.forEach((query) => {
      if (query.value) {
        string += `${firstQueryAdded ? '&' : ''}${query.label}=${query.value}`;
        firstQueryAdded = true;
      }
    });

    return string;
  },
};
