import React from 'react';
import { Container } from '@components/layout';
import {
  GlobalProvidersTitleBlock,
  GlobalProvidersItems,
} from '@components/global/Providers';
import { SEO } from '@components/shared';
import { globalWidgetLink } from '@src/constants';

const GlobalProviders = () => {
  return (
    <div className="global-page global-providers">
      <SEO
        title={`World Education Web | Providers`}
        description={`Explore our list of offering providers at the World Education Web, featuring basic information about each provider. Discover the talented individuals and organizations behind our diverse artistic offerings, including classes, workshops, and events.`}
        canonicalURL={`${globalWidgetLink}/providers`}
        image={'/img/wew-logo.svg'}
      />
      <Container>
        <div className="global-providers__body">
          <GlobalProvidersTitleBlock />
          <GlobalProvidersItems />
        </div>
      </Container>
    </div>
  );
};

export default GlobalProviders;