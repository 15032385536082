import React from 'react';
import { ContactTitleBlock } from '@components/features';

export const GlobalContactTitleBlock = () => {
  const locations = {
    list: [
      {
        title: 'Parent organization',
        subTitle: 'Brookline/Cambridge Community Center for the Arts, Inc. (BCCA, Inc. / CCCA)',
        address: '327 Saint Paul Street, Apt. 2, Brookline, MA 02446',
        mailAddress: '327 Saint Paul Street, Apt. 2, Brookline, MA 02446',
        phone_numbers: ['+1-202-555-0179', '+1-202-555-0194'],
        email: 'staff@cccaonline.org',
        socials: [
          { type: 'instagram', url: '#' },
          { type: 'tiktok', url: '#' },
          { type: 'twitter', url: '#' },
          { type: 'youtube', url: '#' },
          { type: 'facebook', url: '#' },
        ],
      },
    ],
  };

  return <ContactTitleBlock locations={locations} isGlobal={true} />;
};
