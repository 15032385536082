import { Container } from '@components/layout';
import { Button, Icon, Tooltip, Link } from '@components/shared';
import { useSelector } from 'react-redux';
import { globalWidgetLink } from '@src/constants';

export const ProviderFooter = ({ onDonationButtonClick, onPolicyClick }) => {
  const providerData = useSelector((state) => state.provider.providerData.data);
  const providerSocials = providerData.config?.social_networks;

  return (
    <footer className="footer">
      <div className="footer__body">
        <div className="footer__top">
          <Container>
            <div className="footer__top-body">
              <div className="footer__body-block footer__body-block--logo">
                <div className="footer__logo">
                  <Link
                    className="footer__link footer__link--logo"
                    href={providerData.base_url}
                  >
                    <img src={providerData.logo} alt="provider logo" />
                  </Link>
                </div>
              </div>

              <div className="footer__body-block--menu">
                <div className="footer__body-block footer__body-block--menu-column">
                  <Link
                    className="footer__link"
                    to={'/offerings'}
                    isRouterLink={true}
                  >
                    Offerings
                  </Link>

                  <Link
                    className="footer__link"
                    to={'/schedule'}
                    isRouterLink={true}
                  >
                    Schedule
                  </Link>
                </div>

                <div className="footer__body-block footer__body-block--menu-column">
                  <Link
                    className="footer__link"
                    to={'/about'}
                    isRouterLink={true}
                  >
                    About
                  </Link>

                  <Tooltip
                    position="bottom"
                    className="footer__donation-tooltip"
                    body={
                      <Button
                        className="footer__link"
                        onClick={onDonationButtonClick}
                      >
                        Donate
                      </Button>
                    }
                  >
                    Make a tax-deductible donation
                  </Tooltip>
                </div>

                <div className="footer__body-block footer__body-block--menu-column">
                  <Button
                    className="footer__link"
                    onClick={() => onPolicyClick(true)}
                  >
                    WEW Terms & Conditions
                  </Button>
                  <Button
                    className="footer__link"
                    onClick={() => onPolicyClick(false)}
                  >
                    {providerData.short_name} Terms & Conditions
                  </Button>
                </div>
                {!!providerSocials?.length && (
                  <div className="footer__body-block footer__body-block--menu-column">
                    <div className="footer__socials">
                      <Link
                        className="footer__link"
                        to={'/contact'}
                        isRouterLink={true}
                      >
                        Contact
                      </Link>

                      <div className="footer__socials-body">
                        {providerSocials.map(({ url, type }) => (
                          <Link
                            className={`footer__socials-link footer__socials-link--${type}`}
                            href={url}
                            key={url}
                          >
                            <Icon type={`socials--${type}`} size="lg" />
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>

        <div className="footer__bottom">
          <Container>
            <div className="footer__bottom-body">
              <div className="footer__body-block footer__body-block--logo">
                <div className="footer__logo ">
                  <Link
                    className="footer__link footer__link--logo footer__wew-link"
                    href={globalWidgetLink}
                  >
                    <span>powered by</span>
                    <img src="/img/wew-logo.svg" alt="wew-logo" />
                  </Link>
                </div>
              </div>

              <div className="footer__body-block footer__body-block--copyright">
                <div className="footer__copyright">
                  Copyright 2023 &#169; All rights reserved
                </div>
              </div>

              <div className="footer__body-block footer__body-block--payment">
                <div className="footer__payment">
                  <img src="/img/visa-logo.svg" alt="visa logo" />
                </div>

                <div className="footer__payment">
                  <img src="/img/master-card-logo.svg" alt="master-card logo" />
                </div>

                <div className="footer__payment">
                  <img
                    src="/img/american-express-logo.svg"
                    alt="american-express logo"
                  />
                </div>

                <div className="footer__payment">
                  <img src="/img/pay-pal-logo.svg" alt="pay-pal logo" />
                </div>

                <div className="footer__payment">
                  <img src="/img/venmo-logo.svg" alt="venmo logo" />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </footer>
  );
};
