import { format } from 'date-fns';
import { zonedDate } from '@src/utils';
import { allowedDatesEventsCalendarFormat } from '@src/constants';

export const getAllowedMonths = (slots) => {
  const allowedMonths = [];

  slots.forEach((slot) => {
    const { date } = slot;
    
    const formattedDate = format(zonedDate(date), allowedDatesEventsCalendarFormat);
    
    if (!allowedMonths.includes(formattedDate)) {
      allowedMonths.push(formattedDate);
    }
  });

  return allowedMonths;
};
