import React from 'react';
import {
  Registration,
  AuthForm,
  EmailVerification,
  EmailVerificationSuccess,
  ExpiredLinkNotification,
  LogoutRequestNotification,
  DonationModal,
  DirectionsModal,
  ReceiptModal,
  ReceiptSliderModal,
  ContactSuccessModal,
} from '@components/modals';
import { GlobalPolicy } from '@components/global';
import { ProviderPolicy } from '@components/provider';
import {
  GlobalAffiliationInvitation,
  ProviderAffiliationInvitation,
  PhotoPreview,
} from '@components/features';

export const getModal = (modal) => {
  const { name, data, nativeClose } = modal;

  switch (name) {
    case 'registration': {
      return {
        name: 'registration',
        body: <Registration {...data} />,
      };
    }

    case 'authModal': {
      return {
        name: 'registration',
        className: 'modal--narrow',
        body: <AuthForm {...data} />,
      };
    }

    case 'email-verification-success': {
      return {
        name: 'email-verification-success',
        className: 'modal--narrow',
        body: <EmailVerificationSuccess />,
      };
    }

    case 'email-verification': {
      return {
        name: 'email-verification',
        className: 'modal--narrow',
        body: <EmailVerification {...data} />,
      };
    }

    case 'expired-link-notification': {
      return {
        name: 'expired-link-notification',
        className: 'modal--narrow',
        body: <ExpiredLinkNotification {...data} />,
      };
    }

    case 'logout-request-notification': {
      return {
        name: 'logout-request-notification',
        className: 'modal--narrow',
        body: <LogoutRequestNotification {...data} />,
      };
    }

    case 'donation': {
      return {
        name: 'donation',
        className: 'modal--narrow',
        body: <DonationModal {...data} />,
      };
    }

    case 'directions': {
      return {
        name: 'directions',
        className: 'modal--narrow',
        body: <DirectionsModal {...data} />,
      };
    }

    case 'receipt': {
      return {
        name: 'receipt',
        className: 'modal--narrow',
        body: <ReceiptModal {...data} />,
      };
    }

    case 'receipt-slider': {
      return {
        name: 'receipt-slider',
        className: 'modal--narrow',
        body: <ReceiptSliderModal {...data} />,
      };
    }

    case 'policy-notice': {
      return {
        name: 'policy-notice',
        nativeClose,
        className: 'modal',
        body: data.isGlobal ? (
          <GlobalPolicy {...data} />
        ) : (
          <ProviderPolicy {...data} />
        ),
      };
    }

    case 'contact-success': {
      return {
        name: 'contact-success',
        className: 'modal--narrow',
        body: <ContactSuccessModal />,
      };
    }

    case 'global-affiliation-invitation': {
      return {
        name: 'global-affiliation-invitation',
        className: 'modal',
        body: <GlobalAffiliationInvitation {...data} />,
        nativeClose,
      };
    }

    case 'provider-affiliation-invitation': {
      return {
        name: 'provider-affiliation-invitation',
        className: 'modal',
        body: <ProviderAffiliationInvitation {...data} />,
        nativeClose,
      };
    }

    case 'photo-preview': {
      return {
        name: 'photo-preview',
        className: 'modal--wide',
        body: <PhotoPreview {...data} />,
      };
    }

    default:
      return null;
  }
};
