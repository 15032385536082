import React, { useState, useEffect } from 'react';
import { apiRequest } from '@src/utils';
import { Button, Icon, closeModal, Loader } from '@components/shared';

export const EmailVerification = ({ accessDeniedCase }) => {
  const [resendTimer, setResendTimer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const resendVerificationEmail = async () => {
    setIsLoading(true);
    await apiRequest({
      url: '/auth/email/verify/resend',
    });
    setIsLoading(false);
  };

  const onResendClick = () => {
    resendVerificationEmail();
    setResendTimer(60);
  };

  useEffect(() => {
    let timer = null;

    if (resendTimer) {
      timer = setTimeout(() => {
        setResendTimer((value) => value - 1);
      }, 1000);
    }

    if (resendTimer === 0) {
      setResendTimer(null);
    }
    return () => clearTimeout(timer);
  }, [resendTimer]);

  return (
    <div className="auth">
      <Loader sticky show={isLoading} />
      <div className="registration-step registration-step--email-verification">
        <div className="registration-step__container">
          <Icon type="confirm-email" className="auth__icon" />
          <h1 className="auth__title">
            {accessDeniedCase ? 'Ooops...' : 'Almost Done!'}
          </h1>
          <div className="registration-step__text">
            {accessDeniedCase
              ? "You haven't confirmed your email to be able to proceed."
              : "We've sent you a link to confirm your email address."}
          </div>
          <div className="registration-step__text">
            {accessDeniedCase
              ? 'Please check your inbox for confirmation email.'
              : 'Please check your inbox. It can take several minutes to show up in your inbox.'}
          </div>
          <div className="registration-step__text">
            Please click a link we've sent you in confirmation email to proceed.
          </div>
          <div className="form__group form__group--auth-buttons">
            <Button
              type="primary"
              onClick={() => closeModal('email-verification')}
            >
              Got it!
            </Button>
          </div>
          <div className="form__group form__group--resend">
            <div className="registration-step__text">
              Haven't received an email?
            </div>
            <Button
              disabled={!!resendTimer}
              type="transparent"
              onClick={onResendClick}
            >
              Resend Email {resendTimer && <>{resendTimer}s </>}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
