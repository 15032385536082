import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Icon } from '@components/shared';
import { classNames } from '@src/utils';

export const CheckboxesBranch = ({
  data,
  identifier,
  values = [],
  setCustomValue,
}) => {
  const [childsToShow, setChildsToShow] = useState([]);

  useEffect(() => {
    if (data.children) {
      getAllChildren(data);
    }
  }, [data]);

  const onCheckboxChange = (e, item) => {
    const { id, children, parents } = item;
    const isChecked = e.target.checked;
    let newValues = isChecked
      ? [...values, id]
      : values.filter((value) => value !== id);

    if (children) {
      newValues = checkChildren(children, newValues, isChecked);
    }

    if (parents) {
      newValues = checkParents(parents, newValues);
    }

    setCustomValue(identifier, newValues);
  };

  const checkParents = (parents, values) => {
    let checked = [...values];
    const sortedParents = parents.sort((a, b) => (a.level < b.level ? 1 : -1));

    sortedParents.forEach((parent) => {
      parent.children.every((child) => checked.includes(child.id))
        ? checked.push(parent.id)
        : (checked = checked.filter((id) => id !== parent.id));
    });

    return checked;
  };

  const checkChildren = (children, values, isChecked) => {
    const childrenChecked = [];

    (function getIds(children) {
      children.forEach((item) => {
        if (item.children) {
          getIds(item.children);
        }
        childrenChecked.push(item.id);
      });
    })(children);

    return isChecked
      ? [...values, ...childrenChecked]
      : values.filter((value) => !childrenChecked.includes(value));
  };

  const getAllChildren = (data) => {
    (function getChildren(data, parents = [data]) {
      data.children.forEach((child) => {
        if (child.children) {
          getChildren(child, [...parents, child]);
        }
        child.parents = parents;
      });
    })(data);
  };

  const hasCheckedChildren = (children) => {
    let checkedChildrenFlag = false;

    (function checkChildren(children) {
      children.forEach(({id, children}) => {
        if (values.includes(id)) {
          checkedChildrenFlag = true;
          return;
        } else if (children.length) {
          checkChildren(children);
        }
      });
    })(children);

    return checkedChildrenFlag;
  };

  const getGroup = (item) => {
    const { id, children, name } = item;
    const hasChildren = !!children.length;
    const isChildShown = childsToShow.includes(id);
    const isChecked = values.includes(id);
    const isMarked = hasChildren && !isChecked && hasCheckedChildren(children);

    return (
      <div className="form__group" key={id}>
        <div
          className='form__checkbox-wrapper'
        >
          {hasChildren && (
            <Button
              className={classNames([
                'form__checkbox-btn',
                isChildShown && 'form__checkbox-btn--open',
              ])}
              onClick={() =>
                isChildShown
                  ? setChildsToShow(childsToShow.filter((item) => item !== id))
                  : setChildsToShow([...childsToShow, id])
              }
            >
              <Icon type="chevron" size="sm" />
            </Button>
          )}
          <Checkbox
            name={identifier + id}
            onChange={(e) => onCheckboxChange(e, item)}
            value={isChecked}
            label={name}
            className={
              isMarked ? 'checkbox--has-checked-child' : ''
            }
          />
        </div>
        {isChildShown && children.map((child) => getGroup(child))}
      </div>
    );
  };

  return <>{getGroup(data)}</>;
};

CheckboxesBranch.propTypes = {
  data: PropTypes.object,
  identifier: PropTypes.string,
  values: PropTypes.array,
  setCustomValue: PropTypes.func,
};

CheckboxesBranch.defaultProps = {
  data: {},
  identifier: '',
  values: [],
  setCustomValue: () => {},
};
