import React from 'react';
import { ContactTitleBlockCard } from './ContactTitleBlockCard';

export const ContactTitleBlock = ({ locations, isGlobal }) => {
  const { title, list = [] } = locations;
  return (
    <div className="contact-title-block">
      {title && <h2 className="page-block-title contact-title-block__title">
        {title}
      </h2>}

      <div className="contact-title-block__body">
        {list.map((location) => (
          <ContactTitleBlockCard
            key={location.title}
            location={location}
            isGlobal={isGlobal}
          />
        ))}
      </div>
    </div>
  );
};
