import { useEffect, useState, useRef } from 'react';

export const useLazyLoaded = (container, { data, total }, callback) => {
  const [isLoading, setIsLoading] = useState(false);
  const canShowMore = useRef(false);

  let containerBottom = container?.getBoundingClientRect().bottom;

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [containerBottom]);

  useEffect(() => {
    if (!isLoading) return;

    callback();
  }, [isLoading]);

  useEffect(() => {
    canShowMore.current = data.length !== total;
  }, [data, total]);

  const handleScroll = (e) => {
    let containerHeight = container?.getBoundingClientRect().height;

    if (!canShowMore.current) {
      return;
    }

    if (window.innerHeight + window.scrollY < containerHeight) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
  };
};
