export const COURSES = `COURSES`;
export const COURSES_MORE = `COURSES_MORE`;
export const COURSES_LOADING = `COURSES_LOADING`;
export const COURSES_LOADING_MORE = `COURSES_LOADING_MORE`;
export const COURSES_ERROR = `COURSES_ERROR`;

export const PROGRAMS_FILTERS = `PROGRAMS_FILTERS`;
export const PROGRAMS_FILTERS_LOADING = `PROGRAMS_FILTERS_LOADING`;
export const PROGRAMS_FILTERS_ERROR = `PROGRAMS_FILTERS_ERROR`;

export const PROGRAMS_TAB = `PROGRAMS_TAB`;

export const PROGRAMS_SORTING_ORDER = `PROGRAMS_SORTING_ORDER`;

export const PROGRAMS_SORTING_BY = `PROGRAMS_SORTING_BY`;

export const PROGRAMS_FILTERS_SELECT = `PROGRAMS_FILTERS_SELECT`;
