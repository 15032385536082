import React, { useEffect, useState } from 'react';
import { Button, Tabs, Loader, NothingFound } from '@components/shared';
import { setActiveTab, createTab, getActiveTab, queryParam } from '@src/utils';
import { useApi } from '@src/hooks';
import { ProgramsCourseCard } from '@components/features';

export const InstructorDetailsOfferings = ({ instructorId }) => {
  const initTab = queryParam.get('activeTab');
  const [tabs, setTabs] = useState([
    createTab('Current', 'active', initTab ? initTab === 'active' : true),
    createTab('Past', 'past', initTab === 'past'),
  ]);

  const activeTab = getActiveTab(tabs);

  const getSessionsToShow = (sessions) =>
    sessions.slice(0, showOfferingsCounter);

  useEffect(() => {
    setSessionstoShow(4);
  }, [activeTab.value]);

  const offerings = useApi(
    {
      url: `/instructors/${instructorId}/courses?session_status=${activeTab.value}`,
      dataPlaceholder: [],
    },
    [activeTab.value]
  );

  const isActive = activeTab.value === 'active';
  const [showOfferingsCounter, setShowOfferingsCounter] = useState(4);
  const [sessionsToShow, setSessionstoShow] = useState([]);

  useEffect(() => {
    const newSessions = isActive
      ? offerings.data
      : getSessionsToShow(offerings.data);
    setSessionstoShow(newSessions);
  }, [offerings.data, showOfferingsCounter]);

  const canShowMore = offerings.data.length > sessionsToShow.length;

  const onShowMoreClick = () => {
    setShowOfferingsCounter(showOfferingsCounter + 4);
  };

  useEffect(() => {
    queryParam.set('activeTab', activeTab.value);

    return () => queryParam.delete('activeTab');
  }, [activeTab.value]);

  const getSessions = () =>
    sessionsToShow.length ? (
      sessionsToShow.map((item) => (
        <div className='instructor-details-offerings__item'>
          <ProgramsCourseCard course={item} key={item.id} />
        </div>
      ))
    ) : (
      <NothingFound
        title={
          isActive
            ? 'This Faculty does not have any current offerings now.'
            : 'This Faculty does not have any past offerings yet.'
        }
        subtitle={''}
      />
    );

  return (
    <div className="instructor-details-offerings">
      <h3 className="instructor-details-offerings__title">Offerings</h3>

      <Tabs
        tabsData={tabs}
        onTabClick={({ value }) => setActiveTab(setTabs, value)}
        className="tabs--bordered"
      />

      <div className="instructor-details-offerings__body">
        {offerings.loading ? <Loader attached /> : getSessions()}
      </div>

      {canShowMore && !isActive && (
        <div className="instructor-details-offerings__show-more">
          <Button onClick={onShowMoreClick}>Show more</Button>
        </div>
      )}
    </div>
  );
};
