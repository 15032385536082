import React from "react";
import { Container } from '@components/layout';
import { OfferingDetailsPresentersBioCard } from "./OfferingDetailsPresentersBioCard";

export const OfferingDetailsPresentersBio = ({ data }) => {
  const { custom_tabs } = data || {};
  const facultyList = custom_tabs.presenters_bio.custom_faculty;

  return (
    <div className="offering-details__tab-content offering-details__bio">
      <div className="offering-bio">
        <Container>
          <div className="offering-bio__body">
            {facultyList.map((faculty, index) => (
              <div className="offering-bio__item" key={index} >
                <OfferingDetailsPresentersBioCard  faculty={faculty} />
              </div>
            ))}
          </div>
        </Container>
      </div>
    </div>
  );
};