import { openModal, getModal } from '@components/shared';

export const openModalFromQuery = (query) => {
  const authModal = query.get('authModal');
  const emailVerificationSuccess = query.get('email-verification');
  const donationModal = query.get('donationModal');
  const providerAffilation = query.get('provider-affiliation-invitation');

  if (emailVerificationSuccess) {
    openModal(getModal({ name: 'email-verification-success' }));
  }

  if (authModal) {
    openModal(getModal({ name: 'authModal' }));
  }

  if (donationModal) {
    openModal(getModal({ name: 'donation' }));
  }

  if (providerAffilation) {
    openModal(getModal({ name: 'provider-affiliation-invitation' }));
  }
};
