import { useEffect, Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { getModal, openModal, Loader } from '@components/shared';
import { classNames } from '@src/utils';
import { Header, Footer } from '@components/layout';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentProvider } from '@actions/providerActions';
import { getUserData, clearUserData } from '@actions/authActions';
import { HelmetProvider } from 'react-helmet-async';

const Programs = lazy(() => import('@components/features/Programs/Programs'));
const Schedule = lazy(() => import('@components/features/Schedule/Schedule'));
const Faculty = lazy(() => import('@components/features/Faculty/Faculty'));
const OfferingDetails = lazy(() =>
  import('@components/features/OfferingDetails/OfferingDetails')
);
const InstructorDetails = lazy(() =>
  import('@components/features/InstructorDetails/InstructorDetails')
);
const Account = lazy(() => import('@components/features/Account/Account'));
const NotFoundPage = lazy(() =>
  import('@components/features/NotFoundPage/NotFoundPage')
);
const ServerErrorPage = lazy(() =>
  import('@components/features/ServerErrorPage/ServerErrorPage')
);
// const GlobalAbout = lazy(() => import('@components/global/About/GlobalAbout'));
const GlobalContact = lazy(() =>
  import('@components/global/Contact/GlobalContact')
);
const GlobalProviders = lazy(() =>
  import('@components/global/Providers/GlobalProviders')
);
const ProviderAbout = lazy(() =>
  import('@components/provider/About/ProviderAbout')
);
const ProviderContact = lazy(() =>
  import('@components/provider/Contact/ProviderContact')
);
const Rentals = lazy(() => import('@components/provider/Rentals/Rentals'));

export const MainContent = () => {
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const isGlobalWidget = useSelector((state) => state.provider.isGlobal);
  const student = useSelector((state) => state.auth.userData.data?.student);
  const isGlobalTermsAccepted = student?.is_terms_and_conditions_agreed;
  const provider = useSelector((state) => state.provider.providerData);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(getCurrentProvider());
  }, []);

  useEffect(() => {
    isAuthorized ? dispatch(getUserData()) : dispatch(clearUserData());
  }, [isAuthorized]);

  useEffect(() => {
    if (isAuthorized && student && !isGlobalTermsAccepted) {
      openGlobalAffiliationModal();
    }
  }, [isGlobalTermsAccepted, pathname]);

  const openGlobalAffiliationModal = () => {
    const { href } = window.location;
    openModal(
      getModal({
        name: 'global-affiliation-invitation',
        data: { redirectTo: href },
        nativeClose: false,
      })
    );
  };

  return (
    <HelmetProvider context={{}}>
      <Header
        isGlobalWidget={isGlobalWidget}
        hasRentalsPage={provider.data?.config?.rentals_page_enabled}
      />
      <main
        className={classNames([
          'content',
          !isGlobalWidget && 'content--provider',
        ])}
      >
        <Suspense fallback={<Loader sticky />}>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/offerings" />
            </Route>
            <Route path="/offerings" exact>
              <Programs />
            </Route>
            <Route path="/faculty" exact>
              <Faculty />
            </Route>
            <Route path="/schedule">
              <Schedule />
            </Route>
            <Route path="/account">
              {isAuthorized && isGlobalWidget ? (
                <Account />
              ) : (
                <Redirect to="/offerings" />
              )}
            </Route>
            <Route path="/offerings/:offeringId">
              <OfferingDetails />
            </Route>
            <Route path="/faculty/:instructorId">
              <InstructorDetails />
            </Route>
            {isGlobalWidget && (
              <Route path="/providers" exact>
                <GlobalProviders />
              </Route>
            )}
            <Route path="/rentals" exact>
              <Rentals />
            </Route>
            {!isGlobalWidget && (
              <Route path="/about" exact>
                {/* {isGlobalWidget ? <GlobalAbout /> : <ProviderAbout />} */}
                <ProviderAbout />
              </Route>
            )}
            <Route path="/contact" exact>
              {isGlobalWidget ? <GlobalContact /> : <ProviderContact />}
            </Route>
            <Router path="/server-error">
              <ServerErrorPage />
            </Router>
            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </Suspense>
      </main>
      <Footer isGlobalWidget={isGlobalWidget} />
    </HelmetProvider>
  );
};
