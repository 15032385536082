import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '@src/hooks';
import { Loader } from '@components/shared';
import { OfferingDetailsNavigation } from './OfferingDetailsNavigation';
import { queryParam, matomoOfferingHandler } from '@src/utils';
import {
  openModal,
  getModal,
  CourseTitleBlockNew,
  SEO,
} from '@components/shared';
import { useSelector } from 'react-redux';
import {
  OfferingDetailsMainInfo,
  OfferingDetailsSchedule,
  OfferingDetailsMultimedia,
  OfferingDetailsFaculty,
  OfferingDetailsPresentersBio,
  OfferingDetailsTimetable,
  OfferingDetailsAdditionalInfo,
} from '@components/features/OfferingDetails';

const OfferingDetails = () => {
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const { offeringId } = useParams();
  const offering = useApi({ url: `/courses/${offeringId}` }, [isAuthorized]);
  const initView = queryParam.get('offering-view') || 'main-info';
  const [view, setView] = useState(initView);

  matomoOfferingHandler(offeringId);

  useEffect(() => {
    const regStep = queryParam.get('regStep');
    if (regStep && offering.data) {
      openModal(
        getModal({
          name: 'registration',
          data: {
            sessionId: +queryParam.get('sessionId'),
            offering: offering.data,
          },
        })
      );
    }
  }, [offering.data]);

  const { content_provider, title, short_description, profile_image_url } =
    offering.data || {};

  const renderView = {
    'main-info': <OfferingDetailsMainInfo data={offering.data} setView={setView}/>,
    schedule: (
      <OfferingDetailsSchedule data={offering.data} eventId={offeringId} />
    ),
    multimedia: <OfferingDetailsMultimedia data={offering.data} />,
    'presenters-bio': <OfferingDetailsPresentersBio data={offering.data} />,
    faculty: <OfferingDetailsFaculty data={offering.data} />,
    timetable: <OfferingDetailsTimetable data={offering.data} />,
    'additional-info': <OfferingDetailsAdditionalInfo data={offering.data} />,
  };

  return (
    <div className="offering-details">
      {offering.loading || !offering.data ? (
        <Loader attached />
      ) : (
        <>
          <SEO
            title={`${content_provider.short_name} | ${title}`}
            description={short_description}
            canonicalURL={`${content_provider.base_url}/offerings/${offeringId}`}
            image={profile_image_url}
            type={'event'}
          />
          <div className="container">
            <CourseTitleBlockNew
              courseInfo={offering.data}
              setView={setView}
              view={view}
            />
            <OfferingDetailsNavigation
              courseInfo={offering.data}
              setView={setView}
              view={view}
            />
          </div>
          {renderView[view]}
        </>
      )}
    </div>
  );
};

export default OfferingDetails;
