export const zonedDate = (...initialDate) => {
	if (!initialDate) return null

	const date = new Date(initialDate);

	if (date === 'Invalid Date') return

	const offsetInMilliseconds = date.getTimezoneOffset() * 60000;
	const dateInMilliseconds = date.getTime();

	return new Date(offsetInMilliseconds + dateInMilliseconds)
};
