import React, { useState, useEffect } from 'react';
import { PasswordRecovery } from './PasswordRecovery';
import { Login } from './Login';
import { Signup } from './Signup';
import { changeModalProps } from '@components/shared';
import { queryParam, classNames } from '@src/utils';

export const AuthForm = ({
  btnCallbacks,
  isCourseReg,
  proceedLink,
}) => {
  const [isNewUser, setIsNewUser] = useState(false);
  const isPassRecoveryInit = queryParam.get('passRecoveryStep');
  const [isPasswordRecoveryShown, setIsPasswordRecoveryShown] = useState(isPassRecoveryInit);

  useEffect(() => {
    changeModalProps('registration', {
      className: `modal--narrow`,
    });

    return () => {
      changeModalProps('registration', {
        className: `modal`,
      });
    };
  }, []);

  useEffect(() => {
    if (!isCourseReg) {
      queryParam.set('authModal', true);
    }

    return () => {
      queryParam.delete(['authModal']);
    };
  }, []);

  return (
    <>
      <div
        className={classNames([
          'auth',
          isPasswordRecoveryShown && 'auth--hidden',
        ])}
      >
        {isNewUser ? (
          <Signup
            setIsNewUser={setIsNewUser}
            btnCallbacks={btnCallbacks}
            isCourseReg={isCourseReg}
            onPassRecoveryClick={() => setIsPasswordRecoveryShown(true)}
            proceedLink={proceedLink}
          />
        ) : (
          <Login
            setIsNewUser={setIsNewUser}
            btnCallbacks={btnCallbacks}
            isCourseReg={isCourseReg}
            onPassRecoveryClick={() => setIsPasswordRecoveryShown(true)}
            proceedLink={proceedLink}
          />
        )}
      </div>
      {isPasswordRecoveryShown && (
        <PasswordRecovery
          proceedLink={proceedLink}
          onBackBtnClick={() => setIsPasswordRecoveryShown(false)}
        />
      )}
    </>
  );
};
