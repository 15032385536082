import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon, Input, ErrorMessage } from '@components/shared';
import {
  useDeepCallback,
  useDeepDidUpdate,
  useDeepMemo,
  useDidUpdate,
  useViewport,
} from '@src/hooks';
import { SelectList } from './SelectList';
import { classNames, searchDefault, formatErrorMessage } from '@src/utils';
import _ from 'lodash';

export const Select = (props) => {
  const {
    className,
    options,
    optionsValue,
    optionsLabel,
    optionsKey,
    label: title,
    placeholder,
    disabled,
    onChange,
    value,
    alert,
    enableClear,
    searchable,
    searchFunc,
    required,
    valueLabel,
  } = props;

  const valueFromOptions = useDeepMemo(
    () =>
      options.find((option) => {
        if (value && (value[optionsValue] || value[optionsValue] === 0)) {
          return option[optionsValue] === value[optionsValue];
        }
        if (value && !value[optionsValue]) {
          return _.isEqual(option[optionsValue], value);
        }

        return false;
      }),
    [options, value]
  );
  const { isTablet } = useViewport();
  const [selectExpand, setSelectExpand] = useState(false);
  const [label, setLabel] = useState(
    valueFromOptions ? valueFromOptions[optionsLabel] : '...'
  );
  const [search, setSearch] = useState('');

  const selectListRef = useRef(null);
  const selectInputRef = useRef(null);
  const selectSearchInputRef = useRef(null);

  useDeepDidUpdate(() => {
    valueFromOptions && setLabel(valueFromOptions[optionsLabel]);
  }, [options, value]);

  useEffect(() => {
    if (!value) {
      setSearch('');
    }
  }, [value]);

  useDidUpdate(() => {
    if (selectListRef.current && isTablet) {
      selectListRef.current.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  }, [selectExpand]);

  const onSelectOptionClick = useDeepCallback(
    (value) => {
      const isDefaultValue = value.name === 'Clear selection';
      if (isDefaultValue) {
        options.shift();
      }
      onChange(isDefaultValue ? '' : value);
      setLabel(isDefaultValue ? '' : value[optionsLabel]);
    },
    [selectInputRef.current, options]
  );

  const onDocumentClick = (e) => {
    if (
      e.target !== selectSearchInputRef.current &&
      e.target !== selectInputRef.current
    ) {
      closeSelect();
    }
  };

  const openSelect = () => {
    setSelectExpand(true);
    document.addEventListener('click', onDocumentClick);
  };

  const closeSelect = () => {
    setSelectExpand(false);
    document.removeEventListener('click', onDocumentClick);
  };

  const toggle = (e) => {
    if (!selectExpand) {
      openSelect();
    } else if (selectExpand && e.target !== selectSearchInputRef.current) {
      closeSelect();
    }
  };

  const renderList = () => {
    let renderOptions = options;

    if (searchable) {
      if (searchFunc) {
        renderOptions = searchFunc(options, search);
      } else {
        renderOptions = searchDefault(options, search, optionsLabel);
      }
    }

    return (
      selectExpand && (
        <SelectList
          ref={selectListRef}
          options={renderOptions}
          optionsKey={optionsKey}
          optionsLabel={optionsLabel}
          optionsValue={optionsValue}
          value={value}
          onSelectOptionClick={onSelectOptionClick}
          enableClear={enableClear}
        />
      )
    );
  };

  const renderSearch = () => {
    return (
      <div className="select__search">
        <Input
          className="select__search-input"
          inputClassName="select__search-input-element"
          value={search}
          unnamed
          placeholder="Search"
          onChange={(e) => setSearch(e.target.value)}
          ref={selectSearchInputRef}
        />
      </div>
    );
  };

  return (
    <div
      className={classNames([
        'select',
        selectExpand && 'select--expand',
        disabled && 'select--disabled',
        className,
      ])}
      onClick={(e) => toggle(e)}
    >
      {title ? (
        <span
          className={classNames(['input__label', required && 'required-mark'])}
        >
          {title}
        </span>
      ) : null}

      <div className="select__input-container">
        <div
          className={classNames([
            'input__element select-input',
            alert && 'input__element--has-error',
          ])}
          tabIndex="0"
          ref={selectInputRef}
        >
          {value ? (
            <span className="select__value">
              {valueLabel ? `${valueLabel} ${label}` : label}
            </span>
          ) : (
            <span className="select__placeholder">{placeholder}</span>
          )}
        </div>

        {!disabled && (
          <Icon type="chevron" className="select__chevron" size="sm" />
        )}
        <div className="select__inner">
          {searchable && renderSearch()}
          {renderList()}
        </div>
      </div>
      <ErrorMessage text={formatErrorMessage(title, alert)} show={!!alert} />
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  optionsValue: PropTypes.string.isRequired,
  optionsKey: PropTypes.string.isRequired,
  optionsLabel: PropTypes.string.isRequired,
  optionsLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  searchable: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  enableClear: PropTypes.bool,
  alert: PropTypes.string,
  searchFunc: PropTypes.func,
};

Select.defaultProps = {
  label: '',
  handleChange: () => {},
  className: '',
  options: [],
  optionsValue: 'value',
  optionsLabel: 'label',
  optionsKey: 'id',
  disabled: false,
  placeholder: 'Choose',
  searchable: false,
  onChange: () => {},
  enableClear: false,
  alert: '',
};
