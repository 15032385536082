import { useCallback, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import { useDidUpdate } from '@src/hooks/useDidUpdate';

const useDeepCompareMemoize = value => {
  const ref = useRef([]);

  if (!_.isEqual(ref.current, value)) {
    ref.current = value;
    return value;
  }

  return ref.current;
};

export const useDeepEffect = (callback, deps) =>
  useEffect(callback, useDeepCompareMemoize(deps));

export const useDeepMemo = (callback, deps) =>
  useMemo(callback, useDeepCompareMemoize(deps));

export const useDeepCallback = (callback, deps) =>
  useCallback(callback, useDeepCompareMemoize(deps));

export const useDeepDidUpdate = (callback, deps) =>
  useDidUpdate(callback, useDeepCompareMemoize(deps));
