export const searchWithSeparator = (
  options,
  search,
  optionName,
  separator = ' '
) =>
  options.filter(option => {
    if (!search) return true;

    const splittedSearch = search.split(separator);
    let coincidences = [];

    splittedSearch.forEach(word => {
      if (word === '') coincidences.push(true);
      if (
        word.trim() &&
        option[optionName].toLowerCase().includes(word.toLowerCase())
      )
        coincidences.push(true);
    });

    return coincidences.length === splittedSearch.length;
  });
