import { useEffect, useState } from 'react';
import {
  Select,
  Button,
  Icon,
  ErrorMessage,
  BlockOfDatesSchedulePicker,
  RegistrationPriceBlock,
  RegistrationDonationBlock,
} from '@components/shared';
import { format } from 'date-fns';
import { dateBlockStartFormat, dateFormatRequest } from '@src/constants';
import {
  classNames,
  zonedDate,
  getOfferingsAmount,
  formatPrice,
} from '@src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useDidUpdate, useForm, parseOnlyAmount } from '@src/hooks';
import {
  getBlockOrderData,
  getBlockStartDates,
  getBlockSlots,
  getBlockSelectedSlots,
} from '@actions/registrationActions';
import { normalizeBlockRegData } from '@src/normalizers';

export const BlockOfClasses = ({ sessionInfo, btnCallbacks, contentType }) => {
  const { block_registration, inactivity_days } = sessionInfo;
  const slots = useSelector((state) => state.registration.regSlots);
  const selectedSlots = useSelector(
    (state) => state.registration.blockSelectedSlots
  );
  const orderData = useSelector((state) => state.registration.orderData);
  const price = orderData.data?.totals?.total
  const startDates = useSelector((state) => state.registration.blockStartDates);
  const [selectedDates, setSelectedDates] = useState([]);
  const [timeSlotsErrors, setTimeSlotsErrors] = useState([]);
  const dispatch = useDispatch();

  const submitHandler = (values) => {
    const regData = {
      activeBlock,
      selectedStartDate,
      selectedWeekDaySchedules,
      donation: {
        is_anonymous: values.isAnonymous,
        amount: values.donationAmount,
        hasDonation: values.hasDonation,
      },
    };
    dispatch(getBlockOrderData(regData))
    return btnCallbacks.submit(normalizeBlockRegData(regData));
  };

  const schema = {
    donationAmount: {
      parse: parseOnlyAmount,
      validation: ['isRequired', 'donation'],
      valuesRequired: ['hasDonation'],
    },
    activeBlock: {
      validation: ['isRequired'],
    },
    selectedWeekDaySchedules: {
      customValidation: [
        {
          check: () => (activeBlock ? validateTimeslots() : true),
          message: 'Select a Timeslot to proceed',
        },
      ],
    },
  };

  const validateTimeslots = () => {
    const failedTimeslots = getFailedTimeslots();
    if (failedTimeslots.length) {
      setTimeSlotsErrors(failedTimeslots.map(({ day_num }) => day_num));
      return false;
    }
    return true;
  };

  const initialValues = {
    hasDonation: false,
    donationAmount: null,
    isAnonymous: false,
    selectedDays: [],
    activeBlock: null,
    selectedWeekDaySchedules: {},
    selectedStartDate: null,
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setCustomValue,
    handleSubmit,
  } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });

  const { selectedWeekDaySchedules, selectedStartDate, activeBlock } = values;

  useEffect(() => {
    dispatch(getBlockSlots(sessionInfo.id));
  }, []);

  useDidUpdate(() => {
    if (activeBlock) {
      dispatch(getBlockStartDates(activeBlock.id));
    }
  }, [activeBlock]);

  useDidUpdate(() => {
    if (selectedSlots.data.length) {
      const selectedDates = selectedSlots.data.map(({ date }) =>
        zonedDate(date)
      );
      setSelectedDates(selectedDates);
    }
  }, [selectedSlots.data]);

  const selectOptions = startDates.data
    ? startDates.data?.map((startDate) => ({
        label: format(zonedDate(startDate), dateBlockStartFormat),
        value: startDate,
      }))
    : [];

  useDidUpdate(() => {
    setCustomValue('selectedStartDate', selectOptions[0]);
  }, [startDates]);

  useEffect(() => {
    if (activeBlock && selectedStartDate) {
      const startDate = format(
        zonedDate(selectedStartDate.value),
        dateFormatRequest
      );
      dispatch(
        getBlockSelectedSlots(sessionInfo.id, activeBlock.id, startDate)
      );
    }
  }, [selectedStartDate]);

  useDidUpdate(() => {
    if (timeSlotsErrors.length) {
      const selectedWeekDays = Object.keys(selectedWeekDaySchedules);
      setTimeSlotsErrors((errors) =>
        errors.filter((err) => !selectedWeekDays.includes(`${err}`))
      );
    }

    if (!getFailedTimeslots().length) {
      const requestData = {
        activeBlock,
        selectedStartDate,
        selectedWeekDaySchedules,
      };
      dispatch(getBlockOrderData(requestData));
    }
  }, [selectedWeekDaySchedules]);

  const renderBlocks = (blocks) =>
    blocks.map((block) => {
      const { length, rate, id } = block;
      return (
        <div className="columns__item" key={id}>
          <button
            onClick={() => setCustomValue('activeBlock', block)}
            type="button"
            className={classNames([
              'switcher',
              activeBlock && activeBlock.id === id && 'switcher--active',
            ])}
          >
            <span className="switcher__content">
              {getOfferingsAmount(length, contentType)}
            </span>
            <span className="switcher__hint">
              <span className="money">${formatPrice(rate)}</span> per{' '}
              {contentType.singular_short_name}
            </span>
          </button>
        </div>
      );
    });

  const getFailedTimeslots = () => {
    const selectedDaysNumbers = Object.keys(selectedWeekDaySchedules).map(
      (num) => +num
    );
    return selectedSlots.data.filter(
      ({ day_num }) => !selectedDaysNumbers.includes(day_num)
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="registration-step__group">
        <h4 className="registration-step__title">
          Please choose a block of consecutive {contentType.plural_short_name}
        </h4>
      </div>

      <div className="registration-step__group registration-step__group--blocks-select">
        <div className="columns columns--4">
          {block_registration && renderBlocks(block_registration)}
        </div>
        <ErrorMessage
          show={!!errors.activeBlock}
          text={'Select a Block to proceed'}
        />
      </div>
      {activeBlock && selectedStartDate && (
        <div className="registration-step__group registration-step__group--date-choose">
          <h4 className="registration-step__title">
            {selectOptions.length === 1
              ? 'Start date'
              : 'Please choose a start date'}
          </h4>
          {selectOptions.length === 1 ? (
            <span>{selectedStartDate.label}</span>
          ) : (
            <Select
              options={selectOptions}
              onChange={(date) => setCustomValue('selectedStartDate', date)}
              value={selectedStartDate}
              optionsKey="value"
            />
          )}
        </div>
      )}
      <BlockOfDatesSchedulePicker
        slots={slots}
        inactivity_days={inactivity_days}
        selectedSlots={selectedSlots}
        selectedDates={selectedDates}
        selectedWeekDaySchedules={selectedWeekDaySchedules}
        setCustomValue={setCustomValue}
        errors={errors}
        contentType={contentType}
        timeSlotsErrors={timeSlotsErrors}
      />
      <div className="registration-step__group registration-step__group--price">
        <RegistrationDonationBlock
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <div className="registration-step__payment-group">
          <RegistrationPriceBlock
            price={!getFailedTimeslots().length && price}
            rate={activeBlock?.rate}
            contentType={contentType}
            loading={orderData.loading}
            values={values}
          />
        </div>
      </div>
      <div className="btn__wrapper">
        <Button
          htmlType="button"
          onClick={btnCallbacks.back}
          type="transparent"
          iconLeft={<Icon type="chevron" />}
        >
          Back
        </Button>
        <Button
          htmlType="submit"
          type="primary"
        >
          Proceed
        </Button>
      </div>
    </form>
  );
};