import React, { useEffect, useState } from 'react';
import {
  ClassRegistration,
  Checkout,
  RegistrationType,
  AuthForm,
} from '@components/modals';
import {
  closeModal,
  Loader,
  RegistrationTitleBlock,
  getModal,
  openModal,
} from '@components/shared';
import { getRegTypesTabs } from './RegistrationType/RegistrationTypeData';
import { useSelector, useDispatch } from 'react-redux';
import {
  queryParam,
  createOrderRequest,
  checkoutPaymentRequest,
  matomoRegistrationHandler,
  getRegistrationProceedLink,
  apiRequest,
  requestCallback,
} from '@src/utils';
import { registerTypes } from '@src/constants';
import { getManualOrderData } from '@actions/registrationActions';

export const Registration = ({ offering, sessionId }) => {
  const paymentLoading = useSelector(
    (state) => state.registration.paymentLoading
  );
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const isGlobalTermsAccepted = useSelector(
    (state) => state.auth.userData.data?.student?.is_terms_and_conditions_agreed
  );
  const provider = useSelector((state) => state.provider.providerData);
  const [regStep, setRegStep] = useState(+queryParam.get('regStep') || 1);
  const [regType, setRegType] = useState(queryParam.get('regType'));
  const [sessionInfo, setSessionInfo] = useState(null);
  const [regTypesTabs, setRegTypesTabs] = useState([]);
  const [paymentData, setPaymentData] = useState({});
  const [orderId, setOrderId] = useState();

  const { id, title, sessions } = offering;
  const dispatch = useDispatch();
  const [providerAffiliationConfirmed, setProviderAffiliationConfirmed] =
    useState(null);

  if (sessionId) {
    queryParam.set('sessionId', sessionId);
  }

  useEffect(() => {
    if (
      regStep > 2 &&
      !providerAffiliationConfirmed &&
      provider.data.id &&
      isGlobalTermsAccepted
    ) {
      checkIsPolicyConfirmed();
    }
  }, [regStep, provider.data]);

  const checkIsPolicyConfirmed = () => {
    apiRequest({
      url: `/users/affiliations/providers/${provider.data.id}/check`,
      method: 'POST',
      callbacks: requestCallback(({ data }) => {
        data.is_confirmed
          ? setProviderAffiliationConfirmed(true)
          : openProviderAffiliationModal();
      }),
    });
  };

  const openProviderAffiliationModal = () => {
    const { pathname, search } = window.location;
    openModal(
      getModal({
        name: 'provider-affiliation-invitation',
        data: { redirectTo: pathname + search },
      })
    );
  };

  useEffect(() => {
    const currSession = sessions.find(({ id }) => id === sessionId) || {};
    setSessionInfo(currSession);
    const tabs = getRegTypesTabs(currSession, offering);
    setRegTypesTabs(tabs);

    return () => {
      queryParam.delete([
        'regStep',
        'sessionId',
        'regType',
        'orderId',
      ]);
    };
  }, []);

  useEffect(() => {
    queryParam.set('regStep', regStep);
    const modal = document.querySelector('.modal__window');
    modal.scrollTo({ top: 0 });
  }, [regStep]);

  useEffect(() => {
    const orderId = queryParam.get('orderId');
    if (orderId) {
      setOrderId(orderId);
      dispatch(getManualOrderData(orderId));
    }
  }, []);

  const btnCallbacks = {
    registrationType: {
      back: () => closeModal('registration'),
      submit: (regType) => {
        setRegType(regType);
        queryParam.set('regType', regType);
        isAuthorized ? setRegStep(3) : setRegStep(2);
        matomoRegistrationHandler(regType, id, title, offering);
      },
    },
    auth: {
      back: () => {
        setRegStep(1);
      },
      submit: () => {
        regType ? setRegStep(3) : setRegStep(1);
      },
    },
    classRegistration: {
      back: () => {
        setPaymentData({});
        setRegStep(1);
      },
      submit: (data) => {
        setPaymentData(data, registerTypes[regType]);
        setRegStep(4);
      },
    },
    checkout: {
      back: () => {
        orderId ? closeModal('registration') : setRegStep(3);
      },
      submit: (payment_gateway) => {
        return orderId
          ? checkoutPaymentRequest(orderId, payment_gateway)
          : createOrderRequest(
              { ...paymentData, payment_gateway },
              registerTypes[regType]
            );
      },
    },
  };

  const stepsNavigation = {
    1: (
      <RegistrationType
        btnCallbacks={btnCallbacks.registrationType}
        tabs={regTypesTabs}
        isAuthorized={isAuthorized}
        setRegStep={setRegStep}
        regType={regType}
      />
    ),
    2: (
      <AuthForm
        btnCallbacks={btnCallbacks.auth}
        isCourseReg
        proceedLink={getRegistrationProceedLink()}
      />
    ),
    3: (
      <ClassRegistration
        regType={regType}
        sessionInfo={sessionInfo}
        btnCallbacks={btnCallbacks.classRegistration}
        courseInfo={offering}
      />
    ),
    4: <Checkout btnCallbacks={btnCallbacks.checkout} />,
  };

  return (
    <>
      <Loader show={paymentLoading || provider.loading} sticky />
      {sessionInfo && (
        <div className="registration-details">
          <RegistrationTitleBlock
            classDetails={offering}
            regStep={regStep}
            regType={regType}
          />
          {stepsNavigation[regStep]}
        </div>
      )}
    </>
  );
};
