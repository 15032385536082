import React from 'react';
import { Image, Button } from '@components/shared';
import { useHistory } from 'react-router-dom';
import { setLocationFilter } from '@actions/scheduleActions';
import { useDispatch } from 'react-redux';

export const RentalsItem = ({ location }) => {
  const { title, description, gallery, address, location_id } = location;
  const history = useHistory();
  const dispatch = useDispatch();

  const onSeeScheduleClick = () => {
    dispatch(setLocationFilter(location_id));
    history.push(`/schedule`);
  };

  return (
    <div className="rentals-item">
      <h2 className="rentals-item__title">{title}</h2>
      <div
        className="rentals-item__description text-editor-block"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>

      {!!gallery.length && (
        <div className="rentals-item__gallery-block">
          {gallery.map((image, index) => (
            <div className="rentals-item__gallery-image-wrapper" key={index}>
              <Image
                src={image}
                alt="location"
                className="rentals-item__gallery-image"
              />
            </div>
          ))}
        </div>
      )}
      {address && (
        <div className="rentals-item__address-block">
          <h3 className="rentals-item__address-title">Address</h3>
          <span className="rentals-item__address-body">{address}</span>
        </div>
      )}
      {!!location_id && (
        <Button type={'primary'} onClick={onSeeScheduleClick}>
          See Studio Schedule
        </Button>
      )}
    </div>
  );
};
