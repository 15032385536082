import { SelectOption } from '@components/shared/Select/SelectOption';
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export const SelectList = forwardRef(
  (
    {
      options,
      value,
      optionsValue,
      optionsLabel,
      optionsKey,
      activeItemIndex,
      onSelectOptionClick,
      onSelectOptionHover,
      enableClear,
    },
    ref
  ) => {
    const initialSelectOption = {
      [optionsLabel]: 'Clear selection',
      [optionsValue]: 'Clear selection',
    };

    if (
      value &&
      enableClear &&
      options.length &&
      options[0][optionsValue] !== initialSelectOption[optionsValue]
    ) {
      options.unshift(initialSelectOption);
    }

    return (
      <ul ref={ref} className="select__inner-list">
        {options.length ? (
          options.map((option, i) => {
            const isSelected =
              value || value === 0
                ? _.isEqual(option[optionsValue], value[optionsValue] || value)
                : false;

            return (
              <SelectOption
                label={option[optionsLabel]}
                key={JSON.stringify(option[optionsKey])}
                fullObj={option}
                handleClick={onSelectOptionClick}
                isActive={activeItemIndex === i}
                isSelected={isSelected}
                index={i}
                handleMouseEnter={onSelectOptionHover}
              />
            );
          })
        ) : (
          <li className="select__no-data">Nothing to select</li>
        )}
      </ul>
    );
  }
);

SelectList.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  optionsValue: PropTypes.string.isRequired,
  optionsKey: PropTypes.string.isRequired,
  optionsLabel: PropTypes.string.isRequired,
  activeItemIndex: PropTypes.number,
  onSelectOptionClick: PropTypes.func,
  onSelectOptionHover: PropTypes.func,
};