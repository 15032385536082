import { dateFormatRequest } from '@src/constants';
import { format } from 'date-fns';
import { getPhoneRequestFormat } from '@src/utils';

export const normalizeRegisterData = (data) => {
  const {
    firstName,
    lastName,
    middleName,
    userName,
    password,
    confirmPassword,
    email,
    underAge,
    birthDate,
    healthConcerns,
    emergencyContactName,
    emergencyContactPhone,
    parentName,
    parentPhone,
    homePhone,
    workPhone,
    cellPhone,
    country,
    address,
    state,
    city,
    zip,
    agreePolicy,
    subscribeEmail,
    subscribeSnail,
    mediaWaiver,
    knownSource
  } = data;

  return {
    user: {
      login: userName,
      email,
      password,
      password_confirmation: confirmPassword,
    },
    contact: {
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      home_phone: getPhoneRequestFormat(homePhone),
      work_phone: getPhoneRequestFormat(workPhone),
      cell_phone: getPhoneRequestFormat(cellPhone),
      date_of_birth: birthDate && format(new Date(birthDate), dateFormatRequest),
      address: {
        country_id: country,
        address_1: address,
        state_id: state,
        city,
        zip,
      },
    },
    student: {
      under_eighteen: underAge,
      emergency_contact_name: emergencyContactName,
      emergency_contact_phone: getPhoneRequestFormat(emergencyContactPhone),
      health_concerns: healthConcerns,
      guardian_contact_name: parentName,
      guardian_contact_phone: getPhoneRequestFormat(parentPhone),
      is_terms_and_conditions_agreed: agreePolicy,
      receive_email_newsletters: subscribeEmail,
      receive_snail_mail: subscribeSnail,
      is_media_consent_and_waiver_agreed: mediaWaiver,
    },
    source_survey: [{id: knownSource}],
  };
};