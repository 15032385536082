import React from 'react';
import { useViewport } from '@src/hooks';
import { Icon } from '@components/shared';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AboutObjectivesCard } from './AboutObjectivesCard';

import 'swiper/swiper.scss';

export const ProviderAboutObjectives = ({ objectivesData = {} }) => {
  const { isMobile } = useViewport();
  const { subtitle, list = [] } = objectivesData;
  const slidesCount = list.length;
  const sliderSettings = {
    className: 'about-objectives__slider',
    navigation: {
      nextEl: '.about-objectives__next-button',
      prevEl: '.about-objectives__prev-button',
    },
    loop: true,
    slidesPerView: 1,
    spaceBetween: 20,
    enabled: slidesCount > 1,
  };

  return (
    <div className="about-objectives">
      <h2 className="provider-about__subtitle">{subtitle}</h2>

      <div className="about-objectives__body">
        {isMobile ? (
          <>
            <Swiper {...sliderSettings}>
              {list.map(({ title, description }) => (
                <SwiperSlide className="about-objectives__slide" key={title}>
                  <AboutObjectivesCard
                    title={title}
                    description={description}
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="global-page__buttons about-objectives__buttons">
              <div className="global-page__prev-button about-objectives__prev-button">
                <Icon type="arrow-prev" />
              </div>

              <div className="global-page__next-button about-objectives__next-button">
                <Icon type="arrow-next" />
              </div>
            </div>
          </>
        ) : (
          <>
            {list.map(({ title, description }) => {
              return (
                <div className="about-objectives__item" key={title}>
                  <AboutObjectivesCard
                    title={title}
                    description={description}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};
