export const requestCallback = (success, error, loading) => ({
  loading: () => {
    loading && loading(true);
    error && error('');
  },
  success: (res) => {
    loading && loading(false);
    return success && success(res);
  },
  error: (err) => {
    loading && loading(false);
    error && error(err);
  },
});
