import React from 'react';
import { Container } from '@components/layout';
import { classNames } from '@src/utils';
import { useSelector } from 'react-redux';
import { useViewport } from '@src/hooks';
import { AccountNavigation } from '@components/features';
import {
  Button,
  Icon,
  Tabs,
  Tooltip,
  Link,
  BurgerButton,
  SlideMenu,
} from '@components/shared';
import { globalWidgetLink } from '@src/constants';

export const GlobalHeader = ({
  onDonationButtonClick,
  isAuthorized,
  onLoginClick,
  onLogoutClick,
  isMobileNavOpen,
  onBurgerClick,
  closeMenu,
  isAccount,
  setIsMobileNavOpen,
  tabsData,
}) => {
  const { width } = useViewport();
  const isTablet = width < 1150;
  const userData = useSelector((state) => state.auth.userData);

  const renderNavigation = (isMob) => {
    return (
      <>
        <div className="header__nav header__nav--pages">
          <Tabs tabsData={tabsData} isShadowVisible={!isMob} isLinks={true} />
        </div>
        {isAccount && isTablet && (
          <div>
            <span
              className="account-opener"
            >
              Account
              <Icon type="chevron" size="sm" />
            </span>

            <AccountNavigation setIsMobileNavOpen={setIsMobileNavOpen} />
          </div>
        )}

        <div className="header__nav header__nav--auth">
          <ul className="list">
            <li className="list__item">
              <Tooltip
                position="bottom"
                className="header__donation-tooltip"
                body={
                  <Button
                    className="header__donation-btn"
                    iconLeft={<Icon type="donate" />}
                    onClick={onDonationButtonClick}
                  >
                    Donate
                  </Button>
                }
              >
                Make a tax-deductible donation
              </Tooltip>
            </li>
            {isAuthorized ? (
              <>
                {!isAccount && (
                  <li className="list__item">
                    <Link
                      className="header__button"
                      href={`${globalWidgetLink}/account`}
                      iconLeft={<Icon type="profile-tab" />}
                    >
                      My Account
                    </Link>
                  </li>
                )}

                {isAccount && userData.data && !userData.loading && (
                  <li className="list__item list__item--user">
                    <span className="account__user-name">
                      <Icon type="profile-tab" />
                      {userData.data?.display_name}
                    </span>
                  </li>
                )}

                <li className="list__item">
                  <Button
                    type="navigation"
                    iconLeft={<Icon size="md" type="log-out" />}
                    onClick={onLogoutClick}
                  >
                    Log out
                  </Button>
                </li>
              </>
            ) : (
              <li className="list__item">
                <Button
                  className="header__button"
                  iconLeft={<Icon type="profile-tab" />}
                  onClick={onLoginClick}
                >
                  Log in &#38; Sign up
                </Button>
              </li>
            )}
          </ul>
        </div>
      </>
    );
  };

  return (
    <header
      className={classNames([
        'header',
        isMobileNavOpen && 'header--active',
        isAccount && 'account__header',
      ])}
    >
      <Container>
        <div className="header__content">
          <Link className="header__go-back" href={globalWidgetLink} target="">
            <img src="/img/wew-logo.svg" alt="wew logo" />
          </Link>

          {isTablet ? (
            <>
              <BurgerButton
                onClick={onBurgerClick}
                isActive={isMobileNavOpen}
              />

              <SlideMenu
                isShown={isMobileNavOpen}
                className={'mobile-nav'}
                onCloseClick={closeMenu}
              >
                {renderNavigation(true)}
              </SlideMenu>
            </>
          ) : (
            renderNavigation(false)
          )}
        </div>
      </Container>
    </header>
  );
};
