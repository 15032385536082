import React, { useState, useEffect } from 'react';
import {
  PayPalButtons,
  FUNDING,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import { Loader } from '@components/shared';
import { useDispatch } from 'react-redux';
import { apiRequest, requestCallback, openLink, queryParam } from '@src/utils';
import { setPaymentLoading } from '@actions/registrationActions';

export const PaymentPaypalButtons = ({
  handleSubmit,
  setError,
  fundingSources = [],
  setIsButtonsLoading,
}) => {
  const dispatch = useDispatch();
  const [isVenmoEligible, setIsVenmoEligible] = useState(false);
  const [{ isResolved, isPending }] = usePayPalScriptReducer();

  const checkVenmoEligibility = () => {
    const venmo = window?.paypal?.Buttons({ fundingSource: FUNDING.VENMO });
    const isEligible = venmo?.isEligible();
    setIsVenmoEligible(isEligible);
  };

  useEffect(() => {
    setIsButtonsLoading(isPending);
  }, [isPending]);

  useEffect(() => {
    if (isResolved) {
      checkVenmoEligibility();
    }
  }, [isResolved]);

  const paypalOnApproveRequest = async (data) => {
    await dispatch(setPaymentLoading(true));
    await apiRequest({
      url: `/paypal/${data.orderID}/capture?${
        queryParam.get('error') ? 'error=true' : ''
      }`,
      method: 'POST',
      callbacks: requestCallback(
        (res) => {
          openLink(res.data.redirect_link);
        },
        (err) => {
          const error = err?.response?.data?.description;
          if (error) {
            setError(error);
          }
          dispatch(setPaymentLoading(false));
        }
      ),
    });
  };

  const isButtonActive = (sourceName) => fundingSources.includes(sourceName);

  return (
    <>
      {isPending ? (
        <Loader />
      ) : (
        <>
          {isButtonActive('paypal') && (
            <div className="payment-action__button-wrapper">
              <PayPalButtons
                fundingSource={FUNDING.PAYPAL}
                style={{ shape: 'pill' }}
                createOrder={() => handleSubmit('paypal')}
                onApprove={paypalOnApproveRequest}
              />
            </div>
          )}
          {isButtonActive('card') && (
            <div className="payment-action__button-wrapper">
              <PayPalButtons
                fundingSource={FUNDING.CARD}
                style={{ shape: 'pill' }}
                createOrder={() => handleSubmit('paypal')}
                onApprove={paypalOnApproveRequest}
              />
            </div>
          )}
          {isVenmoEligible && isButtonActive('venmo') && (
            <div className="payment-action__button-wrapper">
              <PayPalButtons
                fundingSource={FUNDING.VENMO}
                style={{ shape: 'pill' }}
                createOrder={() => handleSubmit('paypal')}
                onApprove={paypalOnApproveRequest}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
