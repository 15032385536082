import React, { useState } from 'react';
import { Button, Icon, PaymentPaypalButtons } from '@components/shared';
import { useSelector } from 'react-redux';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

export const PaymentMethodButtons = ({ handleSubmit, isFree = false }) => {
  const [error, setError] = useState();
  const [isButtonsLoading, setIsButtonsLoading] = useState(false);
  const providerPaymentMethods = useSelector(
    (state) => state.provider.providerData.data.paymentMethods
  );
  const { payflow, paypal = {} } = providerPaymentMethods;
  const { client_id, funding_sources } = paypal;

  return (
    <div className="payment-action">
      <span className="payment-action__title">
        {isFree ? 'Proceed Free Registration' : 'Pay by'}:
      </span>
      {payflow && !isButtonsLoading && (
        <div className="payment-action__button-wrapper">
          <Button
            className="payment-action__payflow"
            type="primary"
            onClick={() => handleSubmit('payflow')}
          >
            Credit/Debit Card or PayPal
          </Button>
        </div>
      )}
      {client_id && (
        <PayPalScriptProvider
          options={{
            'client-id': client_id,
            'enable-funding': 'venmo',
          }}
        >
          <PaymentPaypalButtons
            handleSubmit={handleSubmit}
            setError={setError}
            fundingSources={funding_sources}
            setIsButtonsLoading={setIsButtonsLoading}
          />
        </PayPalScriptProvider>
      )}


      {error && (
        <div className="popup">
          <div className="popup__overlay" onClick={(e) => setError('')}></div>
          <div className="popup__window">
            <Icon type="danger" className="popup__icon" />
            <div className="popup__text">
              <b>Payment Declined</b>
              <p>{error}</p>
            </div>
            <Button
              className="popup__close-button"
              onClick={() => setError('')}
            >
              <Icon type="close-cross" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
