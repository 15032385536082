import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Thumbs } from 'swiper';
import VideoGalleryCard from '@components/features/Videos/VideoGalleryCard';
import { VideoGalleryThumbs } from './VideoGalleryThumbs';
import { useApi } from '@src/hooks';
import { useSelector } from 'react-redux';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/thumbs/thumbs.scss';

SwiperCore.use([Navigation, Thumbs]);

export const VideoGallery = ({ videos, onPayClick, offeringId }) => {
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const [selectedVideo, setSelectedVideo] = useState(videos[0]);

  const thumbsSettings = {
    navigation: {
      nextEl: '.video-gallery__next-button',
      prevEl: '.video-gallery__prev-button',
    },
    slidesPerView: 2,
    spaceBetween: 10,
    breakpoints: {
      768: {
        slidesPerView: 4,
      },
    },
  };

  const purchasedVideos = useApi(
    {
      url: `/courses/${offeringId}/purchased-videos`,
      dataPlaceholder: [],
    },
    [],
    isAuthorized
  );

  return (
    <div className="video-gallery">
      <div className="container">
        <div className="video-gallery__thumbs">
          <Swiper {...thumbsSettings}>
            {videos?.map((video) => {
              return (
                <SwiperSlide key={video.id}>
                  <VideoGalleryThumbs
                    videoData={video}
                    onThumbClick={(video) => setSelectedVideo(video)}
                    isSelected={selectedVideo.id === video.id}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="video-gallery__buttons">
            <div className="swiper-button-prev video-gallery__prev-button"></div>
            <div className="swiper-button-next video-gallery__next-button"></div>
          </div>
        </div>
        <VideoGalleryCard
          videoData={selectedVideo}
          onPayClick={onPayClick}
          isPurchased={purchasedVideos.data.includes(selectedVideo.id)}
          loading={purchasedVideos.loading}
        />
      </div>
    </div>
  );
};
