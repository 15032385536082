export const roundMinutesToQuarter = (time) => {
  const timeArray = time.split(':');
  let hours = timeArray[0];
  let minutes = timeArray[1];

  minutes = Math.round(minutes/15) * 15;

  if (minutes >= 60) {
    minutes = "00";
    hours = +hours + 1
  } else if (minutes < 1) {
    minutes = "00"
  }

  if (+hours < 10 && +hours > 0) {
    hours = "0" + +hours
  }

  return [hours, minutes].join(':');
};

//module.exports = roundMinutesToQuarter;
