import { tableColors } from '@src/constants';
import { roundMinutesToQuarter } from '@src/utils';

export const getDayTableArray = (data, timeRange) => {
  let schedules = [];
  let table = [];

  timeRange.forEach((timeValue) => {
    const isRoot = timeValue === '@';
    const row = [timeValue];

    data.forEach((location, locationIndex) => {
      const { studios } = location;

      studios.forEach((studio) => {
        let cell = { color: tableColors[locationIndex] };

        if (isRoot) {
          if (studio.schedules.length) {
            schedules = [...schedules, ...studio.schedules];
          }

          cell = { ...cell, studio, location };
        }

        if (!isRoot) {
          const matchedCourse = schedules.find(
            (course) =>
              roundMinutesToQuarter(course.start_time) === timeValue && course.studio_id === studio.id
          );
          if (matchedCourse) {
            cell = { ...cell, event: matchedCourse };
          }
        }
        row.push(cell);
      });
    });

    table.push(row);
  });

  return table;
};
