import React from "react";
import { classNames } from '@src/utils';
import { Image } from '@components/shared';

export const ProviderBanner = ({ className, banner }) => {
  return (    
    <div
      className={classNames([
        'provider-banner', 
        className
      ])}
    >
      <Image 
        className="provider-banner__body" 
        src={banner}
        alt="provider banner" 
      />
    </div>
  );
};