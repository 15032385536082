import { getWeekDays, getWeekRange, zonedDate, queryParam } from '@src/utils';
import * as types from '../types/scheduleTypes';

const initDayDateQuery = queryParam.get('dayDate');
const initWeekDateQuery = queryParam.get('weekDate');

const locationQuery = queryParam.get('location') || '';
const facultyQuery = queryParam.get('faculty') || '';
const categoryQuery = queryParam.get('category') || '';
const letterQuery = queryParam.get('letter') || '';
const programQuery = queryParam.get('program') || '';
const sessionsActiveTabQuery = queryParam.get('activeTab');
const initScheduleSearch = queryParam.get('search') || '';
const initView = queryParam.get('view') || 'week';

const initLocationQuery = locationQuery ? parseFloat(locationQuery) : '';
const initFacultyQuery = facultyQuery ? parseFloat(facultyQuery) : '';
const initCategoryQuery = categoryQuery ? parseFloat(categoryQuery) : '';
const initProgramQuery = programQuery ? parseFloat(programQuery) : '';
const initLetterQuery = letterQuery ? letterQuery : 'all';
const initSessionsActiveTab = sessionsActiveTabQuery || 'active'

const getInitialDate = () => {
  if (initDayDateQuery) {
    return zonedDate(initDayDateQuery);
  } else if (initWeekDateQuery) {
    return zonedDate(initWeekDateQuery);
  } else {
    return new Date();
  }
};

const initialState = {
  filtersSelect: {
    location: initLocationQuery,
    faculty: initFacultyQuery,
    category: initCategoryQuery,
    program: initProgramQuery,
    search: initScheduleSearch
  },
  filters: {
    loading: false,
    error: '',
    data: null,
  },
  alphabetFilter: {
    loading: false,
    error: '',
    letter: initLetterQuery,
  },
  dayTable: {
    loading: false,
    error: '',
    data: null,
  },
  weekTable: {
    loading: false,
    error: '',
    data: null,
  },
  categoriesTable: {
    loading: false,
    error: '',
    data: null,
  },
  instructorsTable: {
    loading: false,
    error: '',
    data: null,
  },
  programsTable: {
    loading: false,
    error: '',
    data: null,
  },
  sessions: {
    activeTab: initSessionsActiveTab,
  },
  activeTab: initView,
  dayDate: getInitialDate(),
  weekDate: getWeekDays(getWeekRange(getInitialDate()).from),
};

const scheduleReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FILTER_SELECT_PROGRAM:
      return {
        ...state,
        filtersSelect: {
          ...state.filtersSelect,
          program: payload,
        },
      };

    case types.FILTER_SELECT_LOCATION:
      return {
        ...state,
        filtersSelect: {
          ...state.filtersSelect,
          location: payload,
        },
      };

    case types.FILTER_SELECT_CATEGORIES:
      return {
        ...state,
        filtersSelect: {
          ...state.filtersSelect,
          category: payload,
        },
      };

    case types.FILTER_SELECT_INSTRUCTORS:
      return {
        ...state,
        filtersSelect: {
          ...state.filtersSelect,
          faculty: payload,
        },
      };

    case types.FILTER_SELECT_CLEAR:
      return {
        ...state,
        filtersSelect: {
          program: '',
          faculty: '',
          category: '',
          location: '',
          search: ''
        },
        alphabetFilter: {
          letter: 'all',
        },
      };

    case types.ALPHABET_FILTER_LETTER:
      return {
        ...state,
        alphabetFilter: {
          letter: payload,
        },
      };

    case types.PROGRAMS_TABLE:
      return {
        ...state,
        programsTable: {
          loading: false,
          data: payload,
          error: '',
        },
      };

    case types.PROGRAMS_TABLE_LOADING:
      return {
        ...state,
        programsTable: {
          loading: true,
          error: '',
        },
      };

    case types.PROGRAMS_TABLE_ERROR:
      return {
        ...state,
        programsTable: {
          loading: false,
          error: payload,
        },
      };

    case types.INSTRUCTORS_TABLE:
      return {
        ...state,
        instructorsTable: {
          loading: false,
          data: payload,
          error: '',
        },
      };

    case types.INSTRUCTORS_TABLE_LOADING:
      return {
        ...state,
        instructorsTable: {
          loading: true,
          error: '',
        },
      };

    case types.INSTRUCTORS_TABLE_ERROR:
      return {
        ...state,
        instructorsTable: {
          loading: false,
          error: payload,
        },
      };

    case types.CATEGORIES_TABLE:
      return {
        ...state,
        categoriesTable: {
          loading: false,
          data: payload,
          error: '',
        },
      };

    case types.CATEGORIES_TABLE_LOADING:
      return {
        ...state,
        categoriesTable: {
          loading: true,
          error: '',
        },
      };

    case types.CATEGORIES_TABLE_ERROR:
      return {
        ...state,
        categoriesTable: {
          loading: false,
          error: payload,
        },
      };

    case types.WEEK_TABLE:
      return {
        ...state,
        weekTable: {
          loading: false,
          data: payload,
          error: '',
        },
      };

    case types.WEEK_TABLE_LOADING:
      return {
        ...state,
        weekTable: {
          loading: true,
          error: '',
        },
      };

    case types.WEEK_TABLE_ERROR:
      return {
        ...state,
        weekTable: {
          loading: false,
          error: payload,
        },
      };

    case types.DAY_TABLE:
      return {
        ...state,
        dayTable: {
          loading: false,
          data: payload,
          error: '',
        },
      };

    case types.DAY_TABLE_LOADING:
      return {
        ...state,
        dayTable: {
          loading: true,
          error: '',
        },
      };

    case types.DAY_TABLE_ERROR:
      return {
        ...state,
        dayTable: {
          loading: false,
          error: payload,
        },
      };

    case types.FILTERS:
      return {
        ...state,
        filters: {
          data: payload,
          loading: false,
          error: '',
        },
      };

    case types.FILTERS_LOADING:
      return {
        ...state,
        filters: {
          ...state.filters,
          loading: true,
          error: '',
        },
      };

    case types.FILTERS_ERROR:
      return {
        ...state,
        filters: {
          ...state.filters,
          loading: false,
          error: payload,
        },
      };

    case types.WEEK_DATE:
      return {
        ...state,
        weekDate: payload,
        dayDate: payload[0],
      };

    case types.DAY_DATE:
      return {
        ...state,
        weekDate: getWeekDays(getWeekRange(payload).from),
        dayDate: payload,
      };

    case types.ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload,
      };

    case types.SESSIONS_ACTIVE_TAB:
      return {
        ...state,
        sessions: {
          activeTab: payload,
        },
      };

    case types.SCHEDULE_SEARCH:
      return {
        ...state,
        filtersSelect: {
          ...state.filtersSelect,
          search: payload,
        },
      };

    default: {
      return state;
    }
  }
};

export { scheduleReducer };
