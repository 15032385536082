import { renderClassOrBadge } from '@src/utils';
import { Image, Icon } from '@components/shared';
import { classNames } from '@src/utils';

export const VideoGalleryThumbs = ({ videoData, onThumbClick, isSelected }) => {
  const { poster_image, is_free, is_paid } = videoData;

  return (
    <div
      className={classNames([
        'video-gallery__thumb',
        isSelected && 'video-gallery__thumb--selected',
      ])}
      onClick={() => onThumbClick(videoData)}
    >
      {poster_image?.path ? (
        <Image src={poster_image.path} alt="video poster" />
      ) : (
        <div className='video-gallery__thumb-icon-wrapper'>
          <Icon type="party-dance" size="full" />
        </div>
      )}

      <div
        className={`video-gallery__thumb-badge video-gallery__thumb-badge--${renderClassOrBadge(
          is_free,
          is_paid
        )}`}
      >
        {renderClassOrBadge(is_free, is_paid, true)}
      </div>
    </div>
  );
};
