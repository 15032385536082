import React from "react";
import { useViewport } from '@src/hooks';
import { classNames, transformLink } from '@src/utils';
import { Link, Icon, Image } from "@components/shared";
import { globalWidgetLink } from '@src/constants';

export const OfferingDetailsPresentersBioCard = ({ faculty }) => {
  const {
    bio,
    custom_social_networks,
    name,
    subtitle,
    profile_image_url,
    user_image_url,
    id
  } = faculty;

  const { isMobile } = useViewport();

  const trimSubtitle = (string) => {
    if (string.length < 100) {
      return string;
    }

    return string.slice(0, 100) + '...';
      
  };

  const renderInstructorDescription = () => {
    return (
      <>
        <h4 className="offering-bio-card__name">
          {name}
        </h4>

        <p className="offering-bio-card__instruction">
          {subtitle && trimSubtitle(subtitle)}
        </p>
      </>
    );
  };

  return (
    <div className="offering-bio-card">
      <div className="offering-bio-card__top">
        {isMobile && 
          renderInstructorDescription()
        }       
        
        <div
          className={classNames([
            'offering-bio-card__visual',
            !profile_image_url && !user_image_url && 'offering-card__visual--empty',
          ])}
        >
          {(profile_image_url || user_image_url) && (
            <>
              {profile_image_url && (
                <Image src={profile_image_url} alt="user" />
              )}

              {user_image_url && !profile_image_url && (
                <Image src={user_image_url} alt="user" />
              )}
            </>
          )}
          {!profile_image_url && !user_image_url && (
            <Icon type="user-undefined--new" />
          )}
        </div>
  
      </div>

      <div className="offering-bio-card__bottom">
        {!isMobile && 
          renderInstructorDescription()
        }        
        
        {custom_social_networks &&
          <div className="offering-bio-card__socials">
            <ul className="offering-bio-card__socials-list">
              {custom_social_networks.map((social, index) => {
                return (
                  <li className="offering-bio-card__socials-item" key={index}>
                    <Link href={transformLink(social.url)} className="offering-bio-card__socials-link" target='_blank'>
                      <Icon 
                        type={isMobile ? `socials--${social.type}` : `socials--${social.type}-xl` }
                        size={isMobile ? 'lg' :'xxl'}
                      />
                    </Link>
                  </li>
                );
              })

              }
            </ul>
          </div>
        }

        {id &&
          <Link 
            className="offering-bio-card__button"
            type="button-transparent"
            href={`${globalWidgetLink}/faculty/${id}`}
          >
            Faculty profile
          </Link>
        }

        <div className="text-editor-block offering-bio-card__bio"
          dangerouslySetInnerHTML={{
            __html: bio,
          }}
        />
      </div>
    </div>
  );
};