import React from 'react';
import { Link, Icon } from '@components/shared';

export const ContactMembersCard = ({ card }) => {
  const { title, description, email, number } = card;
  return (
    <div className="global-page-card member-card">
      <h3 className="global-page-card__title-block member-card__title">
        {title}
      </h3>
      <p className="member-card__description">
        {description}
      </p>
      <div className="member-card__contacts">
        <Link
          href={`tel:${number}`}
          iconLeft={<Icon type="contact--phone" size="lg" />}
        >
          <span>{number}</span>
        </Link>

        <Link
          href={`mailto:${email}`}
          iconLeft={<Icon type="contact--mail" size="lg" />}
        >
          <span>{email}</span>
        </Link>
      </div>
    </div>
  );
};
