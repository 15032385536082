import React, { useState } from 'react';
import { getModal, openModal, Tabs } from '@components/shared';
import { VideoGallery, PhotoGallery } from '@components/features';
import { createTab, setActiveTab, getActiveTab, queryParam } from '@src/utils';

export const OfferingDetailsMultimedia = ({ data }) => {
  const { videos = {}, gallery = [], id } = data;
  const { free = [], paid = [] } = videos;
  const videosToRender = [...free, ...paid.filter(({ is_free }) => !is_free)];
  const hasVideos = !!videosToRender.length;
  const hasPhotos = !!gallery.length;

  const [multimediaTabs, setMultimediaTabs] = useState(
    [
      hasVideos && createTab('Video', 'video', hasVideos),
      hasPhotos && createTab('Photo', 'photo', !hasVideos),
    ].filter((tab) => tab)
  );

  const activeTab = getActiveTab(multimediaTabs);

  const onPayToWatchClick = (sessionId) => {
    queryParam.set('regType', 'v');
    queryParam.set('regStep', 3);
    openModal(
      getModal({
        name: 'registration',
        data: {
          offering: data,
          sessionId,
        },
      })
    );
  };

  const render = {
    video: (
      <VideoGallery
        videos={videosToRender}
        onPayClick={onPayToWatchClick}
        offeringId={id}
      />
    ),
    photo: <PhotoGallery gallery={gallery} />,
  };

  return (
    <div className="offering-details__tab-content offering-details__multimedia">
      <div className="offering-multimedia">
        <div className="offering-multimedia__tabs">
          <div className="container">
            <Tabs
              tabsData={multimediaTabs}
              onTabClick={({ value }) => setActiveTab(setMultimediaTabs, value)}
              className={'tabs--bordered'}
            />
          </div>
        </div>
        {render[activeTab.value]}
      </div>
    </div>
  );
};
