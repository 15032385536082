import React, { useEffect } from 'react';
import { Loader } from '@components/shared';
import {
  AccountInformation,
  PersonalInformation,
  ContactInformation,
  ParentGuardian,
  EmergencyContacts,
} from './ProfileCards';
import { useDispatch } from 'react-redux';

export const AccountProfileInformation = ({
  userData,
  editorState,
  setEditorState,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => setEditorState('');
  }, []);

  return (
    <div className="account-profile">
      <h2 className="account__content-title">Profile Information</h2>
      <Loader show={userData.loading} sticky />
      <div className="account-profile__body">
        <div className="account-profile__item">
          <AccountInformation
            userData={userData}
            editorState={editorState}
            setEditorState={setEditorState}
            dispatch={dispatch}
          />
        </div>

        <div className="account-profile__item">
          <PersonalInformation
            userData={userData}
            editorState={editorState}
            setEditorState={setEditorState}
            dispatch={dispatch}
          />
        </div>

        <div className="account-profile__item">
          <ContactInformation
            userData={userData}
            editorState={editorState}
            setEditorState={setEditorState}
            dispatch={dispatch}
          />
        </div>

        <div className="account-profile__item">
          <ParentGuardian
            userData={userData}
            editorState={editorState}
            setEditorState={setEditorState}
            dispatch={dispatch}
          />
        </div>

        <div className="account-profile__item">
          <EmergencyContacts
            userData={userData}
            editorState={editorState}
            setEditorState={setEditorState}
            dispatch={dispatch}
          />
        </div>
      </div>
    </div>
  );
};
