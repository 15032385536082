import React from "react";

export const AboutObjectivesCard = ({ title, description }) => {
  return (
    <div className="global-page-card objectives-card">
      <div className="global-page-card__title-block objectives-card__title-block">
        <h4>{title}</h4>
      </div>

      <div className="global-page-card__content objectives-card__content">
        <p>
          {description}
        </p>
      </div>
    </div>
  );
};