

export const normalizeOfferingsFiltersData = (filters) => {
    const {isPublic, isPrivate} = filters;

    const data = {
        ...filters,
        instructor: filters.faculty,
      }

      if (isPublic && !isPrivate) {
        data.is_private = false;
      }

      if (!isPublic && isPrivate) {
        data.is_private = true;
      }

      delete data.faculty
      delete data.isPublic
      delete data.isPrivate
      
      return data
}