import React, { useEffect, useState } from 'react';
import { useApi } from '@src/hooks';
import { zonedDate, queryParam } from '@src/utils';
import { dateFormatRequest } from '@src/constants';
import { format, startOfDay } from 'date-fns';
import { Loader, NothingFound } from '@components/shared';
import { AccountScheduleCard } from './AccountScheduleCard';
import { AccountScheduleCalendar } from './AccountScheduleCalendar';
import { GET_APPOINTMENTS_URL } from '@src/endpoints';

export const AccountScheduleCalendarView = () => {
  const today = startOfDay(new Date());
  const querryDate = queryParam.get('chosen-date');
  const initDate = querryDate ? zonedDate(querryDate) : today
  const [selectedDay, setSelectedDay] = useState(initDate);

  const requestDate = format(selectedDay, dateFormatRequest);

  const userSchedules = useApi(
    { url: GET_APPOINTMENTS_URL({ date: requestDate }), dataPlaceholder: [] },
    [requestDate]
  );

  useEffect(() => {
    queryParam.set('chosen-date', requestDate);
    return () => queryParam.delete('chosen-date');
  }, [requestDate]);

  return (
    <>
      <div className="account-schedule__calendar-block">
        <AccountScheduleCalendar
          today={today}
          selectedDay={selectedDay}
          setSelectedDay={(day) => setSelectedDay(day)}
        />
      </div>
      {userSchedules.loading ? (
        <Loader sticky />
      ) : (
        <>
          {userSchedules.data.length ? (
            userSchedules.data.map((schedule) => (
              <AccountScheduleCard scheduleData={schedule} key={schedule.id} />
            ))
          ) : (
            <NothingFound title="You have no events today" subtitle=""/>
          )}
        </>
      )}
    </>
  );
};
