import React from 'react';
import { transformLink } from '@src/utils';
import { Link, Icon } from '@components/shared';
import { globalWidgetLink } from '@src/constants';

export const OfferingDetailsFacultyCard = ({ instructor }) => {
  const {
    id,
    faculty: { art_instruction, profile_image, user_image, bio },
    display_name,
    facebook,
    instagram,
    twitter,
    youtube,
    site,
    email,
  } = instructor;

  const trimSubtitle = (string) => {
    if (string.length < 100) {
      return string;
    }

    return string.slice(0, 100) + '...';
  };

  return (
    <div className="offering-faculty-card">
      <div className="offering-faculty-card__top">
        <div className="offering-faculty-card__socials">
          <ul className="offering-faculty-card__socials-list">
            {/* <li className="offering-faculty-card__socials-item">
              <a href="#" className="offering-faculty-card__socials-link" target='_blank'>
                <Icon type='socials--tiktok' size='lg' />
              </a>
            </li> */}

            {twitter && (
              <li className="offering-faculty-card__socials-item">
                <Link
                  href={transformLink(twitter)}
                  className="offering-faculty-card__socials-link"
                  target="_blank"
                >
                  <Icon type="socials--twitter" size="lg" />
                </Link>
              </li>
            )}

            {youtube && (
              <li className="offering-faculty-card__socials-item">
                <Link
                  href={transformLink(youtube)}
                  className="offering-faculty-card__socials-link"
                  target="_blank"
                >
                  <Icon type="socials--youtube" size="lg" />
                </Link>
              </li>
            )}

            {instagram && (
              <li className="offering-faculty-card__socials-item">
                <Link
                  href={transformLink(instagram)}
                  className="offering-faculty-card__socials-link"
                  target="_blank"
                >
                  <Icon type="socials--instagram" size="lg" />
                </Link>
              </li>
            )}

            {facebook && (
              <li className="offering-faculty-card__socials-item">
                <Link
                  href={transformLink(facebook)}
                  className="offering-faculty-card__socials-link"
                  target="_blank"
                >
                  <Icon type="socials--facebook" size="lg" />
                </Link>
              </li>
            )}

            {site && (
              <li className="offering-faculty-card__socials-item">
                <Link
                  href={transformLink(site)}
                  className="offering-faculty-card__socials-link"
                  target="_blank"
                >
                  <Icon type="socials--website" size="lg" />
                </Link>
              </li>
            )}

            {email && (
              <li className="offering-faculty-card__socials-item">
                <Link
                  href={transformLink(email, true)}
                  className="offering-faculty-card__socials-link"
                >
                  <Icon type="socials--email" size="lg" />
                </Link>
              </li>
            )}
          </ul>
        </div>

        <div className="offering-faculty-card__visual">
          {(user_image || profile_image) && (
            <>
              {user_image && <img src={user_image.path} alt="user" />}

              {profile_image && !user_image && (
                <img src={profile_image.path} alt="user" />
              )}
            </>
          )}
          {!profile_image && !user_image && <Icon type="user-undefined--new" />}
        </div>
      </div>

      <div className="offering-faculty-card__bottom">
        <div className="offering-faculty-card__content">
          <div className="offering-faculty-card__content-top">
            <h4 className="offering-faculty-card__name">{display_name}</h4>

            <p className="offering-faculty-card__instruction">
              {art_instruction && trimSubtitle(art_instruction)}
            </p>

            <div
              className="offering-faculty-card__bio text-editor-block"
              dangerouslySetInnerHTML={{
                __html: bio,
              }}
            />
          </div>

          <div className="offering-faculty-card__content-bottom">
            <Link
              className="offering-faculty-card__button"
              type="button-transparent"
              href={`${globalWidgetLink}/faculty/${id}`}
            >
              Faculty profile
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
