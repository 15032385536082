import React, { useEffect, useState } from 'react';
import {
  setActiveTab,
  createTab,
  changeBodyScroll,
  getActiveTab,
} from '@src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getModal, openModal } from '@components/shared';
import { useHistory, useLocation } from 'react-router-dom';
import { logout } from '@actions/authActions';
import { GlobalHeader } from '@components/global';
import { ProviderHeader } from '@components/provider';
import { Link } from '@mui/material';

export const Header = ({ isGlobalWidget, hasRentalsPage }) => {
  const dispatch = useDispatch();
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();
  const [isAccount, setIsAccount] = useState();
  const initTab = pathname.split('/')[1] || 'offerings';

  const [pageTabs, setPageTabs] = useState(
    [
      createTab('Offerings', 'offerings'),
      createTab('Schedule', 'schedule'),
      createTab('Faculty', 'faculty'),
      isGlobalWidget && createTab('Providers', 'providers'),
      !isGlobalWidget && createTab('About', 'about'),
      createTab('Contact', 'contact'),
    ].filter((tab) => tab)
  );

  useEffect(() => {
    if (hasRentalsPage) {
      setPageTabs((tabs) => [...tabs, createTab('Rentals', 'rentals')]);
    }
    setActiveTab(setPageTabs, initTab);
  }, [hasRentalsPage]);

  useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      const newActiveTab = pathname.split('/')[1];
      setActiveTab(setPageTabs, newActiveTab);
    });

    return () => unlisten();
  }, []);

  const onBurgerClick = () => {
    if (!isMobileNavOpen) {
      setIsMobileNavOpen(true);
      changeBodyScroll(true, 'modal');
    } else {
      setIsMobileNavOpen(false);
      changeBodyScroll(false, 'modal');
    }
  };

  const closeMenu = () => {
    setIsMobileNavOpen(false);
    changeBodyScroll(false, 'modal');
  };

  const onLoginClick = () => {
    setIsMobileNavOpen(false);
    openModal(
      getModal({
        name: 'authModal',
      })
    );
  };

  const onLogoutClick = () => {
    setIsMobileNavOpen(false);
    dispatch(logout());
  };

  const onDonationButtonClick = () => {
    setIsMobileNavOpen(false);
    openModal(
      getModal({
        name: 'donation',
      })
    );
  };

  useEffect(() => {
    const currentPage = pathname.split('/')[1];

    if (currentPage === 'account') {
      setIsAccount(true);
    }

    if (currentPage !== 'account' && isAccount) {
      setIsAccount(false);
    }

    if (isMobileNavOpen) {
      setIsMobileNavOpen(false);
      changeBodyScroll(false, 'modal');
    }
  }, [pathname]);

  const isActiveTabClickable =
    pathname !== `/${getActiveTab(pageTabs)?.value}` &&
    getActiveTab(pageTabs)?.value !== 'schedule';

  return (
    <>
      {isGlobalWidget ? (
        <GlobalHeader
          onDonationButtonClick={onDonationButtonClick}
          isAuthorized={isAuthorized}
          onLoginClick={onLoginClick}
          onLogoutClick={onLogoutClick}
          isMobileNavOpen={isMobileNavOpen}
          onBurgerClick={onBurgerClick}
          closeMenu={closeMenu}
          isAccount={isAccount}
          tabsData={pageTabs}
        />
      ) : (
        <ProviderHeader
          onDonationButtonClick={onDonationButtonClick}
          isAuthorized={isAuthorized}
          onLoginClick={onLoginClick}
          onLogoutClick={onLogoutClick}
          isMobileNavOpen={isMobileNavOpen}
          onBurgerClick={onBurgerClick}
          closeMenu={closeMenu}
          tabsData={pageTabs}
          isActiveTabClickable={isActiveTabClickable}
        />
      )}
    </>
  );
};
