import React from 'react';
import { enrollmentTypes } from '@src/constants';
import { getAges, getFormattedUpcommingDate } from '@src/utils';
import {
  Button,
  Icon,
  InstructorName,
  CourseCategory,
  CourseAgeTypes,
  CourseParticipantLevels,
  CourseMarketing,
  Loader,
  Link,
  Image,
} from '@components/shared';
import { Container } from '@components/layout';
import { Sponsors } from '@components/features';
import { globalWidgetLink } from '@src/constants';

export const OfferingDetailsMainInfo = ({ data, setView }) => {
  const {
    description,
    participant_levels,
    participant_level_exception,
    participant_age_from,
    participant_age_to,
    participant_age_types,
    participant_age_exception,
    categories,
    type,
    profile_image_url,
    location,
    is_onsite,
    is_online,
    main_instructor_1,
    upcoming_class,
    notes,
    social_links,
    loading,
    sponsors,
    is_private_rental,
    is_private,
    content_provider,
    content_type,
  } = data;

  const { address } = location;
  const { address_1, city, zip } = address;
  const hasAdditional = !!notes;
  const ageRange = participant_age_from || participant_age_to;
  const instructorName = main_instructor_1?.display_name;
  const locationId = location?.id;

  const addLocation = () => {
    if (location && is_onsite) {
      return (
        <Link
          href={`${globalWidgetLink}/offerings?is_onsite=true&location=${locationId}`}
        >
          <span className="cdm-text--semibold">Onsite: </span>
          {location.name},{' '}
          <span>
            {address_1}, {city}, {zip}
          </span>
        </Link>
      );
    } else if (is_online) {
      return (
        <Link href={`${globalWidgetLink}/offerings?is_online=true`}>
          Online
        </Link>
      );
    } else {
      return '–';
    }
  };

  const onScheduleClick = () => {
    setView('schedule');
  };

  const onLocationClick = () => {
    setView('additional-info');
  };

  return (
    <>
      <div className="offering-details__tab-content offering-details__main-info">
        {loading && <Loader global />}

        <div className="offering-main">
          <Container>
            <div className="offering-main__body">
              <div className="offering-main__top">
                <div className="offering-main__visual">
                  {profile_image_url ? (
                    <>
                      <Image
                        className="offering-main__image"
                        src={profile_image_url}
                        alt=""
                      />
                    </>
                  ) : (
                    <div className="offering-main__image-stub"></div>
                  )}
                </div>

                <div className="offering-main__options">
                  <div className="options options--instructor">
                    <p className="options__title">Faculty</p>
                    <div className="options__description">
                      {instructorName ? (
                        <InstructorName
                          course={data}
                          hasLink={true}
                          isOfferingDetailsPage
                        />
                      ) : (
                        '–'
                      )}
                    </div>
                  </div>

                  <div className="options options--category">
                    <p className="options__title">Category</p>
                    <div className="options__description">
                      {categories ? <CourseCategory course={data} /> : '–'}
                    </div>
                  </div>

                  <div className="options options--marketings">
                    <p className="options__title">Socials:</p>

                    <div className="options__description">
                      {social_links.length ? (
                        <CourseMarketing socialLinks={social_links} />
                      ) : (
                        '–'
                      )}
                    </div>
                  </div>

                  <div className="options options--enrollment-type">
                    <p className="options__title">Enrollment Type</p>
                    <div className="options__description">
                      {type ? enrollmentTypes[type] : '–'}
                    </div>
                  </div>

                  <div className="options options--age-range">
                    <p className="options__title">Age Range</p>
                    <div className="options__description">
                      {ageRange ? (
                        <>
                          <Link
                            href={`${globalWidgetLink}/offerings?participant_age_from=${participant_age_from}&participant_age_to=${participant_age_to}`}
                          >
                            {`Ages ${getAges(
                              participant_age_from,
                              participant_age_to
                            )}`}
                            <sup>{participant_age_exception}</sup>
                          </Link>
                        </>
                      ) : (
                        '–'
                      )}
                    </div>
                  </div>

                  <div className="options options--type">
                    <p className="options__title">Age Type</p>
                    <div className="options__description">
                      {participant_age_types.length ? (
                        <CourseAgeTypes ageTypes={participant_age_types} />
                      ) : (
                        '–'
                      )}
                    </div>
                  </div>

                  <div className="options options--participant-level">
                    <p className="options__title">Participant Level</p>
                    <div className="options__description">
                      {participant_levels.length ? (
                        <CourseParticipantLevels
                          levels={participant_levels}
                          exceptions={participant_level_exception}
                        />
                      ) : (
                        '–'
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="offering-main__bottom">
                {is_private && !is_private_rental && (
                  <div className="offering-main__notification">
                    <Icon type="info" size="md" />
                    This Offering is available for registation. Please see
                    registration details below.
                  </div>
                )}
                {is_private_rental && (
                  <div className="offering-main__notification">
                    <Icon type="info" size="md" />
                    <span>
                      This is a Private Studio Rental.{' '}
                      {content_provider.config?.rentals_page_enabled && (
                        <>
                          If you are interested in renting facilities for your
                          personal events, art events, rehearsals, in{' '}
                          {content_provider.name}, please check our rental
                          policy{' '}
                          <Link isRouterLink={true} to="/rentals">
                            here
                          </Link>
                          .
                        </>
                      )}
                    </span>
                  </div>
                )}
                <div className="offering-main__options">
                  <div className="options options--upcoming">
                    <p className="options__title">
                      <Icon type="calendar" size="md" />
                      Upcoming {content_type.singular_short_name}:
                    </p>
                    <div className="options__description">
                      {upcoming_class
                        ? getFormattedUpcommingDate(upcoming_class)
                        : '–'}

                      <Button
                        className="options__button"
                        type="link"
                        onClick={onScheduleClick}
                      >
                        Schedule
                      </Button>
                    </div>
                  </div>

                  <div className="options options--location">
                    <p className="options__title">
                      <Icon type="location" size="md" /> Location:
                    </p>
                    <div className="options__description">
                      {addLocation()}

                      {hasAdditional && (
                        <Button
                          className="options__button"
                          type="link"
                          onClick={onLocationClick}
                        >
                          See Location details
                        </Button>
                      )}
                    </div>
                  </div>
                </div>

                {description && (
                  <div
                    className="text-editor-block offering-main__description"
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></div>
                )}
              </div>
            </div>
          </Container>

          {!!sponsors && (
            <div className="offering-main__sponsors">
              <Container>
                <Sponsors
                  sponsorsData={sponsors}
                  titleClassName="offering-main__title"
                />
              </Container>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
