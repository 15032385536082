import React from 'react';
import { classNames } from '@src/utils';
import PropTypes from 'prop-types';
import { Button } from '@components/shared';

export const BurgerButton = ({ isActive, onClick }) => {
  return (
    <Button
      className={classNames(['burger-btn', isActive && 'active'])}
      onClick={onClick}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </Button>
  );
};

BurgerButton.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

BurgerButton.defaultProps = {
  isActive: false,
  onClick: () => {},
};
