import React, { useState, useEffect } from 'react';
import { Loader, ErrorMessage } from '@components/shared';
import { SnailAddress } from './InstitutionsCards';
import { AccountInstitutionsSettingsItem } from './AccountInstitutionsSettingsItem';
import { scrollToFirstError } from '@src/utils';
import { globalWidgetLink } from '@src/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAffiliations,
  updateProviderAffiliations,
} from '@actions/authActions';

export const AccountInstitutionsSettings = ({
  userData,
  editorState,
  setEditorState,
}) => {
  const providersAffilations = useSelector((state) => state.auth.affiliations);
  const [institutions, setInstitutions] = useState([]);
  const [addressError, setAddressError] = useState(false);
  const dispatch = useDispatch();

  const globalInstitutionParams = {
    isGlobal: true,
    content_provider_id: 'global',
    content_provider: {
      name: 'World Education Web',
      base_url: globalWidgetLink,
      config: {
        support_phone_number: '(617) 642-1444',
        support_email: 'dan.yonah.marshall@gmail.com',
      },
    },
  };

  useEffect(() => {
    getAffiliationsRequest();

    return () => setEditorState('');
  }, []);

  useEffect(() => {
    if (providersAffilations && userData.data) {
      const globalAffilations = {
        ...userData.data.student,
        ...globalInstitutionParams,
      };
      setInstitutions([globalAffilations, ...providersAffilations]);
    }

    if (editorState) {
      setEditorState('');
    }
  }, [providersAffilations, userData.data]);

  useEffect(() => {
    if (addressError) {
      setEditorState('');
      scrollToFirstError();
    }
  }, [addressError]);

  useEffect(() => {
    if (editorState) {
      setAddressError(false);
    }
  }, [editorState]);

  const updateProviderAffiliation = (data, id) => {
    dispatch(updateProviderAffiliations(data, id, getAffiliationsRequest));
  };

  const getAffiliationsRequest = () => {
    dispatch(getAffiliations());
  };

  return (
    <div className="account-institutions">
      <h2 className="account__content-title">Institutions settings</h2>
      <Loader show={userData.loading} sticky />

      <div className="account-institutions__body">
        <div className="account-institutions__address">
          <SnailAddress
            userData={userData}
            editorState={editorState}
            setEditorState={setEditorState}
          />
          <ErrorMessage
            show={addressError}
            text="Please, fill in your address to recieve snail mail"
          />
        </div>
        {institutions.map((institution, index) => (
          <div className="account-institutions__item" key={index}>
            <AccountInstitutionsSettingsItem
              editorState={editorState}
              setEditorState={setEditorState}
              institution={institution}
              setAddressError={setAddressError}
              updateProviderAffiliation={updateProviderAffiliation}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
