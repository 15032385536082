import React, { useEffect, useRef } from 'react';
import { Icon } from '@components/shared';
import { classNames } from '@src/utils';

export const Tooltip = ({
  children,
  title,
  position,
  className,
  body,
  type = 'info',
}) => {
  const tooltipWrapperRef = useRef(null);

  const changeTooltipPosition = () => {
    const tooltipWrapper = tooltipWrapperRef.current;
    const tooltipWrapperLeft = tooltipWrapper?.getBoundingClientRect().left;

    if (tooltipWrapperLeft < 0) {
      tooltipWrapper.style.left = '50%';
    } else {
      tooltipWrapper.removeAttribute('style');
    }
  };

  useEffect(() => {
    changeTooltipPosition();

    window.addEventListener('resize', changeTooltipPosition);

    return () => window.removeEventListener('resize', changeTooltipPosition);
  }, [tooltipWrapperRef.current?.getBoundingClientRect().left]);

  const positions = {
    top: 'tooltip--top',
    right: 'tooltip--right',
    left: 'tooltip--left',
    bottom: 'tooltip--bottom',
  };

  const types = {
    info: 'tooltip--info',
    danger: 'tooltip--danger',
  };

  return (
    <div
      className={classNames([
        'tooltip',
        positions[position],
        types[type],
        className,
      ])}
    >
      {body || <Icon type="info" className="tooltip__icon" />}
      <div className="tooltip__content">
        <div ref={tooltipWrapperRef} className="tooltip__wrapper">
          <div className="tooltip__title">{title}</div>
          <div className="tooltip__body">{children}</div>
        </div>
      </div>
    </div>
  );
};
