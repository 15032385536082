import React, { useState, useEffect } from 'react';
import { Tabs } from '@components/shared';
import { createTab, setActiveTab, getActiveTab, queryParam } from '@src/utils';

export const AccountTicketsNavigation = ({ upcomingTickets, pastTickets }) => {
  const upcomingTicketsTab = 'upcoming',
  pastTicketsTab = 'past';

  const initView = queryParam.get('tab');

  const [ticketsTabs, setTicketsTabs] = useState([
    createTab('Upcoming', upcomingTicketsTab, initView !== 'past'),
    createTab('Past', pastTicketsTab, initView === 'past'),
  ]);

  const render = {
    [upcomingTicketsTab]: upcomingTickets,
    [pastTicketsTab]: pastTickets,
  };

  const activeTab = getActiveTab(ticketsTabs);

  useEffect(() => {
    queryParam.set('tab', activeTab?.value);

    return () => queryParam.delete('tab')
  }, [activeTab]);

  return (
    <>
      <div className="account-tickets__tabs">
        <Tabs
          tabsData={ticketsTabs}
          className="tabs--bordered"
          onTabClick={(tab) =>
            setActiveTab(setTicketsTabs, tab?.value)
          }
        />
      </div>
      {render[activeTab?.value]}
    </>
  )
};