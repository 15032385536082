import React from 'react';
import { Icon } from '@components/shared';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ContactMembersCard } from './ContactMembersCard';
import 'swiper/swiper.scss';

export const ContactMembers = ({ contacts }) => {
  const { title, list } = contacts;
  const slidesCount = list.length;
  const sliderSettings = {
    className: 'contact-members__slider',
    navigation: {
      nextEl: '.contact-members__next-button',
      prevEl: '.contact-members__prev-button',
    },
    loop: true,
    slidesPerView: slidesCount > 1 ? 1 : slidesCount,
    enabled: slidesCount > 1,
    spaceBetween: 24,
    breakpoints: {
      768: {
        slidesPerView: slidesCount > 2 ? 2 : slidesCount,
        enabled: slidesCount > 2,
      },
      1150: {
        slidesPerView: slidesCount > 3 ? 3 : slidesCount,
        enabled: slidesCount > 3,
      },
    },
  };

  return (
    <div className="page-block contact-members">
      {title && (
        <div className="contact-members__title-block">
          <h2 className="page-block-title contact-members__title">{title}</h2>
        </div>
      )}
      <div className="contact-members__body">
        <Swiper {...sliderSettings}>
          {list.map((card, index) => {
            return (
              <SwiperSlide className="contact-members__slide" key={index}>
                <ContactMembersCard card={card} />
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className="global-page__buttons contact-members__buttons">
          <div className="global-page__prev-button contact-members__prev-button">
            <Icon type="arrow-prev" />
          </div>

          <div className="global-page__next-button contact-members__next-button">
            <Icon type="arrow-next" />
          </div>
        </div>
      </div>
    </div>
  );
};
