import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserData, logout } from '@actions/authActions';
import { normalizeUserData } from '@src/normalizers';
import { getModal, openModal } from '@components/shared';
import { AffiliationInvitation } from './AffiliationInvitation';
import { openLink, queryParam } from '@src/utils';

export const GlobalAffiliationInvitation = ({ redirectTo }) => {
  const dispatch = useDispatch();
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  queryParam.delete('regStep');

  const submitHandler = (values) => {
    const userData = normalizeUserData(values, 'affiliation');
    dispatch(updateUserData(userData, submitCallback));
  };

  const submitCallback = () => {
    openLink(redirectTo);
  };

  useEffect(() => {
    if (!isAuthorized) {
      openLink(redirectTo);
    }
  }, [isAuthorized]);

  const openPolicy = () => {
    openModal(
      getModal({
        name: 'policy-notice',
        nativeClose: false,
        data: {
          isModal: true,
          isGlobal: true,
        },
      })
    );
  };

  const onUserLogaut = () => {
    dispatch(logout());
  };

  const WEWData = {
    organizationName: 'World Education Web',
    waiverEnabled: true,
    snailSubcriptionEnabled: true,
    emailSubcriptionEnabled: true,
    sourceSurveysEnabled: true,
    waiverText: `I understand that occasional photography, video, and audio, may be taken during classes/workshops/events/other offerings, and used for archival, marketing, and social media purposes. When used for archival and reselling of digital recordings of classes/workshops/events/other offerings (to provide residual income for instructors/producers/faculty members, and for other charitable purposes) a specific authorization in the form of explicit permission will be needed. By signing the Media Consent and Waiver, I am giving permission only to be recorded with photography, video, and/or audio during classes/workshops/events/other offerings. I understand that I may reserve the right not to sign the Media Consent and Waiver or to change my preference at any time, in which case my image, and/or video, and/or audio, will no longer be taken going forward from the date I choose to decline the Media and Consent Waiver.  This Media Consent and Waiver applies to offerings produced/facilitated by the World Education Web (worldeducationweb.org), a non-profit online platform created, owned, and developed by the Brookline/Cambridge Community Center for the Arts, Inc. (AKA BCCA / CCCA), while each content Provider that has an account in the World Education Web, may have their own individual media consent and waiver to govern the offerings it produces/facilitates.  Additionally, the World Education Web (worldeducationweb.org) platform may use examples of photography, cover art, video, and audio from Provider offerings it hosts, to demonstrate the types and diversity of offerings in the platform for archival, marketing, and social media purposes.`,
  };

  return (
    <AffiliationInvitation
      submitHandler={submitHandler}
      openPolicy={openPolicy}
      organizationData={WEWData}
      isGlobal={true}
      onUserLogaut={onUserLogaut}
    />
  );
};
