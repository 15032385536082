import { useState } from 'react';

export const useOldState = initialState => {
  const [oldState, setOldState] = useState(initialState);

  const setState = newState => {
    if (typeof newState === 'function') {
      setOldState(oldState => ({ ...oldState, ...newState(oldState) }));
    }

    setOldState(oldState => ({ ...oldState, ...newState }));
  };

  return [oldState, setState];
};
