import axios from 'axios';
import { store } from '@src/store';
import { closeAllModals, openModal, getModal } from '@components/shared';
import { apiRequest } from '@src/utils';
import Cookies from 'js-cookie';
import { apiUrl } from '@src/constants';

const request = axios.create({
  baseURL: apiUrl,
});

const tokenKey = 'token';

const userLogin = (token) => {
  Cookies.set(tokenKey, token, { domain: '.worldeducationweb.org' });
  // Cookies.set(tokenKey, token);
  store.dispatch({ type: 'IS_AUTHORIZED', payload: true });
};

const userLogout = () => {
  Cookies.remove(tokenKey, { domain: '.worldeducationweb.org' });
  // Cookies.remove(tokenKey);
  
  store.dispatch({ type: 'IS_AUTHORIZED', payload: false });
};

const redirectToLogin = () => {
  const currentUrl = window.location.href;
  closeAllModals();
  openModal(getModal({ name: 'authModal', data: { proceedLink: currentUrl } }));
};

const tokenRefreshApiConfig = (token) => {
  return {
    method: 'POST',
    url: `/auth/refresh`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const authURLs = ['/auth/login', '/auth/refresh', '/account/profile'];

function tokenHandling(res) {
  const isAuthURL = authURLs.includes(res.config.url);
  const isLogout = res.config.url === '/auth/logout';
  const token = res.data.data.token;

  if (isAuthURL && token) {
    userLogin(token);
  }

  if (isLogout) {
    userLogout();
  }

  return res;
}

const onError = (err) => {
  const {
    config,
    response: { status, data },
  } = err;
  const unauthorized = status === 401 && config.url !== '/auth/login';
  const emailNotVerificated =
    status === 403 && data.message === 'Your email address is not verified.';

  if (unauthorized) {
    const originalRequest = config;
    const token = Cookies.get(tokenKey);

    if (token) {
      request(tokenRefreshApiConfig(token))
        .then((response) => {
          const refreshedToken = response.data.data.token;
          Cookies.set(tokenKey, refreshedToken, { domain: '.worldeducationweb.org' });
          // Cookies.set(tokenKey, refreshedToken);
          apiRequest({ ...originalRequest, refreshedToken });
        })
        .catch((err) => {
          userLogout();
          if (err.response?.status === 422) {
            redirectToLogin();
          }
          return Promise.reject(err);
        });
    } else {
      redirectToLogin();
      return Promise.reject(err);
    }
  } else if (emailNotVerificated) {
    closeAllModals();
    openModal(
      getModal({ name: 'email-verification', data: { accessDeniedCase: true } })
    );
  } else if (status >= 500 || status === 429) {
    if (window.location.pathname !== '/server-error') {
      window.location.href = '/server-error';
    }
  } else if (status === 404) {
    if (window.location.pathname !== '/not-found') {
      window.location.href = '/not-found';
    }
  } else {
    return Promise.reject(err);
  }
};

export const interceptors = (axios) => {
  axios.interceptors.response.use(tokenHandling, onError);
}
