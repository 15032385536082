import { Helmet } from 'react-helmet-async';

export const SEO = ({
  title,
  description,
  type,
  canonicalURL,
  image,
  disallow
}) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}

      {type && <meta property="og:type" content={type} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      {canonicalURL && <meta property="og:url" content={canonicalURL} />}

      {type && <meta name="twitter:card" content={type} />}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}

      {canonicalURL && <link rel="canonical" href={canonicalURL}></link>}
      {disallow && <meta name="robots" content="noindex, nofollow"></meta>}
    </Helmet>
  );
};
