import { zonedDate } from '@src/utils';
import { isBefore } from 'date-fns';
import { useApi } from '@src/hooks';
import { CalendarDropdown } from '@components/shared';

export const AccountScheduleCalendar = ({
  today,
  selectedDay,
  setSelectedDay,
}) => {
  const selectedYear = selectedDay.getFullYear();
  const selectedMonth = selectedDay.getMonth() + 1;

  const userScheduleDates = useApi(
    {
      url: `/account/appointments/dates?month=${selectedMonth}&year=${selectedYear}`,
      dataPlaceholder: [],
    },
    [selectedDay]
  );

  const formattedScheduleDates = userScheduleDates.data.map((date) =>
    zonedDate(date)
  );

  const pastEvents = formattedScheduleDates
    .filter((day) => isBefore(day, today))
    .map((day) => day.getTime());
  const futureEvents = formattedScheduleDates
    .filter((day) => !isBefore(day, today))
    .map((day) => day.getTime());

  return (
    <CalendarDropdown
      today={today}
      selectedDay={selectedDay}
      setSelectedDay={setSelectedDay}
      pastEvents={pastEvents}
      futureEvents={futureEvents}
      isAccountCalendar
    />
  );
};
