const getYearsFromMonths = (months) => Math.round(months / 12)

export const getAges = (ageFrom, ageTo) => {
  if (ageFrom && ageTo) {
    return `${getYearsFromMonths(ageFrom)} to ${getYearsFromMonths(ageTo)}`;
  } else if (!ageTo) {
    return `${getYearsFromMonths(ageFrom)} & Up`;
  } else if (!ageFrom) {
    return `${getYearsFromMonths(ageTo)} & Below`;
  } else {
    return '';
  }
};
