import React, { useState, useContext } from 'react';
import { parseNoSpaces, parseOnlyLettersAndNumbers, useForm } from '@src/hooks';
import {
  Button,
  Input,
  Icon,
  closeModal,
  Tooltip,
  Loader,
  RecaptchaMessage,
} from '@components/shared';
import { openLink, apiRequest, requestCallback } from '@src/utils';
import { RecaptchaContext } from '@src/RecaptchaContext.js';

export const Login = ({
  btnCallbacks,
  isCourseReg,
  proceedLink,
  setIsNewUser,
  onPassRecoveryClick,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const recaptcha = useContext(RecaptchaContext);

  const submitHandler = (values) => {
    setIsLoading(true);
    recaptcha.executeAsync().then((token) => {
      const authData = { ...values, g_recaptcha_response: token };
      onSubmitSuccess(authData);
      recaptcha.reset();
    });
  };

  const onSubmitSuccess = async (authData) => {
    if (proceedLink) {
      authData.redirect_to = proceedLink;
    }

    await apiRequest({
      url: `/auth/login`,
      method: 'POST',
      data: JSON.stringify(authData),
      callbacks: requestCallback(
        () => getSubmitCallback(),
        (err) => err && handleServerErrors(err.response)
      ),
    });

    setIsLoading(false);
  };

  const getSubmitCallback = () => {
    window._mtm = window._mtm || [];
    if (isCourseReg) {
      btnCallbacks.submit();
    } else {
      proceedLink ? openLink(proceedLink) : closeModal('registration');
    }
    window._mtm.push({ event: 'login' });
  };

  const schema = {
    login: {
      parse: parseNoSpaces,
      validation: ['isRequired'],
    },
    password: {
      validation: ['isRequired'],
      parse: parseOnlyLettersAndNumbers,
    },
  };

  const { values, errors, handleChange, handleSubmit, setErrors, handleBlur } =
    useForm({
      callback: submitHandler,
      schema,
    });

  const onAuthBtnClick = () => {
    setIsNewUser(true);
  };

  const onBackBtnClick = () => {
    return isCourseReg ? btnCallbacks.back() : closeModal('registration');
  };

  const handleServerErrors = (error) => {
    const { data } = error;

    setErrors({ login: data.message, password: data.message });
  };

  return (
    <div className="registration-step">
      {<Loader show={isLoading} sticky />}
      <div className="registration-step__container">
        <form className="form" onSubmit={handleSubmit}>
          <Icon
            type={isCourseReg ? 'registration-step' : 'auth-welcome'}
            className="auth__icon"
          />
          <h1 className="auth__title">
            {isCourseReg ? (
              'Almost Done!'
            ) : (
              <>
                Welcome to
                <br />
                World Education Web!
              </>
            )}
          </h1>

          <div className="auth__help">
            <span className="auth__help-text">Need Registration Help?</span>
            <Tooltip position="bottom" className="auth__tooltip">
              <span>
                For Log in, Sign up, or Registration assistance, please email
              </span>
              <a href="mailto:dan.yonah.marshalll@gmail.com">
                dan.yonah.marshall@gmail.com
              </a>
              <span>or call (617) 642-1444.</span>
            </Tooltip>
          </div>

          <div className="form__group">
            <Input
              label="Email or Username"
              placeholder="Enter your Email or Username"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.login}
              name="login"
              alert={errors.login}
              required
            />
          </div>

          <div className="form__group">
            <Input
              label="Password"
              placeholder="Enter your Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              password
              name="password"
              alert={errors.password}
              required
            />
          </div>

          <div className="form__group auth__switcher auth__switcher--sign-in">
            <div className="auth__switcher__block">
              <span>Don’t have an account?</span>
              <Button
                type="navigation"
                disabled={isLoading}
                onClick={onAuthBtnClick}
              >
                Sign up
              </Button>
            </div>

            <div className="auth__switcher__block">
              <div>Returning Student or Forgot password?</div>
              <Button
                type="navigation"
                htmlType="button"
                className="auth__forgot-password"
                onClick={() => onPassRecoveryClick()}
              >
                Recover / Create Password
              </Button>
            </div>
          </div>

          <div className="form__group form__group--auth-buttons">
            <Button
              type="transparent"
              onClick={onBackBtnClick}
              htmlType="button"
              disabled={isLoading}
              iconLeft={<Icon type="chevron" />}
            >
              Back
            </Button>

            <Button type="primary" htmlType="submit" disabled={isLoading}>
              Submit
            </Button>
          </div>
          <RecaptchaMessage />
        </form>
      </div>
    </div>
  );
};
