import { parseOnlyLettersAndNumbers } from '@src/hooks';

export const getProfileSchema = (
  editorState,
  { isUnderAge } = {}
) => {
  let schema = {};

  if (editorState === 'personalInfo') {
    schema = {
      firstName: { validation: ['isRequired'] },
      lastName: { validation: ['isRequired'] },
      birthDate: { validation: ['birthDate'] },
    };
  }

  if (editorState === 'contact') {
    schema = {
      cellPhone: { validation: ['phone'] },
      workPhone: { validation: ['phone'] },
      homePhone: { validation: ['phone'] },
    };
  }

  if (editorState === 'parentInfo') {
    schema = {
      parentPhone: isUnderAge
        ? { validation: ['isRequired', 'phone'], valuesRequired: ['underAge'] }
        : { validation: ['phone'] },
      parentName: { validation: ['isRequired'], valuesRequired: ['underAge'] },
    };
  }

  if (editorState === 'emergencyContacts') {
    schema = {
      emergencyContactPhone: { validation: ['phone'] },
    };
  }

  // if (editorState === 'snailMailAddress') {
  //   schema = {
  //     address: {
  //       validation: ['isRequired'],
  //     },
  //     country: {
  //       validation: ['isRequired'],
  //     },
  //     city: {
  //       validation: ['isRequired'],
  //     },
  //     zip: {
  //       validation: ['isRequired'],
  //     },
  //     state: {
  //       customValidation: [
  //         {
  //           check: (value) => (showStates ? value : true),
  //           message: 'State is required',
  //         },
  //       ],
  //     },
  //   };
  // }

  if (editorState === 'changePassword') {
    schema = {
      oldPassword: {
        validation: ['isRequired'],
        parse: parseOnlyLettersAndNumbers,
      },
      password: {
        validation: ['isRequired', 'password'],
        parse: parseOnlyLettersAndNumbers,
      },
      confirmPassword: {
        parse: parseOnlyLettersAndNumbers,
        customValidation: [
          {
            check: (value) => value,
            message: 'Please repeat your password',
          },
          {
            check: (value, values) =>
              values.newPassword ? value === values.newPassword : true,
            message: 'Passwords do not match. Please repeat.',
          },
        ],
      },
    };
  }

  return schema;
};
