export const getDateTimes = (start, end) => {
    const splittedStartTime = start.split(':');
    const splittedEndTime = end.split(':');
  
    const startHours = splittedStartTime[0];
    const startMinutes = splittedStartTime[1];
  
    const endHours = splittedEndTime[0];
    const endMinutes = splittedEndTime[1];
  
    const startTime = new Date();
    const endTime = new Date();
  
    startTime.setHours(+startHours, +startMinutes);
    endTime.setHours(+endHours, +endMinutes);
  
    return {
      startTime,
      endTime,
    };
  };