import React from 'react';

export const CheckoutTableMobile = ({ items = [] }) => {
  return (
    <div className="checkout-table checkout-table--mobile-only">
      {items.map((item, index) => {
        const {
          title,
          quantity,
          date,
          time,
          price,
          discount,
          increase,
          number,
        } = item;

        return (
          <div className="checkout-table__block" key={index}>
            {!!number && (
              <div className="checkout-table__row">
                <p className="checkout-table__value checkout-table__number">
                  № {number}
                </p>
              </div>
            )}
            {!!title && (
              <div className="checkout-table__row">
                {!!number && <p className="checkout-table__label">Name</p>}
                <p className="checkout-table__value checkout-table__name">
                  {title}
                </p>
              </div>
            )}
            {!!date && (
              <div className="checkout-table__row">
                <p className="checkout-table__label">Date</p>
                <p className="checkout-table__value checkout-table__date">
                {Array.isArray(date)
                  ? date.map((value) => <span>{value}</span>)
                  : date}
                </p>
              </div>
            )}
            {!!time && (
              <div className="checkout-table__row">
                <p className="checkout-table__label">Time</p>
                <p className="checkout-table__value checkout-table__time">
                  {Array.isArray(time)
                    ? time.map((value) => <span>{value}</span>)
                    : time}
                </p>
              </div>
            )}
            {!!quantity && (
              <div className="checkout-table__row">
                <p className="checkout-table__label">Quantity</p>
                <p className="checkout-table__value checkout-table__quantity">
                  {quantity}
                </p>
              </div>
            )}
            <div className="checkout-table__row">
              <p className="checkout-table__label">Price</p>
              <p className="checkout-table__value checkout-table__price">
                ${price}
              </p>
            </div>
            {!!discount && (
              <div className="checkout-table__row">
                <p className="checkout-table__label">Discount</p>
                <p className="checkout-table__value checkout-table__discount">
                  -${discount}
                </p>
              </div>
            )}
            {!!increase && (
              <div className="checkout-table__row">
                <p className="checkout-table__label">Increase</p>
                <p className="checkout-table__value checkout-table__increase">
                  ${increase}
                </p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
