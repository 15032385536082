import React, { useEffect, useState } from 'react';
import { PaymentMethodButtons, Loader, Button, Icon } from '@components/shared';
import { useViewport } from '@src/hooks';
import { CheckoutTable } from './CheckoutTable';
import { CheckoutTableMobile } from './CheckoutTableMobile';
import { useSelector } from 'react-redux';
import { normalizeCheckoutData } from '@src/normalizers';

export const Checkout = ({ btnCallbacks }) => {
  const orderData = useSelector((state) => state.registration.orderData);
  const [checkoutData, setCheckoutData] = useState({});

  useEffect(() => {
    if (orderData.data) {
      setCheckoutData(normalizeCheckoutData(orderData.data));
    }

    if (!orderData.loading && !orderData.data) {
      btnCallbacks.back();
    }
  }, [orderData.data]);

  const { isMobile } = useViewport();

  return (
    <div className="registration-step registration-step--5 checkout">
      <form>
        <div className="registration-step__group">
          <h4 className="registration-step__title">Checkout information</h4>
          <span className="registration-step__subtitle">
            Please check your order information, choose a desired payment
            method, and click to process your order
          </span>
        </div>
        {orderData.loading ? (
          <Loader className={'checkout__loader'}/>
        ) : (
          <>
            {!!checkoutData.items?.length && (
              <div className="registration-step__group">
                {isMobile ? (
                  <CheckoutTableMobile items={checkoutData.items} />
                ) : (
                  <CheckoutTable items={checkoutData.items} />
                )}
              </div>
            )}

            <div className="checkout__summary">
              <h4 className="registration-step__title checkout__summary-title">
                Order summary
              </h4>
              {!!checkoutData.subtotal &&
                checkoutData.subtotal !== checkoutData.total && (
                  <div className="checkout__correction">
                    <span className="checkout__correction-label">Subtotal</span>
                    <span className="checkout__correction-value">
                      ${checkoutData.subtotal}
                    </span>
                  </div>
                )}
              {!!checkoutData.discount && (
                <div className="checkout__correction">
                  <span className="checkout__correction-label">Discount</span>
                  <span className="checkout__correction-value">
                    -${checkoutData.discount}
                  </span>
                </div>
              )}
              {!!checkoutData.increase && (
                <div className="checkout__correction">
                  <span className="checkout__correction-label">Increase</span>
                  <span className="checkout__correction-value">
                    ${checkoutData.increase}
                  </span>
                </div>
              )}
              {!!checkoutData.donation && (
                <div className="checkout__correction">
                  <span className="checkout__correction-label">Donation</span>
                  <span className="checkout__correction-value">
                    ${checkoutData.donation}
                  </span>
                </div>
              )}
              <div className="checkout__total">
                <span className="checkout__total-label">TOTAL</span>
                <span className="checkout__total-value">
                  ${checkoutData.total}
                </span>
              </div>
            </div>

            <div className="btn__wrapper">
              <Button
                htmlType="button"
                onClick={btnCallbacks.back}
                type="transparent"
                iconLeft={<Icon type="chevron" />}
              >
                Back
              </Button>
              {checkoutData.total ? (
                <PaymentMethodButtons
                  handleSubmit={(payment_gateway) =>
                    btnCallbacks.submit(payment_gateway)
                  }
                />
              ) : (
                <Button
                  htmlType="button"
                  onClick={() => btnCallbacks.submit()}
                  type="primary"
                >
                  Proceed for free
                </Button>
              )}
            </div>
          </>
        )}
      </form>
    </div>
  );
};
