export const dragScroll = (element, horizontalOnly = true) => {
  let isHolding = false;
  let initOffsetTop = 0;
  let initOffsetLeft = 0;
  let initClientX = 0;
  let initClientY = 0;

  const child = element.firstElementChild;

  if (child.offsetWidth - element.offsetWidth > 0) element.classList.add('blurred');

  const onResize = () => {
    child.offsetWidth - element.offsetWidth > 0
      ? element.classList.add('blurred')
      : element.classList.remove('blurred');
  };

  const onScroll = () => {
    child.offsetWidth - element.offsetWidth - 5 <= element.scrollLeft
    ? element.classList.add('blurred--inactive')
    : element.classList.remove('blurred--inactive');
  }

  const onMouseUp = (e) => {
    e.preventDefault();
    isHolding = false;
    element.classList.remove('drag-scroll--hold');
  };

  const onMouseDown = (e) => {
    e.preventDefault();
    if (child.offsetWidth - element.offsetWidth <= 0) return
    element.classList.add('drag-scroll--hold');
    isHolding = true;

    initOffsetTop = element.offsetTop - element.scrollTop;
    initOffsetLeft = element.offsetLeft - element.scrollLeft;

    initClientX = e.clientX;
    initClientY = e.clientY;
  };

  const onMouseMove = (e) => {
    if (isHolding) {
      const element = e.currentTarget;
      const offsetTop = element.offsetTop;
      const offsetLeft = element.offsetLeft;

      const clientX = e.clientX;
      const clientY = e.clientY;

      const differenceX = clientX - offsetLeft;
      const initDifferenceX = initClientX - initOffsetLeft;
      const differenceY = clientY - offsetTop;
      const initDifferenceY = initClientY - initOffsetTop;

      element.scrollLeft = initDifferenceX - differenceX;

      if (!horizontalOnly) element.scrollTop = initDifferenceY - differenceY;
    }
  };

  const onMouseLeave = (e) => {
    isHolding = false;
  };

  const init = () => {
    if (!element) throw new Error('No element passed to dragScroll');

    element.addEventListener('mouseup', onMouseUp);
    element.addEventListener('mousemove', onMouseMove);
    element.addEventListener('mousedown', onMouseDown);
    element.addEventListener('mouseleave', onMouseLeave);
    element.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onResize);
  };

  const destroy = () => {
    if (!element) throw new Error('No element passed to dragScroll');

    element.removeEventListener('mouseup', onMouseUp);
    element.removeEventListener('mousemove', onMouseMove);
    element.removeEventListener('mousedown', onMouseDown);
    element.removeEventListener('mouseleave', onMouseLeave);
    element.removeEventListener('scroll', onScroll);
    window.removeEventListener('resize', onResize);
  };

  return {
    init,
    destroy,
  };
};
