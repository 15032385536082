import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BlockOfClasses } from './BlockOfClasses';
import { Trial } from './Trial';
import { BuyingVideo } from './BuyingVideo';
import { BuyingTickets } from './BuyingTickets/BuyingTickets';
import { PreRegistration } from './PreRegistration';
import { DropIn } from './DropIn';
import { SlidingScale } from './SlidingScale';
import { classNames } from '@src/utils';
import { registerTypes } from '@src/constants';
import { clearRegData } from '@actions/registrationActions';
import { useDispatch } from 'react-redux';

export const ClassRegistration = ({
  regType,
  btnCallbacks,
  sessionInfo,
  courseInfo,
}) => {
  const { content_type } = courseInfo;
  const renderRegTypes = {
    d: (
      <DropIn
        btnCallbacks={btnCallbacks}
        sessionInfo={sessionInfo}
        contentType={content_type}
      />
    ),
    f: (
      <DropIn
        btnCallbacks={btnCallbacks}
        sessionInfo={sessionInfo}
        contentType={content_type}
        isFree
      />
    ),
    b: (
      <BlockOfClasses
        btnCallbacks={btnCallbacks}
        sessionInfo={sessionInfo}
        contentType={content_type}
      />
    ),
    s: (
      <SlidingScale
        btnCallbacks={btnCallbacks}
        sessionInfo={sessionInfo}
        contentType={content_type}
      />
    ),
    p: (
      <PreRegistration
        btnCallbacks={btnCallbacks}
        sessionInfo={sessionInfo}
        contentType={content_type}
      />
    ),
    t: (
      <BuyingTickets
        btnCallbacks={btnCallbacks}
        courseInfo={courseInfo}
        sessionInfo={sessionInfo}
      />
    ),
    v: <BuyingVideo btnCallbacks={btnCallbacks} courseInfo={courseInfo} />,
    tr: (
      <Trial
        btnCallbacks={btnCallbacks}
        sessionInfo={sessionInfo}
        contentType={content_type}
      />
    ),
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearRegData());
  }, []);

  return (
    <div
      className={classNames([
        'registration-step',
        `registration-step--${registerTypes[regType]}`,
        'registration-step--3',
      ])}
    >
      {renderRegTypes[regType]}
    </div>
  );
};
ClassRegistration.propTypes = {
  btnCallbacks: PropTypes.object,
  sessionInfo: PropTypes.object,
};

ClassRegistration.defaultProps = {
  regType: 'd',
};
