import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  searchWithSeparator,
  setFiltersQuery,
  selectedFiltersCounter,
} from '@src/utils';
import { Select, Button, SlideMenu, Icon, Counter } from '@components/shared';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCategoryFilter,
  setInstructorFilter,
  setLocationFilter,
  setProgramFilter,
  clearFilters,
} from '@actions/scheduleActions';
import { instructorsToSelectData } from '@src/utils/dataNormalizers/instructorsToSelectData';
import { useViewport } from '@src/hooks';

export const NavigationPanel = ({
  pagination,
  additionalFilters,
}) => {
  const filtersSelect = useSelector((state) => state.schedule.filtersSelect);
  const filters = useSelector((state) => state.schedule.filters);
  const [selectedFiltersNumber, setSelectedFiltersNumber] = useState(null);
  const { isTablet } = useViewport();
  const [filtersMenuOpen, setFiltersMenuOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setFiltersQuery(filtersSelect);
    setSelectedFiltersNumber(selectedFiltersCounter(filtersSelect));
  }, [filtersSelect]);

  const renderFilters = () => (
    <>
      <Select
        className="input-group__wrapper"
        placeholder="Location"
        onChange={({ id }) => dispatch(setLocationFilter(id))}
        value={filtersSelect.location || ''}
        options={filters.data?.locations}
        optionsKey="id"
        optionsLabel="name"
        optionsValue="id"
        enableClear
        optionsLoading={filters.loading}
      />

      <Select
        className="input-group__wrapper"
        placeholder="Program"
        onChange={({ id }) => dispatch(setProgramFilter(id))}
        value={filtersSelect.program || ''}
        options={filters.data?.programs}
        optionsKey="id"
        optionsLabel="name"
        optionsValue="id"
        enableClear
        optionsLoading={filters.loading}
      />

      <Select
        className="input-group__wrapper"
        placeholder="Category"
        onChange={({ id }) => dispatch(setCategoryFilter(id))}
        value={filtersSelect.category || ''}
        options={filters.data?.categories}
        optionsKey="id"
        optionsLabel="name"
        optionsValue="id"
        enableClear
        optionsLoading={filters.loading}
      />

      <Select
        className="input-group__wrapper"
        placeholder="Faculty"
        onChange={({ id }) => dispatch(setInstructorFilter(id))}
        value={filtersSelect.faculty || ''}
        options={instructorsToSelectData(filters.data?.instructors)}
        optionsKey="id"
        optionsLabel="name"
        optionsValue="id"
        enableClear
        optionsLoading={filters.loading}
        searchable
        searchFunc={(instructors, search) =>
          searchWithSeparator(instructors, search, 'name', ' ')
        }
      />
    </>
  );

  const renderFiltersContainer = (isInSlideMenu) => {
    return (
      <>
        <div className="schedule-table__filters-wrapper">
          <div className="schedule-table__filters-header">
            {!isInSlideMenu && (
              <span className="schedule-table__filters-title">filter by:</span>
            )}
            <Button type="navigation" onClick={() => dispatch(clearFilters())}>
              Clear All Filters
            </Button>
          </div>
          {isInSlideMenu && additionalFilters}
          <div className="schedule-table__filters-body">{renderFilters()}</div>
        </div>
        {!isInSlideMenu && additionalFilters}
      </>
    );
  };

  return (
    <div className="calendar-dropdown__wrapper">
      {pagination}
      {!isTablet ? (
        renderFiltersContainer(false)
      ) : (
        <>
          <Button
            type="transparent"
            iconLeft={
              selectedFiltersNumber < 1 ? (
                <Icon size="md" type="filters" />
              ) : (
                <Counter number={selectedFiltersNumber} />
              )
            }
            className="btn--filters-menu"
            onClick={() => setFiltersMenuOpen(true)}
          >
            Filters
          </Button>
          <SlideMenu
            isShown={filtersMenuOpen}
            className={'mobile-filters'}
            onCloseClick={() => setFiltersMenuOpen(false)}
          >
            {renderFiltersContainer(true)}
          </SlideMenu>
        </>
      )}
    </div>
  );
};

NavigationPanel.propTypes = {
  pagination: PropTypes.node,
  filterOptions: PropTypes.shape({
    locations: PropTypes.object,
    categories: PropTypes.object,
    instructors: PropTypes.object,
  }),
  additionalFilters: PropTypes.node,
  className: PropTypes.string,
  filterLoading: PropTypes.bool,
};
NavigationPanel.defaultProps = {
  pagination: <></>,
  filterOptions: {
    locations: {},
    categories: {},
    instructors: {},
  },
  additionalFilters: null,
  className: '',
  filterLoading: false,
};
