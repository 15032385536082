import React from 'react';
import { useSelector } from 'react-redux';
import {
  classNames,
  matomoInstructorHandler,
} from '@src/utils';
import { Icon, Link, Image } from '@components/shared';
import { globalWidgetLink } from '@src/constants';

export const FacultyCard = ({ instructor }) => {
  const filters = useSelector((state) => state.schedule.filters);

  const {
    id,
    faculty: { art_instruction, profile_image, user_image },
    display_name,
  } = instructor;

  const matomoHandler = () => {
    const instructorsList = filters?.data?.instructors;
    matomoInstructorHandler(instructorsList);
  };

  const onProfileClick = () => {
    matomoHandler();
  };

  const onOfferingClick = () => {
    matomoHandler();
  };

  const trimSubtitle = (string) => {
    if (string.length < 100) {
      return string;
    }

    return string.slice(0, 100) + '...';
  };

  return (
    <div className="global-page-card faculty-card">
      <div
        className={classNames([
          'faculty-card__visual',
          !profile_image && !user_image && 'faculty-card__visual--empty',
        ])}
      >
        {(user_image || profile_image) && (
          <>
            {user_image && (
              <Image src={user_image.path} alt="user" />
            )}

            {profile_image && !user_image && (
              <Image src={profile_image.path} alt="user" />
            )}
          </>
        )}

        {!profile_image && !user_image && <Icon type="user-undefined" />}
      </div>

      <div className="faculty-card__content">
        <h3 className="faculty-card__title">{display_name}</h3>

        {art_instruction && (
          <span className="faculty-card__subtitle">
            {trimSubtitle(art_instruction)}
          </span>
        )}

        <div className="faculty-card__actions">
          <Link
            className="faculty-card__view-schedule"
            type="button-primary"
            iconLeft={<Icon type="calendar" />}
            onClick={() => onOfferingClick()}
            href={`${window.location.origin}/offerings?faculty=${id}`}
          >
            Offerings
          </Link>
          <Link
            className="faculty-card__open-profile"
            type="button-primary"
            iconLeft={<Icon type="profile-tab" />}
            href={`${globalWidgetLink}/faculty/${id}`}
            onClick={() => onProfileClick()}
          >
            Profile
          </Link>
        </div>
      </div>
    </div>
  );
};
