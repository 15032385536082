import React from 'react';
import { Icon, Link } from '@components/shared';

export const ContactTitleBlockCard = ({ location, isGlobal }) => {
  const {
    address,
    email,
    phone_numbers = [],
    socials = [],
    title,
    mailAddress,
    subTitle,
  } = location;

  return (
    <div className="contact-title-block__item">
      <div className="contact-title-block__organization">
        <h3>
          <span>{title}</span>
        </h3>
        <p>{subTitle}</p>
      </div>
      <div className="contact-title-block__contacts">
        <div className="contact-title-block__contacts-body">
          {isGlobal && (
            <div className="contact-title-block__contacts-item">
              <div className="contact-card">
                <h4 className="contact-title-block__contact-title contact-card__title">
                  Corporate/Mailing Address
                </h4>
                <div className="contact-card__content">
                  <p>{mailAddress}</p>
                </div>
              </div>
            </div>
          )}
          <div className="contact-title-block__contacts-item">
            <div className="contact-card">
              <h4 className="contact-title-block__contact-title contact-card__title">
                {isGlobal ? 'Office Address' : 'Address'}
              </h4>
              <div className="contact-card__content">
                <p>{address}</p>
              </div>
            </div>
          </div>
          <div className="contact-title-block__contacts-item">
            <div className="contact-card">
              <h4 className="contact-title-block__contact-title contact-card__title">
                Phone
              </h4>
              <div className="contact-card__content">
                {phone_numbers.map((phone) => (
                  <Link href={`tel:${phone}`} key={phone}>
                    {phone}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="contact-title-block__contacts-item">
            <div className="contact-card">
              <h4 className="contact-title-block__contact-title contact-card__title">
                Email
              </h4>
              <div className="contact-card__content">
                <Link href={`mailto:${email}`}>{email}</Link>
              </div>
            </div>
          </div>
          <div className="contact-title-block__contacts-item contact-title-block__contacts-item--socials">
            <div className="contact-card">
              <h4 className="contact-title-block__contact-title contact-card__title">
                Socials
              </h4>
              <div className="contact-card__content">
                {socials.map(({ type, url }) => (
                  <Link href={url} key={type}>
                    <Icon type={`socials--${type}-xl`} size="xxl" />
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
