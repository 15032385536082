import React from 'react';
import { ContactMembers } from '@components/features';

export const GlobalContactMembers = () => {
  const contacts = {
    title: 'Contact Members',
    list: [
      {
        title: 'Dan Yonah Ben-Dror Marshall',
        description: [
          'BCCA & CCCA Co-Founder, President, Treasurer, & Executive Artistic Director',
        ],
        number: '(617) 642-1444',
        email: 'dan.yonah.marshall@gmail.com',
      },
      {
        title: 'Phillip L. Morse  ',
        description: [
          'BCCA Board Member & Clerk',
          'BCCA & CCCA Assistant Executive Artistic Director',
          'Director of the “Share Our Stage” Program',
          'President & Executive Director of Break the Silence! Foundation',
        ],
        number: '(978) 804-7705',
        email: 'philmorseis@gmail.com',
      },
      {
        title: 'Sherry Stearn',
        description: [
          'BCCA Board Member',
          'Assistant Director of the “Share Our Stage” Program',
          'Co-Founder & Treasurer of Break the Silence! Foundation',
        ],
        number: '(917) 497-7659',
        email: 'sherbear802@gmail.com',
      },
      {
        title: 'Joseph Valmond',
        description: [
          'BCCA Board Member',
          'Director of the Stars Outreach Program',
          'Executive Artistic Director of the BCCA Florida Division',
        ],
        number: '(954) 812-2300',
        email: 'starsoutreach@gmail.com',
      },
      {
        title: 'Emily Zhang',
        description: [
          'Grant Researcher & Writer, Social Media Administrator, Marketing Manager, & Graphics Designer',
        ],
        number: '(978) 503-2899',
        email: 'emilyxzhang16@gmail.com',
      },
    ],
  };

  return (
    <>
      <ContactMembers contacts={contacts} />
    </>
  );
};
