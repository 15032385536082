import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  ErrorMessage,
  Icon,
  RegistrationPriceBlock,
  RegistrationDonationBlock,
  Loader,
} from '@components/shared';
import { useForm, parseOnlyAmount, useApi } from '@src/hooks';
import { BuyingTicketsCard } from './BuyingTicketsCard';
import { getTicketsOrderData } from '@actions/registrationActions';
import { debounce } from '@src/utils';

export const BuyingTickets = ({ btnCallbacks, sessionInfo }) => {
  const [isNoTicketsSelected, setIsNoTicketsSelected] = useState(true);
  const tickets = useApi({
    url: `/sessions/${sessionInfo.id}/tickets`,
    dataPlaceholder: [],
  });
  const orderData = useSelector((state) => state.registration.orderData);
  const price = orderData.data?.totals?.total
  const dispatch = useDispatch();

  const submitHandler = (values) => {
    const { quantity, attendees } = values;
    const regRequestData = normalizeTickets(quantity, attendees);
    if (values.hasDonation) {
      regRequestData.donation = {
        is_anonymous: values.isAnonymous,
        amount: values.donationAmount,
        hasDonation: values.hasDonation,
      };
    }
    dispatch(getTicketsOrderData(regRequestData));
    return btnCallbacks.submit(regRequestData);
  };

  const normalizeTickets = (quantity, attendees) => {
    const tickets = Object.entries(quantity)
      .map(([id, quantity]) => ({
        ticket_id: +id,
        quantity,
      }))
      .filter(({ quantity }) => quantity);
    const ticketsWithAttendees = tickets.map((ticket) => ({
      ...ticket,
      attendees: attendees[ticket.ticket_id] || [],
    }));
    return {
      session_id: sessionInfo.id,
      tickets: ticketsWithAttendees,
    };
  };

  const validateTicketsQuantity = (quantity) =>
    !!Object.values(quantity).filter((item) => item).length;

  const schema = {
    donationAmount: {
      parse: parseOnlyAmount,
      validation: ['isRequired', 'donation'],
      valuesRequired: ['hasDonation'],
    },
    quantity: {
      validation: ['ticketsRequired'],
    },
  };

  const initialValues = {
    hasDonation: false,
    donationAmount: null,
    isAnonymous: false,
    quantity: {},
    attendees: {},
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setCustomValue,
    handleSubmit,
  } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });

  const { quantity, attendees } = values;

  useEffect(() => {
    if (validateTicketsQuantity(quantity)) {
      setIsNoTicketsSelected(false);
      const regRequestData = normalizeTickets(quantity, attendees);
      getOrderData(regRequestData)
    } else {
      setIsNoTicketsSelected(true);
    }
  }, [quantity]);

  const getOrderData = useCallback(
    debounce((data) => {
      dispatch(getTicketsOrderData(data));
    }, 500),
    []
  );

  return (
    <form className="buying-tickets" onSubmit={handleSubmit}>
      <div className="registration-step__group">
        <h4 className="registration-step__title">
          Please choose the desired ticket type and number of tickets
        </h4>
      </div>
      <div className="buying-tickets__tickets-container">
        {tickets.loading && <Loader />}
        {tickets.data.map((ticket) => (
          <BuyingTicketsCard
            ticketData={ticket}
            key={ticket.id}
            values={values}
            setCustomValue={setCustomValue}
          />
        ))}
        <ErrorMessage
          show={!!errors.quantity}
          text={'Select the type and quantity of Tickets you want to purchase'}
        />
      </div>
      <div className="registration-step__group registration-step__group--price">
        <RegistrationDonationBlock
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <div className="registration-step__payment-group">
          <RegistrationPriceBlock
            price={isNoTicketsSelected ? 0 : price}
            loading={orderData.loading}
            values={values}
          />
        </div>
      </div>

      <div className="btn__wrapper">
        <Button
          onClick={btnCallbacks.back}
          type="transparent"
          iconLeft={<Icon type="chevron" />}
        >
          Back
        </Button>
        <Button
          htmlType="submit"
          type="primary"
        >
          Proceed
        </Button>
      </div>
    </form>
  );
};
