export const normalizePreRegData = ({
  sessionId,
  selectedWeekDaySchedules,
  donation,
}) => {
  const schedules = Object.entries(selectedWeekDaySchedules).map(
    ([day_num, { schedule_id: id, label = '' }]) => {
      return {
        day_num: +day_num,
        id,
        label,
      };
    }
  );
  const schedulesWithNoOptions = schedules.filter((schedule) => schedule.label);
  const regData = {
    session_id: sessionId,
    schedules: schedulesWithNoOptions,
  };

  if (donation?.hasDonation) {
    regData.donation = donation;
  }

  return regData;
};
