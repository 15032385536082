import React from 'react';
import { insertClassName } from '@src/utils';

export const Switcher = ({ content, hint, disabled, active, className }) => {
  return (
    <div
      className={`switcher${insertClassName(
        className,
        className
      )}${insertClassName(disabled, 'switcher--disabled')}${insertClassName(
        active,
        'switcher--active'
      )}`}
    >
      <span className="switcher__content">{content}</span>
      <span className="switcher__hint">{hint}</span>
    </div>
  );
};