import React from 'react';
import { Container } from '@components/layout';
import {
  ProviderAboutMission,
  ProviderAboutObjectives,
  ProviderAboutTestimonials,
  ProviderAboutPrograms,
  ProviderAboutSponsors,
} from '@components/provider/About';
import { ProviderBanner } from '@components/provider';
import { useApi } from '@src/hooks';
import { Loader, SEO } from '@components/shared';
import { useSelector } from 'react-redux';

const ProviderAbout = () => {
  const providerData = useSelector((state) => state.provider.providerData.data);
  const aboutPageData = useApi({
    url: `/providers/current/pages/about`,
    dataPlaceholder: {},
  });
  const { content = {}, title, banner, content_order } = aboutPageData.data;
  const { our_mission, objectives, testimonials, programs, sponsors } = content;

  const pageRender = {
    our_mission: (
      <ProviderAboutMission missionData={our_mission} key="our_mission" />
    ),
    objectives: (
      <ProviderAboutObjectives objectivesData={objectives} key="objectives" />
    ),
    testimonials: (
      <ProviderAboutTestimonials
        testimonialsData={testimonials}
        key="testimonials"
      />
    ),
    programs: <ProviderAboutPrograms programsData={programs} key="programs" />,
    sponsors: <ProviderAboutSponsors sponsorsData={sponsors} key="sponsors" />,
  };

  return (
    <div className="global-page provider-about">
      {providerData.short_name && (
        <SEO
          title={`${providerData.short_name} | About`}
          description={`Discover the mission, objectives, and vision of ${providerData.short_name}. Explore testimonials from our community and examples of our diverse artistic offerings.`}
          canonicalURL={`${providerData.base_url}/about`}
          image={providerData.logo}
        />
      )}
      {aboutPageData.loading ? (
        <Loader global />
      ) : (
        <div className="provider-about__body">
          {!!banner && <ProviderBanner banner={banner} />}
          <Container>
            <div className="provider-about__title-block">
              <h1 className="page-title">{title}</h1>
            </div>
            {content_order?.map((block) => pageRender[block])}
          </Container>
        </div>
      )}
    </div>
  );
};

export default ProviderAbout;
