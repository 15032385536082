import React from 'react';
import PropTypes from 'prop-types';
import ICONS from './icons';
import { classNames } from '@src/utils';

export const Icon = (props) => {
  const { type, className, handleClick, size } = props;

  const SIZES = {
    default: '',
    xs: ' icon--xs',
    sm: ' icon--sm',
    md: ' icon--md',
    lg: ' icon--lg',
    xl: ' icon--xl',
    xxl: ' icon--xxl',
    full: ' icon--full',
  };

  const { format, markup, path, viewbox, initColor } = ICONS[type] || {};

  const icon = format === 'data' ? <path d={path} /> : markup;

  return (
    <svg
      className={classNames(['icon', className, SIZES[size]])}
      viewBox={viewbox}
      aria-labelledby="title"
      onClick={handleClick}
      fill={initColor || ''}
    >
      {icon}
    </svg>
  );
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
};

Icon.defaultProps = {
  type: 'plus',
  className: '',
  size: 'default',
};
