import { useState } from 'react';
import { Image, Button, Tooltip } from '@components/shared';
import { classNames, openLink } from '@src/utils';

export const PhotoPreview = ({ photo }) => {
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  const onCopyClick = () => {
    navigator.clipboard.writeText(photo);
    setIsTooltipShown(true);
    setTimeout(() => {
      setIsTooltipShown(false);
    }, 3000);
  };

  return (
    <div className="photo-priview">
      <div className="photo-privew__actions">
        <Button type="transparent" onClick={onCopyClick}>
          Copy Photo URL
          <Tooltip
            position="bottom"
            className={classNames([
              'photo-privew__tooltip',
              isTooltipShown && 'photo-privew__tooltip--active',
            ])}
          >
            URL Copied!
          </Tooltip>
        </Button>
        <Button type="transparent" onClick={() => openLink(photo, true)}>
          Open Photo in a new tab
        </Button>
      </div>
      <Image src={photo} alt="multimedia-item" />
    </div>
  );
};
