import React, { useEffect, useState } from 'react';
import { Button } from '@components/shared';
import { AccountScheduleCalendarView } from './AccountScheduleCalendarView';
import { AccountScheduleListView } from './AccountScheduleListView';
import { queryParam } from '@src/utils';

export const AccountSchedule = () => {
  const initView = queryParam.get('schedule-view') || 'calendar';
  const [currentView, setCurrentView] = useState(initView);

  useEffect(() => {
    queryParam.set('schedule-view', currentView);

    return () => queryParam.delete('schedule-view');
  }, [currentView]);

  const nextView = currentView === 'calendar' ? 'list' : 'calendar';

  const view = {
    calendar: <AccountScheduleCalendarView />,
    list: <AccountScheduleListView />,
  };

  return (
    <div className="account-schedule">
      <div className="account-schedule__title-block">
        <h2 className="account__content-title">Schedule</h2>
        <Button onClick={() => setCurrentView(nextView)} type="primary">
          Switch to{' '}
          <span className="account-schedule__next-view-name">{nextView}</span>
        </Button>
      </div>
      {view[currentView]}
    </div>
  );
};
