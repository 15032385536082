import * as types from '@types/providerTypes';
import { normalizeProviderData } from '@src/normalizers';

const initialState = {
  // isGlobal: true,
  isGlobal: window.location.host.split('.')[2] !== 'worldeducationweb',
  providerData: {
    data: {},
    loading: false,
    error: ''
  },
  providersList: {
    data: [],
    loading: false,
    error: ''
  }
};

export const providerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.IS_GLOBAL: {
      return {
        ...state,
        isGlobal: payload,
      };
    }

    case types.CURRENT_PROVIDER: {
      return {
        ...state,
        providerData: {
          data: normalizeProviderData(payload),
          loading: false,
          error: ''
        },
      };
    }

    case types.CURRENT_PROVIDER_LOADING: {
      return {
        ...state,
        providerData: {
          data: {},
          loading: true,
          error: ''
        },
      };
    }

    case types.CURRENT_PROVIDER_ERROR: {
      return {
        ...state,
        providerData: {
          data: {},
          loading: false,
          error: payload
        },
      };
    }

    case types.PROVIDERS_LIST: {
      return {
        ...state,
        providersList: {
          data: payload,
          error: '',
          loading: false
        },
      };
    }

    case types.PROVIDERS_LIST_LOADING: {
      return {
        ...state,
        providersList: {
          ...state.providersList,
          error: '',
          loading: true
        },
      };
    }

    case types.PROVIDERS_LIST_ERROR: {
      return {
        ...state,
        providersList: {
          ...state.providersList,
          error: payload,
          loading: false
        },
      };
    }

    default: {
      return state;
    }
  }
};
