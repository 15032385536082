import React, { useState, useEffect } from 'react';
import { updateUserData } from '@actions/authActions';
import { useForm, useDeepEffect } from '@src/hooks';
import { getProfileSchema } from '@src/utils';
import { normalizeUserData } from '@src/normalizers';
import { Input, PhoneListInput, Checkbox } from '@components/shared';
import { AccountProfileCard } from '../../Supports/AccountProfileCard';

export const ParentGuardian = ({   
  userData, 
  editorState, 
  setEditorState, 
  dispatch
}) => {
  const { student } = userData.data || {};
  const {
    guardian_contact_name,
    guardian_contact_phone,
    under_eighteen,
  } = student || {};

  const [isUnderAge, setIsUnderAge] = useState(under_eighteen);

  const submitHandler = (values) => {
    const userData = normalizeUserData(values, editorState);
    dispatch(updateUserData(userData));
  };

  useDeepEffect(() => {
    if (userData.data) {
      setValues(initialValues);
    }
  }, [userData]);

  const initialValues = {
    parentName: guardian_contact_name,
    parentPhone: guardian_contact_phone,
    underAge: under_eighteen,
  };

  const schema = getProfileSchema(editorState, {isUnderAge});

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setErrors,
    clearValues,
    setValues,
    setCustomValue,
  } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });

  useEffect(() => {
    setIsUnderAge(values.underAge);
  }, [values.underAge]);

  const parentBlockData = [
    {
      label: 'Parent or Guardian name',
      inputType: Input,
      inputName: 'parentName',
      placeholder: 'Enter Parent or Guardian Name',
    },
    {
      label: 'Phone Number',
      inputType: PhoneListInput,
      inputName: 'parentPhone',
      placeholder: 'Enter Parent or Guardian Phone',
      additionalProps: {
        onChange: (value) => setCustomValue('parentPhone', value),
      },
    },
    {
      label: 'I’m under 18 years old',
      inputType: Checkbox,
      inputName: 'underAge',
    },
  ];

  const profileBlocksData = [
    {
      title: 'Parent or Guardian',
      data: parentBlockData,
      name: 'parentInfo',
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      userData,
      editorState, 
      setEditorState,
      clearValues,
      setErrors,
    },
  ];

  return (
    <>
      {profileBlocksData.map((blockData) => AccountProfileCard(blockData))}
    </>
  );
};