import React, { useEffect, useState } from 'react';
import { modalStore, closeAllModals, closeModal } from './ModalStore';
import { Modal } from './Modal';

export const ModalInit = ({ setIsModalOpen }) => {
  const [modals, setModals] = useState([]);

  useEffect(() => {
    modalStore.watch((modals) => {
      setModals(modals);
    });

    return () =>
      modalStore.unwatch((modals) => {
        setModals(modals);
      });
  }, []);

  useEffect(() => {
    modals.length ? setIsModalOpen(true) : setIsModalOpen(false);
  }, [modals]);

  const modal = modals[modals.length - 1] || {};

  return (
    <Modal
      show={modal.visible || false}
      changeState={(isCloseAllModals) => {
        isCloseAllModals ? closeAllModals() : closeModal(modal.name);
      }}
      headerContent={modal.header}
      className={modal.className}
      nativeClose={modal.nativeClose}
    >
      {modal.body}
    </Modal>
  );
};