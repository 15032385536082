import React, { useEffect } from 'react';
import {
  InfoBox,
  Button,
  changeModalProps,
  closeModal,
} from '@components/shared';
import { useSelector } from 'react-redux';

export const ProviderPolicy = ({
  policyContent,
}) => {
  const currentProviderPolicy = useSelector(
    (state) => state.provider.providerData.data.config.terms
  );

  useEffect(() => {
    changeModalProps('registration', {
      nativeClose: false,
    });
  }, []);

  return (
    <>
      <InfoBox className="info-box--policy">
        <div
          className="policy text-editor-block"
          dangerouslySetInnerHTML={{
            __html: policyContent || currentProviderPolicy,
          }}
        ></div>
      </InfoBox>
      <div className="policy-footer">
        <Button type="primary" onClick={() => closeModal('policy-notice')}>
          Back
        </Button>
      </div>
    </>
  );
};
