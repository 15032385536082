import { updateUserData } from '@actions/authActions';
import { useForm, useDeepEffect } from '@src/hooks';
import { getProfileSchema } from '@src/utils';
import { normalizeUserData } from '@src/normalizers';
import { Button, Input, Tooltip, Link } from '@components/shared';
import { AccountProfileCard } from '../../Supports/AccountProfileCard';

export const AccountInformation = ({ 
  userData, 
  editorState, 
  setEditorState, 
  dispatch
}) => {
  const isChangePassword = editorState === 'changePassword';
  const { email, login } = userData.data || {};

  const submitHandler = (values) => {
    const userData = normalizeUserData(values, editorState);
    dispatch(updateUserData(userData));
  };

  useDeepEffect(() => {
    if (userData.data) {
      setValues(initialValues);
    }
  }, [userData]);


  const initialValues = {
    userName: login,
    email: email,
  };

  const schema = getProfileSchema(editorState);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setErrors,
    clearValues,
    setValues,
  } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });

  const renderChangePassword = () => {
    return (
      !editorState && (
        <Button
          onClick={() => setEditorState('changePassword')}
          className="account-profile__change-password-button"
        >
          Change Password
        </Button>
      )
    );
  };

  const renderSupportTooltip = () => (
    <Tooltip position="bottom">
      <span>Also you can directly email</span>
      <a href="mailto:dan.yonah.marshalll@gmail.com">
        dan.yonah.marshall@gmail.com
      </a>
      <span>or call (617) 642-1444.</span>
    </Tooltip>
  );

  const renderAccountInfoSuggestion = () => {
    return !editorState ? (
      <span className="account-profile__subcription-note">
        If you want to change your Username and / or Email please{' '}
        <span className="account-profile__subcription-support">

          <Link href='https://cccaonline.org/contact-us/#contacts'>
            contact Support
          </Link>
          {renderSupportTooltip()}
        </span>
      </span>
    ) : null;
  };

  const accountBlockData = [
    {
      label: 'Username',
      inputType: Input,
      inputName: 'userName',
    },
    {
      label: 'Email',
      inputType: Input,
      inputName: 'email',
    },
  ];

  const changePasswordBlockData = [
    {
      label: 'Old Password',
      inputType: Input,
      inputName: 'oldPassword',
      placeholder: 'Enter Old Password',
      additionalProps: {
        password: true,
      },
    },
    {
      label: 'New Password',
      inputType: Input,
      inputName: 'password',
      placeholder: 'Enter New Password',
      additionalProps: {
        password: true,
        hint: 'Password should include at least 6 characters, both uppercase and lowercase letters and at least one number',
      },
    },
    {
      label: 'Repeat New Password',
      inputType: Input,
      inputName: 'confirmPassword',
      placeholder: 'Repeat New Password',
      additionalProps: {
        password: true,
      },
    },
  ];

  const profileBlocksData = [
    {
      title: 'Account Information',
      data: !isChangePassword ? accountBlockData : changePasswordBlockData,
      name: !isChangePassword ? 'accountInfo' : 'changePassword',
      additionalButtons: renderChangePassword(),
      editRestricted: true,
      subscription: renderAccountInfoSuggestion(),
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      userData,
      editorState, 
      setEditorState,
      clearValues,
      setErrors,
    },
  ];

  return (
    <>
      {profileBlocksData.map((blockData) => AccountProfileCard(blockData))}
    </>
  );
};