import React from "react";
import { useApi } from '@src/hooks';
import { Loader } from '@components/shared';
import { Container } from '@components/layout';
import { OfferingDetailsFacultyCard } from "./OfferingDetailsFacultyCard";

export const OfferingDetailsFaculty = ({ data }) => {
  const { main_instructor_1, main_instructor_2, assistant_instructors = [] } = data || {};

  const instructorsId = [
    main_instructor_1?.id,
    main_instructor_2?.id,
  ].filter((element) => !!element);

  assistant_instructors
    .filter((element) => !!element)
    .forEach((element) => instructorsId.push(element?.id));

  const transformedInstructorsId = instructorsId.join('&ids[]=');

  const instructorsData = useApi({ url: `/instructors?ids[]=${transformedInstructorsId}`});

  return (
    <div className="offering-details__tab-content offering-details__faculty">
      <div className="offering-faculty">
        <Container>
          <div className="offering-faculty__body">
            {instructorsData.loading && <Loader global />}

            {instructorsData.data && instructorsData.data.map((instructor) => (
              <div className="offering-faculty__item" key={instructor.id} >
                <OfferingDetailsFacultyCard  instructor={instructor} />
              </div>
            ))}
          </div>
        </Container>
      </div>
    </div>
  )
};