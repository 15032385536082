import {
  allowedDatesEventsCalendarFormat,
  dateEventsCalendarFormat,
} from '@src/constants';
import { format, addMonths, subMonths } from 'date-fns';
import { getDaysInMonth, zonedDate } from '@src/utils';

export const getDisabledDays = (slots, allowedMonths) => {
  if (!allowedMonths.length || !slots.length) {
    return [];
  }

  const availableDays = slots.map(
    (slot) => format(zonedDate(slot.date), dateEventsCalendarFormat),
    dateEventsCalendarFormat
  );

  let disabledDays = [];

  const firstMonthToCheck = format(
    subMonths(zonedDate(allowedMonths[0]), 1),
    allowedDatesEventsCalendarFormat
  );
  let lastMonthToCheck = format(
    addMonths(zonedDate(allowedMonths[allowedMonths.length - 1]), 1),
    allowedDatesEventsCalendarFormat
  );

  const monthsToCheck = [firstMonthToCheck, ...allowedMonths, lastMonthToCheck];

  monthsToCheck.forEach((month) => {
    const daysInMonth = getDaysInMonth(
      zonedDate(month).getMonth(),
      zonedDate(month).getFullYear()
    );
    const filteredDays = daysInMonth.filter(
      (day) => !availableDays.includes(format(day, dateEventsCalendarFormat))
    );

    disabledDays = [...disabledDays, ...filteredDays];
  });

  return disabledDays;
};
