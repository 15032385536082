import React from 'react';
import { ContactTitleBlock } from '@components/features';
import { ProviderContactMap } from '@components/provider/Contact';

export const ProviderContactTitleBlock = ({ locations = {} }) => {
  return (
    <div className='page-block'> 
      <ContactTitleBlock locations={locations} isGlobal={false} />
      <ProviderContactMap locationsList={locations?.list} />
    </div>
  );
};
