import React, { useState, useEffect } from 'react';
import { countriesWithStates } from '@src/constants';
import { useSelector } from 'react-redux';
import { useForm, useApi } from '@src/hooks';
import {
  Button,
  Checkbox,
  Input,
  Select,
  Radio,
  InfoBox,
  ErrorMessage,
  Loader,
} from '@components/shared';
import { Container } from '@components/layout';

export const AffiliationInvitation = ({
  submitHandler,
  organizationData,
  openPolicy,
  isGlobal,
  onUserLogaut,
}) => {
  const updateLoading = useSelector(
    (state) => state.auth.userData.updateLoading
  );
  const userData = useSelector((state) => state.auth.userData);
  const isLoading = updateLoading || userData.loading;
  const [showStates, setShowStates] = useState(false);
  const {
    waiverText,
    organizationName,
    waiverEnabled,
    snailSubcriptionEnabled,
    emailSubcriptionEnabled,
    sourceSurveysEnabled,
  } = organizationData;
  const { contact } = userData.data || {};
  const { address } = contact || {};
  const { country, address_1, state, city } = address || {};

  const initialValues = {
    address: address_1,
    country: country?.id,
    city: city,
    state: state?.id,
  };

  const schema = {
    agreePolicy: { validation: ['isRequired'] },
    address: {
      validation: ['isRequired'],
      valuesRequired: ['snailSubscription'],
    },
    city: {
      validation: ['isRequired'],
      valuesRequired: ['snailSubscription'],
    },
    country: {
      validation: ['isRequired'],
      valuesRequired: ['snailSubscription'],
    },
    state: {
      validation: showStates && ['isRequired'],
      valuesRequired: ['snailSubscription'],
    },
    tookClassesBefore: { validation: !isGlobal && ['isRequiredChoice'] },
    mediaWaiver: waiverEnabled && { validation: ['isRequiredChoice'] },
    knownSource: sourceSurveysEnabled && { validation: ['isRequired'] },
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setCustomValue,
  } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });

  const surveys = useApi({
    url: isGlobal ? '/global/source-survey' : '/source-survey',
    dataPlaceholder: [],
  });
  const countries = useApi({ url: '/countries', dataPlaceholder: [] });
  const states = useApi(
    {
      url: `/countries/${values.country}/states`,
      dataPlaceholder: [],
    },
    [values.country],
    !!values.country
  );

  useEffect(() => {
    if (countries.data.length) {
      checkStates(values.country, countries.data);
    }
  }, [values.country, countries.data.length]);

  const checkStates = (countryId, countries) => {
    const selectedCountry = countries.find(
      (country) => country.id === countryId
    );
    const countryWithStates = countriesWithStates.includes(
      selectedCountry?.name
    );
    if (!countryWithStates) {
      setCustomValue('state', '');
    }
    setShowStates(countryWithStates);
  };

  return (
    <div className="affiliation-invitation">
      <Loader sticky show={isLoading} />
      <Container>
        <div className="affiliation-invitation__body">
          <h1 className="affiliation-invitation__title">
            <span>Welcome to</span>

            {isGlobal ? (
              <span className="affiliation-invitation__logo">
                <img src="/img/wew-logo.svg" alt="wew logo" />
              </span>
            ) : (
              <span>{organizationName}</span>
            )}
          </h1>
          <h2 className="affiliation-invitation__subtitle">
            Start your journey with us by confirming your consent to our
            policies
          </h2>
          <form
            className="affiliation-invitation__form"
            onSubmit={handleSubmit}
          >
            <div className="form affiliation-form">
              <div className="form__section form__section--checkbox">
                <h4 className="form__group-title required-mark">
                  Terms and Condition
                </h4>

                <div className="form__group form__group--checkox">
                  <Checkbox
                    name="agreePolicy"
                    onChange={handleChange}
                    value={values.agreePolicy}
                    alert={errors.agreePolicy}
                    label={
                      <>
                        I agree to {organizationName}{' '}
                        <Button
                          type="link"
                          htmlType="button"
                          onClick={openPolicy}
                        >
                          Terms & Conditions and Privacy Policy
                        </Button>
                      </>
                    }
                  />
                </div>
              </div>

              {waiverEnabled && (
                <div className="form__section form__section--info-box">
                  <h4 className="form__group-title required-mark">
                    {organizationName} Media Concert and Waiver
                  </h4>

                  <InfoBox
                    height={250}
                    className="info-box info-box--media-waiver"
                  >
                    <p>{waiverText}</p>
                  </InfoBox>

                  <div className="form__group form__group--radio">
                    <Radio
                      name="mediaWaiver"
                      label="Yes"
                      className={!!errors.mediaWaiver ? 'radio--has-error' : ''}
                      value={values.mediaWaiver === true}
                      onChange={() => setCustomValue('mediaWaiver', true)}
                    />

                    <Radio
                      name="mediaWaiver"
                      label="No"
                      className={!!errors.mediaWaiver ? 'radio--has-error' : ''}
                      onChange={() => setCustomValue('mediaWaiver', false)}
                      value={values.mediaWaiver === false}
                    />

                    <ErrorMessage
                      show={!!errors.mediaWaiver}
                      text={errors.mediaWaiver}
                    />
                  </div>
                </div>
              )}

              {!isGlobal && (
                <div className="form__section">
                  <h4 className="form__group-title required-mark">
                    Have you taken classes here before?
                  </h4>

                  <div className="form__group form__group--radio">
                    <Radio
                      onChange={() => setCustomValue('tookClassesBefore', true)}
                      value={values.tookClassesBefore === true}
                      name="tookClassesBefore"
                      label="Yes"
                      className={
                        !!errors.tookClassesBefore ? 'radio--has-error' : ''
                      }
                    />
                    <Radio
                      onChange={() =>
                        setCustomValue('tookClassesBefore', false)
                      }
                      value={values.tookClassesBefore === false}
                      name="tookClassesBefore"
                      label="No"
                      className={
                        !!errors.tookClassesBefore ? 'radio--has-error' : ''
                      }
                    />
                    <ErrorMessage
                      show={!!errors.tookClassesBefore}
                      text={errors.tookClassesBefore}
                    />
                  </div>
                </div>
              )}

              {sourceSurveysEnabled && (
                <div className="form__section form__section--select">
                  <h4 className="form__group-title required-mark">
                    How did you hear about {organizationName}?
                  </h4>

                  <div className="form__group form__group--select">
                    <Select
                      name="knownSource"
                      placeholder="Select option"
                      optionsLabel="option"
                      optionsValue="id"
                      optionsKey="id"
                      value={values.knownSource}
                      options={surveys.data}
                      onChange={(result) =>
                        setCustomValue('knownSource', result.id)
                      }
                      loading={surveys.loading}
                    />

                    <ErrorMessage
                      text={errors.knownSource}
                      show={!!errors.knownSource}
                    />
                  </div>
                </div>
              )}

              {(emailSubcriptionEnabled || snailSubcriptionEnabled) && (
                <div className="form__section form__section--checkbox">
                  {emailSubcriptionEnabled && (
                    <div className="form__group form__group--checkox">
                      <Checkbox
                        name="emailSubscription"
                        label={`I want to receive ${organizationName} Email newsletters`}
                        onChange={handleChange}
                        value={values.emailSubscription}
                      />
                    </div>
                  )}

                  {snailSubcriptionEnabled && (
                    <div className="form__group form__group--checkox">
                      <Checkbox
                        name="snailSubscription"
                        label={`I want to receive ${organizationName} snail (standart post) mail`}
                        onChange={handleChange}
                        value={values.snailSubscription}
                      />
                    </div>
                  )}

                  {values.snailSubscription && (
                    <>
                      <div className="form__hint">
                        Please provide us with your mailing address for if/when
                        we send snail mail communications.
                      </div>

                      <div className="form__group">
                        <Input
                          name="address"
                          label="Address"
                          placeholder="Enter your Address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address}
                          alert={errors.address}
                          required
                        />
                      </div>

                      <div className="form__group">
                        <Input
                          name="city"
                          label="City"
                          placeholder="Enter your City"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.city}
                          alert={errors.city}
                          required
                        />
                      </div>

                      <div className="form__group">
                        <Select
                          label="Country"
                          optionsLabel="name"
                          placeholder="Select Country"
                          optionsKey="id"
                          optionsValue="id"
                          options={countries.data}
                          loading={countries.loading}
                          value={values.country}
                          onChange={(val) => setCustomValue('country', val.id)}
                          alert={errors.country}
                          searchable
                          required
                        />
                      </div>

                      {showStates && (
                        <div className="form__group">
                          <Select
                            label="State"
                            optionsLabel="name"
                            placeholder="Select State"
                            optionsKey="id"
                            optionsValue="id"
                            options={states.data}
                            value={values.state}
                            onChange={(val) => setCustomValue('state', val)}
                            alert={errors.state}
                            searchable
                            required
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

              <div className="form__group form__group--auth-buttons">
                {isGlobal && (
                  <Button type="transparent" onClick={() => onUserLogaut()}>
                    Log out
                  </Button>
                )}
                <Button type="primary" htmlType="submit" disabled={isLoading}>
                  Save Settings
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Container>
    </div>
  );
};
