import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from '@components/shared';
import { classNames } from '@src/utils';

const Details = ({
  children,
  linesToShow = 4,
  className,
  onCollapse,
  isCollapse,
  isVideoGalleryCard,
  isViewButton
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const text = useRef();
  const [shouldReduce, setShouldReduce] = useState(false);

  useEffect(() => {
    setIsExpanded(isCollapse);
  }, [isCollapse]);

  const textStyle = {
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: isExpanded ? 'initial' : linesToShow,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const setIsCollapse = (isExpanded) => {
    setIsExpanded(isExpanded);
    onCollapse && onCollapse(isExpanded);
  };

  useEffect(() => {
    setShouldReduce(text.current.getClientRects().length > linesToShow);
  }, []);

  const DetailsButton = () => {
    return (
      <Button
        className={classNames(['details__button', isExpanded && 'details__button--expanded'])}
        onClick={() => {
          setIsCollapse(!isExpanded);
        }}
        iconRight={<Icon type="chevron" size={'sm'} />}
      >
        {isExpanded ? 'Hide' : 'View More'}
      </Button>
    );
  };

  return (
    <div className={classNames(['details__body', className])}>
      <span style={shouldReduce ? textStyle : {}} ref={text}>
        {children}
      </span>
      {!isViewButton && (isVideoGalleryCard ? (
        shouldReduce && <DetailsButton /> 
      ) : ( 
        <DetailsButton />
      ))}
    </div>
  );
};

Details.propTypes = {
  linesToShow: PropTypes.number,
};

export default Details;