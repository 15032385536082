import React, { useEffect } from 'react';
import { Select, Button } from '@components/shared';
import { getFilters } from '@actions/scheduleActions';
import { clearFacultyFilters } from '@actions/facultyActions';

import {
  setFacultyCategoryFilter,
  setFacultyInstructorFilter,
  setFacultyLocationFilter,
  setFacultyProgramFilter,
} from '@actions/facultyActions';

import {
  instructorsToSelectData,
  searchWithSeparator,
  setFiltersQuery,
} from '@src/utils';
import { useDispatch, useSelector } from 'react-redux';

export const FacultyFilters = () => {
  const filtersSelect = useSelector((state) => state.faculty.filtersSelect);
  const filters = useSelector((state) => state.schedule.filters);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFilters());
  }, []);

  useEffect(() => {
    setFiltersQuery(filtersSelect);
  }, [filtersSelect]);

  return (
    <>
      <div className="faculty__filters-header">
        <span className="faculty__filters-title">filter by:</span>
        <Button
          type="navigation"
          onClick={() => dispatch(clearFacultyFilters())}
        >
          Clear All Filters
        </Button>
      </div>
      <div className="faculty__filters">
        <Select
          placeholder="Location"
          onChange={(value) => dispatch(setFacultyLocationFilter(value))}
          value={filtersSelect.location || ''}
          options={filters.data?.locations}
          optionsKey="id"
          optionsLabel="name"
          optionsValue="id"
          enableClear
          optionsLoading={filters.loading}
        />
        <Select
          placeholder="Program"
          onChange={(value) => dispatch(setFacultyProgramFilter(value))}
          value={filtersSelect.program || ''}
          options={filters.data?.programs}
          optionsKey="id"
          optionsLabel="name"
          optionsValue="id"
          enableClear
          optionsLoading={filters.loading}
        />
        <Select
          placeholder="Category"
          onChange={(value) => dispatch(setFacultyCategoryFilter(value))}
          value={filtersSelect.category || ''}
          options={filters.data?.categories}
          optionsKey="id"
          optionsLabel="name"
          optionsValue="id"
          enableClear
          optionsLoading={filters.loading}
        />
        <Select
          placeholder="Faculty"
          onChange={(value) => dispatch(setFacultyInstructorFilter(value))}
          value={filtersSelect.faculty || ''}
          options={instructorsToSelectData(filters.data?.instructors)}
          optionsKey="id"
          optionsLabel="name"
          optionsValue="id"
          enableClear
          optionsLoading={filters.loading}
          searchable
          searchFunc={(instructors, search) =>
            searchWithSeparator(instructors, search, 'name', ' ')
          }
        />
      </div>
    </>
  );
};