import React from 'react';
import { classNames } from '@src/utils';
import { Icon } from '@components/shared';
import { SponsorsCard } from './SponsorsCard';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';

export const Sponsors = ({
  sponsorsData = {},
  blockClassName,
  titleClassName,
}) => {
  const { title, description, list = [] } = sponsorsData;
  const slidesCount = list.length;
  const sliderSettings = {
    className: 'sponsors-block__slider',
    navigation: {
      nextEl: '.sponsors-block__next-button',
      prevEl: '.sponsors-block__prev-button',
    },
    slidesPerView: 1,
    enabled: slidesCount > 1,
    spaceBetween: 40,
    breakpoints: {
      576: {
        slidesPerView: 2,
        enabled: slidesCount > 2,
      },
      768: {
        slidesPerView: 3,
        enabled: slidesCount > 3,
      },
    },
  };

  return (
    <div className={classNames(['sponsors-block', blockClassName])}>
      <div className="sponsors-block__body">
        <div className="sponsors-block__title-block">
          <h2 className={classNames(['sponsors-block__title', 'page-block-title', titleClassName])}>
            {title}
          </h2>
          <p>{description}</p>
        </div>

        <Swiper {...sliderSettings}>
          {list.map(({ name, logo, url, description }) => {
            return (
              <SwiperSlide className="sponsors-block__slide" key={name}>
                <SponsorsCard
                  logo={logo}
                  name={name}
                  description={description}
                  url={url}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="global-page__buttons sponsors-block__buttons">
          <div className="global-page__prev-button sponsors-block__prev-button">
            <Icon type="arrow-prev" />
          </div>

          <div className="global-page__next-button sponsors-block__next-button">
            <Icon type="arrow-next" />
          </div>
        </div>
      </div>
    </div>
  );
};
