import React from 'react';
import Details from '@components/shared/Details/Details';
import { classNames } from '@src/utils';
import JWPlayer from 'react-jwplayer';
import Vimeo from '@u-wave/react-vimeo';
import { Icon, Link, Image } from '@components/shared';

const VideoCard = ({
  video,
  isAccount,
  isExpanded,
  setExpandedVideo,
  isVideoGalleryCard,
}) => {
  const {
    title,
    subtitle,
    description,
    poster_image,
    embed_id,
    type,
    id,
    content_provider,
  } = video;

  return (
    <div
      className={classNames([
        'video__card',
        isExpanded && isAccount && 'video__card--expanded',
      ])}
    >
      <div
        className={`video__preview video__preview--${type}`}
        onClick={() => setExpandedVideo(isExpanded ? null : id)}
      >
        {isExpanded && isAccount ? (
          <>
            {type === 'vimeo' ? (
              <Vimeo video={embed_id} responsive={true} />
            ) : (
              <JWPlayer videoId={embed_id} />
            )}
          </>
        ) : (
          <div className="video__preview-image">
            {poster_image?.path ? (
              <Image src={poster_image?.path} alt="video poster" />
            ) : (
              <Icon type="party-dance" size="full" />
            )}
          </div>
        )}
      </div>
      <div className="video__description">
        <h3 className="video__title">{title}</h3>
        {/* <h4 className="video__subtitle">{subtitle}</h4> */}
        {isAccount && (
          <div className="video__provider">
            <span className="video__provider-label">Presented by:</span>
            <Link href={content_provider.base_url}>
              {content_provider.name}
            </Link>
          </div>
        )}
        {subtitle && (
          <div className="video__subtitle">
            <span  className="text-editor-block" dangerouslySetInnerHTML={{ __html: subtitle }}></span>
          </div>
        )}
        <Details
          className="video__text"
          onCollapse={() => setExpandedVideo(isExpanded ? null : id)}
          isCollapse={isExpanded}
          isVideoGalleryCard={isVideoGalleryCard}
        >
          <span
            dangerouslySetInnerHTML={{ __html: description }}
            className="text-editor-block"
          ></span>
        </Details>
      </div>
    </div>
  );
};

export default VideoCard;
