import { useEffect } from 'react';
import { getModal, openModal } from '@components/shared';
import { useHistory } from 'react-router-dom';
import { GlobalFooter } from '@components/global';
import { ProviderFooter } from '@components/provider';

export const Footer = ({ isGlobalWidget }) => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      document.body.scrollIntoView();
    });

    return unlisten;
  }, []);

  const onDonationButtonClick = () => {
    openModal(
      getModal({
        name: 'donation',
      })
    );
  };

  const onPolicyClick = (isGlobal) => {
    openModal(
      getModal({
        name: 'policy-notice',
        data: { isModal: true, isGlobal },
      })
    );
  };

  return (
    <>
      {isGlobalWidget ? (
        <GlobalFooter
          onDonationButtonClick={onDonationButtonClick}
          onPolicyClick={onPolicyClick}
        />
      ) : (
        <ProviderFooter
          onDonationButtonClick={onDonationButtonClick}
          onPolicyClick={onPolicyClick}
        />
      )}
    </>
  );
};
