import React from 'react';

export const CheckoutTable = ({ items = [] }) => {
  const item = items[0];

  return (
    <div className="checkout-table checkout-table--desktop-only">
      <div className="checkout-table__row">
        {!!item.number && (
          <span className="checkout-table__label checkout-table__number">
            №
          </span>
        )}
        {!!item.title && (
          <span className="checkout-table__label checkout-table__name">
            Name
          </span>
        )}

        {!!item.date && (
          <span className="checkout-table__label checkout-table__date">
            Date
          </span>
        )}
        {!!item.time && (
          <span className="checkout-table__label checkout-table__time">
            Time
          </span>
        )}
        {!!item.quantity && (
          <span className="checkout-table__label checkout-table__quantity">
            Quantity
          </span>
        )}
        <span className="checkout-table__label checkout-table__price">
          Price
        </span>
      </div>
      {items.map((item, index) => {
        const {
          title,
          quantity,
          date,
          time,
          price,
          discount,
          increase,
          number,
        } = item;

        return (
          <div className="checkout-table__row" key={index}>
            {!!number && (
              <span className="checkout-table__value checkout-table__number">
                {number}
              </span>
            )}
            {!!title && (
              <span className="checkout-table__value checkout-table__name">
                {title}
              </span>
            )}
            {!!date && (
              <span className="checkout-table__value checkout-table__date">
                {Array.isArray(date)
                  ? date.map((value) => <span>{value}</span>)
                  : date}
              </span>
            )}
            {!!time && (
              <span className="checkout-table__value checkout-table__time">
                {Array.isArray(time)
                  ? time.map((value) => <span>{value}</span>)
                  : time}
              </span>
            )}
            {!!quantity && (
              <span className="checkout-table__value checkout-table__quantity">
                {quantity}
              </span>
            )}
            <span className="checkout-table__value checkout-table__price">
              ${price}
              {!!discount && (
                <span className="checkout-table__discount">
                  <span className="discount__label">Discount</span>
                  <span className="discount__value">-${discount}</span>
                </span>
              )}
              {!!increase && (
                <span className="checkout-table__increase">
                  <span className="increase__label">Increase</span>
                  <span className="increase__value">${increase}</span>
                </span>
              )}
            </span>
          </div>
        );
      })}
    </div>
  );
};
