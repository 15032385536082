import * as React from 'react';
import PropTypes from 'prop-types';
import { forwardRef, useState, useRef } from 'react';
import { Icon, ErrorMessage } from '@components/shared';
import { classNames, formatErrorMessage } from '@src/utils';
import MaskedInput from 'react-text-mask';
import { useDidUpdate } from '@src/hooks';

export const Input = forwardRef(
  (
    {
      name,
      label,
      value,
      onChange,
      onBlur,
      onFocus,
      password,
      alert,
      placeholder,
      maxLength,
      disabled,
      icon,
      className,
      inputClassName,
      hint,
      unnamed,
      required,
      pattern,
      mask,
      maskGuide,
      tabIndex
    },
    ref
  ) => {
    const [type, setType] = useState(password ? 'password' : 'text');
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef();

    const onShowPasswordClick = () => {
      setType(type === 'text' ? 'password' : 'text');

      const input = inputRef.current;

      input.focus();
    };

    useDidUpdate(() => {
      // Moving cursor to the end
      if (!mask && password) {
        inputRef.current.selectionStart = inputRef.current.value.length;
        inputRef.current.selectionEnd = inputRef.current.value.length;
      }
    }, [type]);

    const onInputBlur = (e) => {
      setIsFocused(false);
      onBlur(e);
    };

    const renderInput = () => {
      const props = {
        id: unnamed ? '' : name,
        name: unnamed ? '' : name,
        type,
        placeholder,
        maxLength,
        value: value || '',
        disabled,
        onChange,
        onBlur: (e) => onInputBlur(e),
        onFocus: () => setIsFocused(true),
        ref: (elemRef) => {
          inputRef.current = elemRef;
          ref && (ref.current = elemRef);
        },
        pattern,
        className: classNames([
          'input__element',
          alert && 'input__element--has-error',
          disabled && 'input__element--disabled',
          inputClassName,
        ]),
        tabIndex: {tabIndex}
      };
      return mask ? (
        <MaskedInput mask={mask} guide={maskGuide} {...props} />
      ) : (
        <input {...props} />
      );
    };

    return (
      <div
        className={classNames(['input', className, icon && 'input--has-icon'])}
      >
        {label && (
          <label
            htmlFor={name}
            className={classNames([
              'input__label',
              isFocused && 'input__label--focused',
              required && 'required-mark',
            ])}
          >
            {label}
          </label>
        )}

        <div className="input__wrapper">
          {renderInput()}
          {icon}
          {password && !disabled && (
            <button
              onClick={onShowPasswordClick}
              className="input__show-password"
              type="button"
            >              
              <Icon 
                type={type === 'text' ? 'hide-password' : 'show-password'} 
                size='lg'
              />
            </button>
          )}
        </div>
        {hint && <div className="input__hint">{hint}</div>}
        <ErrorMessage text={formatErrorMessage(label, alert)} show={!!alert} />
      </div>
    );
  }
);

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  alert: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  disabled: PropTypes.bool,
  unnamed: PropTypes.bool,
  required: PropTypes.bool,
  mask: PropTypes.array,
  maskGuide: PropTypes.bool,
  tabindex: PropTypes.number
};

Input.defaultProps = {
  name: 'input-name',
  label: '',
  type: 'text',
  value: '',
  alert: '',
  onChange: () => {},
  className: '',
  onBlur: () => {},
  placeholder: '',
  maxLength: '50',
  disabled: false,
  unnamed: false,
  required: false,
  mask: null,
  maskGuide: true,
  tabindex: 0
};