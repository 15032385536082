import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Icon, Link, Loader } from '@components/shared';
import { ProgramsCourseCard } from '@components/features';
import { getCourses } from '@actions/programsActions';
import 'swiper/swiper.scss';

export const ProviderAboutPrograms = ({ programsData = {} }) => {
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.programs.courses);
  const isLoading = courses.loading;
  const { title, subtitle, description } = programsData;
  useEffect(() => {
    if (!courses.data.length) {
      dispatch(getCourses());
    }
  }, []);

  const sliderSettings = {
    className: 'about-programs__slider',
    navigation: {
      nextEl: '.about-programs__next-button',
      prevEl: '.about-programs__prev-button',
    },
    slidesPerView: 1,
    spaceBetween: 20,
    breakpoints: {
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <div className="about-programs">
      <div className="about-programs__title-block">
        <div className="about-programs__title-block-top">
          <h2 className="provider-about__subtitle">{title}</h2>
          <h3 className="page-block-title">{subtitle}</h3>
          <p>{description}</p>
        </div>

        <div className="about-programs__title-block-bottom">
          <Link href={`${window.location.origin}/offerings`}>
            View all Offerings
          </Link>
        </div>
      </div>

      <div className="about-programs__body">
        <Loader global show={isLoading} />
        <Swiper {...sliderSettings}>
          {courses.data.map((course) => {
            return (
              <SwiperSlide className="about-programs__slide" key={course.id}>
                <ProgramsCourseCard
                  className="programs-course--about"
                  course={course}
                  isProviderAbout
                />
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className="global-page__buttons about-programs__buttons">
          <div className="global-page__prev-button about-programs__prev-button">
            <Icon type="arrow-prev" />
          </div>

          <div className="global-page__next-button about-programs__next-button">
            <Icon type="arrow-next" />
          </div>
        </div>
      </div>
    </div>
  );
};
