import React from 'react';

export const renderClassOrBadge = (is_free, is_paid, badge) => {
  if (is_free === true && is_paid === true) { 
    return badge ? <p>Free or Pay to Watch</p> : 'common'
  }  if (is_free === true) {
    return badge ? <p>Free</p> : 'free'
  } if (is_paid === true) {
    return badge ? <p>Pay to Watch</p> : 'paid'
  } 
}