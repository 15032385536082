import { format } from 'date-fns';
import { dateFormatRequest } from '@src/constants';
import { zonedDate } from '@src/utils';

export const normalizeBlockRegData = ({
  selectedStartDate,
  selectedWeekDaySchedules,
  activeBlock,
  donation,
}) => {
  const start_date = format(
    zonedDate(selectedStartDate.value),
    dateFormatRequest
  );
  const schedules = Object.entries(selectedWeekDaySchedules).map(
    ([day_num, { schedule_id: id, label = '' }]) => {
      return {
        day_num: +day_num,
        id,
        label,
      };
    }
  );
  const schedulesWithNoOptions = schedules.filter((schedule) => schedule.label);
  const regData = {
    block_id: activeBlock.id,
    start_date,
    schedules: schedulesWithNoOptions,
  };

  if (donation?.hasDonation) {
    regData.donation = donation;
  }

  return regData;
};