import { useEffect, useState, useCallback } from 'react';
import { useDidUpdate } from '@src/hooks';
import {
  debounce,
  setActiveTab,
  createTab,
  apiRequest,
  requestCallback,
  getActiveTab,
} from '@src/utils';
import {
  Loader,
  Tabs,
  Input,
  Icon,
  Select,
  SortingOrderButton,
  Button,
  NothingFound,
} from '@components/shared';
import { AccountScheduleCard } from './AccountScheduleCard';
import { GET_APPOINTMENTS_URL } from '@src/endpoints';

export const AccountScheduleListView = () => {
  const [listTabs, setListTabs] = useState([
    createTab('Upcoming', 'upcoming', true),
    createTab('Past', 'past'),
  ]);
  const [search, setSearch] = useState('');
  const [sortingBy, setSortingBy] = useState('date');
  const [sortingOrder, setSortingOrder] = useState('asc');
  const [appointments, setAppointments] = useState([]);
  const [pageCounter, setPageCounter] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNoEvents, setHasNoEvents] = useState(false);
  const activeTab = getActiveTab(listTabs);

  const sortingOptions = [
    { name: 'Date', value: 'date' },
    { name: 'Title', value: 'title' },
    { name: 'Content Type', value: 'content_type' },
    { name: 'Offering ID', value: 'classID' },
  ];

  const getAppointmentsList = async (search, loadMore) => {
    setHasNoEvents(false);
    setIsLoading(true);
    const requesData = {
      tab: activeTab.value,
      sort: sortingOrder,
      sort_by: sortingBy,
      page: loadMore ? pageCounter : 1,
      search,
    };
    await apiRequest({
      url: GET_APPOINTMENTS_URL(requesData),
      method: 'GET',
      callbacks: requestCallback(({ data, links }) => {
        if (!data.length) {
          setHasNoEvents(true);
        }
        setHasMore(!!links.next);
        loadMore
          ? setAppointments((list) => [...list, ...data])
          : setAppointments(data);
      }),
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getAppointmentsList(search);
  }, [sortingBy, sortingOrder, activeTab]);

  useEffect(() => {
    getAppointmentsList(search, true);
  }, [pageCounter]);

  useDidUpdate(() => {
    requestSearched(search);
  }, [search]);

  const requestSearched = useCallback(
    debounce((search) => {
      getAppointmentsList(search);
    }, 500),
    []
  );

  return (
    <>
      <div className="account-schedule__list-header">
        <Tabs
          className="account-schedule__list-tabs tabs--bordered"
          tabsData={listTabs}
          onTabClick={({ value }) => {
            setActiveTab(setListTabs, value);
          }}
        />
        <div className="account-schedule__list-actions">
          <Input
            className="account-schedule__list-search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            icon={<Icon type="search" />}
          />
          <div className="account-schedule__list-sorting">
            <Select
              className="account-schedule__list-sorting-select"
              options={sortingOptions}
              optionsLabel="name"
              optionsKey="value"
              optionsValue="value"
              onChange={({ value }) => setSortingBy(value)}
              value={sortingBy}
              valueLabel="Sort by:"
            />
            <SortingOrderButton
              className={`account-schedule__list-sorting-btn`}
              sortingOrder={sortingOrder}
              onClick={() =>
                setSortingOrder(sortingOrder === 'asc' ? 'desc' : 'asc')
              }
            >
              <Icon type="sorting" />
            </SortingOrderButton>
          </div>
        </div>
      </div>
      <Loader sticky show={isLoading} />
      {appointments?.map((schedule, i) => (
        <AccountScheduleCard scheduleData={schedule} key={i} />
      ))}
      {hasNoEvents && (
        <NothingFound title="Sorry, no results found" subtitle="" />
      )}
      {hasMore && (
        <Button
          className="account__more-button"
          onClick={() => setPageCounter(pageCounter + 1)}
          iconRight={<Icon type="chevron" size={'sm'} />}
        >
          View More
        </Button>
      )}
    </>
  );
};
