import React from 'react';
import { Sponsors } from '@components/features';

export const ProviderAboutSponsors = ({ sponsorsData }) => (
  <Sponsors
    sponsorsData={sponsorsData}
    blockClassName="about-sponsors"
    titleClassName="page-title"
  />
);
