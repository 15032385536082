export function basicStore(initialData, isSingleValue = false) {
  const handlers = [];
  let nextHandlerId = 0;
  let storeData = initialData;

  return {
    get data() {
      return storeData;
    },
    set data(t) {
      storeData = t;

      handlers.forEach(handler => {
        handler.handler(storeData);
      });
    },
    watch(cb) {
      let ID = nextHandlerId++;
      handlers.push({
        id: ID,
        handler: cb,
      });
      return ID;
    },
    unwatch(id) {
      for (let i = 0; i < handlers.length; i++) {
        if (handlers[i].id === id) {
          handlers.splice(i, 1);
          break;
        }
      }
    },
    setState(newState) {
      if (isSingleValue) return (this.data = newState);

      this.data = { ...this.data, ...newState };
    },
  };
}