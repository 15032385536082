import PropTypes from 'prop-types';
import { Icon, Link, Image } from '@components/shared';
import { classNames, transformLink } from '@src/utils';
import { useViewport } from '@src/hooks';
import { InstructorDetailsOfferings } from './InstructorDetailsOfferings';

export const InstructorDetailsContent = ({ data, id }) => {
  const { isMobile } = useViewport();

  const {
    phone,
    cell_phone,
    home_phone,
    email,
    site,
    site_2,
    twitter,
    facebook,
    instagram,
    youtube,
    faculty,
    display_name,
  } = data;

  const {
    art_instruction,
    bio,
    profile_image,
    user_image,
    affiliation_title_1,
    affiliation_title_2,
    affiliation_company_1,
    affiliation_company_2,
  } = faculty;

  const hasSocials = twitter || facebook || instagram || youtube;

  const hasAffilations =
    affiliation_title_1 ||
    affiliation_title_2 ||
    affiliation_company_1 ||
    affiliation_company_2;

  const protocol = 'http';

  const changeBadUrl = (link) => {
    if (!link) return;

    const newUrl = !link.includes(protocol) ? `https://${link}` : link;

    return newUrl;
  };

  const phones = [
    { phone: phone, icon: 'contact--work-phone' },
    { phone: cell_phone, icon: 'contact--phone' },
    { phone: home_phone, icon: 'contact--home-phone' },
  ];

  const hasContacts = phones || email || site || site_2;

  const renderProfile = () => {
    return (
      <>
        <div className="instructor-details__block instructor-details__profile">
          {user_image && (
            <div className="instructor-details__profile-image">
              <img src={user_image.path} alt="instructor" />
            </div>
          )}

          <div className="instructor-details__profile-content">
            <h1 className="page-title instructor-details__title">
              {display_name}
            </h1>
            {art_instruction && (
              <p className="instructor-details__subtitle">{art_instruction}</p>
            )}
          </div>
        </div>

        {hasSocials && (
          <div className="instructor-details__block instructor-details__socials">
            <h2 className="instructor-details__title instructor-details__title--secondary">
              Socials
            </h2>

            <ul className="instructor-details__socials-list">
              {twitter && (
                <li className="instructor-details__socials-item">
                  <Link href={transformLink(twitter)} rel="noopener noreferrer">
                    <Icon type="socials--twitter-xl" size="xxl" />
                  </Link>
                </li>
              )}

              {facebook && (
                <li className="instructor-details__socials-item">
                  <Link
                    href={transformLink(facebook)}
                    rel="noopener noreferrer"
                  >
                    <Icon type="socials--facebook-xl" size="xxl" />
                  </Link>
                </li>
              )}

              {instagram && (
                <li className="instructor-details__socials-item">
                  <Link
                    href={transformLink(instagram)}
                    rel="noopener noreferrer"
                  >
                    <Icon type="socials--instagram-xl" size="xxl" />
                  </Link>
                </li>
              )}

              {youtube && (
                <li className="instructor-details__socials-item">
                  <Link href={transformLink(youtube)} rel="noopener noreferrer">
                    <Icon type="socials--youtube-xl" size="xxl" />
                  </Link>
                </li>
              )}
            </ul>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="instructor-details__body">
        {isMobile && renderProfile()}

        <div
          className={classNames([
            'instructor-details__visual',
            !profile_image && 'instructor-details__visual--empty',
          ])}
        >
          {profile_image ? (
            <Image src={profile_image.path} alt="profile" />
          ) : (
            <Icon
              type="user-undefined--new"
              className="instructor-details__no-photo-image"
            />
          )}
        </div>

        <div className="instructor-details__content">
          {!isMobile && renderProfile()}

          {hasContacts && (
            <div className="instructor-details__block instructor-details__contacts">
              <h2 className="instructor-details__title instructor-details__title--secondary">
                Contacts
              </h2>

              <ul className="instructor-details__contacts-list">
                {phones.map(
                  (phone, idx) =>
                    phone.phone && (
                      <li
                        className="instructor-details__contacts-item"
                        key={idx}
                      >
                        <Link
                          iconLeft={<Icon type={phone.icon} size="lg" />}
                          href={`tel:${email}`}
                        >
                          {phone.phone}
                        </Link>
                      </li>
                    )
                )}

                {email && (
                  <li className="instructor-details__contacts-item">
                    <Link
                      iconLeft={<Icon type="contact--mail" size="lg" />}
                      href={`mailto:${email}`}
                    >
                      {email}
                    </Link>
                  </li>
                )}

                {site && (
                  <li className="instructor-details__contacts-item">
                    <Link
                      iconLeft={<Icon type="contact--site" size="lg" />}
                      href={changeBadUrl(site)}
                      rel="noopener noreferrer"
                    >
                      {site}
                    </Link>
                  </li>
                )}

                {site_2 && (
                  <li className="instructor-details__contacts-item">
                    <Link
                      iconLeft={<Icon type="contact--site" size="lg" />}
                      href={changeBadUrl(site_2)}
                      rel="noopener noreferrer"
                    >
                      {site_2}
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          )}

          {hasAffilations && (
            <div className="instructor-details__block instructor-details__affiliations">
              <h2 className="instructor-details__title instructor-details__title--secondary">
                Affiliations
              </h2>

              <ul className="instructor-details__affiliations-list">
                {(affiliation_title_1 || affiliation_company_1) && (
                  <li className="instructor-details__affiliations-item">
                    {affiliation_title_1}
                    {!!affiliation_title_1 && !!affiliation_company_1 && ', '}
                    {affiliation_company_1}
                  </li>
                )}

                {(affiliation_title_2 || affiliation_company_2) && (
                  <li className="instructor-details__affiliations-item">
                    {affiliation_title_2}
                    {!!affiliation_title_2 && !!affiliation_company_2 && ', '}
                    {affiliation_company_2}
                  </li>
                )}
              </ul>
            </div>
          )}

          {bio && (
            <div className="instructor-details__block instructor-details__about">
              <h2 className="instructor-details__title instructor-details__title--secondary">
                About
              </h2>

              <div
                className="instructor-details__about-body text-editor-block"
                dangerouslySetInnerHTML={{ __html: bio }}
              />
            </div>
          )}
        </div>
      </div>
      <InstructorDetailsOfferings instructorId={id} />
    </>
  );
};

InstructorDetailsContent.propTypes = {
  data: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string,
    site: PropTypes.string,
    twitter: PropTypes.string,
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    faculty: PropTypes.object,
  }),
};

InstructorDetailsContent.defaultProps = {
  data: {
    phone: null,
    email: null,
    site: null,
    twitter: null,
    facebook: null,
    instagram: null,
    faculty: null,
  },
};
