import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  createTab,
  setActiveTab,
  classNames,
  changeBodyScroll,
} from '@src/utils';
import { useViewport } from '@src/hooks';
import { Tabs, Icon } from '@components/shared';

export const AccountNavigation = ({ setIsMobileNavOpen }) => {
  const { isTablet, height } = useViewport();
  const { pathname } = useLocation();
  const history = useHistory();
  const [offset, setOffset] = useState(false);

  const accountPath = '/account';

  const TabContent = (type, text) => (
    <>
      {!isTablet && (
        <span
          className={classNames([
            'account__tab-icon',
            `account__tab-icon--${type}`,
          ])}
        >
          <Icon type={type} size="lg" />
        </span>
      )}
      <span className="account__tab-text">{text}</span>
    </>
  );

  const [navTabs, setNavTabs] = useState([
    createTab(TabContent('calendar', 'Schedule'), `account/schedule`, true),
    createTab(TabContent('tickets-tab', 'Tickets'), `account/tickets`),
    createTab(TabContent('video-tab', 'Videos'), `account/videos`),
    createTab(
      TabContent('transaction-tab', 'Transactions'),
      `account/transactions`
    ),
    createTab(
      TabContent('profile-tab', 'Profile'),
      `account/profile-information`
    ),
    createTab(
      TabContent('institutions-tab', 'Institution Settings'),
      `account/institutions-settings`
    ),
  ]);

  useEffect(() => {
    if (pathname !== accountPath) {
      setActiveTab(setNavTabs, pathname);
    }

    if (isTablet && setIsMobileNavOpen) {
      setIsMobileNavOpen(false);
      changeBodyScroll(false, 'modal');
    }

    const unlisten = history.listen((location) => {
      setActiveTab(setNavTabs, location.pathname);
    });

    return () => unlisten();
  }, []);

  const onScroll = () => {
    if (!document.querySelector('.modal')) {
      setOffset(window.scrollY);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div
      className={classNames([
        'account-navigation',
        height > 565 && offset > 68 && 'account-navigation--scroll',
      ])}
    >
      {!isTablet && <h1 className="page-title">Account</h1>}
      <Tabs
        tabsData={navTabs}
        className="account__tabs"
        isShadowVisible={false}
        isLinks={true}
      />
    </div>
  );
};
