import { apiRequest, requestReduxHandler } from '@src/utils';
import { COURSES_URL, PROGRAMS_FILTERS_URL } from '@src/endpoints';
import {
  getAction,
  onError,
  onLoading,
  onSuccess,
} from '@src/store/actionCreators';
import * as types from '@types/programsTypes';
import { normalizeOfferingsFiltersData } from '@src/normalizers';

export const getCourses =
  (reqData = {}, search, page, lazy = false) =>
  () => {
    const { filtersSelect = {} } = reqData || {};

    apiRequest({
      url: COURSES_URL(reqData, search, lazy ? page + 1 : 1),
      method: 'POST',
      reduxHandlers: requestReduxHandler(
        (res) => onSuccess(lazy ? types.COURSES_MORE : types.COURSES, res),
        () =>
          onLoading(lazy ? types.COURSES_LOADING_MORE : types.COURSES_LOADING),
        (err) => onError(types.COURSES_ERROR, err)
      ),
      data: normalizeOfferingsFiltersData(filtersSelect)
    });
  };

export const getCoursesFilters = () => () => {
  apiRequest({
    url: PROGRAMS_FILTERS_URL,
    method: 'GET',
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.PROGRAMS_FILTERS, res.data),
      () => onLoading(types.PROGRAMS_FILTERS_LOADING),
      (err) => onError(types.PROGRAMS_FILTERS_ERROR, err)
    ),
  });
};

export const setProgramsTab = (tab) =>
  getAction(types.PROGRAMS_TAB, tab);

export const setProgramsSortingBy = (value) =>
  getAction(types.PROGRAMS_SORTING_BY, value);

export const setProgramsSortingOrder = (order) =>
  getAction(types.PROGRAMS_SORTING_ORDER, order);

export const setProgramsFiltersSelect = (filters) =>
  getAction(types.PROGRAMS_FILTERS_SELECT, filters);
