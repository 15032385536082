import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '@src/utils';

export const SelectOption = (props) => {
  const { label, handleClick, fullObj, isActive, isSelected } = props;

  const onClick = () => {
    handleClick(fullObj);
  };

  const isClearListItem = label === 'Clear selection'

  return (
    <li
      onClick={onClick}
      className={classNames([
        'select__inner-list-item',
        isActive && 'select__inner-list-item--active',
        isSelected && 'select__inner-list-item--selected',
        isClearListItem && 'select__inner-list-item--clear'
      ])}
    >
      {label}
    </li>
  );
}

SelectOption.propTypes = {
  text: PropTypes.string.isRequired,
};

SelectOption.defaultProps = {
  text: '',
};