import { Select } from '@components/shared';
import React from 'react';

export const ProgramsFilterSelectSection = ({
  title,
  identifier,
  data = [],
  loading,
  values,
  searchable = false,
  setCustomValue,
}) => {

  const onSelectChange = (value) => {
    setCustomValue(identifier, value.id);
  };

  return (
    <div className="form__section">
      <div className={`form__group-title form__group-title--${identifier}`}>
        {title}
      </div>
      <div className="form__group">
        <Select
          placeholder={`Choose the ${title}`}
          options={data}
          optionsLabel="name"
          optionsKey="id"
          optionsValue="id"
          optionsLoading={loading}
          onChange={(value) => onSelectChange(value)}
          value={values[identifier]}
          searchable={searchable}
          enableClear
        />
      </div>
    </div>
  );
};