import React from 'react';
import { 
  Button, 
  getModal, 
  openModal,  
} from '@components/shared';
import { zonedDate } from '@src/utils';
import { dateSessionsFormat } from '@src/constants';
import { format } from 'date-fns';
import { useViewport } from '@src/hooks';

export const AccountTransactionsCard = ({ transaction }) => {
  const { amount, order, id } = transaction;
  const { isMobile } = useViewport();
  const orderDate = format(zonedDate(order.created_at), dateSessionsFormat);
  const receiptId = id;

  const onReceiptButtonClick = () => {
    openModal(
      getModal({
        name: 'receipt',
        data: { receiptId },
      })
    );
  };

  const ReceiptButton = () => {
    return(
      <Button
        type="navigation"
        onClick={onReceiptButtonClick}
        className="account-transactions__receipt-button"
      >
        Show Receipt
      </Button>
    )
  };

  return (
    <div className="ftable__row account-transactions__card">
      <div className="ftable__cell account-transactions__id">
        <div className="ftable__cell-content">
          {isMobile && <span>ID</span>}
          <span>{id}</span>  
        </div>
      </div>
      <div className="ftable__cell account-transactions__date">
        <div className="ftable__cell-content">
          <span>{orderDate}</span>
          {isMobile && <span><ReceiptButton /></span>}
        </div>
      </div>
      <div className="ftable__cell account-transactions__class-title">
        <div className="ftable__cell-content">
          {isMobile && <span>Title</span>}
          <span>{order.title}</span>
        </div>
      </div>
      <div className="ftable__cell account-transactions__amount">
        <div className="ftable__cell-content">
          {isMobile && <span>Amount</span>}
          <span>&#36;{amount}</span>
        </div>
      </div>
      {!isMobile && (
        <div className="ftable__cell account-transactions__action">
          <div className="ftable__cell-content">
            <ReceiptButton />
          </div>
        </div>
      )}
    </div>
  );
};
