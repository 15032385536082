import React, { useEffect, useState, useRef } from 'react';
import { createTab, setActiveTab, dragScroll, queryParam } from '@src/utils';
import { Icon, Tabs, Tooltip } from '@components/shared';

export const OfferingDetailsNavigation = ({ courseInfo, setView, view }) => {
  const { custom_tabs, notes, videos, gallery } = courseInfo || {};
  const { timetable, presenters_bio } = custom_tabs || {};
  const hasMultimedia = !!videos?.length || !!gallery?.length;
  const tabsContainerRef = useRef(null);
  
  useEffect(() => {
    if (tabsContainerRef?.current) {
      const newDragScroll = dragScroll(tabsContainerRef.current);
      newDragScroll.init();
    }
  }, [tabsContainerRef]);

  const TabContent = (type, text) => (
    <>
      <span className="offering-details__tab-icon">
        <Icon type={type} size="md" />
      </span>

      <span className="offering-details__tab-text">{text}</span>

      {type === 'video-tab' && (
        <Tooltip
          position="bottom"
          className="offering-details__tab-tooltip"
          body={<Icon type="info--new" />}
        >
          Explore our photo gallery, enjoy a selection of free videos, or make
          payments to access a wider range of exclusive videos
        </Tooltip>
      )}
    </>
  );

  const tabsData = [
    { value: 'main-info', title: 'Main info', icon: 'main-info-tab' },
    { value: 'schedule', title: 'Schedule', icon: 'calendar' },
    hasMultimedia && {
      value: 'multimedia',
      title: 'Multimedia',
      icon: 'video-tab',
    },
    !presenters_bio
      ? {
          value: 'faculty',
          title: `Faculty`,
          icon: 'transaction-tab',
        }
      : {
          value: 'presenters-bio',
          title: `Presenters' Bio`,
          icon: 'transaction-tab',
        },
    !!timetable && {
      value: 'timetable',
      title: 'Timetable',
      icon: 'transaction-tab',
    },
    !!notes && {
      value: 'additional-info',
      title: 'Additional info',
      icon: 'additional-info-tab',
    },
  ].filter((tab) => tab);

  const createTabs = (tabsData) =>
    tabsData.map(({ title, value, icon }) =>
      createTab(TabContent(icon, title), value)
    );

  const [navTabs, setNavTabs] = useState(createTabs(tabsData));

  useEffect(() => {
      setActiveTab(setNavTabs, view);
  }, [view]);

  const onTabClick = (value) => {
    setView(value)
    queryParam.set('offering-view', value)
  } 

  return (
    <Tabs
      tabsData={navTabs}
      className="offering-details__tabs"
      onTabClick={({value}) => onTabClick(value)}
      tabsContainerRef={tabsContainerRef}
    />
  );
};
