import { Icon } from '@components/shared';
import React from 'react';
import {
  createCustomTab,
  zonedDate,
  getOfferingsAmount,
  formatPrice,
} from '@src/utils';
import { registerTypes, dateAndMonthShort } from '@src/constants';
import { format } from 'date-fns';
import _ from 'lodash';

export const getRegTypesTabs = (
  {
    drop_in_registration,
    block_registration,
    sliding_scale_registration,
    pre_registration,
    free_registration,
    available_tickets,
    trial_registration,
    has_available_trial_dates,
    days_count,
    length,
  },
  { content_type, videos, video_prices }
) => {
  const tabsData = [];

  

  const getLastDate = (date) => format(zonedDate(date), dateAndMonthShort);
  const getMinRate = (regData, rateName) => {
    const rates = regData.map((reg) => +reg[rateName]);
    return _.min(rates);
  };

  if (drop_in_registration) {
    const { rate, last_date } = drop_in_registration;
    const lastDate = last_date && getLastDate(last_date);
    const dropInRegTab = createCustomTab(
      registerTypes['d'],
      'd',
      dropInReg(rate, lastDate, content_type),
      false
    );
    tabsData.push(dropInRegTab);
  }

  if (free_registration) {
    const { last_date } = free_registration;
    const lastDate = last_date && getLastDate(last_date);
    const freeRegTab = createCustomTab(
      registerTypes['f'],
      'f',
      freeReg(lastDate, content_type, length),
      false
    );
    tabsData.push(freeRegTab);
  }

  if (block_registration) {
    const minRate = getMinRate(block_registration, 'rate');
    const blockRegTab = createCustomTab(
      registerTypes['b'],
      'b',
      blockReg(minRate, content_type),
      false
    );
    tabsData.push(blockRegTab);
  }

  if (sliding_scale_registration) {
    const { rates, last_date } = sliding_scale_registration;
    const lastDate = last_date && getLastDate(last_date);
    const minRate = getMinRate(rates, 'rate');
    const scaleRegTab = createCustomTab(
      registerTypes['s'],
      's',
      scaleReg(minRate, lastDate, content_type),
      false
    );

    tabsData.push(scaleRegTab);
  }

  if (pre_registration) {
    const { rate, last_date } = pre_registration;
    const lastDate = last_date ? getLastDate(last_date) : null;
    const preRegTab = createCustomTab(
      registerTypes['p'],
      'p',
      preReg(rate, lastDate, days_count, content_type),
      false
    );
    tabsData.push(preRegTab);
  }

  if (videos?.paid) {
    const minRate = getMinRate(video_prices, 'price');
    const videosTab = createCustomTab(
      registerTypes['v'],
      'v',
      videoPurchase(minRate, video_prices.length),
      false
    );
    tabsData.push(videosTab);
  }

  if (available_tickets) {
    const minRate = getMinRate(available_tickets, 'price');
    const ticketsTab = createCustomTab(
      registerTypes['t'],
      't',
      ticketsPurchase(minRate, content_type, available_tickets.length),
      false
    );
    tabsData.push(ticketsTab);
  }

  if (trial_registration && has_available_trial_dates) {
    const trialRegTab = createCustomTab(
      registerTypes['tr'],
      'tr',
      trialReg(content_type, length),
      false
    );
    tabsData.push(trialRegTab);
  }

  return tabsData;
};

const dropInReg = (rate, lastDate, content_type) => {
  return (
    <div className="registration-step__class-option">
      <div className="registration-step__last-date-label">
        {lastDate ? (
          <>
            Last Drop-In Date is <b>{lastDate}</b>
          </>
        ) : (
          <>Continuous drop-in allowed</>
        )}
      </div>
      <Icon type="single-class" className="registration-step__class-icon" />
      <p className="registration-step__option-title">
        I would like to <b>drop in</b> to 1 or more{' '}
        {content_type.plural_short_name}
      </p>
      <span className="registration-step__min-rate-label">
        <b>${formatPrice(rate)}</b> per {content_type.singular_short_name}
      </span>
    </div>
  );
};

const blockReg = (minRate, content_type) => (
  <div className="registration-step__class-option">
    <Icon type="block-class" className="registration-step__class-icon" />
    <p className="registration-step__option-title">
      I would like to <b>register for a block</b> of{' '}
      {content_type.plural_short_name} <b>at a discounted rate</b>
    </p>
    <span className="registration-step__min-rate-label">
      starting from <b>${formatPrice(minRate)}</b> per{' '}
      {content_type.singular_short_name}
    </span>
  </div>
);

const scaleReg = (minRate, lastDate, content_type) => (
  <div className="registration-step__class-option">
    <div className="registration-step__last-date-label">
      {lastDate ? (
        <>
          Last Drop-In Date is <b>{lastDate}</b>
        </>
      ) : (
        <>Continuous drop-in allowed</>
      )}
    </div>
    <Icon type="scale-registration" className="registration-step__class-icon" />
    <p className="registration-step__option-title">
      I would like to <b>choose a scaled rate</b> for attending 1 or more{' '}
      {content_type.plural_short_name}
    </p>
    <span className="registration-step__min-rate-label">
      starting from <b>${formatPrice(minRate)}</b> per{' '}
      {content_type.singular_short_name}
    </span>
  </div>
);

export const preReg = (rate, lastDate, days_count, content_type) => (
  <div className="registration-step__class-option">
    {lastDate && (
      <div className="registration-step__last-date-label">
        Last Pre-Registration Date is <b>{lastDate}</b>
      </div>
    )}
    <Icon type="block-class" className="registration-step__class-icon" />
    <p className="registration-step__option-title">
      I would like to <b>pre-register</b> for this session of
      <br />
      <b>{getOfferingsAmount(days_count, content_type)}</b>
    </p>
    <span className="registration-step__min-rate-label">
      <b>${formatPrice(rate)}</b> per {content_type.singular_short_name}
    </span>
  </div>
);

const freeReg = (lastDate, content_type, length) => {
  return (
    <div className="registration-step__class-option">
      <div className="registration-step__last-date-label">
        {lastDate ? (
          <>
            Last Drop-In Date is <b>{lastDate}</b>
          </>
        ) : (
          <>Continuous drop-in allowed</>
        )}
      </div>
      <Icon
        type="free-registration"
        className="registration-step__class-icon"
      />
      <p className="registration-step__option-title">
        {length > 1 ? (
          <>
            I would like to attend 1 or more {content_type.plural_short_name}{' '}
            <b>for free</b>
          </>
        ) : (
          <>
            I would like to attend this {content_type.singular_short_name}{' '}
            <b>for free</b>
          </>
        )}
      </p>
    </div>
  );
};

const videoPurchase = (minRate, pricesCount) => (
  <div className="registration-step__class-option">
    <Icon type="video-registration" className="registration-step__class-icon" />
    <p className="registration-step__option-title">
      I would like to pay <b>to watch one or more videos</b>
    </p>
    <span className="registration-step__min-rate-label">
      {pricesCount > 1 && 'starting from '}
      <b>${formatPrice(minRate)}</b> per video
    </span>
  </div>
);

const ticketsPurchase = (minRate, content_type, pricesCount) => (
  <div className="registration-step__class-option">
    <Icon type="tickets-purchase" className="registration-step__class-icon" />
    <p className="registration-step__option-title">
      I would like to <b>get tickets</b> to attend this{' '}
      {content_type.singular_short_name}
    </p>
    <span className="registration-step__min-rate-label">
      {pricesCount > 1 && 'starting from '}
      <b>${formatPrice(minRate)}</b> per ticket
    </span>
  </div>
);

const trialReg = (content_type, length) => {
  return (
    <div className="registration-step__class-option">
      <Icon type="trial" className="registration-step__class-icon" />
      <p className="registration-step__option-title">
        {length > 1 ? (
          <>
            I would like to attend{' '}
            <b>trial&nbsp;{content_type.plural_short_name}</b>
          </>
        ) : (
          <>
            I would like to attend{' '}
            <b>trial&nbsp;{content_type.singular_short_name}</b>
          </>
        )}
      </p>
    </div>
  );
};