import React, { useState, useEffect, useContext } from 'react';
import {
  normalizeRegisterData,
  normalizeUserDataErrors,
} from '@src/normalizers';
import {
  countriesWithStates,
  defaultBirthDateFormatMask,
} from '@src/constants';
import { useApi, useForm } from '@src/hooks';
import { GlobalPolicy } from '@components/global';
import {
  Button,
  Checkbox,
  Input,
  Select,
  Icon,
  Radio,
  InfoBox,
  ErrorMessage,
  closeModal,
  openModal,
  getModal,
  Tooltip,
  Loader,
  PhoneListInput,
  RecaptchaMessage,
  changeModalProps,
} from '@components/shared';
import { apiRequest, requestCallback, classNames } from '@src/utils';
import { useDidUpdate } from '@src/hooks';
import { getLoginSchema } from './getLoginSchema';
import { RecaptchaContext } from '@src/RecaptchaContext.js';

export const Signup = ({
  isCourseReg,
  setCurrentView,
  proceedLink,
  setIsNewUser,
  onPassRecoveryClick,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPhoneCounter, setShowPhoneCounter] = useState(1);
  const [showStates, setShowStates] = useState(false);
  const [isPolicyShown, setIsPolycyShown] = useState(false);
  const recaptcha = useContext(RecaptchaContext);

  const submitHandler = (values) => {
    setIsLoading(true);
    recaptcha.executeAsync().then((token) => {
      onSubmitSuccess(values, token);
      recaptcha.reset();
    });
  };

  const onSubmitSuccess = async (values, token) => {
    let authData = normalizeRegisterData(values);
    authData.g_recaptcha_response = token;

    if (proceedLink) {
      authData.redirect_to = proceedLink;
    }

    await apiRequest({
      url: `/account/profile`,
      method: 'POST',
      data: JSON.stringify(authData),
      callbacks: requestCallback(
        () => getSubmitCallback(),
        (err) => err && handleServerErrors(err.response)
      ),
    });

    setIsLoading(false);
  };

  const getSubmitCallback = () => {
    window._mtm = window._mtm || [];

    openModal(getModal({ name: 'email-verification' }));
    closeModal('registration');
    window._mtm.push({ event: 'sign_up' });
  };

  useDidUpdate(() => {
    if (!isPolicyShown) {
      const terms = document.querySelector('.form__section--terms');
      terms.scrollIntoView();
      changeModalProps('registration', {
        className: `modal--narrow`,
        nativeClose: true,
      });
    } else {
      changeModalProps('registration', {
        className: `modal`,
        nativeClose: false,
      });
    }
  }, [isPolicyShown]);

  const schema = getLoginSchema(showStates);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setCustomValue,
    setErrors,
    handleBlur,
  } = useForm({
    callback: submitHandler,
    schema,
  });

  const surveys = useApi({ url: '/global/source-survey', dataPlaceholder: [] });
  const countries = useApi({ url: '/countries', dataPlaceholder: [] });
  const states = useApi(
    {
      url: `/countries/${values.country}/states`,
      dataPlaceholder: [],
    },
    [values.country],
    !!values.country
  );

  useEffect(() => {
    if (countries.data.length) {
      checkStates(values.country, countries.data);
    }
  }, [values.country, countries.data.length]);

  useEffect(() => {
    setCustomValue('state', '');
  }, [values.country]);

  const checkStates = (countryId, countries) => {
    if (!countryId) {
      setShowStates(false);
      return;
    }
    const selectedCountry = countries.find(
      (country) => country.id === countryId
    );
    const countryWithStates = countriesWithStates.includes(
      selectedCountry?.name
    );
    setShowStates(countryWithStates);
  };

  const handleServerErrors = (error) => {
    const { data } = error;

    if (data.errors) {
      setErrors(normalizeUserDataErrors(data.errors));
    }
  };

  const onPolicyAction = (value) => {
    setCustomValue('agreePolicy', value);
    setIsPolycyShown(false);
  };

  return (
    <>
      <div
        className={classNames([
          'registration-step',
          isPolicyShown && 'auth--hidden',
        ])}
      >
        {<Loader show={isLoading} sticky />}
        <div className="registration-step__container">
          <form className="form" onSubmit={handleSubmit}>
            <Icon
              type={isCourseReg ? 'registration-step' : 'auth-welcome'}
              className="auth__icon"
            />
            <h1 className="auth__title">
              {isCourseReg ? (
                'Almost Done!'
              ) : (
                <>
                  Welcome to
                  <br />
                  World Education Web!
                </>
              )}
            </h1>
            <div className="auth__help">
              <span className="auth__help-text">Need Registration Help?</span>
              <Tooltip position="bottom" className="auth__tooltip">
                <span>
                  For Log in, Sign up, or Registration assistance, please email
                </span>
                <a href="mailto:dan.yonah.marshalll@gmail.com">
                  dan.yonah.marshall@gmail.com
                </a>
                <span>or call (617) 642-1444.</span>
              </Tooltip>
            </div>

            <div className="form__group">
              <Input
                label="Email"
                placeholder="Enter your Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                name="email"
                alert={errors.email}
                required
              />
            </div>

            <div className="form__group">
              <Input
                label="Password"
                placeholder="Enter your Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                password
                name="password"
                alert={errors.password}
                required
                hint="Password should include at least 6 characters, both uppercase and lowercase letters and at least one number"
              />
            </div>

            <div className="form__group">
              <Input
                label="Repeat Password"
                placeholder="Repeat your Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmPassword}
                password
                name="confirmPassword"
                alert={errors.confirmPassword}
                required
              />
            </div>

            <div className="form__group">
              <Input
                label="Username"
                placeholder="Enter your Username"
                onChange={handleChange}
                value={values.userName}
                name="userName"
                alert={errors.userName}
              />
            </div>

            <div className="form__group auth__switcher auth__switcher--sign-up">
              <div className="auth__switcher__block">
                <span>Already have an account?</span>
                <Button
                  type="navigation"
                  disabled={isLoading}
                  onClick={() => setIsNewUser(false)}
                >
                  Sign in
                </Button>
              </div>
              <div className="auth__switcher__block">
                <div>Returning Student?</div>
                <Button
                  type="navigation"
                  htmlType="button"
                  className="auth__forgot-password"
                  onClick={() => onPassRecoveryClick()}
                >
                  Create Password
                </Button>
              </div>
            </div>

            <div className="form__section">
              <div className="form__group">
                <Checkbox
                  onChange={handleChange}
                  value={values.underAge}
                  name="underAge"
                  label="I’m under 18 years old"
                />
              </div>

              {values.underAge && (
                <>
                  <div className="form__group">
                    <Input
                      placeholder="Enter Name of Parent or Guardian"
                      label="Name of Parent or Guardian"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.parentName}
                      name="parentName"
                      alert={errors.parentName}
                      required
                    />
                  </div>

                  <div className="form__group">
                    <PhoneListInput
                      placeholder="Enter Parent or Guardian Phone Number"
                      label="Phone Number"
                      onChange={(value) => setCustomValue('parentPhone', value)}
                      onBlur={handleBlur}
                      value={values.parentPhone}
                      name="parentPhone"
                      alert={errors.parentPhone}
                      required
                    />
                  </div>
                </>
              )}
            </div>

            <div className="form__section">
              <h4 className="form__group-title form__group-title--big">
                Please tell us a little more about yourself
              </h4>

              <h4 className="form__group-title">Personal Information</h4>

              <div className="form__group">
                <Input
                  label="First Name"
                  placeholder="Enter your First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  name="firstName"
                  alert={errors.firstName}
                  required
                />
              </div>

              <div className="form__group">
                <Input
                  label="Middle name"
                  placeholder="Enter your Middle Name"
                  onChange={handleChange}
                  value={values.middleName}
                  name="middleName"
                  alert={errors.middleName}
                />
              </div>

              <div className="form__group">
                <Input
                  label="Last name"
                  placeholder="Enter your Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  name="lastName"
                  alert={errors.lastName}
                  required
                />
              </div>

              <div className="form__group">
                <Input
                  mask={defaultBirthDateFormatMask}
                  label="Date of Birth"
                  placeholder="MM/DD/YYYY"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.birthDate}
                  name="birthDate"
                  alert={errors.birthDate}
                  hint="Optional: We use your date of birth to find other great Content/Offerings for you"
                />
              </div>
            </div>
            <div className="form__section">
              <h4 className="form__group-title">Contact information</h4>

              <div className="form__group">
                <PhoneListInput
                  placeholder="Enter your Cell Phone"
                  label="Cell Phone"
                  onChange={(value) => setCustomValue('cellPhone', value)}
                  onBlur={handleBlur}
                  value={values.cellPhone}
                  name="cellPhone"
                  alert={errors.cellPhone}
                  hint="Entering your phone number will allow us to contact you if such a need arises"
                />

                {showPhoneCounter < 3 && (
                  <Button
                    className="form__button--add-phone"
                    onClick={() => setShowPhoneCounter(showPhoneCounter + 1)}
                    htmlType="button"
                  >
                    <Icon type="plusInCircle" size="md" />
                  </Button>
                )}
              </div>

              {showPhoneCounter > 1 && (
                <div className="form__group">
                  <PhoneListInput
                    placeholder="Enter your Work Phone"
                    label="Work Phone"
                    onChange={(value) => setCustomValue('workPhone', value)}
                    onBlur={handleBlur}
                    value={values.workPhone}
                    name="workPhone"
                    alert={errors.workPhone}
                  />
                </div>
              )}

              {showPhoneCounter > 2 && (
                <div className="form__group">
                  <PhoneListInput
                    placeholder="Enter your Home Phone"
                    label="Home Phone"
                    onChange={(value) => setCustomValue('homePhone', value)}
                    onBlur={handleBlur}
                    value={values.homePhone}
                    name="homePhone"
                    alert={errors.homePhone}
                  />
                </div>
              )}
            </div>

            <div className="form__group">
              <Input
                label="Zip"
                placeholder="Enter Zip"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.zip}
                name="zip"
                alert={errors.zip}
                required
                hint="Entering your ZIP code helps us analyze our audience location in order to tailor our services to better meet the needs of our community."
              />
            </div>

            <div className="form__section">
              <h4 className="form__group-title">Emergency Contacts</h4>

              <div className="form__group">
                <Input
                  placeholder="Enter Emergency Contact Name"
                  label="Emergency Contact Name"
                  onChange={handleChange}
                  value={values.emergencyContactName}
                  name="emergencyContactName"
                  alert={errors.emergencyContactName}
                />
              </div>

              <div className="form__group">
                <PhoneListInput
                  placeholder="Enter Emergency Contact Phone"
                  label="Emergency Contact Phone"
                  onChange={(value) =>
                    setCustomValue('emergencyContactPhone', value)
                  }
                  onBlur={handleBlur}
                  value={values.emergencyContactPhone}
                  name="emergencyContactPhone"
                  alert={errors.emergencyContactPhone}
                />
              </div>

              <div className="form__group">
                <Input
                  label="Health Concerns"
                  placeholder="List your Health Concerns"
                  onChange={handleChange}
                  value={values.healthConcerns}
                  name="healthConcerns"
                  alert={errors.healthConcerns}
                />
              </div>
            </div>

            <div className="form__section">
              <h4 className="form__group-title required-mark">
                How did you hear about us?
              </h4>

              <div className="form__group">
                <Select
                  name="knownSource"
                  placeholder="Select option"
                  value={values.knownSource}
                  options={surveys.data}
                  onChange={(result) =>
                    setCustomValue('knownSource', result.id)
                  }
                  loading={surveys.loading}
                  optionsLabel="option"
                  optionsValue="id"
                  optionsKey="id"
                />
                <ErrorMessage
                  text={errors.knownSource}
                  show={!!errors.knownSource}
                />
              </div>
            </div>

            <div className="form__section form__section--terms">
              <h4 className="form__group-title required-mark">
                Terms and Conditions
              </h4>

              <div className="form__group form__group--checkox">
                <Checkbox
                  onChange={handleChange}
                  value={values.agreePolicy}
                  name="agreePolicy"
                  label={
                    <>
                      I agree{' '}
                      <Button
                        type="link"
                        htmlType="button"
                        onClick={() => setIsPolycyShown(true)}
                      >
                        Terms & Conditions and Privacy Policy
                      </Button>
                    </>
                  }
                  alert={errors.agreePolicy}
                />
              </div>
            </div>

            <div className="form__section">
              <h4 className="form__group-title required-mark">
                Media Consent and Waiver
              </h4>

              <InfoBox height={150} className="info-box--media-waiver">
                <p>
                  I understand that occasional photography, video, and audio,
                  may be taken during classes/workshops/events/other offerings,
                  and used for archival, marketing, and social media purposes.
                  When used for archival and reselling of digital recordings of
                  classes/workshops/events/other offerings (to provide residual
                  income for instructors/producers/faculty members, and for
                  other charitable purposes) a specific authorization in the
                  form of explicit permission will be needed. By signing the
                  Media Consent and Waiver, I am giving permission only to be
                  recorded with photography, video, and/or audio during
                  classes/workshops/events/other offerings. I understand that I
                  may reserve the right not to sign the Media Consent and Waiver
                  or to change my preference at any time, in which case my
                  image, and/or video, and/or audio, will no longer be taken
                  going forward from the date I choose to decline the Media and
                  Consent Waiver. This Media Consent and Waiver applies to
                  offerings produced/facilitated by the World Education Web
                  (worldeducationweb.org), a non-profit online platform created,
                  owned, and developed by the Brookline/Cambridge Community
                  Center for the Arts, Inc. (AKA BCCA / CCCA), while each
                  content Provider that has an account in the World Education
                  Web, may have their own individual media consent and waiver to
                  govern the offerings it produces/facilitates. Additionally,
                  the World Education Web (worldeducationweb.org) platform may
                  use examples of photography, cover art, video, and audio from
                  Provider offerings it hosts, to demonstrate the types and
                  diversity of offerings in the platform for archival,
                  marketing, and social media purposes.
                </p>
              </InfoBox>

              <div className="form__group form__group--radio">
                <Radio
                  onChange={() => setCustomValue('mediaWaiver', true)}
                  value={values.mediaWaiver === true}
                  name="mediaWaiver"
                  label="Yes"
                  className={!!errors.mediaWaiver ? 'radio--has-error' : ''}
                />
                <Radio
                  onChange={() => setCustomValue('mediaWaiver', false)}
                  value={values.mediaWaiver === false}
                  name="mediaWaiver"
                  label="No"
                  className={!!errors.mediaWaiver ? 'radio--has-error' : ''}
                />
                <ErrorMessage
                  show={!!errors.mediaWaiver}
                  text={errors.mediaWaiver}
                />
              </div>
            </div>

            <div className="form__section">
              <div className="form__group">
                <Checkbox
                  onChange={handleChange}
                  value={values.subscribeEmail}
                  name="subscribeEmail"
                  label="I want to receive World Education Web Email newsletters"
                />
              </div>

              <div className="form__group">
                <Checkbox
                  onChange={handleChange}
                  value={values.subscribeSnail}
                  name="subscribeSnail"
                  label="I want to receive World Education Web snail (standard post) mail"
                />
              </div>

              {values.subscribeSnail && (
                <>
                  <div className="form__hint">
                    Please provide us with your mailing address for if/when we
                    send snail mail communications.
                  </div>

                  <div className="form__group">
                    <Input
                      label="Address"
                      placeholder="Enter your Address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                      name="address"
                      alert={errors.address}
                      required
                    />
                  </div>

                  <div className="form__group">
                    <Input
                      label="City"
                      placeholder="Enter your City"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      name="city"
                      alert={errors.city}
                      required
                    />
                  </div>

                  <div className="form__group">
                    <Select
                      label="Country"
                      options={countries.data}
                      loading={countries.loading}
                      value={values.country}
                      placeholder="Select Country"
                      onChange={(val) => setCustomValue('country', val.id)}
                      optionsKey="id"
                      optionsValue="id"
                      optionsLabel="name"
                      alert={errors.country}
                      searchable
                      required
                    />
                  </div>

                  {showStates && !!states.data.length && (
                    <div className="form__group">
                      <Select
                        options={states.data}
                        value={values.state}
                        placeholder="Select State"
                        label="State"
                        onChange={(val) => setCustomValue('state', val.id)}
                        optionsKey="id"
                        optionsValue="id"
                        optionsLabel="name"
                        required
                        searchable
                        alert={errors.state}
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="form__group form__group--auth-buttons">
              <Button
                type="transparent"
                onClick={() => setIsNewUser(false)}
                htmlType="button"
                disabled={isLoading}
                iconLeft={<Icon type="chevron" />}
              >
                Back
              </Button>

              <Button type="primary" htmlType="submit" disabled={isLoading}>
                Submit
              </Button>
            </div>
            <RecaptchaMessage />
          </form>
        </div>
      </div>
      {isPolicyShown && (
        <GlobalPolicy
          setCurrentView={setCurrentView}
          onPolicyAction={(value) => onPolicyAction(value)}
          options
        />
      )}
    </>
  );
};
