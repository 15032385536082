export const selectedFiltersCounter = (filters) => {
  let counter = 0;
  Object.entries(filters).forEach(([name, value]) => {
    switch (name) {
      case 'letter': {
        return value !== 'all' && ++counter;
      }

      case 'participant_age_to': {
        return value && value !== 1440 && ++counter;
      }

      default: {
        return Array.isArray(value)
          ? (counter += value.length)
          : !!value && ++counter;
      }
    }
  });

  return counter;
};
