import React from 'react';
import { classNames } from '@src/utils';
import { Button, Icon } from '@components/shared';

export const SortingOrderButton = ({
  className,
  onClick,
  disabled,
  sortingOrder,
}) => {
  return (
    <Button
      className={classNames([
        'sorting-order-button',
        `sorting-order-button--${sortingOrder}`,
        className,
      ])}
      disabled={!!disabled}
      onClick={onClick}
    >
      <Icon type="sorting" />
    </Button>
  );
};
