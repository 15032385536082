import React, { useEffect, useState } from 'react';
import { queryParam, createOrderRequest } from '@src/utils';
import { Checkout } from '@components/modals';
import { DonationForm } from './DonationForm';

export const DonationModal = () => {
  const [donationStep, setDonationStep] = useState(1);
  const [donationData, setDonationData] = useState({});
  

  useEffect(() => {
    queryParam.set('donationModal', true);

    return () =>
      queryParam.delete(['donationModal', 'donationAmount', 'isAnonymous']);
  }, []);

  const btnCallbacks = {
    submit: (payment_gateway) => {
      return createOrderRequest(
        { ...donationData, payment_gateway },
        'donation'
      );
    },
    back: () => {
      setDonationStep(1);
    },
  };

  const stepsNavigation = {
    1: <DonationForm setDonationStep={setDonationStep} setDonationData={setDonationData}/>,
    2: <Checkout btnCallbacks={btnCallbacks} />,
  };

  return <>{stepsNavigation[donationStep]}</>;
};
