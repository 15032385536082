export const openPdf = ({ pdf, name }) => {
  let pdfWindow = window.open('about:blank');
  if (pdfWindow) {
    pdfWindow.document.write(
      `<html><head><title>${name}</title></head><body><iframe width='100%' height='100%' src='data:application/pdf;base64, ` +
        encodeURI(pdf) +
        "'></iframe></body></html>"
    );
  }
};
