import React from 'react';
import { Button, Icon } from '@components/shared';
import { useDispatch, useSelector } from 'react-redux';
import {
  setProgramsSortingBy,
  setProgramsSortingOrder,
} from '@actions/programsActions';
import { classNames } from '@src/utils';

export const ProgramsSortingMenu = () => {
  const sortingBy = useSelector((state) => state.programs.sortingBy);
  const sortingOrder = useSelector((state) => state.programs.sortingOrder);
  const tab = useSelector((state) => state.programs.tab);
  const dispatch = useDispatch();

  const sortingOptions = [
    tab === "current" && { name: 'Date', value: 'upcoming_class' },
    { name: 'Title', value: 'title' },
    { name: 'Content Type', value: 'content_type' },
    { name: 'Content ID', value: 'classID' },
  ].filter(opt => opt);

  return (
    <div className="sorting-menu">
      <div className="sorting-menu__method-list">
        <span className="sorting-menu__title form__group-title">Sort by</span>
        {sortingOptions.map(({ name, value }) => (
          <Button
            className={classNames([
              'sorting-menu__method-item',
              sortingBy === value && 'sorting-menu__method-item--active',
            ])}
            value={value}
            key={value}
            onClick={(e) => dispatch(setProgramsSortingBy(value))}
          >
            {name}
          </Button>
        ))}
      </div>
      <div className="sorting-menu__order-list">
        <Button
          className={classNames([
            'sorting-menu__order-item',
            sortingOrder === 'asc' && 'sorting-menu__order-item--active',
          ])}
          value={'asc'}
          onClick={() => dispatch(setProgramsSortingOrder('asc'))}
          iconLeft={<Icon type={'arrow-up'} />}
        >
          Ascending
        </Button>
        <Button
          className={classNames([
            'sorting-menu__order-item',
            sortingOrder === 'desc' && 'sorting-menu__order-item--active',
          ])}
          value={'desc'}
          onClick={() => dispatch(setProgramsSortingOrder('desc'))}
          iconLeft={<Icon type={'arrow-down'} />}
        >
          Descending
        </Button>
      </div>
    </div>
  );
};