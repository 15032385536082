import React from "react";

export const ContactFoundersCard = ({ name, position}) => {
  return (
    <div className="founder-card">
      <h3 className="founder-card__title">
        {name}
      </h3>

      <p className="founder__content">
        <span>{position}</span>
      </p>
    </div>
  );
};