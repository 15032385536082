import axios from 'axios';
import {interceptors} from './interceptors';
import { apiUrl } from '@src/constants';

const providerName =
  window.location.host.split('.')[2] === 'worldeducationweb' &&
  window.location.host.split('.')[0];

const reqParams = {
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'x-content-provider': providerName || 'global',
    // 'x-content-provider': 'global'
  },
};

const request = axios.create(reqParams);

interceptors(request);

export { request };
