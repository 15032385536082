import { Link } from '@components/shared';

export const RecaptchaMessage = () => {
  return (
    <div className="grecaptcha-message">
      This site is protected by reCAPTCHA and the Google{' '}
      <Link href="https://policies.google.com/privacy" target="_blank">
        Privacy Policy
      </Link>{' '}
      and{' '}
      <Link href="https://policies.google.com/terms" target="_blank">
        Terms of Service
      </Link>{' '}
      apply.
    </div>
  );
};
