import React, { useEffect, useState } from 'react';
import 'react-day-picker/lib/style.css';
import DayPicker from 'react-day-picker';
import { fullWeekDays, shortWeekDays } from '@src/constants';
import { DayPickerNavBar, Loader } from '@components/shared';
import { getAllowedMonths, getDisabledDays } from '@src/utils';
import { useViewport } from '@src/hooks';

export const EventsCalendar = ({
  slots,
  onDayClick,
  selectedDays,
  isLoading,
}) => {
  const allowedMonths = getAllowedMonths(slots);
  const disabledDays = getDisabledDays(slots, allowedMonths) || [];
  const initialMonth = allowedMonths[0] && new Date(allowedMonths[0]);
  const [month, setMonth] = useState();
  const { isMobile } = useViewport();

  useEffect(() => {
    if (slots.length) {
      setMonth(initialMonth);
    }
  }, [slots]);

  return (
    <>
      {!slots.length || isLoading ? (
        <Loader attached />
      ) : (
        <DayPicker
          className="events-calendar"
          month={month}
          weekdaysShort={isMobile ? shortWeekDays : fullWeekDays}
          disabledDays={disabledDays}
          captionElement={
            <DayPickerNavBar
              setMonth={setMonth}
              allowedMonths={allowedMonths}
            />
          }
          selectedDays={selectedDays}
          enableOutsideDaysClick={false}
          onDayClick={onDayClick}
        />
      )}
    </>
  );
};

EventsCalendar.defaultProps = {
  slots: [],
};
