import React from 'react';
import { Icon } from '@components/shared';

export const DailyRootCell = () => (
  <>
    <span className="schedule-table__header-icon">
      <Icon type="clock" />
    </span>
    <span className="schedule-table__header-title schedule-table__header-title--root">
      Time
    </span>
  </>
);