import React, { useEffect, useState } from 'react';
import { classNames } from '@src/utils';
import { Button, Icon } from '@components/shared';
import { useViewport } from '@src/hooks';

export const ScrollToTopButton = ({ isModalOpen }) => {
  const { isMobile } = useViewport();
  const [isShown, setIsShown] = useState(false);
  const [container, setContainer] = useState(window);

  const handleScroll = () => {
    const offset = isModalOpen ? container.scrollTop : container.pageYOffset;
    offset > 500 ? setIsShown(true) : setIsShown(false);
  };

  useEffect(() => {
    const modal = document.querySelector('.modal__window');

    isModalOpen ? setContainer(modal) : setContainer(window);
  }, [isModalOpen]);

  useEffect(() => {
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () =>
      container && container.removeEventListener('scroll', handleScroll);
  }, [container]);

  const scrollToTop = () => {
    const body = document.body;
    const modal = document.querySelector('.modal__window');
    modal ? modal.scroll({behavior: "smooth", top: 0}) : body.scrollIntoView({block: "start", behavior: "smooth"});
  };

  return (
    <Button
      onClick={scrollToTop}
      type="navigation"
      className={classNames([
        'scroll-top-btn',
        isShown && isMobile && 'scroll-top-btn--shown',
      ])}
    >
      <Icon size="lg" type="arrow-top" />
    </Button>
  );
};
