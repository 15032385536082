import React, { useEffect } from 'react';
import { NavigationPanel } from '../Navigation';

import { useDispatch, useSelector } from 'react-redux';
import { getCategoriesTable } from '@actions/scheduleActions';
import { setWeekDateQuery, WeekPagination } from '../weekView';
import { ScheduleError } from '../ScheduleError';
import { WeekViewTable } from '../weekView/WeekViewTable';
import { Loader, NothingFound, TableCell } from '@components/shared';

export const Categories = () => {
  const categoriesTable = useSelector(state => state.schedule.categoriesTable);
  const filtersSelect = useSelector(state => state.schedule.filtersSelect);
  const weekDate = useSelector(state => state.schedule.weekDate);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategoriesTable(weekDate, filtersSelect));
    setWeekDateQuery(weekDate);
  }, [filtersSelect, weekDate]);

  const categoryTitleRowRenderer = ({ name }, color) => (
    <TableCell style={{ backgroundColor: color }}>
      <span className="schedule-table__subheader-title">{name}</span>
    </TableCell>
  );

  const isMainCategoriesTableData = categoriesTable.data?.categories?.length;

  return (
    <>
      <NavigationPanel pagination={<WeekPagination />} />
      <Loader show={categoriesTable.loading} global />
      {categoriesTable.error ? (
        <ScheduleError />
      ) : (
        categoriesTable.data && (
          isMainCategoriesTableData ? (
            <WeekViewTable
              data={categoriesTable.data}
              filter="categories"
              titleRowRenderer={categoryTitleRowRenderer}
            />
            ) : (
              <NothingFound/>
          )
        )
      )}
    </>
  );
};