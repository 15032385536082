import React from "react";
import { Button, Icon } from '@components/shared';
import { useLocation } from 'react-router-dom';
import {
  openPdf,
  downloadPdf,
  apiRequest,
  requestCallback,
} from '@src/utils';
import { useViewport } from '@src/hooks';

export const DirectionsModal = ({ id, special_notes_and_directions }) => {
  const location = useLocation();
  const { isMobile } = useViewport();
  const initTab = `${location.pathname.split('/')[2]}`;
  const path = `${initTab === 'schedule' ? 'appointments' : 'tickets'}`;

  const getSpecialDirectionsPdf = (id, callback) => {
    apiRequest({
      url: `/account/${path}/${id}/special-notes-and-directions-pdf`,
      callbacks: requestCallback(({ data }) => {
        callback(data);
      }),
    });
  };

  const onPrintDirectionsClick = () => {
    getSpecialDirectionsPdf(id, openPdf);
  };

  const onSaveDirectionsClick = () => {
    getSpecialDirectionsPdf(id, downloadPdf);
  };

  return(
    <>
      <div
        className="account-modal--directions"
        dangerouslySetInnerHTML={{
          __html: special_notes_and_directions,
        }}
      />
      <div className="account-modal__actions">
        {!isMobile && (
          <Button
            type="primary"
            onClick={onPrintDirectionsClick}
            className="account-modal__print-button"
            iconRight={<Icon size="md" type="printer" />}
          >
            Print
          </Button>
        )}
        <Button
          type="primary"
          onClick={onSaveDirectionsClick}
          className="account-modal__print-button account-modal__print-button--save"
          iconRight={<Icon size="md" type="save" />}
        >
          Save
        </Button>
      </div>
    </>
  )
}