import React from 'react';
import { Container } from '@components/layout';

export const OfferingDetailsTimetable = ({ data }) => {
  const content = data.custom_tabs.timetable.content
  return (
    <div className="offering-details__tab-content offering-details__timetable">
      <div className="offering-timetable">
        <Container>
          <div
            className="text-editor-block offering-timetable__body"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Container>
      </div>
    </div>
  );
};
