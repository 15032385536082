import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '@src/utils';

export const Radio = ({
  name,
  label,
  value,
  alert,
  onChange,
  className,
  disabled,
  tooltip,
}) => {
  return (
    <div
      className={classNames([
        'radio',
        className,
        disabled && 'radio--disabled',
      ])}
    >
      <div className="radio__container">
        <input
          id={name + label}
          name={name}
          type="radio"
          value={value}
          checked={value}
          onChange={onChange}
          className="radio__input"
          disabled={disabled}
        />
        <label htmlFor={name + label} className="radio__label">
          {label}
        </label>
        {tooltip}
      </div>
    </div>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  value: PropTypes.bool,
  alert: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

Radio.defaultProps = {
  name: 'input-name',
  label: '',
  value: false,
  alert: '',
  onChange: () => {},
  className: '',
};
