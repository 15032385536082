import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '@src/utils';
import { Link as RouterLink } from 'react-router-dom';

export const Link = ({
  children,
  htmlType = 'text/html',
  type,
  className,
  href,
  target,
  onClick,
  disabled,
  iconLeft,
  iconRight,
  isIcon,
  isRouterLink,
  to,
  title,
  ...attrs
}) => {
  const types = {
    button: 'link--button',
    'button-primary': 'link--button-primary',
    'button-secondary': 'link--button-secondary',
    'button-transparent': 'link--button-transparent',
  };

  return (
    <>
      {!isRouterLink ? (
        <a
          className={classNames([
            'link',
            types[type],
            className,
            disabled && 'link--disabled',
          ])}
          target={target}
          type={htmlType}
          href={href}
          onClick={!disabled ? onClick : () => {}}
          disabled={disabled}
          title={title}
          {...attrs}
        >
          {iconLeft && (
            <div className="link__icon link__icon--left">{iconLeft}</div>
          )}
          <span className="link__content">{children}</span>

          {iconRight && (
            <div className="link__icon link__icon--right">{iconRight}</div>
          )}
        </a>
      ) : (
        <RouterLink
          className={classNames([
            'link',
            types[type],
            className,
            disabled && 'link--disabled',
          ])}
          to={to}
        >
          {iconLeft && (
            <div className="link__icon link__icon--left">{iconLeft}</div>
          )}
          <span className="link__content">{children}</span>
          {iconRight && (
            <div className="link__icon link__icon--right">{iconRight}</div>
          )}
        </RouterLink>
      )}
    </>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  href: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

Link.defaultProps = {
  children: 'link',
  onClick: () => {},
  className: '',
  href: '#',
  target: '_self',
  disabled: false,
};
