import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '@src/utils';
import { Icon } from '@components/shared';

export const ErrorMessage = ({ show, className, text }) => {
  return show ? (
    <div className={classNames(['error__wrapper', className])}>
      <Icon type="warning" />
      <span className="error__text">{text}</span>
    </div>
  ) : null;
};

ErrorMessage.propTypes = {
  show: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
};

ErrorMessage.defaultProps = {
  show: false,
  className: '',
  text: 'Error',
};
