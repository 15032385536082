import React, { useEffect, useState, useContext } from 'react';
import { parseNoSpaces, parseOnlyLettersAndNumbers, useForm } from '@src/hooks';
import {
  Button,
  Input,
  Icon,
  closeModal,
  getModal,
  openModal,
  Tooltip,
  Loader,
  RecaptchaMessage,
} from '@components/shared';
import { queryParam, apiRequest, requestCallback, openLink } from '@src/utils';
import { RecaptchaContext } from '@src/RecaptchaContext.js';

export const PasswordRecovery = ({ onBackBtnClick, proceedLink }) => {
  const initRecoveryStep = +queryParam.get('passRecoveryStep');
  const [recoveryStep, setRecoveryStep] = useState(initRecoveryStep || 1);
  const [resendTimer, setResendTimer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const recaptcha = useContext(RecaptchaContext);

  useEffect(() => {
    queryParam.set('passRecoveryStep', recoveryStep);

    return () =>
      queryParam.delete(['passRecoveryStep', 'email', 'token', 'redirect_to']);
  }, [recoveryStep]);

  useEffect(() => {
    let timer = null;

    if (resendTimer) {
      timer = setTimeout(() => {
        setResendTimer((value) => value - 1);
      }, 1000);
    }

    if (resendTimer === 0) {
      setResendTimer(null);
    }
    return () => clearTimeout(timer);
  }, [resendTimer]);

  const onEmailSubmit = () => {
    recaptcha.executeAsync().then(async (token) => {
      setIsLoading(true);
      let data = { email: values.email, g_recaptcha_response: token };

      if (proceedLink) {
        data.redirect_to = proceedLink;
      }

      await apiRequest({
        url: '/auth/password/email',
        method: 'POST',
        data: JSON.stringify(data),
        callbacks: requestCallback(
          () => setRecoveryStep(2),
          (err) => {
            if (err?.response?.data?.errors?.email) {
              const error = err.response.data.errors.email[0];
              setErrors({ email: error });
            }
          }
        ),
      });
      setResendTimer(60);
      setIsLoading(false);
      recaptcha.reset();
    });
  };

  const onGotItClick = () => {
    const proceedLink = encodeURI(queryParam.get('redirect_to'));

    proceedLink ? openLink(proceedLink) : closeModal('registration');
  };

  const onNewPasswordSubmit = async () => {
    recaptcha.executeAsync().then(async (token) => {
      setIsLoading(true);
      const data = {
        email: encodeURI(queryParam.get('email')),
        password: values.password,
        password_confirmation: values.passwordConfirm,
        token: encodeURI(queryParam.get('token')),
        g_recaptcha_response: token,
      };
      await apiRequest({
        url: '/auth/password/reset',
        method: 'POST',
        data: JSON.stringify(data),
        callbacks: requestCallback(
          () => setRecoveryStep(4),
          (err) => err && handleServerErrors(err.response)
        ),
      });
      setIsLoading(false);
      recaptcha.reset();
    });
  };

  const handleServerErrors = (err) => {
    const { data, status } = err;
    const linkExpired =
      status === 422 &&
      data?.errors?.token[0] === 'The token field is incorrect';

    if (linkExpired) {
      const notificationData = {
        linkType: 'password',
        email: encodeURI(queryParam.get('email')),
        redirect_to: encodeURI(queryParam.get('redirect_to') || ''),
      };
      closeModal('registration');
      openModal(
        getModal({
          name: 'expired-link-notification',
          data: notificationData,
        })
      );
    }
  };

  let schema = {
    email: {
      parse: parseNoSpaces,
      validation: ['email', 'isRequired'],
    },
  };

  if (recoveryStep === 3) {
    schema = {
      password: {
        validation: ['isRequired', 'password'],
        parse: parseOnlyLettersAndNumbers,
      },
      passwordConfirm: {
        parse: parseOnlyLettersAndNumbers,
        customValidation: [
          {
            check: (value) => value,
            message: 'Please repeat your password',
          },
          {
            check: (value, values) =>
              values.password ? value === values.password : true,
            message: 'Passwords do not match. Please repeat.',
          },
        ],
      },
    };
  }

  const submitHandler = (values) => {
    switch (recoveryStep) {
      case 1:
        return onEmailSubmit();
      case 2:
        return onEmailSubmit();
      case 3:
        return onNewPasswordSubmit();
      default:
        return () => {};
    }
  };

  const { values, errors, handleChange, handleSubmit, setErrors, handleBlur } =
    useForm({
      callback: submitHandler,
      schema,
    });

  const stepsNavigation = {
    1: (
      <>
        <div className="password-recovery__body">
          <div className="password-recovery__text">
            Please enter the email address you used upon sign up.
          </div>
          <Input
            label="Email"
            className="password-recovery__input"
            placeholder="Enter your Email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            name="email"
            alert={errors.email}
            required
          />
        </div>
        <div className="password-recovery__actions">
          <Button
            type="transparent"
            onClick={() => onBackBtnClick()}
            htmlType="button"
            iconLeft={<Icon type="chevron" />}
          >
            Login
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
        <RecaptchaMessage />
      </>
    ),
    2: (
      <>
        <div className="password-recovery__body">
          <div className="password-recovery__text">
            The Password Recovery Email has been sent.
          </div>
          <div className="password-recovery__text">
            Please check your inbox (or spam folder) for further instructions
            from{' '}
            <span className="password-recovery__email">
              <a href="mailto:staff@cccaonline.org">staff@cccaonline.org</a>
            </span>
          </div>
        </div>
        <div className="password-recovery__actions">
          <Button
            type="transparent"
            onClick={() => onBackBtnClick()}
            htmlType="button"
            iconLeft={<Icon type="chevron" />}
            className="password-recovery__login-button"
          >
            Login
          </Button>
        </div>
        <div className="password-recovery__resend-email">
          <div className="password-recovery__text">
            Haven't received an email?
          </div>
          <Button type="primary" htmlType="submit" disabled={!!resendTimer}>
            Resend {resendTimer && <>{resendTimer}s</>}
          </Button>
        </div>
        <RecaptchaMessage />
      </>
    ),

    3: (
      <>
        <div className="password-recovery__body">
          <Input
            label="New Password"
            className="password-recovery__input"
            placeholder="Enter your new password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            name="password"
            alert={errors.password}
            required
            password
            hint="Password should include at least 6 characters, both uppercase and lowercase letters and at least one number"
          />
          <Input
            label="Repeat Password"
            className="password-recovery__input"
            placeholder="Repeat your new password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.passwordConfirm}
            name="passwordConfirm"
            alert={errors.passwordConfirm}
            required
            password
          />
        </div>
        <div className="password-recovery__actions">
          <Button
            type="primary"
            htmlType="submit"
            className="password-recovery__login-button"
          >
            Submit
          </Button>
        </div>
        <RecaptchaMessage />
      </>
    ),

    4: (
      <>
        <div className="password-recovery__body">
          <div className="password-recovery__text">
            Congratulations!
            <br />
            Your new password has been set.
          </div>
          <div className="password-recovery__actions">
            <Button
              type="primary"
              onClick={() => onGotItClick()}
              className="password-recovery__login-button"
            >
              Got It
            </Button>
          </div>
        </div>
      </>
    ),
  };

  return (
    <div className="password-recovery">
      <Loader show={isLoading} sticky />
      <form
        className={`password-recovery__form password-recovery--step${recoveryStep}`}
        onSubmit={handleSubmit}
      >
        <div className="password-recovery__banner">
          <Icon type="password-recovery" />
          <h1 className="password-recovery__title">
            Recover / Create
            <br />
            Password
          </h1>
        </div>
        {recoveryStep < 4 && (
          <div className="password-recovery__help">
            <span className="password-recovery__help-text">Need Registration Help?</span>
            <Tooltip position="bottom" className="password-recovery__help-tooltip">
              <span>
                For Log In, Sign Up, or Registration assistance, please email
              </span>
              <a href="mailto:dan.yonah.marshalll@gmail.com">
                dan.yonah.marshall@gmail.com
              </a>
              <span>or call (617) 642-1444.</span>
            </Tooltip>
          </div>
        )}
        {stepsNavigation[recoveryStep]}
      </form>
    </div>
  );
};

PasswordRecovery.defaultProps = {
  initialStep: 1,
};
