import { useEffect, useState, useRef } from 'react';
import { ViewportProvider } from '@src/hooks';
import { ModalInit, ScrollToTopButton } from '@components/shared';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from '@src/store';
import { addScriptAfterCookie } from '@src/utils';
import { GlobalQueryChecker, MainContent, Cookie } from '@components/basic';
import { RecaptchaContext } from './RecaptchaContext.js';
import ReCAPTCHA from 'react-google-recaptcha';
import './styles/index.scss';

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isCookie = document.cookie.includes('BCCACookie=true');
  const recaptchaRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', () => {
      document.body.classList.add('mouse-used');
    });

    document.addEventListener('keydown', (e) => {
      if (e.key === 'Tab') {
        document.body.classList.remove('mouse-used');
      }
    });

    document.addEventListener('touchstart', () => {
      document.body.classList.remove('mouse-used');
      document.body.classList.add('touch-used');
    });

    if (isCookie) {
      addScriptAfterCookie();
    }
  }, []);

  return (
    <Provider store={store}>
      <RecaptchaContext.Provider value={recaptchaRef.current}>
        <ViewportProvider>
          <Router>
            <MainContent />
            <Cookie />
            <ScrollToTopButton isModalOpen={isModalOpen} />
            <ModalInit setIsModalOpen={setIsModalOpen} />
            <GlobalQueryChecker />
          </Router>
        </ViewportProvider>
      </RecaptchaContext.Provider>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LfZJ8caAAAAABBaCwZxn2ErqB50RrNN7A_eXO9m"
      />
    </Provider>
  );
};

export default App;
