import React from 'react';
import { Button, Input, Icon } from '@components/shared';

export const TicketAttendeeInput = ({
  attendees,
  attendeeId,
  setAttendees,
  changeQuantity,
  quantity,
}) => {

  const onNameChange = (e) => {
    e.persist();
    setAttendees((currAttendees) => {
      return currAttendees.map((item) =>
        attendeeId === item.id ? { ...item, value: e.target.value } : item
      );
    });
  };

  const onDeleteAttendeeClick = () => {
    setAttendees((currAttendees) => {
      currAttendees.splice(attendeeId - 1, 1);
      return currAttendees;
    });
    changeQuantity(quantity - 1);
  };

  return (
    <div className="buying-tickets-card__input">
      <Input
        value={attendees.find(({ id }) => id === attendeeId)?.value}
        onChange={onNameChange}
        placeholder="Attendee First &amp; Last Name"
      />
      <Button onClick={onDeleteAttendeeClick}>
        <Icon type="del-basket" />
      </Button>
    </div>
  );
};
