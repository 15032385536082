import React from 'react';
import { ProvidersCard } from './ProvidersCard';
import { useSelector } from 'react-redux';
import { Loader } from '@components/shared';

export const GlobalProvidersItems = () => {
  const providersList = useSelector((state) => state.provider.providersList);

  return (
    <div className="providers-items">
      <Loader global show={providersList?.loading} />
      {providersList.data?.map((provider) => {
        return (
          <div className="providers-item" key={provider.id}>
            <ProvidersCard provider={provider} />
          </div>
        );
      })}
    </div>
  );
};
