import React from "react";
import { Container } from '@components/layout';

export const OfferingDetailsAdditionalInfo = ({ data }) => {
  const { notes } = data;

  return (
    <div className="offering-details__tab-content offering-details__additional-info">
      <Container>
        <div className="additional-info">
          <div 
            className="text-editor-block additional-info__body"
            dangerouslySetInnerHTML={{ __html: notes }}
          />
        </div>
      </Container>
    </div>
  )
};