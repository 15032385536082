import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '@src/utils';

export const Button = ({
  children,
  htmlType = 'button',
  type,
  className,
  onClick,
  disabled,
  iconLeft,
  iconRight,
  isIcon,
  ...attrs
}) => {
  const types = {
    transparent: 'btn--transparent',
    primary: 'btn--primary',
    navigation: 'btn--navigation',
    secondary: 'btn--secondary',
    link: 'btn--link',
    outline: 'btn--outline',
    'primary-outline': 'btn--primary-outline',
    'secondary-outline': 'btn--secondary-outline',
  };

  return (
    <button
      type={htmlType}
      className={classNames([
        'btn',
        types[type],
        className,
        // isIcon && 'btn--icon',
        disabled && 'btn--disabled',
      ])}
      onClick={!disabled ? onClick : () => {}}
      disabled={disabled}
      {...attrs}
    >
      {iconLeft && <div className="btn__icon btn__icon--left">{iconLeft}</div>}
      <span className="btn__content">{children}</span>
      {iconRight && (
        <div className="btn__icon btn__icon--right">{iconRight}</div>
      )}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  children: 'btn',
  onClick: () => {},
  className: '',
  type: 'navigation',
  disabled: false,
};
