
export const getWeekViewTableArray = (weekData, filterKey = 'locations') => {
  if (!weekData) return [];
  const { [filterKey]: filters, schedules } = weekData;

  if (!filters) return [];

  return filters.map(filter => {
    const { id, name } = filter;
    const filterSchedules = schedules[id];
    const maxSchedulesLength = Math.max.apply(
      null,
      Object.keys(filterSchedules).map(key => filterSchedules[key].length)
    );

    const renderArr = [];

    for (let i = 0; i < maxSchedulesLength; i++) {
      const weekRowArr = [];
      for (let week = 1; week <= 7; week++) {
        if (filterSchedules[week] && filterSchedules[week][i]) {
          const extendedSchedules = filterSchedules[week][i]
          extendedSchedules.location = name;
          weekRowArr.push(extendedSchedules);
        } else {
          weekRowArr.push('');
        }
      }
      renderArr.push(weekRowArr);
    }

    return {
      filter,
      schedulesArray: renderArr,
    };
  });
};