import React, { useCallback, useState } from 'react';
import InputRange from 'react-input-range';
import { Input } from '@components/shared';
import { useDidUpdate } from '@src/hooks';
import { debounce } from '@src/utils';
import {
  minAgeRangeDefault,
  maxAgeRangeDefault,
  ageRangeYearMask,
  ageRangeMonthMask,
} from '@src/constants';

export const ProgramsFilterRangerSection = ({ title, values, setCustomValue }) => {
  const { participant_age_from, participant_age_to } = values;

  const [range, setRange] = useState({
    min: participant_age_from || minAgeRangeDefault,
    max: participant_age_to || maxAgeRangeDefault,
  });

  const getYear = (range) => Math.floor(range / 12);
  const getMonth = (range) => range % 12;

  const getDateRange = (range) => ({
    yearsFrom: getYear(range.min),
    monthsFrom: getMonth(range.min),
    yearsTo: getYear(range.max),
    monthsTo: getMonth(range.max),
  });

  const [state, setState] = useState(getDateRange(range));

  const onDateInputChange = (e) => {
    const { value, name } = e.target;

    const parsedValue = parseInt(value, 10);

    const oldState = state;

    if (name === 'yearsFrom') {
      const yearsFrom = parsedValue > 120 ? 120 : parsedValue;
      const checkedYearsFrom = oldState.yearsTo < yearsFrom ? 0 : yearsFrom;

      oldState.yearsFrom = checkedYearsFrom;

      if (checkedYearsFrom === 120) {
        oldState.monthsFrom = 0;
      }

      if (oldState.yearsTo === checkedYearsFrom) {
        oldState.monthsFrom = 0;
        oldState.monthsTo = 0;
      }
    }

    if (name === 'yearsTo') {
      const yearsTo = parsedValue > 120 ? 120 : parsedValue;
      const checkedYearsTo = yearsTo < oldState.yearsFrom ? 120 : yearsTo;

      oldState.yearsTo = checkedYearsTo;

      if (checkedYearsTo === 120) {
        oldState.monthsTo = 0;
      }

      if (checkedYearsTo === oldState.yearsFrom) {
        oldState.monthsFrom = 0;
        oldState.monthsTo = 0;
      }
    }

    if (name === 'monthsFrom') {
      const monthsFrom = parsedValue > 11 ? 11 : parsedValue;

      oldState.monthsFrom = monthsFrom;
    }

    if (name === 'monthsTo') {
      const monthsTo = parsedValue > 11 ? 11 : parsedValue;
      const checkedMonthsTo = oldState.yearsTo !== 120 ? monthsTo : 0;

      oldState.monthsTo = checkedMonthsTo;
    }

    setState(oldState);

    const { yearsFrom, yearsTo, monthsFrom, monthsTo } = oldState;

    setRange({
      min: yearsFrom * 12 + monthsFrom,
      max: yearsTo * 12 + monthsTo,
    });
  };

  const setCustomValues = useCallback(
    debounce((range) => {
      setCustomValue('participant_age_from', range.min);
      setCustomValue('participant_age_to', range.max);
    }, 500),
    []
  );

  useDidUpdate(() => {
    setState(getDateRange(range));
    setCustomValues(range);
  }, [range]);

  useDidUpdate(() => {
    const newRange = {
      min: parseFloat(participant_age_from),
      max: parseFloat(participant_age_to),
    };
    setRange(newRange);
  }, [participant_age_from, participant_age_to]);

  return (
    <div className="form__section">
      <div className="form__group-title">{title}</div>
      <div className="form__group programs__filter-age-range-container">
        <InputRange
          maxValue={maxAgeRangeDefault}
          minValue={minAgeRangeDefault}
          step={1}
          value={range}
          onChange={setRange}
          draggableTrack={false}
          formatLabel={() => ''}
        />
      </div>

      <div className="programs__filter-age-block">
        <div className="programs__filter-age-inputs">
          <Input
            name="yearsFrom"
            value={`${state.yearsFrom}y`}
            mask={ageRangeYearMask}
            maskGuide={false}
            onBlur={onDateInputChange}
          />
          <Input
            name="monthsFrom"
            value={`${state.monthsFrom}m`}
            mask={ageRangeMonthMask}
            maskGuide={false}
            onBlur={onDateInputChange}
          />
        </div>
        <div className="programs__filter-age-inputs">
          <Input
            name="yearsTo"
            value={`${state.yearsTo}y`}
            mask={ageRangeYearMask}
            maskGuide={false}
            onBlur={onDateInputChange}
          />
          <Input
            name="monthsTo"
            value={`${state.monthsTo}m`}
            mask={ageRangeMonthMask}
            maskGuide={false}
            onBlur={onDateInputChange}
          />
        </div>
      </div>
    </div>
  );
};