export const createTab = (label, value, isActive = false) => ({
  label,
  value,
  active: isActive
});

export const createCustomTab = (label, value, renderer, isActive = false) => ({
  ...createTab(label, value, isActive),
  renderer
});
