import * as types from '@types/cookieTypes';

const initialState = {
  isCookie: false,
};

export const cookieReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case types.IS_COOKIE: {
      return {
        ...state,
        isCookie: !initialState.isCookie,
      };
    }

    default: {
      return state;
    }
  }
};