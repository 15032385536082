import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Checkbox,
  ErrorMessage,
  Icon,
  RegistrationPriceBlock,
  RegistrationDonationBlock,
  Loader,
  Link,
} from '@components/shared';
import VideoCard from '@components/features/Videos/VideoCard';
import { useForm, parseOnlyAmount, useApi } from '@src/hooks';
import { classNames, formatPrice, debounce } from '@src/utils';
import { getVideosOrderData } from '@actions/registrationActions';
import { globalWidgetLink } from '@src/constants';

export const BuyingVideo = ({ btnCallbacks, courseInfo }) => {
  const orderData = useSelector((state) => state.registration.orderData);
  const videosPrice = orderData.data?.totals?.total;
  const dispatch = useDispatch();
  const rates = courseInfo.video_prices;
  const [expandedVideo, setExpandedVideo] = useState(null);
  const [purchasedVideos, setPurchasedVideosIds] = useState([]);
  const [unPurchasedVideos, setUnPurchasedVideos] = useState([]);

  const purchasedVideosIds = useApi({
    url: `/courses/${courseInfo.id}/purchased-videos`,
  });

  const paidVideos = courseInfo.videos?.paid;

  useEffect(() => {
    if (paidVideos && purchasedVideosIds.data) {
      const purchasedArr = [];
      const unPurchasedArr = [];
      paidVideos.forEach((video) => {
        purchasedVideosIds.data.includes(video.id)
          ? purchasedArr.push(video)
          : unPurchasedArr.push(video);
      });
      setPurchasedVideosIds(purchasedArr);
      setUnPurchasedVideos(unPurchasedArr);
    }
  }, [purchasedVideosIds, paidVideos]);

  const submitHandler = (values) => {
    const requestData = {
      price_id: activeScale.id,
      videos: selectedVideos,
    };

    if (values.hasDonation) {
      requestData.donation = {
        is_anonymous: values.isAnonymous,
        amount: values.donationAmount,
        hasDonation: values.hasDonation,
      };
    }
    dispatch(getVideosOrderData(requestData));
    return btnCallbacks.submit(requestData);
  };

  const schema = {
    donationAmount: {
      parse: parseOnlyAmount,
      validation: ['isRequired', 'donation'],
      valuesRequired: ['hasDonation'],
    },
    activeScale: {
      validation: ['isRequired'],
    },
    selectedVideos: {
      validation: ['isLengthRequired'],
    },
  };

  const initialValues = {
    hasDonation: false,
    donationAmount: null,
    isAnonymous: false,
    activeScale: rates.length === 1 ? rates[0] : null,
    selectedVideos: [],
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setCustomValue,
    handleSubmit,
  } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });

  const { activeScale, selectedVideos } = values;

  useEffect(() => {
    if (activeScale && selectedVideos.length) {
      const requestData = {
        price_id: activeScale.id,
        videos: selectedVideos,
      };
      getOrderData(requestData);
    }
  }, [activeScale, selectedVideos]);

  const getOrderData = useCallback(
    debounce((data) => {
      dispatch(getVideosOrderData(data));
    }, 500),
    []
  );

  const onVideoCheck = (e, id) => {
    const isChecked = e.target.checked;
    isChecked
      ? setCustomValue('selectedVideos', [...selectedVideos, id])
      : setCustomValue(
          'selectedVideos',
          selectedVideos.filter((video) => video !== id)
        );
  };

  const renderScales = (scales) =>
    scales.map((scale) => {
      const { price, id } = scale;
      return (
        <div className="columns__item" key={id}>
          <button
            onClick={() => setCustomValue('activeScale', scale)}
            type="button"
            className={classNames([
              'switcher',
              activeScale && activeScale.id === id && 'switcher--active',
            ])}
          >
            <span className="switcher__content">
              {formatPrice(price)}$ per video
            </span>
          </button>
        </div>
      );
    });

  return (
    <form className="buying-video" onSubmit={handleSubmit}>
      <Loader show={purchasedVideosIds.loading} attached />
      {!!unPurchasedVideos.length && (
        <>
          {rates.length > 1 && (
            <div className="buying-video__rates">
              <div className="registration-step__group">
                <h4 className="registration-step__title">
                  Please choose one of these scaled rates based on your
                  financial resources
                </h4>

                <p className="registration-step__subtitle">
                  <Icon type="rate" size="lg" />
                  Contributions help keep artists working, and make our
                  non-profit community programming possible
                </p>
              </div>

              <div className="registration-step__group registration-step__group--blocks-select">
                <div className="columns columns--4">{renderScales(rates)}</div>
                <ErrorMessage
                  show={!!errors.activeScale}
                  text={'Select a Scaled Rate to proceed'}
                />
              </div>
            </div>
          )}

          <div className="buying-video__cards">
            <h4 className="buying-video__title">
              Please choose one or more videos
            </h4>
            {unPurchasedVideos.map((video) => {
              return (
                <div className="buying-video__card" key={video.id}>
                  <Checkbox
                    value={selectedVideos.includes(video.id)}
                    onChange={(e) => onVideoCheck(e, video.id)}
                    name={video.title}
                  />
                  <VideoCard
                    video={video}
                    isExpanded={expandedVideo === video.id}
                    setExpandedVideo={setExpandedVideo}
                    isVideoGalleryCard
                  />
                </div>
              );
            })}
            <ErrorMessage
              show={!!errors.selectedVideos}
              text={'Select a Video to proceed'}
            />
          </div>
        </>
      )}
      {!!purchasedVideos.length && (
        <div className="buying-video__cards">
          <h4 className="buying-video__title">
            <span>
              {' '}
              You've already paid for this Video Content, proceed to your{' '}
              <Link href={`${globalWidgetLink}/account/videos`}>
                Account
              </Link>{' '}
              to watch
            </span>
          </h4>
          {purchasedVideos.map((video) => {
            return (
              <div className="buying-video__card" key={video.id}>
                <Checkbox
                  value={selectedVideos.includes(video.id)}
                  onChange={(e) => onVideoCheck(e, video.id)}
                  name={video.title}
                  disabled
                />
                <VideoCard
                  video={video}
                  isExpanded={expandedVideo === video.id}
                  setExpandedVideo={setExpandedVideo}
                  isVideoGalleryCard
                />
              </div>
            );
          })}
        </div>
      )}

      {!!unPurchasedVideos.length && (
        <div className="registration-step__group registration-step__group--price">
          <RegistrationDonationBlock
            values={values}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <div className="registration-step__payment-group">
            <RegistrationPriceBlock
              price={selectedVideos.length && activeScale && videosPrice}
              rate={activeScale?.price}
              contentType={{ singular_short_name: 'video' }}
              loading={orderData.loading}
              values={values}
            />
          </div>
        </div>
      )}

      <div className="btn__wrapper">
        <Button
          htmlType="button"
          onClick={btnCallbacks.back}
          type="transparent"
          iconLeft={<Icon type="chevron" />}
        >
          Back
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          disabled={!unPurchasedVideos.length}
        >
          Proceed
        </Button>
      </div>
    </form>
  );
};
