import React from "react";
import { format, getDay } from 'date-fns';
import {
  fullWeekDays,
  dateSessionsFormat,
  twelveHoursFormat,
} from '@src/constants';

export const getFormattedUpcommingDate = (date) => {
  const formattedDate = new Date(date);
  const upcommingDate = format(formattedDate, dateSessionsFormat);
  const upcommingWeekday = fullWeekDays[getDay(formattedDate)];
  const upcommingTime = format(formattedDate, twelveHoursFormat);

  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      {upcommingDate}, <b>{upcommingWeekday}</b>{' '}
      <span style={{ textTransform: 'lowercase' }}>{upcommingTime}</span> EST
    </span>
  );
};