import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFacultyInstructors } from '@actions/facultyActions';
import { useLazyLoaded } from '@src/hooks';
import { Container } from '@components/layout';
import { FacultyHeader, FacultyBody } from '@components/features/Faculty';
import { SEO } from '@components/shared';

const Faculty = () => {
  const filtersSelect = useSelector((state) => state.faculty.filtersSelect);
  const search = useSelector((state) => state.faculty.search);
  const privderData = useSelector((state) => state.provider.providerData.data);
  const instructors = useSelector((state) => state.faculty.instructors);
  const { page } = instructors;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFacultyInstructors(page, filtersSelect, search));
  }, [search, filtersSelect]);

  const loadMore = () => {
    dispatch(getFacultyInstructors(page, filtersSelect, search, true));
  };

  const facultyBody = useRef(null);

  useLazyLoaded(facultyBody.current, instructors, loadMore);

  return (
    <Container>
      {privderData.short_name && (
        <SEO
          title={`${privderData.short_name} | Faculty`}
          description={`Meet our talented faculty members of ${privderData.name} and their passion for teaching art, dance, and theater. Browse faculty with convenient search and filters.`}
          canonicalURL={`${privderData.base_url}/faculty`}
          image={privderData.logo}
        />
      )}
      <div className="global-page faculty">
        <FacultyHeader />
        <div ref={facultyBody}>
          <FacultyBody />
        </div>
      </div>
    </Container>
  );
};

export default Faculty;