import React from 'react';
import { insertClassName } from '@src/utils';

export const Table = ({ className, children, tableRef, type }) => {
  const types = {
    default: '',
    curved: 'table--curved',
    primary: 'table--primary',
  };

  return (
    <div
      ref={tableRef}
      className={`table${insertClassName(
        className,
        className
      )}${insertClassName(types[type], types[type])}`}
    >
      <div className={`table__container`}>
        <div className="table__content">{children}</div>
      </div>
    </div>
  );
};

Table.defaultProps = {
  type: 'default',
};