import React, { useEffect } from 'react';
import { Button, Checkbox } from '@components/shared';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCoursesFilters,
  setProgramsFiltersSelect,
} from '@actions/programsActions';
import { useForm, useDeepEffect, useViewport } from '@src/hooks';
import {
  instructorsToSelectData,
  setFiltersQuery,
  queryParam,
} from '@src/utils';
import {
  ProgramsFilterCheckSection,
  ProgramsFilterRangerSection,
  ProgramsFilterSelectSection,
} from './sections';

export const ProgramsFilter = () => {
  const filters = useSelector((state) => state.programs.filters);
  const filtersSelect = useSelector((state) => state.programs.filtersSelect);
  const { isTablet } = useViewport();

  const dispatch = useDispatch();

  const {
    instructors,
    locations,
    participant_age_types,
    participant_levels,
    programs,
    categories,
    content_types,
  } = filters.data;

  useEffect(() => {
    dispatch(getCoursesFilters());
  }, []);

  useDeepEffect(() => {
    setFiltersQuery(filtersSelect);
  }, [filtersSelect]);

  const { handleSubmit, setCustomValue, values, setValues } = useForm({
    callback: (values) => {
      dispatch(setProgramsFiltersSelect(values));
    },
    schema: {},
    initialValues: filtersSelect,
  });

  useDeepEffect(() => {
    handleSubmit();
  }, [values]);

  useEffect(() => {
    if (values.program && programs) {
      const selectedId = values.program;
      const program = programs.find(({ id }) => id === selectedId);
      const valuesToSet = {
        ...values,
        ...program.config,
        program: selectedId,
      };
      setValues(valuesToSet);
    }
  }, [values.program, programs]);

  const onFiltersClear = () => {
    const defaultValues = {
      category: [],
      content_type: [],
      faculty: null,
      is_online: false,
      is_onsite: false,
      location: 0,
      participant_age_from: 0,
      participant_age_to: 1440,
      participant_age_type: [],
      participant_level: [],
      program: null,
      is_private: null,
    };
    setValues(defaultValues);
    queryParam.delete(Object.keys(filtersSelect));
  };

  const PrivacyFiltersSection = ({ values, setCustomValue }) => {
    return (
      <div className="form__section">
        <div className="form__group-title">Offering Availability</div>
        <div className="form__group">
          <Checkbox
            name="isPublic"
            onChange={(e) => setCustomValue('isPublic', e.target.checked)}
            value={values.isPublic}
            label="Public Offerings"
          />
        </div>
        <div className="form__group">
          <Checkbox
            name="isPrivate"
            onChange={(e) => setCustomValue('isPrivate', e.target.checked)}
            value={values.isPrivate}
            label="Private Offerings"
          />
        </div>
      </div>
    );
  };

  const filterSectionsData = [
    {
      title: 'Location',
      identifier: 'location',
      data: locations,
      renderer: ProgramsFilterSelectSection,
    },
    {
      title: 'Faculty',
      identifier: 'faculty',
      data: instructorsToSelectData(instructors),
      searchable: true,
      renderer: ProgramsFilterSelectSection,
    },
    !!programs?.length && {
      title: 'Programs',
      identifier: 'program',
      data: programs,
      renderer: ProgramsFilterSelectSection,
    },
    {
      title: 'Categories',
      identifier: 'category',
      data: categories,
      renderer: ProgramsFilterCheckSection,
    },
    {
      title: 'Offering Types',
      identifier: 'content_type',
      data: content_types,
      renderer: ProgramsFilterCheckSection,
    },
    {
      identifier: 'privacy',
      renderer: PrivacyFiltersSection,
    },
    {
      title: 'Participant Age Range',
      identifier: 'participant_age_range',
      renderer: ProgramsFilterRangerSection,
    },
    !!participant_age_types?.length && {
      title: 'Participant Age Types',
      identifier: 'participant_age_type',
      data: participant_age_types,
      renderer: ProgramsFilterCheckSection,
    },
    !!participant_levels?.length && {
      title: 'Participant Levels',
      identifier: 'participant_level',
      data: participant_levels,
      renderer: ProgramsFilterCheckSection,
    },
  ].filter((filter) => filter);

  return (
    <>
      {!filters.loading && (
        <div className="programs__filter">
          <div className="programs__filter-form form">
            {!isTablet ? (
              <div className="form__section form__section--action-buttons">
                <div className="form__group">
                  <div className="programs__filter-action-buttons">
                    <Button
                      type="transparent"
                      className="programs__filter-clear-all"
                      onClick={onFiltersClear}
                    >
                      Clear Filters
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="programs__filter-action-buttons--mobile">
                <Button
                  type="navigation"
                  className="programs__filter-clear-all"
                  onClick={onFiltersClear}
                >
                  Clear Filters
                </Button>
              </div>
            )}

            <div className="form__section">
              <div className="form__group-title">Offering Formats</div>
              <div className="form__group">
                <Checkbox
                  name="is_online"
                  onChange={(e) =>
                    setCustomValue('is_online', e.target.checked)
                  }
                  value={values.is_online}
                  label="Online"
                />
              </div>
              <div className="form__group">
                <Checkbox
                  name="is_onsite"
                  onChange={(e) =>
                    setCustomValue('is_onsite', e.target.checked)
                  }
                  value={values.is_onsite}
                  label="Onsite"
                />
              </div>
              <div className="form__group">
                <Checkbox
                  name="has_videos"
                  onChange={(e) =>
                    setCustomValue('has_videos', e.target.checked)
                  }
                  value={values.has_videos}
                  label="Video"
                />
              </div>
            </div>

            {filterSectionsData.map((props) => {
              const { identifier, renderer: RenderComponent } = props;
              return (
                <RenderComponent
                  key={identifier}
                  {...props}
                  values={values}
                  setCustomValue={setCustomValue}
                />
              );
            })}

            <div className="form__section">
              <div className="form__group">
                <div className="programs__filter-action-buttons">
                  <Button
                    type="transparent"
                    className="programs__filter-clear-all"
                    onClick={onFiltersClear}
                  >
                    Clear Filters
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
