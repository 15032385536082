import React, { useEffect, useState } from 'react';
import {
  Button,
  Icon,
  SingleDateSchedulePicker,
  RegistrationPriceBlock,
  RegistrationDonationBlock,
} from '@components/shared';
import { normalizeDropinRegData } from '@src/normalizers';
import { useDispatch, useSelector } from 'react-redux';
import { parseOnlyAmount, useForm } from '@src/hooks';
import { getTrialSlots, getTrialOrderData } from '@actions/registrationActions';

export const Trial = ({ sessionInfo, btnCallbacks, contentType }) => {
  const slots = useSelector((state) => state.registration.regSlots);
  const orderData = useSelector((state) => state.registration.orderData);
  const price = orderData.data?.totals?.total
  const [scheduleErrors, setScheduleErrors] = useState([]);
  const [daysData, setDaysData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTrialSlots(sessionInfo.id));
  }, []);

  const submitHandler = (values) => {
    const trialRegData = {
      selectedSchedules,
      sessionId: sessionInfo.id,
      donation: {
        is_anonymous: values.isAnonymous,
        amount: values.donationAmount,
        hasDonation: values.hasDonation,
      },
    };
    dispatch(getTrialOrderData(trialRegData))
    return btnCallbacks.submit(normalizeDropinRegData(trialRegData));
  };

  const validateTimeslots = () => {
    const selectedDates = selectedSchedules.map((day) => day.date);
    const failedSchedules = daysData.filter(
      (day) => !selectedDates.includes(day.date)
    );
    setScheduleErrors(failedSchedules.map(({ date }) => date));

    return !failedSchedules.length;
  };

  const schema = {
    donationAmount: {
      parse: parseOnlyAmount,
      validation: ['isRequired', 'donation'],
      valuesRequired: ['hasDonation'],
    },
    selectedDays: {
      validation: ['isLengthRequired'],
    },
    selectedSchedules: {
      customValidation: [
        {
          check: () => validateTimeslots(),
          message: 'Select a Timeslot to proceed',
        },
      ],
    },
  };

  const initialValues = {
    hasDonation: false,
    donationAmount: null,
    isAnonymous: false,
    selectedDays: [],
    selectedSchedules: [],
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setCustomValue,
    setErrors,
    handleSubmit,
  } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });

  const { selectedSchedules } = values;

  useEffect(() => {
    if (scheduleErrors.length) {
      const selectedDates = selectedSchedules.map((day) => day.date);
      setScheduleErrors((errors) =>
        errors.filter((error) => !selectedDates.includes(error))
      );
    }
  }, [selectedSchedules]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="registration-step__group">
        <h4 className="registration-step__title">
          Please choose one or more trial {contentType.plural_short_name} for free viewing & participation
        </h4>
      </div>
      <SingleDateSchedulePicker
        slots={slots}
        errors={errors}
        daysData={daysData}
        setDaysData={setDaysData}
        selectedSchedules={selectedSchedules}
        contentType={contentType}
        setCustomValue={setCustomValue}
        selectedDays={values.selectedDays}
        setErrors={setErrors}
        scheduleErrors={scheduleErrors}
      />
      <div className="registration-step__group registration-step__group--price">
        <RegistrationDonationBlock
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <div className="registration-step__payment-group">
          <RegistrationPriceBlock
            price={!!selectedSchedules.length && price}
            contentType={contentType}
            loading={orderData.loading}
            values={values}
          />
        </div>
      </div>
      <div className="btn__wrapper">
        <Button
          htmlType="button"
          onClick={btnCallbacks.back}
          type="transparent"
          iconLeft={<Icon type="chevron" />}
        >
          Back
        </Button>
        <Button
          htmlType="submit"
          type="primary"
        >
          Proceed
        </Button>
      </div>
    </form>
  );
};