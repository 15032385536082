import React, { useEffect, useState } from 'react';
import { NavigationPanel } from '../Navigation';
import { useDispatch, useSelector } from 'react-redux';
import { getInstructorsTable, setLetterFilter } from '@actions/scheduleActions';
import { setWeekDateQuery, WeekPagination } from '../weekView';
import { WeekViewTable } from '../weekView/WeekViewTable';
import { ScheduleError } from '../ScheduleError';
import { setActiveTab, getAlphabetTabs } from '@src/utils';
import {
  AlphabetButtons,
  Loader,
  TableCell,
  InstructorName,
  Icon,
  NothingFound,
} from '@components/shared';

export const Instructors = () => {
  const [lettersFilterTabs, setLettersFilterTabs] = useState(getAlphabetTabs());

  const instructorsTable = useSelector(
    (state) => state.schedule.instructorsTable
  );
  const filtersSelect = useSelector((state) => state.schedule.filtersSelect);
  const weekDate = useSelector((state) => state.schedule.weekDate);
  const alphabetFilter = useSelector((state) => state.schedule.alphabetFilter);

  const dispatch = useDispatch();

  const onLetterFilterClick = ({ value }) => {
    dispatch(setLetterFilter(value === 'all' ? '' : value));
  };

  useEffect(() => {
    dispatch(getInstructorsTable(weekDate, filtersSelect, alphabetFilter));
    setWeekDateQuery(weekDate);
  }, [filtersSelect, weekDate, alphabetFilter]);

  useEffect(() => {
    setActiveTab(setLettersFilterTabs, alphabetFilter.letter || 'all');
  }, [alphabetFilter.letter]);

  const instructorTitleRowRenderer = (instructor, color) => {
    const instructorData = {
      main_instructor_1: instructor,
    };

    const { faculty } = instructor;

    return (
      <TableCell
        className="table__cell--instructor"
        style={{ backgroundColor: color }}
      >
        <div className="instructor__img">
          {faculty.user_image && (
            <img src={faculty.user_image.path} alt="instructor" />
          )}
          {!faculty.user_image && <Icon type="user-undefined" />}
        </div>
        <InstructorName
          course={instructorData}
          className="schedule-table__subheader-title"
        />
      </TableCell>
    );
  };

  const isMainInstructorsTableData = instructorsTable.data?.instructors.length;

  return (
    <div className="schedule__instructors-navigation">
      <NavigationPanel
        pagination={<WeekPagination />}
        additionalFilters={
          <AlphabetButtons
            tabs={lettersFilterTabs}
            onButtonClick={onLetterFilterClick}
          />
        }
      />
      <Loader show={instructorsTable.loading} global />
      {instructorsTable.error ? (
        <ScheduleError />
      ) : (
        instructorsTable.data &&
        (isMainInstructorsTableData ? (
          <WeekViewTable
            data={instructorsTable.data}
            filter="instructors"
            titleRowRenderer={instructorTitleRowRenderer}
          />
        ) : (
          <NothingFound />
        ))
      )}
    </div>
  );
};
