import React from "react";
import { Icon } from '@components/shared';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ContactFoundersCard } from './ContactFoundersCard';
import 'swiper/swiper.scss';

export const ContactFounders = ({ founders}) => {
  const {list, title} = founders;
  const slidesCount = list.length
  const sliderSettings = {
    className: 'contact-founders__slider',
    navigation: {
      nextEl: '.contact-founders__next-button',
      prevEl: '.contact-founders__prev-button',
    },
    slidesPerView: slidesCount > 1 ? 1 : slidesCount,
    enabled: slidesCount > 1,
    loop: true,
    centerInsufficientSlides: true,
    spaceBetween: 40,
    breakpoints: {
      576: {
        slidesPerView: slidesCount > 2 ? 2 : slidesCount,
        enabled: slidesCount > 2,
      },
      768: {
        slidesPerView: slidesCount > 3 ? 3 : slidesCount,
        enabled: slidesCount > 3,
      },
      1150: {
        slidesPerView: slidesCount > 4 ? 4 : slidesCount,
        enabled: slidesCount > 4,
      },
    }
  };

  return (
    <div className="page-block contact-founders">
      {title && <div className="contact-founders__title-block">
        <h2 className="page-block-title contact-founders__title">{title}</h2>
      </div>}
      <div className="contact-founders__body">
        <Swiper {...sliderSettings}>
          {list.map((card, index) => {
            return (
            <SwiperSlide className="contact-founders__slide" key={index}>
              <ContactFoundersCard
                name={card.title}
                position={card.description}
              />
            </SwiperSlide>
            );
          })}
        </Swiper>

        <div className="global-page__buttons contact-founders__buttons">
          <div className="global-page__prev-button contact-founders__prev-button">
            <Icon type='arrow-prev' />
          </div>

          <div className="global-page__next-button contact-founders__next-button">
            <Icon type='arrow-next' />
          </div>
        </div>
      </div>
    </div>
  );
};