import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from '@components/shared';
import { classNames } from '@src/utils';

export const Checkbox = ({
  name,
  label,
  value,
  alert,
  onChange,
  className,
  disabled,
}) => {
  return (
    <div className="checkbox__container">
      <div className="checkbox__wrapper">
        <input
          id={name}
          name={name}
          type="checkbox"
          value={value}
          checked={value}
          onChange={onChange}
          className={classNames([
            'checkbox',
            className,
            alert && 'checkbox--has-error',
          ])}
          disabled={disabled}
        />
        <label htmlFor={name} className="checkbox_label">
          {label}
        </label>
      </div>
      <ErrorMessage text={alert} show={!!alert} />
    </div>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  value: PropTypes.bool,
  alert: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  name: 'input-name',
  label: '',
  value: false,
  alert: '',
  onChange: () => {},
  className: '',
};
