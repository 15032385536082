import React from 'react';
import { startOfDay } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { setDayDate } from '@actions/scheduleActions';
import { CalendarDropdown } from '@components/shared';

export const WeekPagination = () => {
  const today = startOfDay(new Date());
  const dayDate = useSelector((state) => state.schedule.dayDate);
  const dispatch = useDispatch();

  return (
    <CalendarDropdown
      today={today}
      selectedDay={dayDate}
      setSelectedDay={(day) => dispatch(setDayDate(day))}
      isScheduleWeekCalendar
    />
  );
};
