import React from 'react';
import { updateUserData } from '@actions/authActions';
import { useForm } from '@src/hooks';
import { getProfileSchema } from '@src/utils';
import { normalizeUserData } from '@src/normalizers';
import { Button, Checkbox, Tooltip, Link } from '@components/shared';
import { AccountProfileCard } from '../../Supports/AccountProfileCard';
import { useDispatch, useSelector } from 'react-redux';

export const SnailSubcription = ({
  institution,
  editorState,
  setEditorState,
  setAddressError,
  updateProviderAffiliation
}) => {
  const { receive_snail_mail, content_provider, content_provider_id, isGlobal } =
    institution || {};
  const { config } = content_provider || {};
  const { base_url, support_email, support_phone_number } = config || {};
  const userAddress = useSelector(
    (state) => state.auth.userData.data?.contact?.address
  );
  const blockName = `snailMailSubscription-${content_provider_id}`;
  const isSnailSubcription = editorState === blockName;
  const dispatch = useDispatch();
  const submitHandler = (values) => {
    const userData = normalizeUserData(values, 'snailMailSubscription');
    isGlobal
    ? dispatch(updateUserData(userData))
    : updateProviderAffiliation(userData.student, content_provider_id);
  };

  const initialValues = {
    snailSubscription: receive_snail_mail,
  };

  const schema = getProfileSchema(editorState);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setCustomValue,
    setErrors,
    clearValues,
  } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });

  const renderSupportTooltip = () => (
    <Tooltip position="bottom">
      <span>Also you can directly email</span>
      <a href={`mailto:${support_email}`}>{support_email}</a>
      <span>or call {support_phone_number}</span>
    </Tooltip>
  );

  const renderSubcriptionSuggestion = (isSubscriber) => {
    if (!editorState) {
      return isSubscriber ? (
        <span className="account-profile__subcription-note">
          If you want to unsubscribe please{' '}
          <span className="account-profile__subcription-support">
            <Link href={`${base_url}/contact`}>contact Support</Link>
            {renderSupportTooltip()}
          </span>
        </span>
      ) : (
        <span className="account-profile__subcription-note">
          If you want to subscribe please{' '}
          <Button onClick={() => setEditorState(blockName)} type="link">
            click here
          </Button>
        </span>
      );
    }
  };

  const onSubscriptionSubmit = (value) => {
    const { address_1, country, city, zip } = userAddress;

    if (!address_1 || !country || !city || !zip) {
      setAddressError(true);
    } else {
      setCustomValue('snailSubscription', value);
    }
  };

  const snailSubscriptionBlockData = [
    {
      label: 'I want to receive snail (standard post) mail',
      inputType: Checkbox,
      inputName: 'snailSubscription',
      hide: !isSnailSubcription,
      additionalProps: {
        onChange: (event) => onSubscriptionSubmit(event.target.checked),
      },
    },
  ];

  const profileBlocksData = 
    {
      title: 'Snail Mail',
      data: snailSubscriptionBlockData,
      infoText: receive_snail_mail
        ? 'You’re already subscribed to our Snail Mail.'
        : 'You’re not subscribed to our Snail Mail.',
      subscription: renderSubcriptionSuggestion(receive_snail_mail),
      name: blockName,
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      editorState,
      setEditorState,
      editRestricted: true,
      clearValues,
      setErrors,
    };
 

  return (
    <>{AccountProfileCard(profileBlocksData)}</>
  );
};
