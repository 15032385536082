export const getFormattedPhone = (phone) => {
  if (!phone) return null;

  const phoneNumbers = phone.match(/\d+/g).join('');
  const phoneArr = phoneNumbers.split('');

  if (phoneArr.length === 10) {
    phoneArr[0] = `(${phoneArr[0]}`;
    phoneArr[2] = `${phoneArr[2]}) `;
    phoneArr[5] = `${phoneArr[5]}-`;
    return `+1 ${phoneArr.join('')}`;
  }

  if (phoneArr.length === 11) {
    phoneArr[0] = `${phoneArr[0]} `;
    phoneArr[1] = `(${phoneArr[1]}`;
    phoneArr[3] = `${phoneArr[3]}) `;
    phoneArr[6] = `${phoneArr[6]}-`;
    return `+${phoneArr.join('')}`;
  }

  return phoneNumbers;
};