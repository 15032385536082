import React from 'react';
import TextField from '@mui/material/TextField';
import { PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import {
  isWithinInterval,
  startOfWeek,
  endOfWeek,
  format,
  isSameDay,
} from 'date-fns';
import { classNames } from '@src/utils';
import { weekdaysObjectByShort } from '@src/constants';
import { useViewport } from '@src/hooks';

export const StaticCalendar = ({
  selectedDay,
  setSelectedDay,
  minDate,
  maxDate,
  pastEvents,
  futureEvents,
  isScheduleWeekCalendar,
  isAccountCalendar
}) => {
  const { width } = useViewport();

  const renderWeekPicker = (date, selectedDates, pickersDayProps) => {
    const start = startOfWeek(selectedDay);
    const end = endOfWeek(selectedDay);
    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    return (
      <PickersDay
        {...pickersDayProps}
        className={classNames([
          dayIsBetween && !isFirstDay && !isLastDay && 'between-day',
          isFirstDay && 'first-day',
          isLastDay && 'last-day',
        ])}
      >
        <p className="date">{format(date, 'd')}</p>
      </PickersDay>
    );
  };

  const renderDayPicker = (day, _value, DayComponentProps) => {
    const isPastEvent = pastEvents?.includes(day.getTime());
    const isfutureEvent = futureEvents?.includes(day.getTime());

    return (
      <PickersDay
        {...DayComponentProps}
        className={classNames([
          isPastEvent && 'past-event',
          isfutureEvent && 'future-event',
        ])}
      >
        {format(day, 'd')}
      </PickersDay>
    );
  };

  const weekDayFormat = (day) => {
    return isAccountCalendar && width > 760
      ? weekdaysObjectByShort[day]
      : day;
  };

  return (
    <StaticDatePicker
      date={selectedDay}
      views={['day']}
      minDate={minDate}
      maxDate={maxDate}
      showToolbar={false}
      onChange={setSelectedDay}
      dayOfWeekFormatter={(day) => weekDayFormat(day)}
      showDaysOutsideCurrentMonth={true}
      renderInput={(params) => <TextField {...params} />}
      renderDay={isScheduleWeekCalendar ? renderWeekPicker : renderDayPicker}
    />
  );
};
