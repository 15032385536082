import React, { Fragment } from 'react';
import { Container } from '@components/layout';
import { useApi } from '@src/hooks';
import { Loader, ContactForm, SEO } from '@components/shared';
import { ProviderBanner } from '@components/provider';
import { RentalsItem } from './RentalsItem';
import { useSelector } from 'react-redux';

const Rentals = () => {
  const providerData = useSelector((state) => state.provider.providerData.data);
  const rentalsPageData = useApi({
    url: `/providers/current/pages/rentals`,
    dataPlaceholder: {},
  });
  const { content = {}, title, banner, content_order } = rentalsPageData.data;
  const { rental_locations_list, contact_form, text_block } = content;

  const rentalsRender = {
    text_block: (
      <div
        className="rentals__description text-editor-block"
        dangerouslySetInnerHTML={{ __html: text_block }}
        key="text_block"
      ></div>
    ),
    rental_locations_list: (
      <Fragment key="rental_locations_list">
        {rental_locations_list?.map((item) => (
          <RentalsItem location={item} key={item.title} />
        ))}
      </Fragment>
    ),
    contact_form: (
      <ContactForm
        key="contact_form"
        formData={{
          ...contact_form,
          apiUrl: '/providers/current/pages/rentals/form-submit',
        }}
      />
    ),
  };

  return (
    <div className="rentals">
      {providerData && (
        <SEO
          title={`${providerData.short_name} | Rentals`}
          description={`Discover our rental options at ${providerData.short_name}, including information about available locations, studios, and our rental policy. Explore the perfect space for your creative endeavors and learn about our flexible rental terms.`}
          canonicalURL={`${providerData.base_url}/rentals`}
          image={providerData.logo}
        />
      )}
      {rentalsPageData.loading ? (
        <Loader sticky />
      ) : (
        <div className="global-page__body rentals">
          {!!banner && <ProviderBanner banner={banner} />}
          <Container>
            <h1 className="page-title rentals__title">{title}</h1>
            {content_order?.map((block) => rentalsRender[block])}
          </Container>
        </div>
      )}
    </div>
  );
};

export default Rentals;