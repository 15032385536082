import React, { useState } from 'react';
import { useApi } from '@src/hooks';
import { Button, Icon, Loader } from '@components/shared';
import VideoCard from '@components/features/Videos/VideoCard';
import { NothingFound } from '../../../shared/NothingFound/NothingFound';

export const AccountVideos = () => {
  const [expandedVideo, setExpandedVideo] = useState(null);
  const [pageCounter, setPageCounter] = useState(1);

  const userVideos = useApi(
    { url: `/account/videos?page=${pageCounter}`, reduce: true },
    [pageCounter]
  );

  return (
    <div className="account-videos">
      <h2 className="account__content-title">Videos</h2>
      <Loader sticky show={userVideos.loading} />
      {userVideos.data?.length ? (
        userVideos.data.map((video) => {
          return (
            <VideoCard
              video={video}
              key={video.id}
              isExpanded={expandedVideo === video.id}
              setExpandedVideo={setExpandedVideo}
              isAccount
            />
          );
        })
      ) : (
        <>
          {userVideos.data && (
            <NothingFound
              title="You haven't any available video to watch."
              subtitle={''}
              icon={'video'}
            />
          )}
        </>
      )}
      {userVideos.hasNextPage && (
        <Button
          className="account__more-button"
          onClick={() => setPageCounter(pageCounter + 1)}
          iconRight={<Icon type="chevron" size={'sm'} />}
        >
          View More
        </Button>
      )}
    </div>
  );
};
