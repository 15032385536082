import React from 'react';
import PropTypes from 'prop-types';
import { enrollmentTypes } from '@src/constants';
import { formatTimeDate, getOptions } from '@src/utils';
import { twelveHoursFormat, splitSign } from '@src/constants';
import { CourseBadges } from '@components/shared/Course/CourseBadges';
import { InstructorName, Icon, Link } from '@components/shared';
import { ProviderLogo } from '@components/provider';

export const ScheduleCard = ({ event }) => {
  const getFormattedTimeString = (timeString) =>
    formatTimeDate(timeString, splitSign, twelveHoursFormat).toLowerCase();

  const { course, start_time, end_time, location, session, is_past, is_trial } =
    event;

  const {
    participant_levels,
    participant_level_exception,
    label,
    type,
    title,
    content_provider,
    is_disabled,
  } = course;

  const { logo, base_url } = content_provider;
  const levels = getOptions(participant_levels);

  const offeringLink = `${base_url}/offerings/${course.id}`;

  return (
    <div className="schedule-card">
      {logo && <ProviderLogo className="schedule-card__logo" src={logo} logoUrl={base_url}/>}
      <div className="schedule-card__title-wrapper">
        <p className="schedule-card__id">
          {label}
          {type && ` | ${enrollmentTypes[type]}`}
        </p>
        {
          <CourseBadges
            course={course}
            isFree={session.is_free}
            isPast={is_past}
            isTrial={is_trial}
          />
        }
        {title && (
          <Link href={offeringLink}>
            <h3 className="schedule-card__title">
              {is_disabled ? 'Booked Slot' : title}
            </h3>
          </Link>
        )}
      </div>

      <div className="schedule-card__group">
        <Icon type="profile-tab" />
        <InstructorName
          course={course}
          className="schedule-card__instructors"
        />
      </div>

      <div className="schedule-card__group">
        <Icon type="clock" />
        <span>
          {getFormattedTimeString(start_time)} &mdash;{' '}
          {getFormattedTimeString(end_time)} EST
        </span>
      </div>

      {location && (
        <div className="schedule-card__group">
          <Icon type="location" />
          <span>{location}</span>
        </div>
      )}

      {!is_disabled && levels && (
        <div className="schedule-card__group">
          <Icon type="participant-level" />
          <span>
            {levels}
            <sup>{levels && participant_level_exception}</sup>
          </span>
        </div>
      )}

      <div className="schedule-card__footer">
        <Link
          iconRight={<Icon type="proceed-next" size="md" />}
          href={offeringLink}
          title={title}
        >
          Learn More
        </Link>
      </div>
    </div>
  );
};

ScheduleCard.propTypes = {
  cardId: PropTypes.string,
  cardTitle: PropTypes.string,
  cardTime: PropTypes.string,
  cardDifficulty: PropTypes.string,
  cardInstructor: PropTypes.string,
  cardStudio: PropTypes.string,
  className: PropTypes.string,
};

ScheduleCard.defaultProps = {
  cardId: '',
  cardTitle: '',
  cardTime: '',
  cardDifficulty: '',
  cardInstructor: '',
  cardStudio: '',
  className: '',
};
