import { basicStore } from '@src/store';
import { changeBodyScroll, removeLocationHash } from '@src/utils';

const modalStore = basicStore([]);

modalStore.openModal = function (modalObject) {
  changeBodyScroll(true, 'modal');
  let modals = this.data.slice();
  modals.push({ ...modalObject, visible: true, showBackButton: true });
  this.data = modals;
  window.history.pushState("forward", null);
};

modalStore.closeModal = function (name) {
  removeLocationHash();
  const modals = this.data.slice().filter((modal) => modal.name !== name);
  if (!modals.length) changeBodyScroll(false, 'modal');

  this.data = modals;
};

modalStore.closeAllModals = function () {
  removeLocationHash();
  changeBodyScroll(false, 'modal');
  this.data = [];
};

modalStore.changeModalProps = function (name, props) {
  let modals = this.data.slice();

  const modalIndex = modals.findIndex((modal) => modal.name === name);

  if (modalIndex < 0) return modals;

  modals[modalIndex] = { ...modals[modalIndex], ...props };

  this.data = modals;
};

window.addEventListener("popstate", (e) => {
  e.preventDefault();
  if (!e.state) return
  if (modalStore.data.length) {
    const lastModalIndex = modalStore.data.length - 1
    modalStore.closeModal(modalStore.data[lastModalIndex].name);
  }
})

const openModal = modalStore.openModal.bind(modalStore);
const closeModal = modalStore.closeModal.bind(modalStore);
const closeAllModals = modalStore.closeAllModals.bind(modalStore);
const changeModalProps = modalStore.changeModalProps.bind(modalStore);

export { 
  openModal, 
  closeModal, 
  changeModalProps, 
  closeAllModals, 
  modalStore 
};