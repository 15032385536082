import { debounce } from '@src/utils';

const defaultSettings = {
  hasLeftBar: false,
};

let lastYOffset;
let headerAlreadySticky = false;

export const tableFixedBar = (tableElem, options = defaultSettings) => {
  const { hasLeftBar } = options;

  if (!tableElem) throw new Error('No table element provided');

  const tableContainer = tableElem.querySelector('.table__container');
  const tableHeader = tableContainer.querySelector('.table__row--title');
  const leftBar = hasLeftBar ? tableHeader.children[0] : null;

  const triggerLayout = () => {
    const tableOffset = tableElem.offsetTop;
    const windowTop = window.pageYOffset;

    if (
      windowTop - 325 >= tableOffset &&
      windowTop <= tableOffset + tableElem.offsetHeight
    ) {
      if (windowTop !== lastYOffset) tableHeader.style.top = '-425px';
      lastYOffset = windowTop;

      if (!headerAlreadySticky) {
        headerAlreadySticky = true;
        tableHeader.classList.add('table__row--title-sticky');
      }

      if (hasLeftBar) {
        tableHeader.style.transform = `translate3d(${
          -tableContainer.scrollLeft + leftBar.offsetWidth
        }px, ${windowTop - tableOffset - 3}px, 0)`;

        leftBar.style.transform = `translate3d(${
          tableContainer.scrollLeft - leftBar.offsetWidth
        }px, 0, 0)`;
      } else {
        tableHeader.style.transform = `translate3d(-${
          tableContainer.scrollLeft
        }px, ${windowTop - tableOffset - 3}px, 0)`;
      }

      debounce(function () {
        tableHeader.style.top = '0';
      }, 300)();
    } else {
      if (headerAlreadySticky) {
        headerAlreadySticky = false;

        tableHeader.classList.remove('table__row--title-sticky');
        tableHeader.style.transform = 'none';

        if (hasLeftBar) {
          tableHeader.children[0].style.transform = `none`;
        }
      }
    }
  };

  const init = () => {
    destroy();

    headerAlreadySticky = false;

    tableContainer.addEventListener('scroll', triggerLayout);
    window.addEventListener('scroll', triggerLayout);
    window.addEventListener('resize', triggerLayout);

    triggerLayout();
  };

  const destroy = () => {
    tableContainer.removeEventListener('scroll', triggerLayout);
    window.removeEventListener('scroll', triggerLayout);
    window.removeEventListener('resize', triggerLayout);
  };

  return {
    init,
    destroy,
  };
};
