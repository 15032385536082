import React from 'react';
import { insertClassName } from '@src/utils';

export const TableCell = ({ className, children, style, onClick }) => {
  return (
    <div
      // style={style}
      onClick={() => onClick && onClick()}
      className={`table__cell${insertClassName(className, className)}`}
    >
      {children}
    </div>
  );
};