import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Input,
  PhoneListInput,
  Textarea,
  RecaptchaMessage,
  Loader,
  getModal,
  openModal,
  Image,
} from '@components/shared';
import { useForm } from '@src/hooks';
import { apiRequest, requestCallback, classNames } from '@src/utils';
import { useSelector } from 'react-redux';
import { RecaptchaContext } from '@src/RecaptchaContext.js';

export const ContactForm = ({ formData, className }) => {
  const { title, apiUrl, image } = formData;
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.auth.userData);
  const recaptcha = useContext(RecaptchaContext);

  const schema = {
    name: {
      validation: ['isRequired'],
    },
    email: {
      validation: ['isRequired'],
    },
    phone: {
      validation: ['isRequired', 'phone'],
    },
  };

  const onSubmitSuccess = async (contactData) => {
    setIsLoading(true);
    recaptcha.executeAsync().then((token) => {
      sendContactData({ contactData, g_recaptcha_response: token });
      recaptcha.reset();
    });
  };

  const sendContactData = async (contactData) => {
    await apiRequest({
      url: apiUrl,
      method: 'POST',
      data: JSON.stringify(contactData),
      callbacks: requestCallback(() => onSendContactSuccess()),
    });
    setIsLoading(false);
  };

  const onSendContactSuccess = () => {
    openSuccessModal();
    clearValues();
  };

  const openSuccessModal = () => {
    openModal(
      getModal({
        name: 'contact-success',
      })
    );
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setCustomValue,
    handleBlur,
    clearValues,
    setValues,
  } = useForm({
    callback: onSubmitSuccess,
    schema,
  });

  useEffect(() => {
    if (userData.data) {
      const { display_name, email, contact } = userData.data;
      setValues({
        name: display_name,
        email,
        phone: contact?.cell_phone,
      });
    }
  }, [userData.data]);

  return (
    <div className={classNames(['page-block', 'contact-form-block', className])}>
      <div className="contact-form-block__body">
        <div className="contact-form-block__visual">
          <Image src={image} alt="admin" />
        </div>

        <div className="contact-form-block__content">
          <h2
            className="page-block-title contact-form-block__title"
            dangerouslySetInnerHTML={{ __html: title }}
          ></h2>
          <Loader show={isLoading} attached />
          <form className="contact-form-block__form" onSubmit={handleSubmit}>
            <div className="contact-form">
              <div className="contact-form__top">
                <div className="contact-form__group contact-form__group--first">
                  <Input
                    name="name"
                    label="Name"
                    placeholder="Enter your Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    alert={errors.name}
                    required
                  />
                </div>

                <div className="contact-form__group contact-form__group--second">
                  <Input
                    name="email"
                    label="Email"
                    placeholder="Enter your Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    alert={errors.email}
                    required
                  />

                  <PhoneListInput
                    name="phone"
                    label="Phone"
                    placeholder="Enter your Phone"
                    onChange={(value) => setCustomValue('phone', value)}
                    onBlur={handleBlur}
                    value={values.phone}
                    alert={errors.phone}
                    required
                  />
                </div>

                <div className="contact-form__group contact-form__group--third">
                  <Textarea
                    name="message"
                    label="Message"
                    placeholder="Enter your Message"
                    onChange={handleChange}
                    value={values.message}
                  />
                </div>
              </div>

              <div className="contact-form__bottom">
                <div className="contact-form__group">
                  <Button
                    className="contact-form__submit-button"
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </div>
                <div className="contact-form__inform-message">
                  <RecaptchaMessage />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
