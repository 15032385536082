import {
  apiRequest,
  requestReduxHandler,
  requestCallback,
} from '@src/utils';
import {
  onError,
  onLoading,
  onSuccess,
  getAction,
} from '@src/store/actionCreators';
import * as types from '@types/authTypes';
import Cookies from 'js-cookie';

export const logout = () => () => {
  apiRequest({
    url: `/auth/logout`,
    method: 'POST',
    callbacks: requestCallback(() =>
      Cookies.remove('token', { domain: '.worldeducationweb.org' }),
      // Cookies.remove('token')
    ),
  });
};

export const getUserData = () => () => {
  apiRequest({
    url: '/account/profile',
    method: 'GET',
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.USER_DATA, res.data),
      () => onLoading(types.USER_DATA_LOADING),
      (err) => onError(types.USER_DATA_ERROR, err)
    ),
  });
};

export const updateUserData = (data, successCallback) => () => {
  apiRequest({
    url: '/account/profile',
    method: 'PUT',
    data: JSON.stringify(data),
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.USER_DATA, res.data),
      () => onLoading(types.USER_DATA_UPDATE_LOADING),
      (err) => onError(types.USER_DATA_ERROR, err)
    ),
    callbacks: requestCallback((res) => {
      successCallback && successCallback();
    }),
  });
};

export const getAffiliations = () => () => {
  apiRequest({
    url: '/users/affiliations',
    method: 'GET',
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.AFFILIATIONS_DATA, res.data),
      () => onLoading(types.USER_DATA_LOADING)
    ),
  });
};

export const updateProviderAffiliations =
  (data, id, successCallback) => () => {
    apiRequest({
      url: `/users/affiliations/providers/${id}`,
      method: 'POST',
      data: JSON.stringify(data),
      reduxHandlers: requestReduxHandler(
        () => {},
        () => onLoading(types.USER_DATA_UPDATE_LOADING),
        (err) => onError(types.USER_DATA_ERROR, err)
      ),
      callbacks: requestCallback((res) => {
        successCallback && successCallback();
      }),
    });
  };

export const clearUserData = () => getAction(types.USER_DATA_CLEAR);
