import React from 'react';
import { Button, Icon, getModal, openModal, Link } from '@components/shared';
import {
  formatTimeDate,
  openPdf,
  downloadPdf,
  zonedDate,
  formatPrice,
  apiRequest,
  requestCallback,
} from '@src/utils';
import {
  twelveHoursFormat,
  splitSign,
  userTicketsDateFormat,
  ticketDateFormat,
  globalWidgetLink,
} from '@src/constants';
import { format } from 'date-fns';
import { useViewport } from '@src/hooks';

export const AccountTicketsCard = ({ ticketData }) => {
  const { isMobile } = useViewport();
  const {
    id,
    title,
    course,
    instructor,
    count,
    date_of_purchase,
    price,
    schedules,
    attendees,
    special_notes_and_directions,
  } = ticketData;
  const { content_provider } = course;
  const { name, base_url } = content_provider;

  const receiptId = id;

  const onReceiptButtonClick = () => {
    openModal(
      getModal({
        name: 'receipt-slider',
        data: { receiptId },
      })
    );
  };

  const onDirectionsButtonClick = () => {
    openModal(
      getModal({
        name: 'directions',
        data: { id, special_notes_and_directions },
      })
    );
  };

  const getTicketPdf = (id, callback) => {
    apiRequest({
      url: `account/tickets/${id}/pdf`,
      callbacks: requestCallback(({ data }) => {
        callback(data);
      }),
    });
  };

  const onPrintTicketClick = () => {
    getTicketPdf(id, openPdf);
  };

  const onSaveTicketClick = () => {
    getTicketPdf(id, downloadPdf);
  };

  const getScheduleTime = (start_time, end_time) => {
    const formattedStart = formatTimeDate(
      start_time,
      splitSign,
      twelveHoursFormat
    ).toLowerCase();

    const formattedEnd = formatTimeDate(
      end_time,
      splitSign,
      twelveHoursFormat
    ).toLowerCase();

    return `${formattedStart} - ${formattedEnd}`;
  };

  const formattedPrice = parseFloat(price) ? `$${formatPrice(price)}` : 'Free';

  return (
    <div className="account-tickets-card">
      <div className="account-tickets-card__ticket-title">{title}</div>

      <div className="account-tickets-card__purchase-date">
        Date of purchase:{' '}
        {format(zonedDate(date_of_purchase), ticketDateFormat)}
      </div>

      <div className="account-tickets-card__offering-title">
        <Link
          href={`${base_url}/offerings/${course.id}`}
        >
          {course.title}
          <span>
            <Icon type="proceed-next" />
          </span>
        </Link>
      </div>

      <div className="account-tickets-card__count"># of Tickets: {count}</div>

      <div className="account-tickets-card__offering-instructor">
        <Link href={`${globalWidgetLink}/faculty/${instructor.id}`}>
          {instructor.display_name}
        </Link>
        <div className="account-tickets-card__provider">
          <span className="account-tickets-card__provider-label">Presented by:</span>
          <Link href={base_url}>{name}</Link>
        </div>
      </div>

      <div className="account-tickets-card__schedule">
        {isMobile ? (
          <>
            <div className="account-tickets-card__schedule-item">
              <span className="account-tickets-card__date-label">Date(s)</span>

              {schedules.map(({ date, start_time, end_time }) => (
                <React.Fragment key={date + start_time + end_time}>
                  <span className="account-tickets-card__date-item">
                    {format(zonedDate(date), userTicketsDateFormat)}
                  </span>
                </React.Fragment>
              ))}
            </div>

            <div className="account-tickets-card__schedule-item">
              <span className="account-tickets-card__time-label">Time(s)</span>
              {schedules.map(({ date, start_time, end_time }) => (
                <React.Fragment key={date + start_time + end_time}>
                  <span className="account-tickets-card__time-item">
                    {getScheduleTime(start_time, end_time)}
                  </span>
                </React.Fragment>
              ))}
            </div>

            <div className="account-tickets-card__schedule-item">
              <span className="account-tickets-card__studio-label">
                Studio(s)
              </span>
              {schedules.map(({ date, start_time, end_time, studios }) => (
                <React.Fragment key={date + start_time + end_time}>
                  <span className="account-tickets-card__studio-item">
                    {studios.map(({ name }, i) =>
                      ++i === studios.length ? name : name + ', '
                    )}
                  </span>
                </React.Fragment>
              ))}
            </div>
          </>
        ) : (
          <>
            <span className="account-tickets-card__date-label">Date(s)</span>
            <span className="account-tickets-card__time-label">Time(s)</span>
            <span className="account-tickets-card__studio-label">
              Studio(s)
            </span>

            {schedules.map(({ date, start_time, end_time, studios }) => (
              <React.Fragment key={date + start_time + end_time}>
                {isMobile && (
                  <span className="account-tickets-card__date-label">Date</span>
                )}
                <span className="account-tickets-card__date-item">
                  {format(zonedDate(date), userTicketsDateFormat)}
                </span>

                {isMobile && (
                  <span className="account-tickets-card__time-label">Time</span>
                )}
                <span className="account-tickets-card__time-item">
                  {getScheduleTime(start_time, end_time)}
                </span>

                {isMobile && (
                  <span className="account-tickets-card__studio-label">
                    Studio
                  </span>
                )}
                <span className="account-tickets-card__studio-item">
                  {studios.map(({ name }, i) =>
                    ++i === studios.length ? name : name + ', '
                  )}
                </span>
              </React.Fragment>
            ))}
          </>
        )}
      </div>

      <div className="account-tickets-card__attendees">
        {isMobile && (
          <>
            <span className="account-tickets-card__circle account-tickets-card__circle--left"></span>
            <span className="account-tickets-card__circle account-tickets-card__circle--right"></span>
          </>
        )}
        Attendees{' '}
        <span>
          {attendees.length
            ? attendees.map((name, i) =>
                ++i === attendees.length ? name : name + ', '
              )
            : 'Not specified'}
        </span>
      </div>

      {(special_notes_and_directions || price !== 0) && (
        <div className="account-tickets-card__actions">
          {special_notes_and_directions && (
            <Button onClick={onDirectionsButtonClick}>
              {!isMobile ? 'Special Directions' : 'Directions'}
            </Button>
          )}

          {price !== 0 && (
            <Button onClick={onReceiptButtonClick}>Receipt</Button>
          )}
        </div>
      )}

      {isMobile ? (
        <>
          <div className="account-tickets-card__price">
            <span>{formattedPrice}</span>

            {formattedPrice !== 'Free' && 'per ticket'}
          </div>

          <div className="account-tickets-card__file-actions">
            <Button>
              <Icon type="download" size="xxl" />
            </Button>
          </div>
        </>
      ) : (
        <div className="account-tickets-card__additional">
          <span className="account-tickets-card__circle account-tickets-card__circle--top"></span>
          <span className="account-tickets-card__circle account-tickets-card__circle--bottom"></span>

          <div className="account-tickets-card__file-actions">
            <Button onClick={onPrintTicketClick}>
              <Icon type="printer" />
            </Button>

            <Button onClick={onSaveTicketClick}>
              <Icon type="download" />
            </Button>
          </div>

          <div className="account-tickets-card__price">
            <span>{formattedPrice}</span>

            {formattedPrice !== 'Free' && 'per ticket'}
          </div>
        </div>
      )}
    </div>
  );
};
