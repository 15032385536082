import React from 'react';
import { Icon } from '@components/shared';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AboutTestimonialsCard } from './AboutTestimonialsCard';

import 'swiper/swiper.scss';

export const ProviderAboutTestimonials = ({ testimonialsData = {} }) => {
  const { title, subtitle, list = [] } = testimonialsData;
  const slidesCount = list.length;
  const sliderSettings = {
    className: 'about-testimonials__slider',
    navigation: {
      nextEl: '.about-testimonials__next-button',
      prevEl: '.about-testimonials__prev-button',
    },
    loop: true,
    slidesPerView: 1,
    enabled: slidesCount > 1,
    spaceBetween: 32,
    breakpoints: {
      768: {
        slidesPerView: slidesCount > 2 ? 2 : slidesCount,
        enabled: slidesCount > 2,
      },
    },
  };

  return (
    <div className="about-testimonials">
      <div className="about-testimonials__title-block">
        <h2 className="provider-about__subtitle">{title}</h2>
        <h3 className="page-block-title">{subtitle}</h3>
      </div>

      <div className="about-testimonials__body">
        <Swiper {...sliderSettings}>
          {list.map(({ title, description }) => {
            return (
              <SwiperSlide className="about-testimonials__slide" key={title}>
                <AboutTestimonialsCard
                  title={title}
                  description={description}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className="global-page__buttons about-testimonials__buttons">
          <div className="global-page__prev-button about-testimonials__prev-button">
            <Icon type="arrow-prev" />
          </div>

          <div className="global-page__next-button about-testimonials__next-button">
            <Icon type="arrow-next" />
          </div>
        </div>
      </div>
    </div>
  );
};
