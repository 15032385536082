import React, { useState, useEffect, useCallback } from 'react';
import { Input, Icon, SortingOrderButton, Select } from '@components/shared';
import { useDispatch } from 'react-redux';
import { getProvidersList } from '@actions/providerActions';
import { debounce } from '@src/utils';
import { useDidUpdate } from '@src/hooks';

export const GlobalProvidersTitleBlock = () => {
  const [sortingBy, setSortingBy] = useState('name');
  const [sortingOrder, setSortingOrder] = useState('asc');
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProvidersList(search, sortingBy, sortingOrder));
  }, [sortingBy, sortingOrder]);

  useDidUpdate(() => {
    requestSearchedProviders(search);
  }, [search]);

  const requestSearchedProviders = useCallback(
    debounce((search) => {
      dispatch(getProvidersList(search, sortingBy, sortingOrder));
    }, 500),
    []
  );

  const sortingOptions = [
    { name: 'Name', value: 'name' },
    { name: 'Id', value: 'id' },
    { name: 'Created At', value: 'created_at' },
    { name: 'Updated At', value: 'updated_at' },
    { name: 'Offerings Count', value: 'offerings_count' },
  ];

  return (
    <div className="providers-title-block">
      <h1 className="page-title">Providers</h1>

      <div className="providers-title-block__actions">
        <Input
          className="providers-title-block__search"
          placeholder="Search"
          icon={<Icon type="search" />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="providers-title-block__sorting">
          <Select
            className="providers-title-block__sorting-select"
            options={sortingOptions}
            onChange={({ value }) => setSortingBy(value)}
            value={sortingBy}
            optionsLabel="name"
            optionsKey="value"
            optionsValue="value"
            valueLabel="Sort by:"
          />

          <SortingOrderButton
            className={`providers-title-block__sorting-button providers-title-block__sorting-button--${sortingOrder}`}
            onClick={() =>
              setSortingOrder(sortingOrder === 'asc' ? 'desc' : 'asc')
            }
          >
            <Icon type="sorting" />
          </SortingOrderButton>
        </div>
      </div>
    </div>
  );
};
