import React, { useState, useRef, forwardRef } from "react";
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import { ErrorMessage } from '@components/shared';
import { classNames, formatErrorMessage } from '@src/utils';

import 'react-phone-input-2/lib/high-res.css'

export const PhoneListInput = forwardRef(
  (
    {
      name,
      label,
      value,
      alert,
      onChange,
      onBlur,
      onFocus,
      placeholder,
      disabled,
      className,
      inputClassName,
      unnamed,
      required,
      pattern,
      hint,
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef();

    const onInputBlur = (e) => {
      setIsFocused(false);
      onBlur(e);
    };
    
    const phoneInputProps = {
      inputProps: {
        id: unnamed ? '' : name,
        name: unnamed ? '' : name,
        pattern,
        ref: (elemRef) => {
          inputRef.current = elemRef;
          ref && (ref.current = elemRef);
        },
      },
      value: value || '',
      placeholder,
      disabled,
      onChange,
      onBlur: (e) => onInputBlur(e),
      onFocus: () => setIsFocused(true),
      inputClass: classNames([
        'input__element',
        inputClassName,
      ]),
      country: "us",
      preferredCountries: ["us"],
      enableSearch: true,
      searchPlaceholder: "Search",
      searchNotFound: "Nothing to select",
    };

    return (
      <div className={classNames(['input', 'input--phone', className])}>
        {label && (
          <label
            htmlFor={name}
            className={classNames([
              'input__label',
              isFocused && 'input__label--focused',
              required && 'required-mark',
            ])}
          >
            {label}
          </label>
        )}

        <div 
          className={classNames([
            'input__wrapper',
            isFocused && 'input__wrapper--focused',
            alert && 'input__wrapper--has-error',
            disabled && 'input__wrapper--disabled',
          ])}
        >
          <PhoneInput {...phoneInputProps} />
        </div>

        {hint && <div className="input__hint">{hint}</div>}
        <ErrorMessage text={formatErrorMessage(label, alert)} show={!!alert} />
      </div>
    );
  }
);

PhoneListInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  alert: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  unnamed: PropTypes.bool,
  required: PropTypes.bool,
};

PhoneListInput.defaultProps = {
  name: 'input-name',
  label: '',
  value: '',
  alert: '',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  className: '',
  inputClassName: '',
  placeholder: '',
  disabled: false,
  unnamed: false,
  required: false,
};