import React from 'react';
import { Image } from '@components/shared';

export const ProviderAboutMission = ({ missionData = {} }) => {
  const { content, image, subtitle } = missionData;
  return (
    <div className="about-mission">
      <div className="about-mission__body">
        <div className="about-mission__visual">
          <Image src={image} alt="provider mission" />
        </div>
        <div className="about-mission__content">
          <h2 className="provider-about__subtitle">{subtitle}</h2>
          <div
            className="mission-text-block text-editor-block"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
      </div>
    </div>
  );
};
