import React from 'react';
import { Link } from '@components/shared';
import Details from '@components/shared/Details/Details';

export const ProvidersCard = ({ provider }) => {
  const { logo, name, description, base_url } = provider;
  return (
    <div className="global-page-card providers-card">
      <div className="providers-card__visual">
        <img src={logo} alt="provider logo" />
      </div>

      <div className="providers-card__content">
        <h3 className="providers-card__title">
          <Link href={base_url}>{name}</Link>
        </h3>

        <Details className="providers-card__description" isViewButton>
          {description}
        </Details>

        <Link className="providers-card__link" href={base_url}>
          View more...
        </Link>
      </div>
    </div>
  );
};
