import React from 'react';
import { Link } from '@components/shared';

export const SponsorsCard = ({ logo, description, url, name }) => {
  return (
    <Link className="global-page-card sponsors-card" href={url}>
      <div className="global-page-card__title-block sponsors-card__title-block">
        {logo ? (
          <img src={logo} alt="sponsor's logo" />
        ) : (
          <div className="sponsors-card__title">{name}</div>
        )}
      </div>
      <div className="global-page-card__content sponsors-card__content">
        <p>{description}</p>
      </div>
    </Link>
  );
};
