import React, { useEffect, useRef, useState } from 'react';
import { renderClassOrBadge } from '@src/utils';
import JWPlayer from 'react-jwplayer';
import Vimeo from '@u-wave/react-vimeo';
import { Button, Link, Loader, Image } from '@components/shared';
import { globalWidgetLink } from '@src/constants';

const VideoGalleryCard = ({ videoData, onPayClick, isPurchased, loading }) => {
  const {
    type,
    embed_id,
    subtitle,
    description,
    title,
    is_free,
    is_paid,
    poster_image,
  } = videoData;
  const [isLoading, setIsLoading] = useState(false);

  const cardEl = useRef(null);

  const onLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    const iframe = cardEl.current.querySelector('iframe');
    if (iframe) {
      setIsLoading(true);
      iframe.addEventListener('load', onLoad);
    }
  }, [videoData]);

  const renderActionButton = () => {
    if (is_free && !is_paid) return;

    return (
      <>
        {is_paid && !isPurchased ? (
          <Button
            type="primary"
            className="videos__btn"
            onClick={() => onPayClick()}
          >
            Pay to Watch
          </Button>
        ) : (
          <div className="video-gallery__notification">
            You've already paid for this video. You can watch it in your{' '}
            <Link href={`${globalWidgetLink}/account/videos`}>Account</Link>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="video-gallery__card" ref={cardEl}>
      <div className="video-gallery__video-wrapper">
        <div className={`video-gallery__video video-gallery__video--${type}`}>
          {isLoading && <Loader sticky />}
          {is_free ? (
            <>
              {type === 'vimeo' ? (
                <Vimeo video={embed_id} responsive={true} />
              ) : (
                <JWPlayer videoId={embed_id} />
              )}
            </>
          ) : (
            <>
              <Image src={poster_image?.path} alt="video poster" />
              <div
                className={`video-gallery__thumb-badge video-gallery__thumb-badge--${renderClassOrBadge(
                  is_free,
                  is_paid
                )}`}
              >
                {renderClassOrBadge(is_free, is_paid, true)}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="video-gallery__info">
        <h4 className="video-gallery__title">{title}</h4>
        {subtitle && (
          <div className="video-gallery__subtitle">
            <span dangerouslySetInnerHTML={{ __html: subtitle }}></span>
          </div>
        )}
        {description && (
          <div className="video-gallery__description">
            <span dangerouslySetInnerHTML={{ __html: description }}></span>
          </div>
        )}
        {loading ? <Loader /> : renderActionButton()}
      </div>
    </div>
  );
};

export default VideoGalleryCard;
