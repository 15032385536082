import React, { createRef, Fragment, useEffect } from 'react';
import { Table, TableCell, TableRow } from '@components/shared';
import {
  dateWeeklyViewFormat,
  tableColors,
  weekdaysObject,
} from '@src/constants';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { getWeekViewTableArray } from './getWeekViewTableArray';
import { ScheduleCard } from '../ScheduleCard';
import {
  convertColorRgba,
  convertRgbStringToObject,
  createLighterColor,
  tableFixedBar,
  dragScroll,
} from '@src/utils';

export const WeekViewTable = ({
  data,
  filter,
  titleRowRenderer,
  onWeekDayClick,
}) => {
  const weekDate = useSelector((state) => state.schedule.weekDate);

  const tableRef = createRef();

  useEffect(() => {
    const newFixedBar = tableFixedBar(tableRef.current);

    const newDragScroll = dragScroll(tableRef.current.children[0]);

    newDragScroll.init();

    newFixedBar.init();

    return () => {
      newDragScroll.destroy();

      newFixedBar.destroy();
    };
  }, []);

  const getWeekHeader = (dates) => {
    return (
      <TableRow title>
        {dates.map((date) => {
          return (
            <TableCell
              key={date}
              onClick={() => onWeekDayClick && onWeekDayClick(date)}
            >
              <span className="schedule-table__header-title">
                {weekdaysObject[date.getDay() + 1]}
              </span>
              <span className="schedule-table__header-subtitle">
                {format(date, dateWeeklyViewFormat)}
              </span>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const getScheduleView = (schedule, color, i) => {
    if (!schedule)
      return <TableCell key={i} style={{ backgroundColor: color }} />;

    return (
      <TableCell key={i} style={{ backgroundColor: color }}>
        <ScheduleCard event={schedule} />
      </TableCell>
    );
  };

  const getTableSectionView = ({ filter, schedulesArray }, i) => {
    const pickedColor = tableColors[i % tableColors.length];

    const titleColor = convertColorRgba(pickedColor, 1);
    const cellColor = createLighterColor(convertRgbStringToObject(pickedColor));
    
    return (
      <Fragment key={i}>
        {<TableRow subtitle>{titleRowRenderer(filter, titleColor)}</TableRow>}
        {schedulesArray.map((scheduleRowArray, i) => (
          <TableRow key={i}>
            {scheduleRowArray.map((schedule, i) =>
              getScheduleView(schedule, cellColor, i)
            )}
          </TableRow>
        ))}
      </Fragment>
    );
  };

  const renderData = getWeekViewTableArray(data, filter);

  return (
    <Table
      tableRef={tableRef}
      className={`schedule-table schedule-table--week-view schedule-table--${filter}`}
    >
      {getWeekHeader(weekDate)}
      {renderData.map(getTableSectionView)}
    </Table>
  );
};