export const FACULTY_INSTRUCTORS = `FACULTY_INSTRUCTORS`;
export const FACULTY_INSTRUCTORS_MORE = `FACULTY_INSTRUCTORS_MORE`;
export const FACULTY_INSTRUCTORS_LOADING = `FACULTY_INSTRUCTORS_LOADING`;
export const FACULTY_INSTRUCTORS_ERROR = `FACULTY_INSTRUCTORS_ERROR`;
export const FACULTY_INSTRUCTORS_PAGE = `FACULTY_INSTRUCTORS_PAGE`;

export const FACULTY_FILTER_SELECT_LETTER = `FACULTY_FILTER_SELECT_LETTER`;
export const FACULTY_FILTER_SELECT_LOCATION = `FACULTY_FILTER_SELECT_LOCATION`;
export const FACULTY_FILTER_SELECT_PROGRAM = `FACULTY_FILTER_SELECT_PROGRAM`;
export const FACULTY_FILTER_SELECT_INSTRUCTORS = `FACULTY_FILTER_SELECT_INSTRUCTORS`;
export const FACULTY_FILTER_SELECT_CATEGORIES = `FACULTY_FILTER_SELECT_CATEGORIES`;
export const FACULTY_FILTERS_CLEAR = `FACULTY_FILTERS_CLEAR`;

export const FACULTY_SEARCH = `FACULTY_SEARCH`;
