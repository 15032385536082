import React from 'react';
import { Badge, Link, Tooltip } from '@components/shared';

export const CourseBadges = ({ course, isFree, isTrial, isPast }) => {
  const {
    is_online,
    is_onsite,
    content_type,
    has_videos,
    is_private,
    is_private_rental,
    is_disabled,
    content_provider,
  } = course || {};

  const displayBadges = () => {
    const badges = [];

    content_type?.name &&
      !is_private_rental &&
      !is_disabled &&
      badges.push({
        id: 'content',
        className: is_private
          ? `offering-details__badge--content-private`
          : `offering-details__badge--content-${content_type.name
              .split(' ')
              .join('-')
              .toLowerCase()}`,
        content: is_private
          ? `Private ${content_type.name}`
          : content_type.name,
        tooltip: is_private && (
          <Tooltip position="bottom">
            This Offering is available for registation. Please see registration
            info inside the Offering details.
          </Tooltip>
        ),
      });

    is_private_rental &&
      !is_disabled &&
      badges.push({
        id: 'PrivateRental',
        className: 'offering-details__badge--content-private',
        content: 'Private Studio Rental',
        tooltip: (
          <Tooltip position="bottom">
            This is a Private Studio Rental.{' '}
            {content_provider.config?.rentals_page_enabled && (
              <>
                If you are interested in renting facilities for your personal
                events, art events, rehearsals, in {content_provider.name},
                please check our rental policy{' '}
                <Link href={`${content_provider.base_url}/rentals`}>here</Link>.
              </>
            )}
          </Tooltip>
        ),
      });

    is_disabled &&
      badges.push({
        id: 'BookedSlot',
        className: 'offering-details__badge--content-private',
        content: 'Private Studio Booking',
      });

    is_online &&
      !is_onsite &&
      badges.push({
        id: 'isOnline',
        className: 'offering-details__badge--online-onsite',
        content: 'Online',
      });

    is_onsite &&
      !is_online &&
      badges.push({
        id: 'isOnsite',
        className: 'offering-details__badge--online-onsite',
        content: 'Onsite',
      });

    is_onsite &&
      is_online &&
      badges.push({
        id: 'isOnsiteOnline',
        className: 'offering-details__badge--onsite-online',
        content: 'Onsite & Online',
      });

    has_videos &&
      badges.push({
        id: 'hasVideos',
        className: 'offering-details__badge--hasVideos',
        content: 'Video',
      });

    isFree &&
      !isPast &&
      badges.push({
        id: 'isFree',
        className: 'offering-details__badge--isFree',
        content: 'Free',
      });

    isFree &&
      isPast &&
      badges.push({
        id: 'isFree',
        className: 'offering-details__badge--isPastFree',
        content: 'Free',
      });

    isTrial &&
      !isPast &&
      badges.push({
        id: 'isTrial',
        className: 'offering-details__badge--isTrial',
        content: 'Free Trial',
      });

    isTrial &&
      isPast &&
      badges.push({
        id: 'isPastTrial',
        className: 'offering-details__badge--isPastTrial',
        content: 'Free Trial',
      });

    if (!badges.length) return null;

    return (
      <div className="offering-details__badges">
        {badges.map(({ id, className, content, tooltip }) => (
          <Badge key={id} className={className}>
            {content}
            {tooltip}
          </Badge>
        ))}
      </div>
    );
  };

  return displayBadges();
};
