import React from 'react';
import PropTypes from "prop-types";

export const Textarea = ({
  name,
  label,
  type,
  value,
  alert,
  onChange,
  onBlur,
  maxLength,
  disabled,
  className,
  placeholder,
  rows
}) => {
  return (
    <div className="input-group__wrapper input-group__wrapper--textarea">
      <label htmlFor={name} className="input__label">
        {label}
      </label>
      <textarea id={name}
        name={name}
        value={value || ""}
        type={type}
        rows={rows}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        className={`${alert ? 'error' : ''}${className ? ` ${className}` : ''}`}
      />
    </div>
  )
}

Textarea.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	type: PropTypes.string,
	value: PropTypes.string,
  rows: PropTypes.string,
	alert: PropTypes.string,
	onChange: PropTypes.func,
	className: PropTypes.string,
	onBlur: PropTypes.func,
	placeholder: PropTypes.string,
	maxLength: PropTypes.string,
	disabled: PropTypes.bool
}

Textarea.defaultProps = {
	name: "input-name",
	label: "",
	type: "textarea",
	value: "",
  rows: "1",
	alert: "",
	onChange: () => {},
	className: "input__element input-textarea",
	onBlur: () => {},
	placeholder: "",
	maxLength: "250",
	disabled: false
}