import React, { useRef } from 'react';
import { Select, Icon, EventsCalendar, ErrorMessage } from '@components/shared';
import { format } from 'date-fns';
import {
  dateInactivityFormat,
  weekdaysObject,
  twelveHoursFormat,
  splitSign,
} from '@src/constants';
import { zonedDate, formatTimeDate } from '@src/utils';
import { useDidUpdate } from '@src/hooks';
import _ from 'lodash';

export const BlockOfDatesSchedulePicker = ({
  slots,
  inactivity_days,
  selectedSlots,
  selectedDates,
  selectedWeekDaySchedules,
  setCustomValue,
  errors,
  contentType,
  timeSlotsErrors,
}) => {
  const selectedSchedules = useRef({});

  const onTimeSlotSelect = (day_num, value) => {
    selectedSchedules.current = {
      ...selectedSchedules.current,
      [day_num]: value,
    };
    setCustomValue('selectedWeekDaySchedules', selectedSchedules.current);
  };

  const updateWeekDaySchedules = (selectedDays) => {
    const selectedWeekDays = _.uniqBy(selectedDays, 'day_num');

    const daysWithNoTimeOptions = selectedWeekDays.filter(
      (day) => day.time.length === 1
    );

    const newSchedules = daysWithNoTimeOptions.reduce((acc, day) => {
      acc[day.day_num] = { schedule_id: day.time[0].schedule_id };
      return acc;
    }, {});

    selectedSchedules.current = newSchedules;

    setCustomValue('selectedWeekDaySchedules', newSchedules);
  };

  useDidUpdate(() => {
    updateWeekDaySchedules(selectedSlots.data);
  }, [selectedSlots.data]);

  const renderInactivity = (days) =>
    days.map(({ inactivity_day }) => {
      const date = format(zonedDate(inactivity_day), dateInactivityFormat);
      return (
        <div className="columns__item" key={inactivity_day}>
          {date} |
        </div>
      );
    });

  const renderWeekDayTimeSelect = (days) => {
    const weekDays = _.uniqBy(days, 'day_num');
    return weekDays.map(({ day_num, time, label = '' }) => {
      if (!label) {
        time.forEach((time) => {
          const formattedStart = formatTimeDate(
            time.start,
            splitSign,
            twelveHoursFormat
          ).toLowerCase();
          const formattedEnd = formatTimeDate(
            time.end,
            splitSign,
            twelveHoursFormat
          ).toLowerCase();
          time.label = `${formattedStart} - ${formattedEnd} EST`;
        });
      }

      return (
        <div key={day_num} className="list__item--time-select">
          <span className="list__item-label">{weekdaysObject[day_num]}</span>
          {time.length === 1 ? (
            <span className="list__item-value">{time[0].label}</span>
          ) : (
            <Select
              placeholder="Select time"
              options={time}
              optionsKey="schedule_id"
              optionsValue="schedule_id"
              optionsLabel="label"
              onChange={(val) => onTimeSlotSelect(day_num, val)}
              value={selectedWeekDaySchedules[day_num]}
            />
          )}
          <ErrorMessage
            show={timeSlotsErrors.includes(day_num)}
            text={'Select a Timeslot to proceed'}
          />
        </div>
      );
    });
  };

  return (
    <>
      <div className="registration-step__group registration-step__group--calendar">
        <EventsCalendar
          selectedDays={selectedDates}
          slots={slots.data}
          isLoading={slots.loading}
        />
      </div>
      {!!selectedDates.length && (
        <div className="registration-step__group--week-timeslots">
          <div className="list">
            {renderWeekDayTimeSelect(selectedSlots.data)}
          </div>
          <div className="registration-step__schedule-note">
            <Icon type="info" size="xxl" />
            <div>
              Please check the schedule in your user account before attending
              the {contentType.plural_short_name}
            </div>
          </div>
        </div>
      )}

      {!!inactivity_days?.length && (
        <div className="registration-step__group registration-step__group--inactivity-days">
          <p className="registration-step__instruction">Inactivity Day</p>
          <div className="columns columns--4">
            {renderInactivity(inactivity_days)}
          </div>
        </div>
      )}
    </>
  );
};
