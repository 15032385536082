import {
  parseNoSpaces,
  parseOnlyLettersAndNumbers,
  parseOnlyNumbers,
} from '@src/hooks';

export const getLoginSchema = (showStates) => {
  return {
    email: {
      parse: parseNoSpaces,
      validation: ['email', 'isRequired'],
    },
    userName: { parse: parseNoSpaces },
    password: {
      validation: ['isRequired', 'password'],
      parse: parseOnlyLettersAndNumbers,
    },
    confirmPassword: {
      parse: parseOnlyLettersAndNumbers,
      customValidation: [
        {
          check: (value) => value,
          message: 'Please repeat your password',
        },
        {
          check: (value, values) =>
            values.password ? value === values.password : true,
          message: 'Passwords do not match. Please repeat.',
        },
      ],
    },
    firstName: { validation: ['isRequired'] },
    lastName: { validation: ['isRequired'] },
    birthDate: { validation: ['birthDate'] },
    cellPhone: { validation: ['phone'] },
    workPhone: { validation: ['phone'] },
    homePhone: { validation: ['phone'] },
    parentName: { validation: ['isRequired'], valuesRequired: ['underAge'] },
    parentPhone: {
      validation: ['isRequired', 'phone'],
      valuesRequired: ['underAge'],
    },
    agreePolicy: { validation: ['isRequired'] },
    address: {
      validation: ['isRequired'],
      valuesRequired: ['subscribeSnail'],
    },
    city: {
      validation: ['isRequired'],
      valuesRequired: ['subscribeSnail'],
    },
    country: {
      validation: ['isRequired'],
      valuesRequired: ['subscribeSnail'],
    },
    state: {
      customValidation: [
        {
          check: (value, values) =>
            showStates && values.subscribeSnail ? value : true,
          message: 'State is required',
        },
      ],
    },
    zip: {
      validation: ['isRequired'],
      parse: parseOnlyNumbers,
    },
    emergencyContactPhone: { validation: ['phone'] },
    mediaWaiver: { validation: ['isRequiredChoice'] },
    knownSource: { validation: ['isRequired'] },
  };
};
