import React from 'react';
import { Button, Icon, closeModal } from '@components/shared';

export const LogoutRequestNotification = () => {
  return (
    <div className="auth">
      <div className="registration-step registration-step--email-verification">
        <div className="registration-step__container">
          <Icon type="link-expired" className="auth__icon" />
          <h1 className="auth__title">Ooops...</h1>

          <div className="registration-step__text">
            Your link doesn't work as you're already logged in at this browser
            with another user account.
          </div>

          <div className="registration-step__text">
            Please log out first and then click a link we've sent you again.
          </div>

          <div className="form__group form__group--auth-buttons">
            <Button
              type="primary"
              onClick={() => closeModal('logout-request-notification')}
            >
              Got it!
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
