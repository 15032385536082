import React from 'react';
import { Container } from '@components/layout';
import { Button, Icon } from '@components/shared';
import { useHistory } from 'react-router-dom';

const NotFoundPage = () => {
  const history = useHistory();

  return (
    <Container>
      <div className="wrong-page">
        <div className="wrong-page__error-code">404</div>
        <div className="wrong-page__description">
          It seems that this page has left to dance, but we are working on getting it to return so you don’t lose your feel for the arts :)
        </div>
        <Button onClick={() => history.push('/offerings')} type="primary">
          Back to Offerings
        </Button>
        <Icon type="not-found-page" className="icon--not-found" />
      </div>
    </Container>
  );
};

export default NotFoundPage;