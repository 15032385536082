import React, { useState, useEffect } from 'react';
import { Container } from '@components/layout';
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import {
  AccountSchedule,
  AccountTickets,
  AccountVideos,
  AccountTransactions,
  AccountProfileInformation,
  AccountInstitutionsSettings,
} from '@components/features/Account';
import { useSelector } from 'react-redux';
import { queryParam } from '@src/utils';
import { useViewport } from '@src/hooks';
import { Button, Icon, SEO } from '@components/shared';
import { AccountNavigation } from './AccountNavigation';

const Account = () => {
  const userData = useSelector((state) => state.auth.userData);

  const [notification, setNotification] = useState(
    queryParam.get('notification')
  );
  const [editorState, setEditorState] = useState(null);
  const { path } = useRouteMatch();
  const history = useHistory();
  const { isTablet } = useViewport();

  useEffect(() => {
    if (editorState) {
      setEditorState('');
    }
  }, [userData.data]);

  useEffect(() => {
    if (!notification) {
      queryParam.delete('notification');
    }
  }, [notification]);

  return (
    <div className="global-page account">
      <SEO title={`World Education Web | Account`} disallow={true} />
      <Container>
        <div className="columns account__columns">
          <div className="columns__item account__navigation-column">
            {!isTablet && <AccountNavigation />}
          </div>
          <div className="columns__item account__content-column">
            <Switch>
              <Route path={`${path}/schedule`}>
                <AccountSchedule />
              </Route>

              <Route path={`${path}/tickets`} exact>
                <AccountTickets />
              </Route>

              <Route path={`${path}/videos`} exact>
                <AccountVideos />
              </Route>

              <Route path={`${path}/transactions`} exact>
                <AccountTransactions />
              </Route>

              <Route path={`${path}/profile-information`} exact>
                <AccountProfileInformation
                  userData={userData}
                  editorState={editorState}
                  setEditorState={setEditorState}
                />
              </Route>

              <Route path={`${path}/institutions-settings`} exact>
                <AccountInstitutionsSettings
                  userData={userData}
                  editorState={editorState}
                  setEditorState={setEditorState}
                />
              </Route>

              <Route>
                <Redirect to={`${path}/schedule`} />
              </Route>
            </Switch>

            {!!notification && (
              <div className="popup">
                <div
                  className="popup__overlay"
                  onClick={() => setNotification('')}
                ></div>
                <div className="popup__window">
                  <Icon type="success" className="popup__icon" />
                  <div className="popup__body">
                    <div
                      className="popup__text"
                      dangerouslySetInnerHTML={{ __html: notification }}
                    ></div>

                    <Button
                      className="popup__return-button"
                      type="primary"
                      onClick={() => history.push('/offerings')}
                    >
                      Return to Offerings
                    </Button>
                  </div>

                  <Button
                    className="popup__close-button"
                    onClick={() => setNotification('')}
                  >
                    <Icon type="close-cross" />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Account;
