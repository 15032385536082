import * as types from '@types/providerTypes';
import { apiRequest, requestReduxHandler } from '@src/utils';
import {
  onError,
  onLoading,
  onSuccess,
} from '@src/store/actionCreators';
import { PROVIDER_CURRENT_URL, PROVIDERS_LIST_URL } from '@src/endpoints';

export const getCurrentProvider = () => () => {
  apiRequest({
    url: PROVIDER_CURRENT_URL(),
    method: 'GET',
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.CURRENT_PROVIDER, res.data),
      () => onLoading(types.CURRENT_PROVIDER_LOADING),
      (err) => onError(types.CURRENT_PROVIDER_ERROR, err)
    ),
  });
};

export const getProvidersList = (search, sortingBy, sortingOrder) => () => {
  apiRequest({
    url: PROVIDERS_LIST_URL(search, sortingBy, sortingOrder),
    method: 'GET',
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.PROVIDERS_LIST, res.data),
      () => onLoading(types.PROVIDERS_LIST_LOADING),
      (err) => onError(types.PROVIDERS_LIST_ERROR, err)
    ),
  });
};
