import React from 'react';
import { Button, Icon, getModal, openModal, Link } from '@components/shared';
import {
  formatTimeDate,
  getFormattedPhone,
  zonedDate,
} from '@src/utils';
import {
  twelveHoursFormat,
  splitSign,
  globalWidgetLink,
  userTicketsDateFormat,
} from '@src/constants';
import { useViewport } from '@src/hooks';
import { format } from 'date-fns';

export const AccountScheduleCard = ({ scheduleData }) => {
  const {
    course,
    instructor,
    location,
    schedule,
    order,
    special_notes_and_directions,
    id,
    class_at,
  } = scheduleData;
  const { content_provider, content_type } = course;
  const { name, base_url } = content_provider;
  const { display_name } = instructor;
  const { isMobile } = useViewport();
  const receiptId = order.transaction?.id;

  const getCourseFormat = () => {
    const { is_online, is_onsite } = course;
    if (is_online && !is_onsite) return 'Online';
    if (!is_online && is_onsite) return 'Onsite';
    if (is_online && is_onsite) return 'Online/Onsite';
    return null;
  };

  const getShceduleTime = () => {
    const formattedStart = formatTimeDate(
      schedule.start_time,
      splitSign,
      twelveHoursFormat
    ).toLowerCase();
    const formattedEnd = formatTimeDate(
      schedule.end_time,
      splitSign,
      twelveHoursFormat
    ).toLowerCase();
    return `${formattedStart} - ${formattedEnd}`;
  };

  const onReceiptButtonClick = () => {
    openModal(
      getModal({
        name: 'receipt',
        data: { receiptId },
      })
    );
  };

  const onDirectionsButtonClick = () => {
    openModal(
      getModal({
        name: 'directions',
        data: { id, special_notes_and_directions },
      })
    );
  };


  return (
    <div className="account-schedule-card">
      <div className="ftable">
        <div className="ftable__row account-schedule-card__heading">
          <div className="ftable__cell">
            <div className="ftable__cell-content">
              <span className="account-schedule-card__offering-title">
                <Link
                  href={`${base_url}/offerings/${course.id}`}
                >
                  {course.title}
                  <Icon type="proceed-next" />
                </Link>
              </span>
            </div>

            <div className="ftable__cell-content account-schedule-card__offering-instructor">
              <Link href={`${globalWidgetLink}/faculty/${instructor.id}`}>
                {display_name}
              </Link>
            </div>

            <div className="ftable__cell-content account-schedule-card__provider">
              <span className="account-schedule-card__provider-label">Presented by:</span>
              <Link href={base_url}>{name}</Link>
            </div>
          </div>
        </div>

        <div className="ftable__row account-schedule-card__body">
          <div className="ftable__cell">
            <div className="ftable__cell-content account-schedule-card__label">
              Date
            </div>

            <div className="ftable__cell-content account-schedule-card__value">
              {format(zonedDate(class_at), userTicketsDateFormat)}
            </div>
          </div>

          <div className="ftable__cell">
            <div className="ftable__cell-content account-schedule-card__label">
              Time
            </div>

            <div className="ftable__cell-content account-schedule-card__value">
              {getShceduleTime()} EST
            </div>
          </div>

          <div className="ftable__cell">
            <div className="ftable__cell-content account-schedule-card__label">
              Faculty's Phone
            </div>

            <div className="ftable__cell-content account-schedule-card__value">
              {instructor.phone ||
              instructor.home_phone ||
              instructor.cell_phone ? (
                <>
                  <div>{getFormattedPhone(instructor.phone)}</div>
                  <div>{getFormattedPhone(instructor.home_phone)}</div>
                  <div>{getFormattedPhone(instructor.cell_phone)}</div>
                </>
              ) : (
                '–'
              )}
            </div>
          </div>

          <div className="ftable__cell">
            <div className="ftable__cell-content account-schedule-card__label">
              Support Phone
            </div>

            <div className="ftable__cell-content account-schedule-card__value">
              {getFormattedPhone('+16176421444')}
            </div>
          </div>

          <div className="ftable__cell">
            <div className="ftable__cell-content account-schedule-card__label">
              Content Type
            </div>

            <div className="ftable__cell-content account-schedule-card__value">
              {content_type.name}
            </div>
          </div>

          <div className="ftable__cell">
            <div className="ftable__cell-content account-schedule-card__label">
              Format
            </div>

            <div className="ftable__cell-content account-schedule-card__value">
              {getCourseFormat()}
            </div>
          </div>

          <div className="ftable__cell">
            <div className="ftable__cell-content account-schedule-card__label">
              Studio
            </div>

            <div className="ftable__cell-content account-schedule-card__value">
              {schedule.studio.name}
            </div>
          </div>

          {course.is_onsite && (
            <div className="ftable__cell">
              <div className="ftable__cell-content account-schedule-card__label">
                Location
              </div>

              <div className="ftable__cell-content account-schedule-card__value">
                {location.name} – {location.address.address_1},{' '}
                {location.address.city}
              </div>
            </div>
          )}
        </div>

        <div className="ftable__row account-schedule-card__footer">
          <div className="ftable__cell">
            <div className="ftable__cell-content">
              <div className="account-schedule-card__footer-buttons">
                {special_notes_and_directions && (
                  <Button
                    onClick={onDirectionsButtonClick}
                    className="account-schedule-card__button"
                  >
                    {!isMobile ? 'Special Directions' : 'Directions'}
                  </Button>
                )}

                {receiptId && (
                  <Button
                    onClick={onReceiptButtonClick}
                    className="account-schedule-card__button"
                  >
                    Receipt
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
