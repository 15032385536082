import { format } from 'date-fns';
import { dateFormatRequest } from '@src/constants';
import { zonedDate } from '@src/utils';

export const normalizeScaleRegData = ({
  selectedSchedules,
  scaleId,
  donation,
}) => {
  const regData = {
    sliding_scale_id: scaleId,
    appointments: selectedSchedules.map(({ date, value }) => ({
      date: format(zonedDate(date), dateFormatRequest),
      schedule_id: value.schedule_id,
    })),
  };

  if (donation?.hasDonation) {
    regData.donation = donation;
  }

  return regData;
};
