import { tableRoot } from '@src/constants';

export const getDailyTimeRangeArray = (start = 9, end = 24, step = 15) => {
  let timeArray = [tableRoot];

  for (let hour = start; hour <= end; hour++) {
    for (let minutes = 0; minutes <= 60; minutes += step) {
      if (hour === end && minutes !== 0) break;
      if (minutes === 60) continue;

      const minutesString =
        minutes.toString().length === 1 ? `0${minutes}` : minutes;

      const hourString = hour.toString().length === 1 ? `0${hour}` : hour;

      timeArray.push(
        `${parseFloat(hourString) === 24 ? '00' : hourString}:${minutesString}`
      );
    }
  }

  return timeArray;
};
