import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useErrors } from '@src/hooks';
import { Tabs, Button, Icon, ErrorMessage } from '@components/shared';
import { classNames, setActiveTab, getActiveTab } from '@src/utils';

export const RegistrationType = ({
  btnCallbacks,
  tabs,
  isAuthorized,
  setRegStep,
  regType,
}) => {
  const [regTabs, setRegTabs] = useState(tabs);
  const { errors, setErrors } = useErrors();

  const activeTab = getActiveTab(regTabs);

  const onTabClick = (tab) => {
    setErrors({});
    setActiveTab(setRegTabs, tab.value);
    if (isAuthorized) {
      btnCallbacks.submit(tab.value);
    }
  };

  useEffect(() => {
    if (regType) {
      setActiveTab(setRegTabs, regType);
    }
    if (regTabs.length === 1 && !activeTab) {
      setActiveTab(setRegTabs, regTabs[0].value);
    }
  }, []);

  const onProceedClick = () => {
    if (isAuthorized) {
      activeTab
        ? btnCallbacks.submit(activeTab?.value)
        : setErrors({ selectType: true });
    } else {
      activeTab ? btnCallbacks.submit(activeTab?.value) : setRegStep(2);
    }
  };

  return (
    <div className="registration-step registration-step--1">
      <div className="registration-step__instruction">
        {isAuthorized ? (
          <span> Please choose your desired registration type </span>
        ) : (
          <span>
            Please{' '}
            <Button type="link" onClick={onProceedClick}>
              Log In or Sign Up
            </Button>
            , in order to choose your desired registration type.
          </span>
        )}
      </div>
      <div
        className={classNames([
          'registration-step__tabs',
          tabs.length > 3 && 'registration-step__tabs--two-rows',
        ])}
      >
        <Tabs
          tabsData={regTabs}
          onTabClick={(tab) => onTabClick(tab)}
          isActiveTabClickable
        />
      </div>
      <div className="btn__wrapper">
        <ErrorMessage
          text="Please select a registration type to proceed"
          show={errors.selectType}
        />
        <Button
          htmlType="button"
          onClick={btnCallbacks.back}
          type="transparent"
          iconLeft={<Icon type="chevron" />}
        >
          Back
        </Button>
        <Button
          htmlType="button"
          onClick={() => onProceedClick()}
          type="primary"
        >
          Proceed
        </Button>
      </div>
    </div>
  );
};

RegistrationType.propTypes = {
  handleClassOption: PropTypes.func,
  sessionInfo: PropTypes.object,
  btnCallbacks: PropTypes.object,
};
RegistrationType.defaultProps = {
  handleClassOption: () => {},
  sessionInfo: {},
  btnCallbacks: {},
};