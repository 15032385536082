import React from 'react';
import { useForm, parseOnlyAmount, useDidUpdate } from '@src/hooks';
import { useSelector, useDispatch } from 'react-redux';
import { queryParam } from '@src/utils';
import {
  Icon,
  Input,
  Checkbox,
  Tooltip,
  Button,
  getModal,
  openModal,
} from '@components/shared';
import { getDonationOrderData } from '@actions/registrationActions';

export const DonationForm = ({ setDonationStep, setDonationData }) => {
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const providerData = useSelector((state) => state.provider.providerData.data);
  const isGlobalWidget = useSelector((state) => state.provider.isGlobal);
  const dispatch = useDispatch();

  const schema = {
    donationAmount: {
      parse: parseOnlyAmount,
      validation: ['isRequired', 'donation'],
    },
  };

  const initialValues = {
    donationAmount: queryParam.get('donationAmount') || '',
    isAnonymous: !!queryParam.get('isAnonymous') || false,
  };

  const submitHandler = () => {
    const data = {
      amount: values.donationAmount,
      is_anonymous: values.isAnonymous,
    };
    setDonationData(data);
    dispatch(getDonationOrderData(data));
    setDonationStep(2);
  };

  const { values, errors, handleChange, handleBlur, handleSubmit } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });

  useDidUpdate(() => {
    values.donationAmount
      ? queryParam.set('donationAmount', values.donationAmount)
      : queryParam.delete('donationAmount');
  }, [values.donationAmount]);

  useDidUpdate(() => {
    values.isAnonymous
      ? queryParam.set('isAnonymous', true)
      : queryParam.delete('isAnonymous');
  }, [values.isAnonymous]);

  const openAuth = () => {
    const currentUrl = window.location.href;

    openModal(
      getModal({
        name: 'authModal',
        data: {
          proceedLink: currentUrl,
        },
      })
    );
  };

  return (
    <form className="donation" onSubmit={handleSubmit}>
      <Icon type="donation-banner" className="donation__banner" />
      <h1 className="donation__title">Make a Donation</h1>
      <span className="donation__subtitle">
        Contributions help keep artists working, and make our non-profit
        community programming possible
      </span>
      <Input
        label="Donation Amount"
        value={values.donationAmount ? `$${values.donationAmount}` : ''}
        placeholder="Please enter the amount"
        onChange={handleChange}
        onBlur={handleBlur}
        alert={errors.donationAmount}
        name="donationAmount"
        className="donation__amount"
      />
      <div className="donation__anonymous">
        <Checkbox
          onChange={handleChange}
          value={values.isAnonymous}
          label="I prefer to remain anonymous"
          name="isAnonymous"
        />
        <Tooltip position="bottom">
          Your name will be added to our list of supporters unless you specify
          to remain anonymous. The name and contact info of anonymous donors
          will only be retained for the purpose of providing a donation thank
          you letter, and in case of large donations, per federal requirements.
        </Tooltip>
      </div>
      {!isAuthorized && (
        <div className="donation__auth">
          Please{' '}
          <Button onClick={() => openAuth()} htmlType="button">
            Log In or Create Account
          </Button>
          , in order to donate
        </div>
      )}
      <div className="donation__note">
        {isGlobalWidget ? (
          'Brookline/Cambridge Community Center for the Arts, Inc. (BCCA / CCCA), is a non-profit, tax-exempt 501(c)(3) public charitable organization. Tax ID number: 05-0548309'
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: providerData.config?.donation_information,
            }}
          ></div>
        )}
      </div>
      <div className="donation__actions">
        {!isAuthorized ? (
          <Button
            className="donation__proceed-button"
            type="primary"
            htmlType="button"
            onClick={() => openAuth()}
          >
            Proceed
          </Button>
        ) : (
          <Button
            className="donation__proceed-button"
            type="primary"
            htmlType="submit"
          >
            Proceed
          </Button>
        )}
      </div>
    </form>
  );
};
