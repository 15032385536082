import { basicStore } from '@src/store';

const confirmationModalStore = basicStore({});

confirmationModalStore.openModal = function(modalObject) {
  this.data = { ...modalObject, visible: true };
};

confirmationModalStore.closeModal = function() {
  this.data = this.data ? { ...this.data, visible: false } : null;
};

const requestConfirm = confirmationModalStore.openModal.bind(
  confirmationModalStore
);
const cancelConfirm = confirmationModalStore.closeModal.bind(
  confirmationModalStore
);

export { requestConfirm, cancelConfirm, confirmationModalStore };
