import { addDays, endOfWeek, startOfWeek } from 'date-fns';

export function getWeekDays(weekStart) {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(addDays(weekStart, i));
  }
  return days;
}

export function getWeekRange(date) {
  return {
    from: startOfWeek(date),
    to: endOfWeek(date),
  };
}