import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFilters,
  setScheduleActiveTab,
  setScheduleSearch,
} from '@actions/scheduleActions';
import { setActiveTab, createTab, debounce, queryParam } from '@src/utils';
import { useViewport, useDidUpdate } from '@src/hooks';
import { Container } from '@components/layout';
import { Tabs, Input, Icon, SEO } from '@components/shared';
import {
  Day,
  Week,
  Categories,
  Instructors,
} from '@components/features/Schedule';

const Schedule = () => {
  const activeTab = useSelector((state) => state.schedule.activeTab);
  const privderData = useSelector((state) => state.provider.providerData.data);
  const scheduleSearch = useSelector(
    (state) => state.schedule.filtersSelect.search
  );
  const { isTablet } = useViewport();
  const [search, setSearch] = useState(scheduleSearch);

  const dispatch = useDispatch();

  const [navTabs, setNavTabs] = useState([
    createTab('Week', 'week'),
    createTab('Day', 'day'),
    createTab('Faculty', 'faculty'),
    createTab('Category', 'category'),
  ]);

  useEffect(() => {
    setActiveTab(setNavTabs, activeTab);
    dispatch(getFilters());
  }, []);

  useEffect(() => {
    queryParam.set('view', activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (scheduleSearch === '' && search) {
      setSearch('');
    }
  }, [scheduleSearch]);

  const setDebouncedSearch = useCallback(
    debounce((search) => {
      dispatch(setScheduleSearch(search));
    }, 500),
    []
  );

  useDidUpdate(() => {
    setDebouncedSearch(search);
  }, [search]);

  const onScheduleTabClick = ({ value }) => {
    setActiveTab(setNavTabs, value);
    dispatch(setScheduleActiveTab(value));
  };

  const renderSearch = () => {
    return (
      <Input
        className="schedule__search-block"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search"
        icon={<Icon type="search" />}
      />
    );
  };

  const view = {
    day: <Day />,
    week: <Week />,
    faculty: <Instructors />,
    category: <Categories />,
  };

  return (
    <Container>
      {privderData.short_name && (
        <SEO
          title={`${privderData.short_name} | Schedule`}
          description={`Explore our schedule of classes, rehearsals, and events. Find the perfect activity at ${privderData.name}. Browse schedules with convenient search and filters.`}
          canonicalURL={`${privderData.base_url}/schedule`}
          image={privderData.logo}
        />
      )}
      <div className="global-page schedule">
        <div className="schedule__title-block">
          <h1 className="page-title">Schedule</h1>

          {!isTablet && renderSearch()}
        </div>

        <Tabs
          tabsData={navTabs}
          label="display by:"
          className="tabs--bordered schedule__tabs"
          onTabClick={(tab) => {
            onScheduleTabClick(tab);
          }}
        />
        {isTablet && renderSearch()}
        {view[activeTab]}
      </div>
    </Container>
  );
};

export default Schedule;