import { format } from 'date-fns';
import { formatTimeDate } from '@src/utils';
import {
  shortWeekdaysObject,
  twelveHoursFormat,
  splitSign,
  checkoutDateFormat,
  checkoutShortDateFormat,
} from '@src/constants';

const getCheckoutTimePeriod = ({ start_time, end_time }) => {
  const formattedStart = formatTimeDate(
    start_time,
    splitSign,
    twelveHoursFormat
  ).toLowerCase();

  const formattedEnd = formatTimeDate(
    end_time,
    splitSign,
    twelveHoursFormat
  ).toLowerCase();

  return `${formattedStart} - ${formattedEnd} EST`;
};

const getDropinAppointments = (appointments) =>
  appointments.map(
    ({ class_date, schedule, final_price, discount, increase }, index) => ({
      date: format(new Date(class_date), checkoutDateFormat),
      time: getCheckoutTimePeriod(schedule),
      price: final_price,
      discount,
      increase,
      number: index + 1,
    })
  );

const getBlockTimeSchedules = (appointments) => {
  const schedulesSet = new Set();

  appointments.forEach(({ schedule }) => {
    const { day_num, start_time, end_time } = schedule;

    const weekDay = shortWeekdaysObject[day_num];

    const formattedStart = formatTimeDate(
      start_time,
      splitSign,
      twelveHoursFormat
    ).toLowerCase();

    const formattedEnd = formatTimeDate(
      end_time,
      splitSign,
      twelveHoursFormat
    ).toLowerCase();

    schedulesSet.add(`${formattedStart} - ${formattedEnd} EST - ${weekDay}`);
  });

  return Array.from(schedulesSet);
};

const getBlockAppointments = (appointments, subtotal, contentType) => {
  const startDate = appointments[0].class_date;
  const appointmenstQuantity = appointments.length;
  const endDate = appointments[appointmenstQuantity - 1].class_date;
  const formattedStartDate = format(
    new Date(startDate),
    checkoutShortDateFormat
  );
  const formattedEndDate = format(new Date(endDate), checkoutShortDateFormat);
  const { plural_short_name, singular_short_name } = contentType;
  const contentTypeName =
    appointmenstQuantity > 1 ? plural_short_name : singular_short_name;
  return [
    {
      title: `Block of consecutive ${contentTypeName} (${appointmenstQuantity} ${contentTypeName})`,
      date: `${formattedStartDate} - ${formattedEndDate}`,
      time: getBlockTimeSchedules(appointments),
      price: subtotal,
    },
  ];
};

const getVideosItems = (videos) =>
  videos.map(({ title, price }, index) => ({
    title,
    price,
    number: index + 1,
  }));

const getTicketsItems = (tickets) =>
  tickets.map(({ type, price, paid_count, schedules }, index) => {
    const dates = schedules.map(({ date }) =>
      format(new Date(date), checkoutDateFormat)
    );
    const times = schedules.map((schedule) => getCheckoutTimePeriod(schedule));

    return {
      title: type,
      price,
      quantity: paid_count,
      time: times,
      date: dates,
    };
  });

export const normalizeCheckoutData = (orderData) => {
  const { registration, totals, type, videos, tickets } = orderData;
  const { subtotal, increase, discount, donation, total } = totals;

  if (type === 'registration') {
    const { appointments, course } = registration;
    if (
      registration.type === 'drop_in' ||
      registration.type === 'sliding_scale' ||
      registration.type === 'free' ||
      registration.type === 'trial'
    ) {
      return {
        items: getDropinAppointments(appointments),
        subtotal,
        increase,
        discount,
        donation,
        total,
      };
    } else if (
      registration.type === 'block' ||
      registration.type === 'pre_reg'
    ) {
      return {
        items: getBlockAppointments(
          appointments,
          subtotal,
          course.content_type
        ),
        subtotal,
        increase,
        discount,
        donation,
        total,
      };
    } else {
      return {};
    }
  } else if (type === 'video_purchase') {
    return {
      items: getVideosItems(videos),
      subtotal,
      increase,
      discount,
      donation,
      total,
    };
  } else if (type === 'ticket') {
    return {
      items: getTicketsItems(tickets),
      subtotal,
      increase,
      discount,
      donation,
      total,
    };
  } else if (type === 'donation') {
    return {
      donation,
      total,
    };
  } else {
    return {};
  }
};

