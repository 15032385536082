import React, { useEffect, useState } from 'react';
import { updateUserData } from '@actions/authActions';
import { useForm, useDeepEffect, useApi } from '@src/hooks';
import { countriesWithStates } from '@src/constants';
import { normalizeUserData } from '@src/normalizers';
import { Select, Input } from '@components/shared';
import { AccountProfileCard } from '../../Supports/AccountProfileCard';
import { useDispatch } from 'react-redux';

export const SnailAddress = ({
  userData,
  editorState,
  setEditorState,
}) => {
  const [showStates, setShowStates] = useState(0);
  const countries = useApi({ url: '/countries', dataPlaceholder: [] });

  const { contact } = userData.data || {};
  const { address } = contact || {};
  const { country, address_1, state, city, zip } = address || {};
  const dispatch = useDispatch();
  const submitHandler = (values) => {
    const userData = normalizeUserData(values, editorState);
    dispatch(updateUserData(userData));
  };

  useDeepEffect(() => {
    if (userData.data) {
      setValues(initialValues);
    }
  }, [userData]);

  const initialValues = {
    address: address_1,
    country: country?.id,
    city: city,
    state: state?.id,
    zip: zip,
  };

  

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setCustomValue,
    setErrors,
    clearValues,
    setValues,
  } = useForm({
    callback: submitHandler,
    initialValues,
  });

  const states = useApi(
    {
      url: `/countries/${values.country}/states`,
      dataPlaceholder: [],
    },
    [values.country],
    !!values.country
  );

  useEffect(() => {
    if (countries.data.length && values.country) {
      checkStates(values.country, countries.data);
    }
  }, [values.country, countries.data.length]);

  const checkStates = (countryId, countries) => {
    const selectedCountry = countries.find(
      (country) => country.id === countryId
    );
    const countryWithStates = countriesWithStates.includes(
      selectedCountry?.name
    );
    if (!countryWithStates) {
      setCustomValue('state', '');
    }
    setShowStates(countryWithStates);
  };

  const snailSubscriptionBlockData = [
    {
      label: 'Address',
      inputType: Input,
      inputName: 'address',
      placeholder: 'Enter your Address',
    },
    {
      label: 'Country',
      inputType: Select,
      inputName: 'country',
      placeholder: 'Select Country',
      additionalProps: {
        options: countries.data,
        onChange: (val) => setCustomValue('country', val.id),
        optionsLabel: 'name',
        optionsValue: 'id',
        optionsKey: 'id',
        searchable: true,
        enableClear: true,
      },
    },
    {
      label: 'City',
      inputType: Input,
      inputName: 'city',
      placeholder: 'Enter your City',
    },
    {
      label: 'State',
      inputType: Select,
      inputName: 'state',
      placeholder:
        (editorState !== 'snailMailAddress' && !values.state) ||
        !showStates ||
        !values.country
          ? '–'
          : 'Select State',
      additionalProps: {
        options: states.data,
        onChange: (val) => setCustomValue('state', val),
        optionsLabel: 'name',
        optionsValue: 'id',
        optionsKey: 'id',
        disabled:
          editorState !== 'snailMailAddress' || !showStates || !values.country,
        searchable: true,
        enableClear: true,
      },
    },
    {
      label: 'Zip Code',
      inputType: Input,
      inputName: 'zip',
      placeholder: 'Enter your Zip Code',
    },
  ];

  const profileBlocksData = [
    {
      title: 'Snail Mail Address',
      data: snailSubscriptionBlockData,
      name: 'snailMailAddress',
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      userData,
      editorState,
      setEditorState,
      clearValues,
      setErrors,
    },
  ];

  return (
    <>{profileBlocksData.map((blockData) => AccountProfileCard(blockData))}</>
  );
};
