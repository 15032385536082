import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Icon,
  SingleDateSchedulePicker,
  RegistrationPriceBlock,
  RegistrationDonationBlock,
} from '@components/shared';
import { debounce } from '@src/utils';
import { normalizeDropinRegData } from '@src/normalizers';
import { useDispatch, useSelector } from 'react-redux';
import { parseOnlyAmount, useForm } from '@src/hooks';
import { getDropinSlots, getDropinOrderData } from '@actions/registrationActions';

export const DropIn = ({ sessionInfo, btnCallbacks, contentType, isFree }) => {
  const slots = useSelector((state) => state.registration.regSlots);
  const orderData = useSelector((state) => state.registration.orderData);
  const price = orderData.data?.totals?.total
  const [scheduleErrors, setScheduleErrors] = useState([]);
  const [daysData, setDaysData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDropinSlots(sessionInfo.id, isFree));
  }, []);

  const submitHandler = (values) => {
    const dropinRegData = {
      selectedSchedules,
      sessionId: sessionInfo.id,
      donation: {
        is_anonymous: values.isAnonymous,
        amount: values.donationAmount,
        hasDonation: values.hasDonation,
      },
    };
    dispatch(getDropinOrderData(dropinRegData))
    return btnCallbacks.submit(normalizeDropinRegData(dropinRegData));
  };

  const validateTimeslots = () => {
    const selectedDates = selectedSchedules.map((day) => day.date);
    const failedSchedules = daysData.filter(
      (day) => !selectedDates.includes(day.date)
    );
    setScheduleErrors(failedSchedules.map(({ date }) => date));

    return !failedSchedules.length;
  };

  const schema = {
    donationAmount: {
      parse: parseOnlyAmount,
      validation: ['isRequired', 'donation'],
      valuesRequired: ['hasDonation'],
    },
    selectedDays: {
      validation: ['isLengthRequired'],
    },
    selectedSchedules: {
      customValidation: [
        {
          check: () => validateTimeslots(),
          message: 'Select a Timeslot to proceed',
        },
      ],
    },
  };

  const initialValues = {
    hasDonation: false,
    donationAmount: null,
    isAnonymous: false,
    selectedDays: [],
    selectedSchedules: [],
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setCustomValue,
    setErrors,
    handleSubmit,
  } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });

  const { selectedSchedules } = values;

  useEffect(() => {
    if (scheduleErrors.length) {
      const selectedDates = selectedSchedules.map((day) => day.date);
      setScheduleErrors((errors) =>
        errors.filter((error) => !selectedDates.includes(error))
      );
    }
    if (selectedSchedules.length && !isFree) {
      const dropPriceReqData = {
        selectedSchedules,
        sessionId: sessionInfo.id,
      };
      getOrderData(dropPriceReqData);
    }
  }, [selectedSchedules]);

  const getOrderData = useCallback(
    debounce((data) => {
      dispatch(getDropinOrderData(data));
    }, 500),
    []
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="registration-step__group">
        <h4 className="registration-step__title">
          {isFree
            ? `Please choose as many free ${contentType.plural_short_name} as you want`
            : `Please choose the dates of the ${contentType.plural_short_name} you would like to drop in to`}
        </h4>
      </div>
      <SingleDateSchedulePicker
        slots={slots}
        errors={errors}
        daysData={daysData}
        setDaysData={setDaysData}
        selectedSchedules={selectedSchedules}
        contentType={contentType}
        setCustomValue={setCustomValue}
        selectedDays={values.selectedDays}
        setErrors={setErrors}
        scheduleErrors={scheduleErrors}
      />
      <div className="registration-step__group registration-step__group--price">
        <RegistrationDonationBlock
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <div className="registration-step__payment-group">
          <RegistrationPriceBlock
            price={!!selectedSchedules.length && price}
            rate={!isFree && sessionInfo.drop_in_registration.rate}
            contentType={contentType}
            loading={orderData.loading}
            values={values}
          />
        </div>
      </div>
      <div className="btn__wrapper">
        <Button
          htmlType="button"
          onClick={btnCallbacks.back}
          type="transparent"
          iconLeft={<Icon type="chevron" />}
        >
          Back
        </Button>
        <Button
          htmlType="submit"
          type="primary"
        >
          Proceed
        </Button>
      </div>
    </form>
  );
};
