export const getOptions = (options) => {
    const optionsNames = options
      .filter((option) => option)
      .map((option) => option.name);

    if (optionsNames.length === 1) {
      return optionsNames;
    } else if (optionsNames.length === 2) {
      return optionsNames.join(' & ');
    } else if (optionsNames.length > 2) {
      const lastOptionName = optionsNames.slice(-1);
      optionsNames[optionsNames.length - 1] = `& ${lastOptionName}`;
      return optionsNames.join(', ');
    }
  };