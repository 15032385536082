import React from 'react';
import { updateUserData } from '@actions/authActions';
import { useForm } from '@src/hooks';
import { getProfileSchema } from '@src/utils';
import { normalizeUserData } from '@src/normalizers';
import { Button, Checkbox, Tooltip, Link } from '@components/shared';
import { AccountProfileCard } from '../../Supports/AccountProfileCard';
import { useDispatch } from 'react-redux';

export const EmailSubcription = ({
  institution,
  editorState,
  setEditorState,
  updateProviderAffiliation,
}) => {
  const dispatch = useDispatch();

  const {
    receive_email_newsletters,
    content_provider,
    content_provider_id,
    isGlobal,
  } = institution || {};
  const { config, base_url } = content_provider || {};
  const { support_email, support_phone_number } = config || {};
  const blockName = `emailSubscription-${content_provider_id}`;
  const isEmailSubcription = editorState === blockName;
  const submitHandler = (values) => {
    const userData = normalizeUserData(values, 'emailSubscription');
    isGlobal
      ? dispatch(updateUserData(userData))
      : updateProviderAffiliation(userData.student, content_provider_id);
  };

  const schema = getProfileSchema(editorState);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setErrors,
    clearValues,
  } = useForm({
    callback: submitHandler,
    schema,
  });

  const renderSupportTooltip = () => (
    <Tooltip position="bottom">
      <span>Also you can directly email</span>
      <a href={`mailto:${support_email}`}>{support_email}</a>
      <span>or call {support_phone_number}</span>
    </Tooltip>
  );

  const renderSubcriptionSuggestion = (isSubscriber) => {
    if (!editorState) {
      return isSubscriber ? (
        <span className="account-profile__subcription-note">
          If you want to unsubscribe please{' '}
          <span className="account-profile__subcription-support">
            <Link href={`${base_url}/contact`}>contact Support</Link>
            {renderSupportTooltip()}
          </span>
        </span>
      ) : (
        <span className="account-profile__subcription-note">
          If you want to subscribe please{' '}
          <Button onClick={() => setEditorState(blockName)} type="link">
            click here
          </Button>
        </span>
      );
    }
  };

  const emailSubscriptionBlockData = [
    {
      label: 'I want to receive Email Newsletters',
      inputType: Checkbox,
      inputName: 'emailSubscription',
      hide: !isEmailSubcription,
    },
  ];

  const profileBlocksData = {
    title: 'Email Newsletters',
    data: emailSubscriptionBlockData,
    infoText: receive_email_newsletters
      ? 'You’re already subscribed to our Email Newsletters.'
      : 'You are not subscribed to our Email Newsletters.',
    subscription: renderSubcriptionSuggestion(receive_email_newsletters),
    name: blockName,
    editRestricted: true,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    editorState,
    setEditorState,
    clearValues,
    setErrors,
  };

  return <>{AccountProfileCard(profileBlocksData)}</>;
};
