import React from 'react';
import { Loader } from '@components/shared';
import { formatPrice, classNames } from '@src/utils';
import { useViewport } from '@src/hooks';

export const RegistrationPriceBlock = ({
  price,
  rate,
  contentType,
  loading,
  values,
}) => {
  const { hasDonation, donationAmount } = values;
  const { isMobile } = useViewport();

  const isCompactView = isMobile && hasDonation;

  const getTotalPrice = () => {
    const totalAmount = (+values.donationAmount || 0) + (price || 0);
    return formatPrice(totalAmount);
  };

  const renderFullView = () => (
    <>
      <span className="registration-price__title">Total</span>
      <div className="registration-price__amount">
        {hasDonation && <span>${getTotalPrice()} =&nbsp;</span>}
        <span>${formatPrice(price)}</span>
        {rate && (
          <>
            <span className="registration-price__separator">/</span>
            <div className="registration-price__rate">
              ${formatPrice(rate)}
              <span> per {contentType.singular_short_name}</span>
            </div>
          </>
        )}
        {hasDonation && (
          <span className="registration-price__donation">
            <b>&nbsp;+ ${formatPrice(+values.donationAmount)}</b> Donation
          </span>
        )}
      </div>
    </>
  );

  const renderСompactView = () => (
    <>
      <span className="registration-price__title">
        Total: ${getTotalPrice()}
      </span>
      <div className="registration-price__amount">
        <span>${formatPrice(price)}</span>
        {rate && (
          <>
            <span className="registration-price__separator">/</span>
            <div className="registration-price__rate">
              ${formatPrice(rate)}
              <span> per {contentType.singular_short_name}</span>
            </div>
          </>
        )}
        {hasDonation && (
          <div className="registration-price__donation">
            <span>
              <b>+</b>
            </span>
            <span>
              <b>${formatPrice(+donationAmount)}</b> Donation
            </span>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div
      className={classNames([
        'registration-price',
        isCompactView && 'registration-price--compact',
      ])}
    >
      <Loader show={loading} attached />
      {isCompactView ? renderСompactView() : renderFullView()}
    </div>
  );
};
