import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useViewport, useLazyLoaded } from '@src/hooks';
import { queryParam } from '@src/utils';
import { getCourses } from '@actions/programsActions';
import { Container } from '@components/layout';
import { SlideMenu, SEO } from '@components/shared';
import {
  ProgramsCourses,
  ProgramsFilter,
  ProgramsHeader,
  ProgramsSortingMenu,
} from '@components/features/Programs';
import { ProviderBanner } from '@components/provider';

const Programs = () => {
  const isGlobalWidget = useSelector((state) => state.provider.isGlobal);
  const providerData = useSelector((state) => state.provider.providerData.data);
  const banner = providerData.config?.offerings_page_banner;
  const tab = useSelector((state) => state.programs.tab);
  const filtersSelect = useSelector((state) => state.programs.filtersSelect);
  const courses = useSelector((state) => state.programs.courses);
  const sortingBy = useSelector((state) => state.programs.sortingBy);
  const sortingOrder = useSelector((state) => state.programs.sortingOrder);
  const { isTablet } = useViewport();
  const [filtersMenuOpen, setFiltersMenuOpen] = useState(false);
  const [sortingMenuOpen, setSortingMenuOpen] = useState(false);
  const [search, setSearch] = useState(queryParam.get('search') || '');

  const coursesRequestData = {
    filtersSelect,
    tab,
    sortingOrder,
    sortingBy,
  };

  const { page } = courses;

  const dispatch = useDispatch();

  const programsBody = useRef(null);

  const loadMore = () => {
    dispatch(getCourses(coursesRequestData, search, page, true));
  };

  useLazyLoaded(programsBody.current, courses, loadMore);

  return (
    <div className="global-page programs">
      {providerData.short_name && (
        <SEO
          title={`${providerData.short_name} | Offerings`}
          description={`Discover diverse offerings at ${providerData.name}. Browse classes, workshops, and events with convenient search and filters.`}
          canonicalURL={`${providerData.base_url}/offerings`}
          image={providerData.logo}
        />
      )}
      {!isGlobalWidget && !!banner && (
        <ProviderBanner className="programs-banner" banner={banner} />
      )}
      <Container>
        <div className="columns programs__columns">
          {!isTablet ? (
            <div className="columns__item programs__filter-column">
              <h1 className="page-title">Offerings</h1>
              <ProgramsFilter />
            </div>
          ) : (
            <SlideMenu
              isShown={filtersMenuOpen}
              className={'mobile-filters'}
              onCloseClick={() => setFiltersMenuOpen(false)}
            >
              <ProgramsFilter />
            </SlideMenu>
          )}

          <div
            ref={programsBody}
            className="columns__item programs__courses-column"
          >
            {isTablet && <h1 className="page-title">Offerings</h1>}
            <ProgramsHeader
              onFiltersMenuButtonClick={() => setFiltersMenuOpen(true)}
              search={search}
              setSearch={setSearch}
              onSortingMenuButtonClick={() => setSortingMenuOpen(true)}
            />
            <ProgramsCourses />
            {isTablet && (
              <SlideMenu
                isShown={sortingMenuOpen}
                className={'mobile-filters'}
                onCloseClick={() => setSortingMenuOpen(false)}
                position="left"
              >
                <ProgramsSortingMenu />
              </SlideMenu>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Programs;