import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@components/shared';
import { globalWidgetLink } from '@src/constants';

export const CourseParticipantLevels = ({ levels, exception }) => {

  const addSeparator = (i, arr) => {
    if (i === arr.length - 2) {
      return <span className="options__separator">&nbsp;&#38;&nbsp;</span>;
    } else if (i !== arr.length - 1) {
      return <span className="options__separator">,&nbsp;</span>;
    } else if (i === arr.length - 1) {
      return <sup className="options__exception">{exception}</sup>;
    } else {
      return '';
    }
  };

  const renderParticipantLevels = (levels) => {
    return levels.map((level, i, arr) => (
      <span key={level.id} className="options__item">
        <Link
          href={`${globalWidgetLink}/offerings?participant_level=${level.id}`}
        >
          {level.name}
        </Link>
        {addSeparator(i, arr)}
      </span>
    ));
  };

  return <>{renderParticipantLevels(levels)}</>;
};

CourseParticipantLevels.propTypes = {
  course: PropTypes.object,
};

CourseParticipantLevels.defaultProps = {
  course: {},
};
