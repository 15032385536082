import React from 'react';
import { Container } from '@components/layout';
import {
  ProviderContactTitleBlock,
  ProviderContactMembers,
  ProviderContactFounders,
  ProviderContactForm,
} from '@components/provider/Contact';
import { useApi } from '@src/hooks';
import { Loader, SEO } from '@components/shared';
import { useSelector } from 'react-redux';

const ProviderContact = () => {
  const providerData = useSelector((state) => state.provider.providerData.data);
  const contactPageData = useApi({
    url: `/providers/current/pages/contact`,
    dataPlaceholder: {},
  });

  const { locations, contact_list, staff, contact_form } =
    contactPageData.data.content || {};

  const pageRender = {
    contact_form: (
      <ProviderContactForm
        formData={{
          ...contact_form,
          apiUrl: '/providers/current/pages/contact/form-submit',
        }}
        key="contact_form"
      />
    ),
    staff: <ProviderContactFounders founders={staff} key="staff"/>,
    locations: <ProviderContactTitleBlock locations={locations} key="locations"/>,
    contact_list: <ProviderContactMembers contacts={contact_list} key="contact_list"/>,
  };

  return (
    <div className="global-page provider-contact">
      {providerData.short_name && (
        <SEO
          title={`${providerData.short_name} | Contact`}
          description={`Find contact information for ${providerData.short_name}, including locations and contact persons. Get in touch with our team to learn more about our offerings.`}
          canonicalURL={`${providerData.base_url}/contact`}
          image={providerData.logo}
        />
      )}
      {contactPageData.loading ? (
        <Loader global />
      ) : (
        <div className="provider-contact__body">
          <Container>
            <h1 className='page-title'>Contact Us</h1>
            {contactPageData.data.content_order?.map(
              (block) => pageRender[block]
            )}
          </Container>
        </div>
      )}
    </div>
  );
};

export default ProviderContact;
