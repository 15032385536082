import React from 'react';
import { Button, Icon } from '../index';
import { format, addMonths, subMonths, isSameMonth } from 'date-fns';

export const DayPickerNavBar = ({ date, setMonth, allowedMonths }) => {
  const nextMonth = addMonths(new Date(date), 1);
  const prevMonth = subMonths(new Date(date), 1);

  const isAllowedMonth = (allowedMonths, specificMonth) => {
    return (
      !allowedMonths.length ||
      allowedMonths.some((month) =>
        isSameMonth(new Date(specificMonth), new Date(month))
      )
    );
  };

  return (
    <div className="DayPicker-Caption">
      <div className="DayPicker-Caption__navigation">
        <Button
          onClick={() => setMonth(prevMonth)}
          htmlType="button"
          disabled={!isAllowedMonth(allowedMonths, prevMonth)}
          className="DayPicker-Caption__navigation-btn DayPicker-Caption__navigation-btn--left DayPicker-Caption__navigation-btn--margin-right"
        >
          <Icon type="chevron" />
        </Button>

        <div className="DayPicker-Caption__date-preview">
          <Icon
            type="calendar"
            className="DayPicker-Caption__date-preview-icon"
          />
          <span className="DayPicker-Caption__date-preview-content">
            {format(date, 'MMMM yyyy')}
          </span>
        </div>
        <Button
          onClick={() => setMonth(nextMonth)}
          htmlType="button"
          disabled={!isAllowedMonth(allowedMonths, nextMonth)}
          className="DayPicker-Caption__navigation-btn DayPicker-Caption__navigation-btn--right DayPicker-Caption__navigation-btn--margin-left"
        >
          <Icon type="chevron" />
        </Button>
      </div>
    </div>
  );
};
