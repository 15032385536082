import React from 'react';
import { format } from 'date-fns';
import {
  dateSessionsFormat,
  twelveHoursFormat,
  weekdaysObject,
} from '@src/constants';
import {
  zonedDate,
  getDateTimes,
  classNames,
} from '@src/utils';

export const ProgramsCourseSessions = ({
  data
}) => {
  const {
    schedules,
    start_date,
    end_date,
  } = data;

  const startDate = format(zonedDate(start_date), dateSessionsFormat);
  const endDate = end_date
    ? format(zonedDate(end_date), dateSessionsFormat)
    : null;


  const dateRenderer =
    startDate === endDate ? (
      <>{startDate}</>
    ) : (
      <>
        {startDate}
        <span className="sessions__date-separator">&nbsp;-&nbsp;</span>
        {endDate}
      </>
    );

  const renderArray = schedules.map((schedule, i) => {
    const { start_time, end_time } = schedule;
    const { startTime, endTime } = getDateTimes(start_time, end_time);

    const dateTime = {
      week: weekdaysObject[schedule.day_num],
      startTime: format(startTime, twelveHoursFormat).toLowerCase(),
      endTime: format(endTime, twelveHoursFormat).toLowerCase(),
    };

    return {
      startTime,
      endTime,
      dateTime,
      date: i === 0 ? dateRenderer : '',
    };
  });

  return (
    <div className="sessions__item">
      <div className="ftable">
        {renderArray.map(
          ({ date, dateTime}, i) => (
            <div
              className={classNames([
                'ftable__row',
                i === 0 && 'ftable__row--first',
              ])}
              key={date + i}
            >
              <div className="ftable__cell sessions__date">
                <span className="ftable__cell-content">{date}</span>
              </div>
              <div className="ftable__cell sessions__date-time">
                <div key={dateTime} className="ftable__cell-content">
                  <span className="sessions__date-time-week">
                    {dateTime.week}
                  </span>
                  &nbsp;
                  <span className="sessions__date-time-content">
                    {dateTime.startTime} – {dateTime.endTime} EST
                  </span>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};