import React from 'react';
import { Container } from '@components/layout';
import { classNames } from '@src/utils';
import { useSelector } from 'react-redux';
import { useViewport } from '@src/hooks';
import {
  Button,
  Icon,
  Tabs,
  Tooltip,
  Link,
  BurgerButton,
  SlideMenu,
  Image,
} from '@components/shared';
import { globalWidgetLink } from '@src/constants';

export const ProviderHeader = ({
  onDonationButtonClick,
  isAuthorized,
  onLoginClick,
  onLogoutClick,
  isMobileNavOpen,
  onBurgerClick,
  closeMenu,
  tabsData,
  isActiveTabClickable,
}) => {
  const { isTablet } = useViewport();
  const providerData = useSelector((state) => state.provider.providerData.data);

  const renderAuthBlock = () => (
    <div className="header__nav header__nav--auth provider-header__auth-block">
      <ul className="list">
        {isTablet && (
          <li className="list__item">
            <Link
              href={providerData.config?.website}
              iconLeft={<Icon type="contact--site" size="md" />}
            >
              Our Website
            </Link>
          </li>
        )}
        <li className="list__item">
          <Tooltip
            position="bottom"
            className="header__donation-tooltip"
            body={
              <Button
                className="header__donation-btn"
                iconLeft={<Icon type="donate" />}
                onClick={onDonationButtonClick}
              >
                Donate
              </Button>
            }
          >
            Make a tax-deductible donation
          </Tooltip>
        </li>
        {!isAuthorized ? (
          <li className="list__item">
            <Button
              iconLeft={<Icon type="profile-tab" />}
              onClick={onLoginClick}
            >
              Log in &#38; Sign up
            </Button>
          </li>
        ) : (
          <>
            <li className="list__item">
              <Link
                href={`${globalWidgetLink}/account`}
                iconLeft={<Icon type="profile-tab" />}
              >
                My Account
              </Link>
            </li>
            <li className="list__item">
              <Button
                type="navigation"
                iconLeft={<Icon size="md" type="log-out" />}
                onClick={onLogoutClick}
              >
                Log out
              </Button>
            </li>
          </>
        )}
      </ul>
    </div>
  );

  const renderNavigation = (isMob) => {
    return (
      <>
        <div className="provider-header__tabs">
          <Tabs
            tabsData={tabsData}
            isShadowVisible={!isMob}
            isActiveTabClickable={isActiveTabClickable}
            isLinks={true}
          />
          {!isTablet && (
            <Link
              className="provider-header__site-link"
              href={providerData.config?.website}
              iconLeft={<Icon type="contact--site" size="md" />}
            >
              Our Website
            </Link>
          )}
        </div>
      </>
    );
  };

  return (
    <header
      className={classNames([
        'provider-header',
        isMobileNavOpen && 'provider-header--active',
      ])}
    >
      <Container>
        <div className="provider-header__body">
          <div className="provider-header__provider-logo">
            {providerData.logo && (
              <Link href={providerData.base_url}>
                <Image
                  src={providerData.logo}
                  alt="provider logo"
                  loaderSize="xs"
                />
              </Link>
            )}
          </div>

          <div className="provider-header__top">
            <div className="provider-header__wew-logo">
              <Link
                className="provider-header__wew-link"
                href={globalWidgetLink}
              >
                <span>powered by</span>
                <img src="/img/wew-logo.svg" alt="wew-logo" />
              </Link>
            </div>
            {!isTablet && renderAuthBlock()}
          </div>

          <div className="provider-header__bottom">
            {isTablet ? (
              <>
                <BurgerButton
                  onClick={onBurgerClick}
                  isActive={isMobileNavOpen}
                />

                <SlideMenu
                  isShown={isMobileNavOpen}
                  className={'mobile-nav'}
                  onCloseClick={closeMenu}
                >
                  {renderNavigation(true)}
                  {renderAuthBlock()}
                </SlideMenu>
              </>
            ) : (
              <>{renderNavigation(false)}</>
            )}
          </div>
        </div>
      </Container>
    </header>
  );
};
