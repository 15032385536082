import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@components/shared';

export const CourseMarketing = ({ socialLinks }) => {

  const renderMarketings = (links) => {
    return (
      links && links.map((link, index, arr) => (
        <span key={index} style={{display: "inline-flex"}}>
          <Link
            href={link.url}
          >
            {link.title}
          </Link>
          {index !== arr.length - 1 && <>&nbsp;|&nbsp;</>}
        </span>
      ))
    );
  };

  return (
    <>
      {socialLinks && renderMarketings(socialLinks)}
    </>
  );
};

CourseMarketing.propTypes = {
  course: PropTypes.array,
};

CourseMarketing.defaultProps = {
  course: [],
};