import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '@src/utils';
import { Icon } from '@components/shared';

export const Loader = ({ global, show, size, className, attached, sticky }) => {
  const SIZES = {
    default: '',
    xs: ' loader--xs',
    md: ' loader--md',
    lg: ' loader--lg',
    xl: ' loader--xl',
  };
  return show ? (
    <div
      className={classNames([
        'loader__wrapper',
        className,
        global && 'loader__wrapper--global',
        attached && 'loader__wrapper--attached',
        sticky && 'loader__wrapper--sticky',
      ])}
    >
      <Icon type="loader" className={`loader ${SIZES[size]}`} />
    </div>
  ) : null;
};

Loader.propTypes = {
  global: PropTypes.bool,
  show: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
};

Loader.defaultProps = {
  global: false,
  show: true,
  handleClick: () => {},
  size: 'default',
  className: '',
};
