import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@components/shared';
import { globalWidgetLink } from '@src/constants';

export const CourseAgeTypes = ({ ageTypes }) => {

  const addSeparator = (i, arr) => {
    if (i === arr.length - 2) {
      return <>&nbsp;&#38;&nbsp;</>;
    } else if (i !== arr.length - 1) {
      return <>,&nbsp;</>;
    } else {
      return '';
    }
  };

  const renderAgeTypes = (types) => {
    return types.map((type, i, arr) => (
      <span key={type.id} className="options__item">
        <Link
          href={`${globalWidgetLink}/offerings?participant_age_type=${type.id}`}
        >
          {type.name}
        </Link>
        <span className="options__separator">{addSeparator(i, arr)}</span>
      </span>
    ));
  };

  return <>{renderAgeTypes(ageTypes)}</>;
};

CourseAgeTypes.propTypes = {
  course: PropTypes.object,
};

CourseAgeTypes.defaultProps = {
  course: {},
};
