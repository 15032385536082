import React, { useEffect, useState } from 'react';
import { Button, Tabs, Loader, NothingFound } from '@components/shared';
import { SessionsItem } from './SessionsItem';
import {
  setActiveTab,
  createTab,
  getActiveTab,
  queryParam,
  classNames,
} from '@src/utils';
import { useApi } from '@src/hooks';

export const Sessions = ({
  offeringId,
  onRegisterClick,
  contentType,
  onTrialClick,
  isPrivate
}) => {
  const initTab = queryParam.get('activeTab');
  const [sessionsTabs, setSessionsTabs] = useState([
    createTab('Current', 'active', initTab ? initTab === 'active' : true),
    createTab('Past', 'past', initTab === 'past'),
  ]);

  const activeTab = getActiveTab(sessionsTabs);

  const getSessionsToShow = (sessions) =>
    sessions.slice(0, showSessionsCounter);

  useEffect(() => {
    setSessionstoShow(4);
  }, [activeTab.value]);

  const sessions = useApi(
    {
      url: `/courses/${offeringId}/sessions?status=${activeTab.value}`,
      dataPlaceholder: [],
    },
    [activeTab.value]
  );

  const isActive = activeTab.value === 'active';
  const [showSessionsCounter, setShowSessionsCounter] = useState(4);
  const [sessionsToShow, setSessionstoShow] = useState([]);

  useEffect(() => {
    const newSessions = isActive
      ? sessions.data
      : getSessionsToShow(sessions.data);
    setSessionstoShow(newSessions);
  }, [sessions.data, showSessionsCounter]);

  const canShowMore = sessions.data.length > sessionsToShow.length;

  const onShowMoreClick = (e) => {
    setShowSessionsCounter(showSessionsCounter + 4);
  };

  useEffect(() => {
    queryParam.set('activeTab', activeTab.value);

    return () => queryParam.delete('activeTab');
  }, [activeTab.value]);

  const getSessions = () =>
    sessionsToShow.length ? (
      sessionsToShow.map((session) => (
        <SessionsItem
          onRegisterClick={onRegisterClick}
          onTrialClick={onTrialClick}
          key={session.id}
          session={session}
          isActive={isActive}
          contentType={contentType}
          isPrivate={isPrivate}
        />
      ))
    ) : (
      <NothingFound
        title={
          isActive
            ? 'There are no any current sessions for this Offering now.'
            : 'There are no any past sessions for this Offering yet.'
        }
        subtitle={''}
      />
    );

  return (
    <div className='sessions'>
      <Tabs
        tabsData={sessionsTabs}
        onTabClick={(tab) => setActiveTab(setSessionsTabs, tab.value)}
        className="tabs--bordered"
      />

      <div className={classNames(['sessions__body'])}>
        {sessions.loading ? <Loader attached /> : getSessions()}
      </div>

      {canShowMore && !isActive && (
        <div className="sessions__actions">
          <Button onClick={onShowMoreClick}>Show more</Button>
        </div>
      )}
    </div>
  );
};
