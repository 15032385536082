import { globalWidgetLink } from '@src/constants';

export const normalizeProviderData = ({
  content_provider,
  global_content_provider,
} = {}) => {
  const {
    name,
    short_name,
    base_url,
    config,
    description,
    id,
    logo,
    payment_methods = [],
  } = content_provider || global_content_provider;

  const paymentMethods = {};

  payment_methods.forEach(({ key, data }) => (paymentMethods[key] = data));

  return global_content_provider
    ? {
        base_url: globalWidgetLink,
        name: 'World Education Web',
        short_name: 'World Education Web',
        paymentMethods,
        config: {},
        logo: '/img/wew-logo.svg'
      }
    : {
        base_url,
        short_name,
        description,
        id,
        logo,
        paymentMethods,
        config,
        name,
      };
};
