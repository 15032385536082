import { updateUserData } from '@actions/authActions';
import { useForm, useDeepEffect } from '@src/hooks';
import { getProfileSchema } from '@src/utils';
import { normalizeUserData } from '@src/normalizers';
import { PhoneListInput } from '@components/shared';
import { AccountProfileCard } from '../../Supports/AccountProfileCard';

export const ContactInformation = ({   
  userData, 
  editorState, 
  setEditorState, 
  dispatch
}) => {
  const { contact } = userData.data || {};
  const {
    home_phone,
    work_phone,
    cell_phone,
  } = contact || {};

  const submitHandler = (values) => {
    const userData = normalizeUserData(values, editorState);

    dispatch(updateUserData(userData));
  };

  useDeepEffect(() => {
    if (userData.data) {
      setValues(initialValues);
    }
  }, [userData]);

  const initialValues = {
    cellPhone: cell_phone,
    homePhone: home_phone,
    workPhone: work_phone,
  };

  const schema = getProfileSchema(editorState);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setErrors,
    clearValues,
    setValues,
    setCustomValue,
  } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });

  const contactBlockData = [
    {
      label: 'Cell Phone',
      inputType: PhoneListInput,
      inputName: 'cellPhone',
      placeholder: 'Enter Cell Phone',
      additionalProps: {
        onChange: (value) => setCustomValue('cellPhone', value),
      },
    },
    {
      label: 'Work Phone',
      inputType: PhoneListInput,
      inputName: 'workPhone',
      placeholder: 'Enter Work Phone',
      additionalProps: {
        onChange: (value) => setCustomValue('workPhone', value),
      },
    },
    {
      label: 'Home Phone',
      inputType: PhoneListInput,
      inputName: 'homePhone',
      placeholder: 'Enter Home Phone',
      additionalProps: {
        onChange: (value) => setCustomValue('homePhone', value),
      },
    },
  ];

   const profileBlocksData = [
    {
      title: 'Contact Information',
      data: contactBlockData,
      name: 'contact',
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      userData,
      editorState, 
      setEditorState,
      clearValues,
      setErrors,
    },
  ];

  return (
    <>
      {profileBlocksData.map((blockData) => AccountProfileCard(blockData))}
    </>
  );
};