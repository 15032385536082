import React from 'react';
import { Container } from '@components/layout';
import { Button, Icon } from '@components/shared';
import { useHistory } from 'react-router-dom';

const ServerErrorPage = () => {
	const history = useHistory();

	return (
		<Container>
			<div className="wrong-page">
				<div className="wrong-page__error-code">500</div>
				<div className="wrong-page__title">
					Ooops, something went wrong...
				</div>
				<div className="wrong-page__description">
					Keep calm and dance on!
					<br/>
					Try to reload the page or repeat the previous action.
					<br/>
					You still can <a href="/" className="wrong-page__link">Contact Support</a> if needed.
				</div>
				<Button onClick={() => history.push('/offerings')} type="primary">
					Back to Offerings
				</Button>
				<Icon type="server-error-page" className="icon--server-error" />
			</div>
		</Container>
	);
};

export default ServerErrorPage;