import * as types from '@types/facultyTypes';
import { queryParam } from '@src/utils';

const locationQuery = queryParam.get('location') || '';
const facultyQuery = queryParam.get('faculty') || '';
const categoryQuery = queryParam.get('category') || '';
const letterQuery = queryParam.get('letter') || '';
const programQuery = queryParam.get('program') || '';

const initLocation = locationQuery ? parseFloat(locationQuery) : '';
const initFaculty= facultyQuery ? parseFloat(facultyQuery) : '';
const initCategory = categoryQuery ? parseFloat(categoryQuery) : '';
const initProgram = programQuery ? parseFloat(programQuery) : '';
const initLetter = letterQuery ? letterQuery : 'all';

const initialState = {
  search: '',
  instructors: {
    loading: false,
    data: [],
    error: '',
    limit: 18,
    page: 1,
    total: 0,
  },

  filtersSelect: {
    location: initLocation,
    faculty: initFaculty,
    category: initCategory,
    letter: initLetter,
    program: initProgram,
  },

  instructorSessions: {
    data: [],
    loading: false,
    error: '',
  },
};



const facultyReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FACULTY_SEARCH:
      return {
        ...state,
        search: payload,
        filtersSelect: {
          ...state.filtersSelect,
          letter: 'all',
          faculty: '',
        },
      };

    case types.FACULTY_FILTER_SELECT_PROGRAM:
      return {
        ...state,
        filtersSelect: {
          ...state.filtersSelect,
          program: payload,
        },
      };

    case types.FACULTY_FILTER_SELECT_LOCATION:
      return {
        ...state,
        filtersSelect: {
          ...state.filtersSelect,
          location: payload,
        },
      };

    case types.FACULTY_FILTER_SELECT_CATEGORIES:
      return {
        ...state,
        filtersSelect: {
          ...state.filtersSelect,
          category: payload,
        },
      };

    case types.FACULTY_FILTER_SELECT_INSTRUCTORS:
      return {
        ...state,
        filtersSelect: {
          ...state.filtersSelect,
          faculty: payload,
          letter: 'all',
        },
        search: '',
      };

    case types.FACULTY_FILTER_SELECT_LETTER:
      return {
        ...state,
        filtersSelect: {
          ...state.filtersSelect,
          faculty: '',
          letter: payload,
        },
        search: '',
      };

    case types.FACULTY_INSTRUCTORS_PAGE:
      return {
        ...state,
        instructors: {
          ...state.instructors,
          page: payload,
        },
      };

    case types.FACULTY_INSTRUCTORS_LOADING:
      return {
        ...state,
        instructors: {
          ...state.instructors,
          loading: true,
          error: '',
        },
      };

    case types.FACULTY_INSTRUCTORS_ERROR:
      return {
        ...state,
        instructors: {
          ...state.instructors,
          loading: false,
          error: '',
        },
      };

    case types.FACULTY_INSTRUCTORS_MORE:
      return {
        ...state,
        instructors: {
          ...state.instructors,
          loading: false,
          data: [...state.instructors.data, ...payload.data],
          total: payload.meta.total,
          page: state.instructors.page + 1,
          error: '',
        },
      };

    case types.FACULTY_INSTRUCTORS:
      return {
        ...state,
        instructors: {
          ...state.instructors,
          loading: false,
          data: payload.data,
          total: payload.meta.total,
          page: 1,
          error: '',
        },
      };

    case types.FACULTY_FILTERS_CLEAR:
      return {
        ...state,
        filtersSelect: {
          location: '',
          faculty: '',
          category: '',
          letter: 'all',
          program: '',
        },
      };

    default: {
      return state;
    }
  }
};

export { facultyReducer };
