import * as types from '@types/programsTypes';
import { queryParam } from '@src/utils';

const contentTypeQuery = queryParam.getAll('content_type');
const facultyQuery = queryParam.get('faculty');
const isOnsiteQuery = queryParam.get('is_onsite');
const isOnlineQuery = queryParam.get('is_online');
const hasVideosQuery = queryParam.get('has_videos');
const locationQuery = queryParam.get('location');
const categoryQuery = queryParam.getAll('category');
const participantAgeTypeQuery = queryParam.getAll('participant_age_type');
const participantLevelQuery = queryParam.getAll('participant_level');
const participantAgeFromQuery = queryParam.getAll('participant_age_from');
const participantAgeToQuery = queryParam.getAll('participant_age_to');
const programQuery = queryParam.get('program');
const tabQuery = queryParam.get('tab');
const sortingByQuery = queryParam.get('sorting_by');
const sortingOrderQuery = queryParam.get('sorting_order');
const isPublicQuery = queryParam.get('isPublic');
const isPrivateQuery = queryParam.get('isPrivate');

const initContentType = contentTypeQuery
  ? contentTypeQuery.map((id) => parseFloat(id))
  : null;
const initFaculty = facultyQuery ? parseFloat(facultyQuery) : null;
const initIsOnsite = Boolean(isOnsiteQuery);
const initIsOnline = Boolean(isOnlineQuery);
const initHasVideos = Boolean(hasVideosQuery);
const initIsPublic = Boolean(isPublicQuery);
const initIsPrivate = Boolean(isPrivateQuery);
const initLocation = locationQuery ? parseFloat(locationQuery) : null;
const initCategory = categoryQuery
  ? categoryQuery.map((id) => parseFloat(id))
  : null;
const initParticipantAgeType = participantAgeTypeQuery
  ? participantAgeTypeQuery.map((id) => parseFloat(id))
  : null;
const initParticipantLevel = participantLevelQuery
  ? participantLevelQuery.map((id) => parseFloat(id))
  : null;
const initParticipantAgeFrom = participantAgeFromQuery
  ? parseFloat(participantAgeFromQuery)
  : 0;
const initParticipantAgeTo = participantAgeToQuery
  ? parseFloat(participantAgeToQuery)
  : 1440;
const initProgram = programQuery ? parseFloat(programQuery) : null;
const initTab = tabQuery || 'current';
const initSortingBy = sortingByQuery || 'upcoming_class';
const initSortingOrder = sortingOrderQuery || 'asc';



const initialState = {
  courses: {
    data: [],
    loading: false,
    error: '',
    limit: 18,
    page: 1,
    total: 0,
  },
  filters: {
    data: {},
    loading: false,
    error: '',
  },
  tab: initTab,
  sortingBy: initSortingBy,
  sortingOrder: initSortingOrder,
  filtersSelect: {
    content_type: initContentType,
    faculty: initFaculty,
    is_onsite: initIsOnsite,
    is_online: initIsOnline,
    location: initLocation,
    category: initCategory,
    participant_age_type: initParticipantAgeType,
    participant_level: initParticipantLevel,
    participant_age_from: initParticipantAgeFrom,
    participant_age_to: initParticipantAgeTo,
    program: initProgram,
    has_videos: initHasVideos,
    isPublic: initIsPublic,
    isPrivate: initIsPrivate
  },
};

const programsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.PROGRAMS_FILTERS_SELECT: {
      return {
        ...state,
        filtersSelect: payload,
      };
    }

    case types.PROGRAMS_FILTERS_LOADING:
      return {
        ...state,
        filters: {
          ...state.filters,
          loading: true,
          error: '',
        },
      };

    case types.PROGRAMS_FILTERS_ERROR:
      return {
        ...state,
        filters: {
          ...state.filters,
          loading: false,
          error: payload,
        },
      };

    case types.PROGRAMS_FILTERS:
      return {
        ...state,
        filters: {
          data: payload,
          loading: false,
          error: '',
        },
      };

    case types.PROGRAMS_TAB:
      return {
        ...state,
        tab: payload,
      };

    case types.PROGRAMS_SORTING_BY:
      return {
        ...state,
        sortingBy: payload,
      };

    case types.PROGRAMS_SORTING_ORDER:
      return {
        ...state,
        sortingOrder: payload,
      };

    case types.COURSES_LOADING:
      return {
        ...state,
        courses: {
          ...state.courses,
          loading: true,
          error: '',
        },
      };

    case types.COURSES_LOADING_MORE:
      return {
        ...state,
        courses: {
          ...state.courses,
          loading: true,
          error: '',
        },
      };

    case types.COURSES_ERROR:
      return {
        ...state,
        courses: {
          ...state.courses,
          loading: false,
          error: payload,
        },
      };

    case types.COURSES:
      return {
        ...state,
        courses: {
          ...state.courses,
          data: payload.data,
          total: payload.meta.total,
          page: 1,
          loading: false,
          error: '',
        },
      };

    case types.COURSES_MORE:
      return {
        ...state,
        courses: {
          ...state.courses,
          data: [...state.courses.data, ...payload.data],
          total: payload.meta.total,
          page: state.courses.page + 1,
          loading: false,
          error: '',
        },
      };

    default: {
      return state;
    }
  }
};

export { programsReducer };
