import React from 'react';
import { Button, Icon } from '../index';
import { format, addMonths, subMonths } from 'date-fns';

export const CalendarDropdownToolbar = ({
  selectedDay,
  setMonth,
  monthHandler,
  yearHandler,
  isAccountCalendar,
}) => {
  const nextMonth = addMonths(new Date(selectedDay), 1);
  const prevMonth = subMonths(new Date(selectedDay), 1);

  return (
    <div className="calendar-dropdown__toolbar">
      <div className="toolbar">
        <div className="toolbar__date-preview">
          <div className="toolbar__month-block">
            <Button
              onClick={() => setMonth(prevMonth)}
              className="toolbar__button-prev"
            >
              <Icon type="chevron" size="sm" />
            </Button>
            <Button
              onClick={monthHandler}
              className="toolbar__date-preview-month"
              iconRight={<Icon type="chevron" size="sm" />}
            >
              {format(selectedDay, 'MMMM')}
            </Button>
            <Button
              onClick={() => setMonth(nextMonth)}
              className="toolbar__button-next"
            >
              <Icon type="chevron" size="sm" />
            </Button>
          </div>
          <Button
            onClick={yearHandler}
            className="toolbar__date-preview-year"
            iconRight={<Icon type="chevron" size="sm" />}
          >
            {format(selectedDay, 'yyyy')}
          </Button>
        </div>

        <div className="toolbar__button-block"></div>
      </div>
    </div>
  );
};
