import { Input, Icon, Button, Counter, Tabs, Select, SortingOrderButton } from '@components/shared';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCourses,
  setProgramsTab,
  setProgramsSortingBy,
  setProgramsSortingOrder,
} from '@actions/programsActions';
import {
  debounce,
  queryParam,
  selectedFiltersCounter,
  setActiveTab,
  createTab,
} from '@src/utils';
import { useViewport, useDeepDidUpdate, useDeepEffect } from '@src/hooks';

export const ProgramsHeader = ({
  onFiltersMenuButtonClick,
  search,
  setSearch,
  onSortingMenuButtonClick,
}) => {
  const [selectedFiltersNumber, setSelectedFiltersNumber] = useState(null);
  const { isTablet } = useViewport();
  const filtersSelect = useSelector((state) => state.programs.filtersSelect);
  const sortingBy = useSelector((state) => state.programs.sortingBy);
  const sortingOrder = useSelector((state) => state.programs.sortingOrder);
  const tab = useSelector((state) => state.programs.tab);
  const coursesRequestData = { filtersSelect, tab, sortingOrder, sortingBy };
  const dispatch = useDispatch();

  const sortingOptions = [
    tab === "current" && { name: 'Date', value: 'upcoming_class' },
    { name: 'Title', value: 'title' },
    { name: 'Content Type', value: 'content_type' },
    { name: 'Content ID', value: 'classID' },
  ].filter(opt => opt);

  const requestSearchedPrograms = useCallback(
    debounce((coursesRequestData, search) => {
      dispatch(getCourses(coursesRequestData, search));
    }, 500),
    []
  );

  const [programsTabs, setProgramsTabs] = useState([
    createTab('Current', 'current'),
    createTab('Past', 'past'),
    createTab('Multimedia', 'multimedia'),
  ]);

  useEffect(() => {
    queryParam.set('sorting_order', sortingOrder);
  }, [sortingOrder]);

  useEffect(() => {
    queryParam.set('sorting_by', sortingBy);
  }, [sortingBy]);

  const fetchCourses = useCallback(
    debounce((coursesRequestData, search) => {
      dispatch(getCourses(coursesRequestData, search));
    }, 100),
    []
  );

  useDeepEffect(() => {
    fetchCourses(coursesRequestData, search);
  }, [coursesRequestData]);

  useEffect(() => {
    queryParam.set('tab', tab);
    setActiveTab(setProgramsTabs, tab);

    if (sortingBy === 'upcoming_class' && tab !== "current") {
      dispatch(setProgramsSortingBy('title'));
    }
  }, [tab]);


  useDeepDidUpdate(() => {
    const queryTimeout = setTimeout(() => {
      search ? queryParam.set('search', search) : queryParam.delete('search');
    }, 500);
    requestSearchedPrograms(coursesRequestData, search);
    return () => clearTimeout(queryTimeout);
  }, [search]);

  useEffect(() => {
    setSelectedFiltersNumber(selectedFiltersCounter(filtersSelect));
  }, [filtersSelect]);

  return (
    <div className="programs__header">
      <Tabs
        tabsData={programsTabs}
        onTabClick={(tab) => dispatch(setProgramsTab(tab.value))}
        className='tabs--bordered'
      />
      <div className="programs__actions">
        <Input
          className="programs__search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          icon={<Icon type="search" />}
        />
        {!isTablet && (
          <div className="programs__sorting">
            <Select
              className="programs__sorting-select"
              options={sortingOptions}
              optionsLabel="name"
              optionsKey="value"
              optionsValue="value"
              onChange={({ value }) => dispatch(setProgramsSortingBy(value))}
              value={sortingBy}
              valueLabel="Sort by:"
            />
            <SortingOrderButton
              className='programs__sorting-button'
              sortingOrder={sortingOrder}
              onClick={() =>
                dispatch(
                  setProgramsSortingOrder(
                    sortingOrder === 'asc' ? 'desc' : 'asc'
                  )
                )
              }
            />
          </div>
        )}
        {isTablet && (
          <>
            <Button
              type="transparent"
              iconRight={<Icon size="md" type="sorting-mini" />}
              className={`btn--sorting-menu btn--sorting-menu--${sortingOrder}`}
              onClick={onSortingMenuButtonClick}
            >
              {sortingOptions.find(({ value }) => value === sortingBy)?.name}
            </Button>
            <Button
              type="transparent"
              iconLeft={
                selectedFiltersNumber < 1 ? (
                  <Icon size="md" type="filters" />
                ) : (
                  <Counter number={selectedFiltersNumber} />
                )
              }
              className={'btn--filters-menu'}
              onClick={onFiltersMenuButtonClick}
            >
              Filters
            </Button>
          </>
        )}
      </div>
    </div>
  );
};