import React, { useEffect, useState } from 'react';
import { Button,  Input } from '@components/shared';
import { formatPrice } from '@src/utils';
import { ticketDateFormat, splitSign, twelveHoursFormat } from '@src/constants';
import { format, } from 'date-fns';
import { zonedDate, formatTimeDate } from '@src/utils';
import { TicketAttendeeInput } from './TicketAttendeeInput';

export const BuyingTicketsCard = ({ ticketData, values, setCustomValue }) => {
  const { price, schedules, type, id } = ticketData;
  const [attendees, setAttendees] = useState([]);
  const quantity = values.quantity[id] || 0;

  const getTicketSchedule = ({ date, start_time, end_time }, isLast) => {
    const formattedDate = format(zonedDate(date), ticketDateFormat);
    const formattedStart = formatTimeDate(
      start_time,
      splitSign,
      twelveHoursFormat
    ).toLowerCase();
    const formattedEnd = formatTimeDate(
      end_time,
      splitSign,
      twelveHoursFormat
    ).toLowerCase();

    return (
      <span
        key={date + start_time + end_time}
      >{`${formattedDate} ${formattedStart} - ${formattedEnd}${
        isLast ? '' : ' | '
      }`}</span>
    );
  };

  useEffect(() => {
    const names = attendees.map(({value}) => value).filter(name => name);
    setCustomValue('attendees', { ...values.attendees, [id]: names });
  }, [attendees, quantity]);

  useEffect(() => {
    createAttendeesInputs();
  }, [quantity]);

  const renderTicketSchedules = () => {
    return schedules.map((schedule, i, arr) => {
      const isLast = arr.length === i + 1;
      return getTicketSchedule(schedule, isLast);
    });
  };

  const changeQuantity = (newValue) => {
    setCustomValue('quantity', { ...values.quantity, [id]: newValue });
  };

  const formattedTotalPrice = parseFloat(price)
    ? `$${formatPrice(quantity * price)}`
    : 'Free';
  const formattedPrice = parseFloat(price) ? `$${price}` : 'Free';

  const createAttendeesInputs = () => {
    let i = 0;
    const attendeesInputs = [];
    while (i < quantity) {
      ++i;
      const attendeeInput = {
        value: attendees[i - 1]?.value || '',
        id: i,
      };
      attendeesInputs.push(attendeeInput);
    }

    setAttendees(attendeesInputs);
  };

  return (
    <div className="buying-tickets-card">
      <div className="buying-tickets-card__counterBlock">
        <div className="buying-tickets-card__counter">
          <Button
            type="transparent"
            disabled={quantity === 0}
            onClick={() => changeQuantity(quantity - 1)}
          >
            -
          </Button>
          <Input
            value={`${quantity}`}
            onChange={(e) => changeQuantity(+e.target.value)}
            maxLength="2"
          />
          <Button
            type="transparent"
            disabled={quantity === 99}
            onClick={() => changeQuantity(quantity + 1)}
          >
            +
          </Button>
        </div>
        <div className="buying-tickets-card__total">{formattedTotalPrice}</div>
      </div>

      <div className="buying-tickets-card__body">
        <div className="buying-tickets-card__left-block">
          <div className="buying-tickets-card__price">{formattedPrice}</div>
          {/* <div className="buying-tickets-card__type">General</div> */}
        </div>
        <div className="buying-tickets-card__right-block">
          <div className="buying-tickets-card__date">
            {renderTicketSchedules()}
          </div>
          <div className="buying-tickets-card__title">{type}</div>
          <div className="buying-tickets-card__attendees">
            {attendees.map(({ id }) => (
              <TicketAttendeeInput
                attendeeId={id}
                attendees={attendees}
                setAttendees={setAttendees}
                changeQuantity={changeQuantity}
                quantity={quantity}
                key={id}
              />
            ))}
            <Button
              onClick={() => changeQuantity(quantity + 1)}
              className="buying-tickets-card__add"
            >
              + Add the attendee
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
