import React from 'react';
import Masonry from '@mui/lab/Masonry';
import { useViewport } from '@src/hooks';
import { Image, getModal, openModal } from '@components/shared';
import { classNames } from '@src/utils';

export const PhotoGallery = ({ gallery }) => {
  const { isMobile } = useViewport();

  const onPhotoClick = (photo) => {
    openModal(
      getModal({
        name: 'photo-preview',
        data: { photo },
        nativeClose: false,
      })
    );
  };

  return (
    <div className="photo-gallery">
      <div className="container">
        <Masonry
          className="photo-gallery__body"
          columns={isMobile ? 2 : 3}
          spacing={isMobile ? 1 : 2}
        >
          {gallery.map((photo) => (
            <div
              className={classNames([
                'photo-gallery__item',
                !isMobile && 'photo-gallery__item--desktop',
              ])}
              onClick={() => onPhotoClick(photo)}
              key={photo}
            >
              <Image src={photo} alt="multimedia-item" />
            </div>
          ))}
        </Masonry>
      </div>
    </div>
  );
};
