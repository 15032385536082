import React, { useEffect, useRef, useState } from 'react';
import { Loader } from '@components/shared';

export const Image = ({ src, alt, height, className, loaderSize }) => {
  const image = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (image.current && image.current.complete) {
      onLoad();
    }
  });

  const onLoad = () => {
    setIsLoading(false);
  };

  return (
    <>
      <img
        className={className}
        loading="lazy"
        src={src}
        alt={alt}
        ref={image}
        onLoad={onLoad}
        height={height}
      />
      <Loader show={isLoading} attached size={loaderSize} />
    </>
  );
};
