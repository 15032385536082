import { queryParam } from '@src/utils';

export const GET_APPOINTMENTS_URL = (
  { tab, date, search, sort_by, sort, page }
) =>
  `/account/appointments${queryParam.generateQuery([
    {
      label: 'tab',
      value: tab,
    },
    {
      label: 'date',
      value: date,
    },
    {
      label: 'sort_by',
      value: sort_by,
    },
    {
      label: 'sort',
      value: sort,
    },
    {
      label: 'search',
      value: search,
    },
    {
        label: 'page',
        value: page,
      },
  ])}`;
