import React from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

export const ProviderContactMap = ({ locationsList = [] }) => {
  const locationsCoords = locationsList.map(({ latitude, longitude }) => [
    latitude,
    longitude,
  ]);

  const FitBounds = ({ locationsCoords }) => {
    const map = useMap();
    locationsCoords.length &&
      map.fitBounds(locationsCoords, { paddingTopLeft: [-50, 50] });
    return null;
  };

  return (
    <div className="contact-map">
      <div className="contact-map__body">
        {!!locationsList.length && (
          <MapContainer
            center={[42.3491385, -71.1159389]}
            zoom={30}
            scrollWheelZoom={true}
          >
            <FitBounds locationsCoords={locationsCoords} />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {locationsList.map(({ title, latitude, longitude }) => {
              return (
                <Marker
                  position={[latitude, longitude]}
                  icon={L.divIcon({
                    className: 'contact-map__marker',
                    html: `<div>${title}</div>`,
                  })}
                  key={latitude}
                ></Marker>
              );
            })}
          </MapContainer>
        )}
      </div>
    </div>
  );
};
