import { useEffect, useState } from 'react';
import { scrollToFirstError } from '@src/utils';
import _ from 'lodash';

export const useErrors = () => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!_.isEmpty(errors)) {
      scrollToFirstError();
    }
  }, [errors]);

  return {
    errors,
    setErrors,
  };
};