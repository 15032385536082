import React from 'react';
import { ContactForm } from '@components/shared';

export const GlobalContactForm = () => {
  const formData = {
    title: `<span>Do you have questions?<br/>We’re happy to answer!</span>`,
    image: 'https://www.bccaonline.com/images/Dan_Marshall_Candid_Head_Shot_Web.jpg',
    apiUrl: '/global/pages/contact/form-submit',
  };
  
  return <ContactForm isGlobalWidget={true} formData={formData}/>;
};
