import { store } from '@src/store';

const { dispatch } = store;

export const onLoading = (type, payload) => {
  dispatch({ type, payload });
};

export const onSuccess = (type, payload) => {
  dispatch({ type, payload });
};

export const onError = (type, payload) => {
  dispatch({ type, payload });
};

export const getAction = (type, payload) => ({ type, payload });