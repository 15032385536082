import React from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '@src/hooks';
import { Container } from '@components/layout';
import { Loader, Link, Icon, SEO } from '@components/shared';
import { InstructorDetailsContent } from './InstructorDetailsContent';
import { globalWidgetLink } from '@src/constants';

const InstructorDetails = () => {
  const referrerPage = document.referrer;
  const isReferrerPage =
    referrerPage.includes('account') ||
    referrerPage.includes('schedule') ||
    referrerPage.includes('faculty') ||
    referrerPage.includes('offerings');

  const { instructorId } = useParams();

  const instructorInfo = useApi({ url: `/instructors/${instructorId}` });

  const {display_name, faculty} = instructorInfo.data || {}

  const defineReferrerPageType = () => {
    if (referrerPage.includes('account')) return 'Account';
    if (referrerPage.includes('schedule')) return 'Schedule';
    if (referrerPage.includes('faculty')) return 'Faculty';
    else return 'Offerings';
  };

  const pageLink = () => {
    if (isReferrerPage) {
      return referrerPage;
    } else {
      return `${window.location.origin}/offerings`;
    }
  };

  return (
    <>
      <Loader show={instructorInfo.loading} attached />

      {instructorInfo.data && (
        <div className="instructor-details">
          <SEO
            title={`World Education Web | ${display_name}`}
            description={`Explore the profile of ${display_name}. Learn about background, expertise, and passion for work. Contact information and biography provided for further inquiries.`}
            canonicalURL={`${globalWidgetLink}/faculty/${instructorId}`}
            image={faculty.profile_image_url?.path || faculty.user_image?.path}
            type={'profile'}
          />
          <Container>
            <div className="instructor-details__block breadcrumbs-block instructor-details__breadcrumbs">
              <Link href={pageLink()}>{defineReferrerPageType()}</Link>

              <Icon type="chevron" size="xs" />

              <span>Details</span>
            </div>
            <InstructorDetailsContent
              data={instructorInfo.data}
              id={instructorId}
            />
          </Container>
        </div>
      )}
    </>
  );
};

export default InstructorDetails;