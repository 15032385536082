import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@components/shared';
import { globalWidgetLink } from '@src/constants';

export const CourseCategory = ({ course }) => {
  const { categories } = course;

  const renderCategories = (categories) => {
    return categories.map((category, i, arr) => (
      <span key={category.id} style={{ display: 'inline-flex' }}>
        <Link href={`${globalWidgetLink}/offerings?category=${category.id}`}>
          {category.name}
        </Link>
        {i !== arr.length - 1 && <>&nbsp;|&nbsp;</>}
      </span>
    ));
  };

  return <>{course && renderCategories(categories)}</>;
};

CourseCategory.propTypes = {
  course: PropTypes.object,
};

CourseCategory.defaultProps = {
  course: {},
};
