import React from 'react';
import { Container } from '@components/layout';
import {
  GlobalContactTitleBlock,
  GlobalContactForm,
  GlobalContactMembers,
  // GlobalContactFounders,
} from '@components/global/Contact';
import { SEO } from '@components/shared';
import { globalWidgetLink } from '@src/constants';

const GlobalContact = () => {
  return (
    <div className="global-page contact-page global-contact">
      <SEO
        title={`World Education Web | Contact`}
        description={`Find contact information for World Education Web, including mail address and contact persons. Get in touch with our team to learn more about our work.`}
        canonicalURL={`${globalWidgetLink}/contact`}
        image={'/img/wew-logo.svg'}
      />
      <Container>
        <h1 className="page-title">Contact Us</h1>
        <div className="contact-page__body global-contact__body">
          <GlobalContactTitleBlock />
          <GlobalContactMembers />
          {/* <GlobalContactFounders /> */}
          <GlobalContactForm />
        </div>
      </Container>
    </div>
  );
};

export default GlobalContact;
