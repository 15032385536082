import { useEffect } from 'react';
import { useQuery } from '@src/hooks';
import { openModalFromQuery } from './queryActions';

export const GlobalQueryChecker = () => {
  const query = useQuery();

  useEffect(() => {
    openModalFromQuery(query);
  }, []);

  return null;
};