import { queryParam } from '@src/utils';

export const matomoOfferingHandler = (id) => {
  window._mtm = window._mtm || [];
  window._mtm.push({
    event: 'view_item',
    ecommerce: {
      view_item: {
        id: `${id}`,
        price: 'price value',
      },
    },
  });
};

export const matomoInstructorHandler = (instructors) => {
  const instructorId = queryParam.get('faculty');
  if (instructorId) {
    const instructor = instructors.find(
      ({ id }) => id === Number(instructorId)
    );
    const instructorName = instructor.display_name;
    window._mtm = window._mtm || [];
    window._mtm.push({ event: 'search_main', query: `${instructorName}` });
  }
};

export const matomoSearchHandler = () => {
  const search = queryParam.get('search');
  if (search) {
    window._mtm = window._mtm || [];
    window._mtm.push({
      event: 'search_main',
      query: search,
    });
  }
};

export const matomoRegistrationHandler = (regType, id, title, offering) => {
  window._mtm = window._mtm || [];

  if (regType === 'v') {
    window._mtm.push({ event: 'ptw_popup', video_popup: `${title}` });
  } else {
    const categoriesString = offering.categories
      .map(({ name }) => name)
      .join(', ');

    window._mtm.push({
      event: 'add_to_cart',
      ecommerce: {
        add_to_cart: {
          id: `${id}`, //SKU
          item_name: `${title}`, //Product name
          item_category: `${categoriesString}`, //Product category
          price: 'dynamic value', // product price
        },
      },
    });
  }
};
