import { useState } from 'react';
import { format, getDay } from 'date-fns';
import { useViewport } from '@src/hooks';
import { getOptions, getAgeRange, classNames } from '@src/utils';
import {
  fullWeekDays,
  dateSessionsFormat,
  twelveHoursFormat,
} from '@src/constants';
import { ProgramsCourseSessions } from '@components/features/Programs/ProgramsCourseSessions';
import {
  Button,
  CourseBadges,
  Icon,
  InstructorName,
  Link,
  Image,
} from '@components/shared';
import { ProviderLogo } from '@components/provider';

export const ProgramsCourseCard = ({
  course,
  className,
  showScheduleBlock,
}) => {
  const { isMobile } = useViewport();
  const {
    label,
    title,
    participant_age_types = [],
    participant_age_from,
    participant_age_to,
    participant_levels = [],
    short_description,
    sessions = [],
    content_type,
    participant_level_exception,
    participant_age_exception,
    upcoming_class,
    profile_image_url,
    content_provider,
    is_private_rental,
    is_disabled,
  } = course;
  const { logo, base_url } = content_provider;
  const [isSessionsShown, setIsSessionsShown] = useState(false);

  const getFormattedUpcommingDate = (date) => {
    const formattedDate = new Date(date);
    const upcommingDate = format(formattedDate, dateSessionsFormat);
    const upcommingWeekday = fullWeekDays[getDay(formattedDate)];
    const upcommingTime = format(formattedDate, twelveHoursFormat);

    return (
      <span>
        {upcommingDate}, <b>{upcommingWeekday}</b>{' '}
        <span style={{ textTransform: 'lowercase' }}>{upcommingTime}</span> EST
      </span>
    );
  };

  const levels = getOptions(participant_levels);
  const ageTypes = getOptions(participant_age_types);
  const ageRange = getAgeRange(
    participant_age_from,
    participant_age_to,
    participant_age_types
  );

  const offeringLink = `${base_url}/offerings/${course.id}`;

  return (
    <div
      className={classNames([
        'programs-course',
        profile_image_url ? 'programs-course--full' : 'programs-course--empty',
        className,
      ])}
    >
      <div
        className={classNames([
          'programs-course__visual',
          (is_private_rental || is_disabled) &&
            'programs-course__visual--blurred',
        ])}
      >
        {logo && (
          <ProviderLogo
            className="programs-course__logo"
            src={logo}
            logoUrl={base_url}
          />
        )}

        <div className="programs-course__visual-body">
          {profile_image_url && (
            <Image src={profile_image_url} alt="offering" />
          )}

          {is_private_rental && !is_disabled && (
            <div className="programs-course__visual-note">
              <span>Private</span>
            </div>
          )}

          {is_disabled && (
            <div className="programs-course__visual-note">
              <span>Booked Slot</span>
            </div>
          )}
        </div>
      </div>

      <div className="programs-course__content">
        <div className="programs-course__content-body">
          <div className="programs-course__content-top">
            <span className="programs-course__course-id">{label}</span>

            <div className="programs-course__title-block">
              <h3>
                <Link
                  href={offeringLink}
                  iconRight={<Icon size="full" type="proceed-next" />}
                >
                  {is_disabled ? 'Booked Slot' : title}
                </Link>
              </h3>
            </div>

            <CourseBadges course={course} />

            {!is_disabled && short_description && (
              <p className="programs-course__course-description">
                {short_description}
              </p>
            )}

            <InstructorName
              course={course}
              className="programs-course__instructors"
              hasIcon
              isInline
            />

            {(levels || ageTypes || ageRange) && (
              <div className="programs-course__requirements">
                <span>
                  {levels}
                  <sup>{levels && participant_level_exception}</sup>
                </span>

                {levels && (ageTypes || ageRange) && ' / '}

                <span>
                  {ageTypes}
                  {ageRange}
                  <sup>{ageTypes && participant_age_exception}</sup>
                </span>
              </div>
            )}
          </div>

          {!!sessions.length && (
            <div className="programs-course__content-bottom">
              {upcoming_class && (
                <div className="programs-course__upcomming-date">
                  <span className="programs-course__upcomming-title">
                    Upcoming {content_type.singular_short_name}:{' '}
                  </span>

                  {getFormattedUpcommingDate(upcoming_class)}
                </div>
              )}

              {showScheduleBlock && (
                <Button
                  className={classNames([
                    'programs-course__schedule-button',
                    isSessionsShown &&
                      'programs-course__schedule-button--active',
                  ])}
                  iconRight={<Icon type="chevron" size="md" />}
                  onClick={() => setIsSessionsShown(!isSessionsShown)}
                >
                  {!isMobile &&
                    className !== 'programs-course--about' &&
                    'Schedule'}
                </Button>
              )}
            </div>
          )}
        </div>

        {showScheduleBlock && isSessionsShown && (
          <div className="programs-course__sessions">
            <div className="programs-course__sessions-title">
              Schedule / Sessions:
            </div>

            {sessions.map((session) => (
              <ProgramsCourseSessions data={session} key={session.id} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
