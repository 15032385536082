import { useRef } from 'react';
import { Button, Icon } from '../index';
import { useViewport } from '@src/hooks';

export const Modal = ({
  show,
  headerContent,
  changeState,
  children,
  className,
  nativeClose = true,
}) => {
  const { isMobile } = useViewport();
  const closeModal = (e, isCloseAllModals) => {
    if (nativeClose) {
      e.preventDefault();
      e.stopPropagation();

      changeState(isCloseAllModals);
    }
  };

  const modalWindow = useRef(null);

  if (!show) return null;

  return (
    <div className={`modal${className ? ` ${className}` : ''}`}>
      <div className="modal__overlay" onClick={(e) => closeModal(e, true)} />
      <div className="modal__window" ref={modalWindow}>
        {nativeClose && (
          <Button
            className="btn btn--navigation modal__close-btn"
            onClick={(e) => closeModal(e, true)}
          >
            {isMobile ? <Icon type="close-cross" /> : 'Close'}
          </Button>
        )}
        <div className="modal__header">{headerContent}</div>
        <div className="modal__body">{children}</div>
      </div>
    </div>
  );
};

Modal.defaultProps = {
  nativeClose: true,
  onClose: () => {},
};
