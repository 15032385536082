import {applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

let rawMiddlewares = [thunk];

if (process.env.NODE_ENV !== 'production') {
  rawMiddlewares = [...rawMiddlewares];
}

const middlewares = composeWithDevTools(applyMiddleware(...rawMiddlewares));

export { middlewares };