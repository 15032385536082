import React from 'react';
import { Button, getModal, openModal } from '@components/shared';
import {
  SnailSubcription,
  EmailSubcription,
  MediaWaiver,
} from './InstitutionsCards';

export const AccountInstitutionsSettingsItem = ({
  editorState,
  setEditorState,
  institution,
  setAddressError,
  updateProviderAffiliation,
}) => {
  const openPolicy = () => {
    openModal(
      getModal({
        name: 'policy-notice',
        data: {
          isModal: true,
          isGlobal: institution.isGlobal,
          policyContent: institution.content_provider?.config?.terms,
        },
      })
    );
  };

  return (
    <div className="institutions-item">
      <h3 className="institutions-item__title">
        {institution.content_provider.name}
      </h3>
      <div className="institutions-item__body">
        <SnailSubcription
          institution={institution}
          editorState={editorState}
          setEditorState={setEditorState}
          setAddressError={setAddressError}
          updateProviderAffiliation={updateProviderAffiliation}
        />
        <EmailSubcription
          institution={institution}
          editorState={editorState}
          setEditorState={setEditorState}
          updateProviderAffiliation={updateProviderAffiliation}
        />
        <MediaWaiver
          institution={institution}
          editorState={editorState}
          setEditorState={setEditorState}
          updateProviderAffiliation={updateProviderAffiliation}
        />
        <Button
          className="institutions-item__policy-button"
          type="link"
          onClick={() => openPolicy()}
        >
          Terms & Conditions and Privacy Policy
        </Button>
      </div>
    </div>
  );
};
