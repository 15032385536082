import { apiRequest, requestReduxHandler } from '@src/utils';
import {
  getAction,
  onError,
  onLoading,
  onSuccess,
} from '@src/store/actionCreators';
import {
  GET_FACULTY_INSTRUCTORS,
} from '@src/endpoints';
import * as types from '@types/facultyTypes';

export const getFacultyInstructors = (
  page,
  filters,
  search,
  lazy = false
) => () => {
  apiRequest({
    url: GET_FACULTY_INSTRUCTORS(lazy ? page + 1 : 1, filters, search),
    method: 'GET',
    reduxHandlers: requestReduxHandler(
      (res) =>
        onSuccess(
          lazy ? types.FACULTY_INSTRUCTORS_MORE : types.FACULTY_INSTRUCTORS,
          res
        ),
      () => onLoading(types.FACULTY_INSTRUCTORS_LOADING),
      (err) => onError(types.FACULTY_INSTRUCTORS_ERROR, err)
    ),
  });
};

export const setFacultySearch = (search) =>
  getAction(types.FACULTY_SEARCH, search);

export const setFacultyLocationFilter = (locationFilter) =>
  getAction(types.FACULTY_FILTER_SELECT_LOCATION, locationFilter.id);
export const setFacultyCategoryFilter = (categoryFilter) =>
  getAction(types.FACULTY_FILTER_SELECT_CATEGORIES, categoryFilter.id);
export const setFacultyInstructorFilter = (facultyFilter) =>
  getAction(types.FACULTY_FILTER_SELECT_INSTRUCTORS, facultyFilter.id);
export const setFacultyLetterFilter = (letterFilter) =>
  getAction(types.FACULTY_FILTER_SELECT_LETTER, letterFilter);
export const setFacultyProgramFilter = (programFilter) =>
  getAction(types.FACULTY_FILTER_SELECT_PROGRAM, programFilter.id);

export const setFacultyInstructorsPage = (page) =>
  getAction(types.FACULTY_INSTRUCTORS_PAGE, page);

export const clearFacultyFilters = () => getAction(types.FACULTY_FILTERS_CLEAR);
