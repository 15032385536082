import React, { useState, useEffect } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MonthPicker } from '@mui/x-date-pickers';
import { YearPicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import {
  StaticCalendar,
  CalendarDropdownToolbar,
  CalendarDropdownDatePreview,
} from '@components/shared';
import { classNames } from '@src/utils';

export const CalendarDropdown = ({
  dayDate,
  selectedDay,
  setSelectedDay,
  isAccountCalendar,
  isScheduleDayCalendar,
  isScheduleWeekCalendar,
  pastEvents,
  futureEvents,
}) => {
  const currentYear = new Date().getFullYear();
  const maxDateYear = currentYear + 5;
  const minDate = new Date('2000-01-01T00:00:00.000');
  const maxDate = new Date(`${maxDateYear}-01-01T00:00:00.000`);
  const [isShown, setIsShown] = useState(isAccountCalendar);
  const initView = isAccountCalendar && 'day';
  const [view, setView] = useState(initView);
  const isDatePicker = view === 'day';
  const isMonthPicker = view === 'month';
  const isYearPicker = view === 'year';

  const windowCalendarPickerHandler = (e) => {
    if (
      !e.target.closest('.calendar-dropdown__date-preview') &&
      !e.target.closest('.calendar-dropdown__dropdown') &&
      !e.target.closest('.calendar-dropdown__month-picker') &&
      !e.target.closest('.calendar-dropdown__year-picker')
    ) {
      setView(null);
    }
  };

  useEffect(() => {
    if (!isAccountCalendar) {
      window.addEventListener('click', windowCalendarPickerHandler);
    }

    return () =>
      window.removeEventListener('click', windowCalendarPickerHandler);
  });

  useEffect(() => {
    setIsShown(!!view);
  }, [view]);

  return (
    <div className="calendar-dropdown">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {!isAccountCalendar && (
          <CalendarDropdownDatePreview
            setMonth={(day) => setSelectedDay(day)}
            selectedDay={selectedDay}
            calendarHandler={() => setView('day')}
            isAccountCalendar={isAccountCalendar}
            isScheduleDayCalendar={isScheduleDayCalendar}
            isScheduleWeekCalendar={isScheduleWeekCalendar}
            dayDate={dayDate}
          />
        )}
        {isShown && (
          <div className="calendar-dropdown__dropdown">
            <div
              className={classNames([
                'calendar-dropdown__toolbar-holder',
                `calendar-dropdown__toolbar-holder--${view}`,
              ])}
            >
              <CalendarDropdownToolbar
                setMonth={(day) => {
                  setSelectedDay(day);
                }}
                selectedDay={selectedDay}
                monthHandler={() => setView(isMonthPicker ? 'day' : 'month')}
                yearHandler={() => setView(isYearPicker ? 'day' : 'year')}
                isAccountCalendar={isAccountCalendar}
              />
            </div>
            <div className="calendar-dropdown__picker-holder">
              {isDatePicker && (
                <div className="calendar-dropdown__date-picker">
                  <StaticCalendar
                    selectedDay={selectedDay}
                    setSelectedDay={(day) => {
                      setSelectedDay(day);
                      !isAccountCalendar && setView(null);
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    pastEvents={pastEvents}
                    futureEvents={futureEvents}
                    isScheduleWeekCalendar={isScheduleWeekCalendar}
                    isAccountCalendar={isAccountCalendar}
                  />
                </div>
              )}

              {isMonthPicker && (
                <div className="calendar-dropdown__month-picker">
                  <MonthPicker
                    date={selectedDay}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(day) => {
                      setSelectedDay(day);
                      setView('day');
                    }}
                  />
                </div>
              )}

              {isYearPicker && (
                <div className="calendar-dropdown__year-picker">
                  <YearPicker
                    date={selectedDay}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(day) => {
                      setSelectedDay(day);
                      setView('day');
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </LocalizationProvider>
    </div>
  );
};

CalendarDropdown.propTypes = {
  slots: PropTypes.array,
  onMonthChange: PropTypes.func,
  yearDiff: PropTypes.number,
  minDateYear: PropTypes.number,
  maxDateYear: PropTypes.number,
};

CalendarDropdown.defaultProps = {
  slots: [],
  onMonthChange: () => {},
};
