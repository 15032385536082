import React, { useEffect } from 'react';
import { format, startOfDay } from 'date-fns';
import { dateFormatRequest } from '@src/constants';
import { queryParam } from '@src/utils';
import { DailyTable } from './DailyTable';
import { useDispatch, useSelector } from 'react-redux';
import { getDayTable, setDayDate } from '@actions/scheduleActions';
import { Loader, CalendarDropdown } from '@components/shared';
import { ScheduleError } from '../ScheduleError';
import { NavigationPanel } from '../Navigation';

export const Day = () => {
  const today = startOfDay(new Date());
  const dayDate = useSelector((state) => state.schedule.dayDate);
  const dayTable = useSelector((state) => state.schedule.dayTable);
  const filtersSelect = useSelector((state) => state.schedule.filtersSelect);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDayTable(dayDate, filtersSelect));
    queryParam.set('dayDate', format(dayDate, dateFormatRequest));
  }, [dayDate, filtersSelect]);

  return (
    <>
      <NavigationPanel
        className="schedule-table__navigation__wrapper--daily"
        pagination={
          <CalendarDropdown
            today={today}
            selectedDay={dayDate}
            setSelectedDay={(day) => dispatch(setDayDate(day))}
            isScheduleDayCalendar
            dayDate={dayDate}
          />
        }
      />
      <Loader show={dayTable.loading} global />
      {dayTable.error ? (
        <ScheduleError />
      ) : (
        dayTable.data && <DailyTable data={dayTable.data} />
      )}
    </>
  );
};
