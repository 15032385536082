import React from 'react';
import PropTypes from 'prop-types';

export const Counter = ({number}) => {

  return (
    <div className="counter">{number}</div>
  );
};

Counter.propTypes = {
    number: PropTypes.number
}

Counter.defaultProps = {
    number: null,
  };