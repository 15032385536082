import { format } from 'date-fns';
import { splitSign, timeFormatView } from '@src/constants';

export const formatTime = dateToFormat => {
  const minutes = dateToFormat.split(splitSign)[1];
  const hours = dateToFormat.split(splitSign)[0];
  let date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  const formattedTime = format(date, timeFormatView)
    .split('.')
    .join('');
  return formattedTime;
};

export const convertColorRgba = (colorString, opacity) => {
  return `rgba(${colorString.split(', ')[0] * 1}, ${colorString.split(', ')[1] *
    1}, ${colorString.split(', ')[2] * 1}, ${opacity})`;
};

export const createLighterColor = (RGBA, bg = { r: 255, g: 255, b: 255 }) => {
  const oldAlpha = 0.3;
  const alpha = 1 - oldAlpha;
  const RGB = {};

  const returnColor = colorType =>
    Math.round(
      (oldAlpha * (RGBA[colorType] / 255) + alpha * (bg[colorType] / 255)) * 255
    );

  RGB.r = returnColor('r');
  RGB.g = returnColor('g');
  RGB.b = returnColor('b');

  return `rgb(${RGB.r}, ${RGB.g}, ${RGB.b})`;
};

export const convertRgbStringToObject = (string = '') => {
  const arr = string.split(',');

  return {
    r: arr[0],
    g: arr[1],
    b: arr[2],
  };
};
