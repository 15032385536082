import { dateFormatRequest } from '@src/constants';
import { format } from 'date-fns';
import { getPhoneRequestFormat } from '@src/utils';

export const normalizeUserData = (values, formName, initialDateOfBirth) => {
  const {
    firstName,
    lastName,
    middleName,
    birthDate,
    oldPassword,
    password,
    confirmPassword,
    healthConcerns,
    emergencyContactName,
    emergencyContactPhone,
    parentName,
    parentPhone,
    homePhone,
    workPhone,
    cellPhone,
    country,
    address,
    state,
    city,
    zip,
    emailSubscription,
    snailSubscription,
    mediaWaiver,
    underAge,
    agreePolicy,
    tookClassesBefore
  } = values;

  const normalizeSchema = {
    personalInfo: {
      contact: {
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        date_of_birth:
          birthDate && format(new Date(birthDate), dateFormatRequest),
      },
    },
    contact: {
      contact: {
        cell_phone: getPhoneRequestFormat(cellPhone),
        home_phone: getPhoneRequestFormat(homePhone),
        work_phone: getPhoneRequestFormat(workPhone),
      },
    },
    changePassword: {
      user: {
        password,
        password_confirmation: confirmPassword,
        old_password: oldPassword,
      },
    },
    snailMailAddress: {
      contact: {
        address: {
          country_id: country || '',
          address_1: address,
          state_id: state?.id || '',
          city,
          zip,
        },
      },
    },
    snailMailSubscription: {
      student: {
        receive_snail_mail: snailSubscription,
      },
    },
    emailSubscription: {
      student: {
        receive_email_newsletters: emailSubscription,
      },
    },
    parentInfo: {
      student: {
        guardian_contact_name: parentName,
        guardian_contact_phone: getPhoneRequestFormat(parentPhone),
        under_eighteen: underAge,
      },
    },
    emergencyContacts: {
      student: {
        emergency_contact_name: emergencyContactName,
        emergency_contact_phone: getPhoneRequestFormat(emergencyContactPhone),
        health_concerns: healthConcerns,
      },
    },
    mediaWaiver: {
      student: {
        is_media_consent_and_waiver_agreed: mediaWaiver,
      },
    },
    affiliation: {
      student: {
        is_media_consent_and_waiver_agreed: mediaWaiver,
        receive_email_newsletters: emailSubscription,
        receive_snail_mail: snailSubscription,
        is_terms_and_conditions_agreed: agreePolicy,
      },
    },
    providerAffiliation: {
      is_media_consent_and_waiver_agreed: mediaWaiver,
      receive_email_newsletters: emailSubscription,
      receive_snail_mail: snailSubscription,
      is_terms_and_conditions_agreed: agreePolicy,
      took_classes_before: tookClassesBefore,
    },
  };

  if (initialDateOfBirth) {
    delete normalizeSchema.personalInfo.contact.date_of_birth;
  }

  if (
    (formName === 'affiliation' || formName === 'providerAffiliation') &&
    snailSubscription
  ) {
      normalizeSchema[formName].contact = {
      address: {
        country_id: country || '',
        address_1: address,
        state_id: state?.id || '',
        city,
        zip,
      },
    };
  }
 
  return normalizeSchema[formName];
};
