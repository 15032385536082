import { useEffect, useRef } from 'react';

export const useDidUpdate = (callback, dependencies) => {
  const componentMounted = useRef(null);

  useEffect(() => {
    if (componentMounted.current) {
      callback();
    } else {
      componentMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
